export const getCompetitorsSteps = () => [
  {
    content: (
      <>
        Here are a list of the competitors you added
        <br />
        when you created your account.
        <br />
        You can use these buttons to control which
        <br />
        competitors are displaying in the graph.
        <br />
        (Don’t worry, you can edit or add more
        <br />
        competitors using the Edit button in the top
        <br />
        right hand corner of the screen).
      </>
    ),
    target: '.companies__container',
    placement: 'right',
    offset: 0,
    disableBeacon: true,
    disabledPrevButton: true,
  },
  {
    content: (
      <>
        This graph shows you your website content
        <br />
        volume over time in comparison to your
        <br />
        competitors.
        <br />
        <br />
        You can upgrade to be able to compare
        <br />
        Linked-in engagement count and public
        <br />
        media mentions
      </>
    ),
    target: '.segment-control__LinkedIn_Engagement',
    placement: 'top-start',
    offset: 0,
    xOffset: -5,
  },
  {
    content: <>Click on the competitor content tab</>,
    target: '.tab-Competitor_Content',
    placement: 'top-start',
    offset: 0,
    xOffset: 80,
    withoutNavigation: true,
  },
  {
    content: (
      <>
        You can narrow your results using the filter or
        <br />
        search for a specific topic to see your
        <br />
        competitors coverage.
      </>
    ),
    target: '.filter-by',
    placement: 'bottom-end',
    offset: 0,
    xOffset: -5,
    disabledPrevButton: true,
  },
  {
    content: (
      <>
        Here you can find topics that your
        <br />
        competitors write about a lot.
        <br />
        <br />
        Click on one to filter your results by that
        <br />
        topic.
      </>
    ),
    target: '.topic-guide-box',
    placement: 'bottom-start',
    offset: 10,
    xOffset: -5,
    withoutNavigation: true,
    withProgress: true,
  },
  {
    content: (
      <>
        Great! Now try selecting a content item
        <br />
        below to see a detailed report.
      </>
    ),
    target: '.card-2',
    placement: 'top',
    offset: 20,
    withoutNavigation: true,
    withProgress: true,
  },
  {
    content: (
      <>
        Now, try adding more competitors for us to
        <br />
        track so that we can give you the best
        <br />
        insights about your content.
      </>
    ),
    target: '.edit-competitors__button',
    placement: 'left-start',
    offset: 20,
    withoutNavigation: true,
    withProgress: true,
  },
];
