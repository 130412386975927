// AlertComponent.jsx
import React from 'react';
import { Box, Alert } from '@mantine/core';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const AlertComponent = ({
  showAlert, onClose, title, description,
}) => (
    <Box
      position="fixed"
      top="1rem"
      left="50%"
      transform="translateX(-50%)"
      zIndex="9999"
      width="80%"
      maxWidth="800px"
    >
      {showAlert && (
        <Alert
          title={title}
          onClose={onClose}
          icon={<AiOutlineInfoCircle />}
        >
          {description}
        </Alert>
      )}
    </Box>
);

export default AlertComponent;
