import { Flex, rem, Stack, UnstyledButton } from '@mantine/core';
import React from 'react';
import MainButton from '../../buttons/buttons';
import ContentWithImage from '../../ContentWithImage/ContentWithImage';
import CustomText from '../../Typography/CustomText/CustomText';
import CustomTitle from '../../Typography/CustomTitle/CustomTitle';
import { useStyle } from './CheckInboxComponent.style';
import imageRocket from '../../../assets/images/rocket-image.png';

function CheckInboxComponent({ email }) {
  const { classes } = useStyle();

  return (
    <ContentWithImage
      withLines
      content={
        <Stack spacing={24} mt={rem(88)} w={rem(500)}>
          <CustomTitle order={0} sx={{ fontSize: rem(48), lineHeight: '110%' }}>
            Check your inbox
          </CustomTitle>
          <CustomText variant="bodyLarge">
            We sent you a magic link to the email address
            <CustomText variant="bodyLarge" sx={{ fontWeight: 700 }}>
              {email}
            </CustomText>
            <br />
            It will be active for 20 minutes. Make sure to check your junk
            folder if you cannot see the email.
          </CustomText>
          <Flex justify="space-between" align="center">
            <UnstyledButton>
              <CustomText variant="bodyLarge" sx={{ fontWeight: 700 }}>
                Didn’t receive your link?
              </CustomText>
            </UnstyledButton>
            <MainButton hugePadding customClassName={classes.button}>
              <CustomText
                variant="body2"
                sx={{ fontSize: rem(18), fontWeight: 500 }}
              >
                Re-send the link
              </CustomText>
            </MainButton>
          </Flex>
        </Stack>
      }
      image={<img src={imageRocket} style={{width: rem(560), height: rem(560)}} />}
    />
  );
}

export default CheckInboxComponent;
