import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  layout: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: rem(8),
    background: "rgba(12, 10, 68, 0.50)",
    backdropFilter: `blur(${rem(10)})`,
    padding: rem(24),
    zIndex: 10,
  }
}));
