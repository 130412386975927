import React, { useMemo } from 'react';
import { Box, Flex, Group, rem, Skeleton, Stack } from '@mantine/core';
import CustomText from '../../../Typography/CustomText/CustomText';
import { useStyle } from './ImageContent.style';
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrow-left-icon.svg';
import ContentCard from './ContentCard/ContentCard';
import ImageContentWrapper from './ImageContentWrapper';

function ImageContent({
  title,
  company,
  userEmail,
  contactEmail,
  name,
  cards,
  redButtons,
  huge,
}) {
  const { classes, cx } = useStyle();

  const cardList = useMemo(() => {
    return cards.map((card) => (
      <ContentCard
        key={`content-card-${card.id}`}
        card={card}
        redButtons={redButtons}
        huge={huge}
      />
    ));
  });

  return (
    <ImageContentWrapper huge={huge}>
      <Group spacing={8} align="flex-start" sx={{ flexWrap: 'nowrap' }}>
        <Stack spacing={0}>
          <Flex className={cx(classes.navItem, classes.navItemActive)}>
            <CustomText variant="caption1" sx={{ fontSize: rem(huge ? 9 : 8) }}>
              Inbox
            </CustomText>
            <CustomText variant="h6" sx={{ fontSize: rem(7) }}>
              311
            </CustomText>
          </Flex>
          <Flex className={classes.navItem}>
            <CustomText variant="caption1" sx={{ fontSize: rem(huge ? 9 : 8) }}>
              Starred
            </CustomText>
          </Flex>
          <Flex className={classes.navItem}>
            <CustomText variant="caption1" sx={{ fontSize: rem(huge ? 9 : 8) }}>
              Snoozed
            </CustomText>
          </Flex>
          <Flex className={classes.navItem}>
            <CustomText variant="caption1" sx={{ fontSize: rem(huge ? 9 : 8) }}>
              Sent
            </CustomText>
          </Flex>
          <Flex className={classes.navItem}>
            <CustomText variant="caption1" sx={{ fontSize: rem(huge ? 9 : 8) }}>
              Drafts
            </CustomText>
          </Flex>
        </Stack>
        <Stack spacing={huge ? 10 : 8} sx={{ flexGrow: '1 !important' }}>
          <Box className={classes.input}>
            <ArrowLeft />
          </Box>
          <Group spacing={8} align="flex-end">
            <Box className={classes.emptyIcon} />
            <Stack spacing={4}>
              <CustomText
                variant="caption1"
                sx={{ fontSize: rem(huge ? 14 : 12) }}
              >
                {title}
              </CustomText>
              <Stack spacing={2}>
                <Group spacing={2}>
                  {company ? (
                    <CustomText
                      variant="h6"
                      sx={{ fontSize: rem(huge ? 9 : 7) }}
                    >
                      {company}
                    </CustomText>
                  ) : (
                    <Skeleton ml={rem(4)} w={rem(60)} h={rem(5)} />
                  )}
                  {userEmail ? (
                    <CustomText variant="caption1" sx={{ fontSize: rem(8) }}>
                      {`<${userEmail}>`}
                    </CustomText>
                  ) : (
                    <Skeleton ml={rem(4)} w={rem(60)} h={rem(5)} />
                  )}
                </Group>
                <Group>
                  {contactEmail ? (
                    <CustomText variant="body2" sx={{ fontSize: rem(8) }}>
                      to {contactEmail}
                    </CustomText>
                  ) : (
                    <Skeleton ml={rem(4)} w={rem(60)} h={rem(5)} />
                  )}
                </Group>
              </Stack>
            </Stack>
          </Group>
          <Box className={classes.contentContainer}>
            <Stack spacing={huge ? 13 : 10}>
              <CustomText
                variant="body2"
                sx={{ fontSize: rem(huge ? 15 : 13) }}
              >
                <Group spacing={0}>
                Hi {name || ''},
                {!name && (
                  <Skeleton ml={rem(4)} w={rem(60)} h={rem(12)} />
                )}
                </Group>
              </CustomText>
              <Stack>
                <Stack spacing={5}>
                  <Box
                    className={classes.contentLine}
                    sx={{ width: rem(127) }}
                  />
                  <Box
                    className={classes.contentLine}
                    sx={{ width: rem(209) }}
                  />
                  <Box
                    className={classes.contentLine}
                    sx={{ width: rem(288) }}
                  />
                  <Box
                    className={classes.contentLine}
                    sx={{ width: rem(288) }}
                  />
                </Stack>
                <Group spacing={24}>{cardList}</Group>
                <Stack spacing={5}>
                  <Box
                    className={classes.contentLine}
                    sx={{ width: rem(209) }}
                  />
                  <Box
                    className={classes.contentLine}
                    sx={{ width: rem(288) }}
                  />
                  <Box
                    className={classes.contentLine}
                    sx={{ width: rem(127) }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Group>
    </ImageContentWrapper>
  );
}

export default ImageContent;
