import React, { useCallback } from 'react';
import { UnstyledButton } from '@mantine/core';
import { useStyle } from './buttons.style';
import CustomText from '../Typography/CustomText/CustomText';

function MainButton({
  icon,
  children,
  reversed,
  fontStyle,
  disabled,
  onClick,
  smallPadding,
  hugePadding,
  secondary,
  customClassName,
  ...rest
}) {
  const { classes, cx } = useStyle();

  const handleClickWrapped = useCallback(() => {
    if (onClick && !disabled) {
      onClick();
    }
  }, [onClick, disabled]);

  return (
    <UnstyledButton
      component="button" // Ensure underlying element is a <button>
      className={cx(classes.button, customClassName, {
        [classes.noIcon]: !icon,
        [classes.reversed]: reversed,
        [classes.buttonDisabled]: disabled,
        [classes.smallPadding]: smallPadding,
        [classes.hugePadding]: hugePadding,
        [classes.secondary]: secondary,
      })}
      onClick={handleClickWrapped}
      disabled={disabled} // Pass the disabled prop
      {...rest}
    >
      {icon}
      <CustomText
        variant="body1"
        c={secondary ? '#0F0E24' : '#fff'}
        sx={fontStyle}
      >
        {children}
      </CustomText>
    </UnstyledButton>
  );
}

export default MainButton;
