import React, { useState } from 'react';
import { Group, rem, Stack } from '@mantine/core';
import { ReactComponent as PaymentMethodsIcon } from '../../../../../assets/icons/payment-methods-icon.svg';
import { ReactComponent as PayPalIcon } from '../../../../../assets/icons/paypal-logo.svg';
import CustomRadio from '../../../../CustomRadio/CustomRadio';
import CustomText from '../../../../Typography/CustomText/CustomText';

function PaymentMethodSelection() {
  const [paymentMethodIndex, setPaymentMethodIndex] = useState(0);

  return (
    <Stack spacing={8} mb={24}>
        <CustomText variant="h4">
            Payment method
        </CustomText>
        <Group spacing={24}>
            <Group spacing={8} onClick={() => setPaymentMethodIndex(0)} sx={{ cursor: 'pointer' }}>
                <CustomRadio selected={paymentMethodIndex === 0} />
                <PaymentMethodsIcon style={{ width: rem(178), height: rem(22) }} />
            </Group>
            <Group spacing={8} onClick={() => setPaymentMethodIndex(1)} sx={{ cursor: 'pointer' }}>
                <CustomRadio selected={paymentMethodIndex === 1} />
                <PayPalIcon style={{ width: rem(90), height: rem(24) }}/>
            </Group>
        </Group>
    </Stack>
  );
}

export default PaymentMethodSelection;
