import React from 'react';
import { Box, Flex } from '@mantine/core';
import { useStyle } from './ContentWithImage.style';
import { ReactComponent as LineLeft } from '../../assets/images/line-left.svg';
import { ReactComponent as LineRight } from '../../assets/images/line-right.svg';

function ContentWithImage({ content, image, withLines }) {
  const { classes } = useStyle();

  return (
    <Box>
      {withLines && (
        <>
          <Box className={classes.lineLeftContainer}>
            <LineLeft />
          </Box>
          <Box className={classes.lineRightContainer}>
            <LineRight />
          </Box>
        </>
      )}
      <Flex justify="flex-end" gap={135} pl={104}>
        <Box>{content}</Box>
        <Box className={classes.imageContainer}>
          <Box className={classes.imageWrapper}>{image}</Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default ContentWithImage;
