import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  addressInput: {
    height: rem(20),
    minHeight: rem(20),
    background: 'none',
    border: 'none',
    padding: 0,

    '&:disabled': {
      background: 'none',
      border: 'none',
      color: '#000',
      opacity: 1,
    },
  },
}));
