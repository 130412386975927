import { CloseButton, Group } from '@mantine/core';
import React, { useMemo } from 'react';
import CustomText from '../Typography/CustomText/CustomText';

function ValueItem({ label, onRemove }) {
  const validatedLabel = useMemo(() => {
    if (label.includes('_disabled')) {
      return label.split('_')[0];
    }
    return label;
  }, [label]);

  return (
    <Group
      spacing={2}
      sx={(theme) => ({
        background:
          validatedLabel !== label
            ? theme.colors.gray[4]
            : theme.colors.yellow[0],
        padding: `${2}px ${8}px`,
        marginLeft: 8,
        borderRadius: 5,
      })}
    >
      <CustomText variant="h6" sx={{ fontWeight: 400 }}>
        {validatedLabel}
      </CustomText>
      <CloseButton onMouseDown={onRemove} variant="transparent" size={16} />
    </Group>
  );
}

export default ValueItem;
