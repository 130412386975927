import React, { useMemo, useState } from 'react';
import { useCallback } from 'react';
import CheckInboxComponent from '../../components/login/CheckInboxComponent/CheckInboxComponent';
import EnterEmailComponent from '../../components/login/EnterEmailComponent/EnterEmailComponent';

function LoginPage() {
  const [pageIndex, setPageIndex] = useState(0);
  const [email, setEmail] = useState('');

  const handleEnterEmailClick = useCallback((value) => {
    setPageIndex(1);
    setEmail(value);
  }, []);

  const loginPage = useMemo(() => {
    switch (pageIndex) {
      case 0:
        return <EnterEmailComponent onClick={handleEnterEmailClick} />;

      case 1:
        return <CheckInboxComponent email={email} />;
    }
  }, [pageIndex, email]);

  return <>{loginPage}</>;
}

export default LoginPage;
