import { Group, rem } from '@mantine/core';
import CustomText from '../../../../Typography/CustomText/CustomText';
import { useStyle } from './ListItem.style';
import { ReactComponent as CrossIcon } from '../../../../../assets/icons/cross-icon.svg';

const ListItem = ({
  title,
  isActive,
  addActive,
  removeActive,
  customClassName,
}) => {
  const { classes, cx } = useStyle();

  return (
    <Group
      spacing={8}
      onClick={(e) => addActive(e)}
      className={cx(classes.topicItem, customClassName, {
        [classes.topicItemActive]: isActive,
      })}
    >
      <CustomText variant="body2">{title}</CustomText>
      {isActive && (
        <CrossIcon
          onClick={removeActive}
          style={{ width: rem(16), height: rem(16) }}
        />
      )}
    </Group>
  );
};

export default ListItem