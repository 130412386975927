/* eslint-disable max-len */
import React from 'react';
import { Box, Flex, Stack } from '@mantine/core';
import { useParams } from 'react-router-dom';
import CustomText from '../../components/Typography/CustomText/CustomText';
import useURLParams from '../../hooks/useURLParams';
import TimePeriodSelection from '../../components/filtering/TimePeriodSelection/TimePeriodSelection';
import BackNagination from '../../components/BackNavigation/BackNagination';
import DownloadsMatchesFlow from '../../components/Modals/DownloadsMatchesFlow';
import TagsList from '../../components/TagsList/TagsList';
import TabsWithCards from '../../components/TabsWithCards/TabsWithCards';
import { useDispatch, useSelector } from 'react-redux';
import GuidedTour from '../../components/GuidedTour/GuidedTour';
import GuidedTourModal from '../../components/GuidedTour/GuidedTourModal/GuidedTourModal';
import useGuideModal from '../../hooks/useGuideModal';
import { getContentDetailsSteps } from '../../features/guidedTourSteps/contentDetailsPage';
import useRadarApi from '../../hooks/useRadarApi';
import useJwtToken from '../../hooks/useJwtToken';
import ContentStats from './components/ContentStats';
import useTabsWithContent from '../../hooks/useTabsWithContent';
import useFetchTabsWithContent from '../../hooks/useFetchTabsWithContent';
import useFetchContentDetails from './hooks/useFetchContentDetails';
import useContentCSV from './hooks/useContentCSV';
import { DEFAULT_DATE_FILTER_PARAMS } from '../../components/filtering/TimePeriodSelection/constants';

const PARAMS_KEYS = ['dateFilter'];

const CONTENT_TABS = ['Related Content'];

function ContentsDetailPage() {
  //filters and custom hooks
  const { showGuide, stepIndex, guideModalIndex } = useSelector(
    (state) => state.profile.guide.contentDetails,
  );
  const dispatch = useDispatch();
  const { contentId } = useParams();
  const { loading } = useJwtToken(); // Get the loading state from the useJwtToken hook
  const { handleGuideModalClick } = useGuideModal('contentDetails', stepIndex);
  const { getContentDetailsRelatedContent } = useRadarApi();
  const fetchContent = useFetchTabsWithContent(
    contentId,
    getContentDetailsRelatedContent,
  );
  const [relatedContentData] = useTabsWithContent(
    ['Related Content'],
    [''],
    0,
    (params) => fetchContent(params),
  );

  const { urlDateParams, updateDateParam, getValidatedDateFilter, urlState } =
    useURLParams(PARAMS_KEYS, {
      ...DEFAULT_DATE_FILTER_PARAMS,
    });

  const [contentDetails, contentDetailsLoading] = useFetchContentDetails(
    loading,
    getValidatedDateFilter(),
    contentId,
  );

  const [onLinkedinExportClick, onFullReportExportClick] = useContentCSV(
    contentDetails?.contacts,
  );

  return (
    <Box maw={1143} mx="auto">
      <Stack spacing={19}>
        <Flex justify="space-between">
          <BackNagination
            href={urlState?.prevUrl || '/contents'}
            title="Back to content"
          />
          <DownloadsMatchesFlow
            onLinkedinExportClick={onLinkedinExportClick}
            onFullReportExportClick={onFullReportExportClick}
            disabled={!contentDetails?.contacts?.length}
          />
        </Flex>
        <Stack spacing={19}>
          <Flex justify="space-between">
            <CustomText variant="h3">{contentDetails?.title}</CustomText>
            <Box
              mb={-16}
              sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: 'fit-content',
              }}
            >
              <TimePeriodSelection
                updateDateFilter={updateDateParam}
                initialFetchPeriodKey={urlDateParams?.dateKey}
              />
            </Box>
          </Flex>
          <ContentStats
            contentDetails={contentDetails}
            loading={contentDetailsLoading}
          />
        </Stack>
      </Stack>
      <Box mt={40} mb={30}>
        <TagsList
          data={contentDetails?.tags}
          loading={contentDetailsLoading}
          title="Content tags"
        />
      </Box>
      <TabsWithCards
        tabs={CONTENT_TABS}
        tabsData={relatedContentData}
        withScroll
        tabMinWidth={168}
      />
      {/* Guided Tour Components */}
      <GuidedTourModal
        opened={showGuide && guideModalIndex === 0}
        title="Content Report"
        onClick={handleGuideModalClick}
        onClose={handleGuideModalClick}
      >
        <CustomText variant="body2">
          Content reports show give you a detailed look at the items
          performance. You can also control whether you like the content to be
          active for automation.
        </CustomText>
      </GuidedTourModal>
      <GuidedTour
        stepIndex={stepIndex}
        setStepIndex={handleGuideModalClick}
        run={showGuide && guideModalIndex === null}
        steps={getContentDetailsSteps(dispatch)}
      />
    </Box>
  );
}

export default ContentsDetailPage;
