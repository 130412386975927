import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

// This component is used to update child components by updating the parant div key
// When changing the url param react router v6 does not refresh the component
// So make sure to wrap every page that uses the param ( :paramName ) in the url in this component.

const RefreshRoute = ({ children }) => {
    const params = useParams();

    const currentUrlParam = useMemo(() => {
        return Object.values(params)[0]
    }, [params])

    return (
        <div key={currentUrlParam}>
            {children}
        </div>
    )
};

export default RefreshRoute;
