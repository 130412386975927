import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme, { isActive }) => ({
  navitemWrapper: {
    '& .logo-icon': {
      marginBottom: '40px',
    },

    '& a:hover': {
      textDecoration: 'none',
    },
  },
  navitem: {
    padding: `0 0 0 ${rem(26)}`,
    cursor: 'pointer',
    borderRadius: '0 50px 50px 0',
    width: 'calc(100% - 16px)',

    '& svg': {
      color: theme.colors.white[2],
    },

    '&:hover': {
      background: !isActive && theme.colors.gray[0],
    },
  },
  active: {
    background: theme.colors.gray[1],
  },
  responsiveIcon: {
    "& svg": {
      width: rem(20),
      height: rem(20)
    }
  }
}));
