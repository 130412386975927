import { useMemo, useState } from 'react';
import { Box, Center, Flex, Group, Stack, rem, Skeleton } from '@mantine/core';
import CustomText from '../../components/Typography/CustomText/CustomText';
import { useStyle } from './TopicsPage.style';
import TableWithSorting from '../../components/TableWithSorting/TableWithSorting';
import FilterBar from '../../components/filtering/FilterBar/FilterBar';
import useURLParams from '../../hooks/useURLParams';
import RadarChart from '../../components/RadarChart/RadarChart';
import GuidedTourModal from '../../components/GuidedTour/GuidedTourModal/GuidedTourModal';
import { useDispatch, useSelector } from 'react-redux';
import { setGuide } from '../../features/profileSlice';
import GuidedTour from '../../components/GuidedTour/GuidedTour';
import useGuideModal from '../../hooks/useGuideModal';
import { getTopicsSteps } from '../../features/guidedTourSteps/topicsPage';
import useJwtToken from '../../hooks/useJwtToken';
import useTable from '../../hooks/useTable';
import useFetchAudienceRelevance from './hooks/useFetchAudienceRelevance';
import useFetchIncidenceAndEngagement from './hooks/useFetchIncidenceAndEngagement';
import useFetchSummaries from './hooks/useFetchSummaries';
import useSorting from '../../hooks/useSorting';
import AudienceRelevanceChart from './components/AudienceRelevanceChart';
import useStateFromURL from '../../hooks/useStateFromURL';
import useFilter from '../../hooks/useFilter';
import FilterList from '../../components/filtering/FilterBar/Lists/FilterList/FilterList';
import useActiveRelevance from './hooks/useActiveRelevance';
import TitleWithTooltip from '../../components/TitleWithTooltip/TitleWithTooltip';
import { DEFAULT_DATE_FILTER_PARAMS } from '../../components/filtering/TimePeriodSelection/constants';
import useIntegrations from '../../hooks/useIntegrations';
import FilterPanelDropdown from './components/FilterPanelDropdown';

const SORT_OPTIONS = {
  Topic: 'value',
  Contacts: 'contacts',
  'Topic Competition': 'topicCompetition',
  'Coverage Score': 'coverageScore',
};

const DEFAULT_URL_PARAMS = {
  ...DEFAULT_DATE_FILTER_PARAMS,
  sortBy: 'contacts',
  sortDirection: 'desc',
  topics: [],
  filterByData: [],
  activeRelevance: [],
  'searchBy[title]': '',
  list: '',
};
const PARAMS_KEYS = [
  'topics',
  'filterByData',
  'dateFilter',
  'sortBy',
  'searchBy[title]',
  'list',
  'sortDirection',
  'activeRelevance',
];

function TopicsPage() {
  const [filterOpened, setFilterOpened] = useState(false);
  const { showGuide, stepIndex, guideModalIndex } = useSelector(
    (state) => state.profile.guide.topics,
  );

  const dispatch = useDispatch();
  const { handleGuideModalClick } = useGuideModal('topics', stepIndex);
  const { classes } = useStyle();
  const { urlParams, urlDateParams, updateUrlParams, getValidatedDateFilter } =
    useURLParams(PARAMS_KEYS, {
      ...DEFAULT_URL_PARAMS,
    });

  const [filteringParams, setFilteringParams] = useStateFromURL(
    PARAMS_KEYS,
    urlParams,
  );
  const updateFilter = useFilter(urlParams, updateUrlParams);
  const [activeRelevance, updateActiveRelevance, audienceChartColors] =
    useActiveRelevance(updateFilter, filteringParams);

  const { getTableData } = useTable();
  const { loading } = useJwtToken();
  const handleSorting = useSorting(urlParams, updateUrlParams, SORT_OPTIONS);

  const { hubspotDetails } = useIntegrations();

  const selectedListsId = useMemo(() => {
    if (filteringParams && hubspotDetails) {
      return hubspotDetails.availableContactLists.find(
        (el) =>
          el.name ===
          (typeof filteringParams.list === 'string'
            ? filteringParams.list
            : filteringParams.list?.[0]),
      )?.listId;
    }
    return '';
  }, [filteringParams, hubspotDetails]);

  const [audienceChartData, audienceChartLoading] = useFetchAudienceRelevance(
    loading,
    getValidatedDateFilter(),
    selectedListsId,
    urlParams,
  );

  const [incidenceAndEngagement, incidenceAndEngagementLoading] =
    useFetchIncidenceAndEngagement(
      loading,
      activeRelevance,
      selectedListsId,
      urlParams,
    );

  const [
    topicSummaries,
    topicSummariesContent,
    topicSummariesOffset,
    setTopicSummariesOffset,
    topicSummariesLoading,
  ] = useFetchSummaries(loading, urlParams, selectedListsId, activeRelevance);

  const tableData = useMemo(() => {
    return getTableData(
      topicSummariesContent,
      ['Topic', 'Contacts', '_id', 'Coverage Score'],
      [
        {
          columnSpan: 5,
        },
        {
          columnSpan: 4,
        },
        {
          columnSpan: 0,
        },
        {
          columnSpan: 3,
          useRange: true,
        },
      ],
      true,
    );
  }, [topicSummariesContent]);

  const infinteScrollOptions = useMemo(() => {
    return {
      dataLength: topicSummariesContent?.length,
      next: () => setTopicSummariesOffset((prevState) => prevState + 15),
      hasMore: topicSummaries?.totalItems > topicSummariesOffset + 15,
      loader: <Skeleton width="100%" height={rem(48)} radius={8} />,
    };
  }, [topicSummariesOffset, topicSummaries, topicSummariesContent]);

  const allFilters = useMemo(() => {
    if (!urlParams) return [];
    const filtersToDisplay = [
      {
        key: 'activeRelevance',
        value: urlParams.activeRelevance,
      },
    ];

    return filtersToDisplay.reduce(
      (acc, curr) =>
        acc.concat(curr.value.map((value) => ({ key: curr.key, value }))),
      [],
    );
  }, [urlParams]);

  const topicFilterableList = useMemo(() => {
    if (hubspotDetails?.availableContactLists) {
      const filterableList = [];

      hubspotDetails.availableContactLists.forEach((filterableListItem) => {
        filterableList.push({
          listId: filterableListItem.listId,
          title: filterableListItem.name,
        });
      });

      return filterableList;
    }
    return [];
  }, [topicSummaries, hubspotDetails]);

  return (
    <div>
      <Flex justify="flex-end">
        <Group>
          <CustomText variant="h5">Filter by List:</CustomText>
          <FilterPanelDropdown
            filterOpened={filterOpened}
            setFilterOpened={setFilterOpened}
            updateUrlParams={updateUrlParams}
            filterByData={topicFilterableList || []}
            filteringParams={filteringParams}
            setFilteringParams={setFilteringParams}
            hubspotDetails={hubspotDetails}
            selectedListsId={selectedListsId}
            onlyDropdown
          />
        </Group>
      </Flex>
      <Center mt={16}>
        <Stack spacing={16}>
          <Group spacing={24} sx={{ flexWrap: 'nowrap' }}>
            <AudienceRelevanceChart
              audienceChartColors={audienceChartColors}
              audienceChartData={audienceChartData}
              activeRelevance={activeRelevance}
              updateActiveRelevance={updateActiveRelevance}
              loading={audienceChartLoading}
            />
            <RadarChart
              data={incidenceAndEngagement}
              tooltipActive={stepIndex === 4}
              loading={incidenceAndEngagementLoading}
              onModalClose={() =>
                handleGuideModalClick(stepIndex === 4 ? 5 : stepIndex)
              }
            />
          </Group>
        </Stack>
      </Center>
      <Box mt={33}>
        <Stack>
          <Stack spacing={0}>
            <Flex gap={100}>
              <Stack spacing={0} w="100%">
                <Box
                  mb={filterOpened ? rem(175) : 0}
                  sx={{ transition: '0.05s' }}
                >
                  <FilterBar
                    title={
                      <TitleWithTooltip
                        title="Your Topics"
                        modalTitle="Your Topics"
                        modalContent={
                          <>
                            Here you will find a complete list of every topic
                            that has appeared in your content, that your
                            contacts are interested in, that your tracked
                            competitors have written about, and that has been
                            covered in your related news.
                            <br />
                            <br />
                            You can sort the list below based on the
                            alphabetical order of topics, by how many of your
                            contacts are interested in the topic, and your
                            coverage score (which is how well your own content
                            has covered a topic).
                            <br />
                            <br />A great way to use this report is to filter
                            results based on your relevancy report above to see
                            what topics to write about to increase your
                            relevancy for contacts with no content
                            recommendations
                          </>
                        }
                      />
                    }
                    withOffset={filterOpened}
                    offset={rem(100)}
                    placeholder="Search topics"
                    paramKeys={{
                      searchBy: 'searchBy[title]',
                    }}
                    filteringParams={filteringParams}
                    setFilteringParams={setFilteringParams}
                    setParams={updateUrlParams}
                    urlParams={urlParams}
                    onApply={(params) => {
                      setFilterOpened(false);
                      updateUrlParams({
                        ...filteringParams,
                        ...params,
                      });
                    }}
                    onReset={() => {
                      setFilterOpened(false);
                      setFilteringParams(DEFAULT_URL_PARAMS);
                      updateActiveRelevance(null);
                      updateUrlParams({
                        ...DEFAULT_URL_PARAMS,
                        ...urlDateParams,
                      });
                    }}
                  />
                </Box>
              </Stack>
            </Flex>
            {allFilters?.length > 0 && (
              <FilterList
                title="Filtering by:"
                filters={allFilters}
                updateFilter={(filter) => {
                  updateFilter(filter.key, filter.value);
                  updateActiveRelevance(null);
                  updateUrlParams({
                    [filter.key]: [],
                  });
                }}
                customClassName={classes.topicItem}
              />
            )}
          </Stack>
          <Box sx={{ position: 'relative' }}>
            <TableWithSorting
              errorText="No topics found"
              loading={topicSummariesLoading}
              infiniteScrollOptions={infinteScrollOptions}
              tableData={tableData}
              withScroll
              onSort={handleSorting}
              hoverOptions={{
                navigateTo: '/topics/detail/',
                hoverElement: (
                  <CustomText variant="caption1">See report</CustomText>
                ),
              }}
            />
            {/* <Box sx={{position: "absolute", top: rem(23), right: rem(170)}}>
              <TitleWithTooltip
                modalTitle="Your Topics"
                modalContent={
                  <>
                    Lorem ipsum dolor sit amet consectetur. Ut platea mauris
                    tempus enim massa. Viverra quis fermentum senectus ut tortor
                    aliquet ultricies sem ac. Diam proin viverra sagittis elit
                    molestie volutpat.
                    <br />
                    <br />
                    Morbi purus semper porttitor neque ut gravida. Eu eget
                    fringilla mus orci semper pellentesque sit at. Ornare
                    ultrices molestie nulla id amet non nam platea. Consequat
                    aliquam suspendisse elementum tortor malesuada nunc.
                    Pharetra nibh porta elit dictumst ultrices. Lacus vel tellus
                    lorem pretium.
                  </>
                }
              />
            </Box> */}
          </Box>
        </Stack>
      </Box>
      {/* Guided Tour Components */}
      <GuidedTourModal
        opened={showGuide && guideModalIndex === 0}
        title="Topics"
        onClose={handleGuideModalClick}
      >
        <CustomText variant="body2">
          Topic insights help you understand where your content is hitting the
          mark, topics that might not be worth investing in, and the ones you
          need to create content for to engage your audience.
          <br />
          <br />
          ATC finds insights about topics in your own content, interests of your
          audience, what your competitors are writing about, and public media.
        </CustomText>
      </GuidedTourModal>
      <GuidedTourModal
        opened={showGuide && guideModalIndex === 1}
        title="Topics"
        onClick={() => handleGuideModalClick(1)}
        onClose={() => handleGuideModalClick(1)}
      >
        <CustomText variant="body2">
          Now, let's go through the Audience relevance and Topic Radar. These
          will help you get an overview of how your topics are performing and
          how relevant your content is to your audience.
        </CustomText>
      </GuidedTourModal>
      <GuidedTour
        stepIndex={stepIndex}
        setStepIndex={(index) =>
          dispatch(
            setGuide({
              key: 'topics',
              value: {
                stepIndex: index,
              },
            }),
          )
        }
        run={showGuide && guideModalIndex === null}
        steps={getTopicsSteps(dispatch)}
      />
    </div>
  );
}

export default TopicsPage;
