import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  input: {
    display: 'flex',
    alignItems: 'center',
    borderColor: theme.colors.gray[3],
    borderRadius: 100,
    padding: `${rem(8)} ${rem(8)} ${rem(8)} ${rem(24)} !important`,
    minHeight: rem(40),
  },
  inputErrorBorder: {
    border: `${rem(1)} solid ${theme.colors.red[0]}`
  },
  inputError: {
    color: theme.colors.red[0],
  },
}));
