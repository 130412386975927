import React from 'react';
import { TextInput } from '@mantine/core';
import { useStyle } from './CustomInput.style';

function CustomInput({ customClassName, error, ...rest }) {
  const { classes, cx } = useStyle();
  return (
    <TextInput
        classNames={{
          input: cx(classes.input, customClassName, {[classes.inputErrorBorder]: error}),
          error: classes.inputError,
        }}
        error={error}
        {...rest}
    />
  );
}

export default CustomInput;
