import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Flex, Group, Menu, rem, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { TbSortDescending, TbSortAscending } from 'react-icons/tb';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevronDown-icon.svg';
import { useStyle } from './CustomDropdown.style';
import CustomText from '../Typography/CustomText/CustomText';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

function BasicMenuOption({ itemData, onClick, targetStyles }) {
  const getSortingIcon = () => {
    if (itemData.sortDirection === 'desc') {
      return <TbSortDescending />;
    }
    if (itemData.sortDirection === 'asc') {
      return <TbSortAscending />;
    }
  };

  return (
    <Flex gap={10} onClick={onClick} align="center" sx={targetStyles}>
      {itemData.icon}
      <CustomText variant="body2">{itemData.title}</CustomText>
      {getSortingIcon()}
    </Flex>
  );
}

function CustomDropdown({
  customItem,
  targetItem,
  menuOptions,
  dropdownClassName,
  onSelect,
  onChange,
  globalOptions,
  targetStyles,
  gap = 24,
  customAnimationState,
  withoutAnimation,
  customDropdownComponent,
  ...rest
}) {
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions && menuOptions.slice(0, Math.min(101, menuOptions.length)),
  );
  const [opened, handlers] = useDisclosure(false);
  const { classes, cx } = useStyle();

  const handleChange = useCallback(
    (state) => {
      handlers.toggle();
      if (onChange) {
        onChange(state);
      }
    },
    [onChange, opened],
  );

  const handleClick = useCallback(
    (option) => {
      if (option.onClick) {
        option.onClick(option.options);
      } else if (onSelect) {
        onSelect(option);
      }
    },
    [onSelect],
  );

  const menuOptionsList = useMemo(() => {
    const elements = [];
    const CustomItemElement = customItem;

    if (!currentMenuOptions.length) {
      return (
        <Box mb={6} mt={-12}>
          <CustomText
            variant="h5"
            sx={{ textAlign: 'center', fontWeight: 500 }}
          >
            No filters
          </CustomText>
        </Box>
      );
    }
    currentMenuOptions.forEach((option, index) => {
      elements.push(
        <Menu.Item key={index} className={`menu__item-${index}`}>
          {CustomItemElement ? (
            <CustomItemElement
              itemData={option}
              onClick={() => handleClick(option)}
              targetStyles={targetStyles}
              globalOptions={globalOptions}
            />
          ) : (
            <BasicMenuOption
              itemData={option}
              onClick={() => handleClick(option)}
              targetStyles={targetStyles}
            />
          )}
        </Menu.Item>,
      );
    });

    return elements;
  }, [currentMenuOptions, customItem, globalOptions]);

  const loadMoreOptions = useCallback(() => {
    const lengthDelta = menuOptions.length - currentMenuOptions.length;
    setCurrentMenuOptions(
      menuOptions.slice(
        0,
        currentMenuOptions.length + (lengthDelta >= 100 ? 100 : lengthDelta),
      ),
    );
  }, [menuOptions, currentMenuOptions]);

  useEffect(() => {
    setCurrentMenuOptions(
      menuOptions.slice(0, Math.min(101, menuOptions.length))
    );
  }, [menuOptions]);

  return (
    <Menu
      classNames={{
        dropdown: cx(classes.dropdown, dropdownClassName),
        item: classes.item,
      }}
      onChange={handleChange}
      trigger="click"
      {...rest}
    >
      <Menu.Target>
        <UnstyledButton className={classes.button} onClick={handlers.toggle}>
          <Flex align="center" gap={gap} sx={targetStyles}>
            <Group spacing={12}>{targetItem}</Group>
            <ChevronDown
              style={{
                width: rem(14),
                height: rem(8),
                transform: `rotate(${
                  (customAnimationState !== undefined
                    ? customAnimationState
                    : opened) && !withoutAnimation
                    ? 180
                    : 0
                }deg)`,
              }}
            />
          </Flex>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown className="dropdown-wrapper">
        {menuOptions?.length > 50 ? (
          <>
            <InfiniteScroll
              dataLength={currentMenuOptions?.length}
              next={loadMoreOptions}
              hasMore={menuOptions?.length > currentMenuOptions?.length}
              height={500}
              loader={<CustomText variant="caption1">Loading...</CustomText>}
              scrollableTarget="dropdown-wrapper"
            >
              {customDropdownComponent ? (
                <>{customDropdownComponent}</>
              ) : (
                <>{menuOptionsList}</>
              )}
            </InfiniteScroll>
          </>
        ) : (
          <>
            {customDropdownComponent ? (
              <>{customDropdownComponent}</>
            ) : (
              <>{menuOptionsList}</>
            )}
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}

export default CustomDropdown;
