export const getContactName = (contact) => {
  if (!contact?.firstName || !contact.lastName) return undefined;
  return `${contact?.firstName} ${contact?.lastName}`;
};

export const getCompanyName = (email) => {
  const companyWithDomain = email.split('@')[1];
  return companyWithDomain.split('.')[0];
};

export const capitalizeFirstLetter = (string) => {
    if(!string) return string
    return string.charAt(0).toUpperCase() + string.slice(1);
}