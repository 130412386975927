import { useEffect } from 'react';
import './LogoutPage.css';

function LogoutPage() {
  useEffect(() => {
    window.location = 'https://www.airtrafficcontrol.io';
  }, []);

  return <></>;
}

export default LogoutPage;
