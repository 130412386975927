import { Box, Card, Center, Flex, Image } from '@mantine/core';
import React from 'react';
import CustomText from '../Typography/CustomText/CustomText';
import { useStyle } from './CompetitorContentCard.style';
import imagePlaceholder from '../../assets/icons/placeholder.png';
import { getCountryAdjustedDate } from '../../utils';

import logger from '../../utils/logger';

function CompetitorContentCard({
  image,
  title,
  publishDate,
  source,
  onClick,
  matches,
  smallCard,
  customClassName
}) {
  const { classes, cx } = useStyle();

  return (
    <Box
      className={cx(classes.card, customClassName, { [classes.cardSmall]: smallCard })}
      onClick={() => {
        logger.debug('Card clicked');
        onClick();
      }}
    >
      <Card>
        <Card.Section>
          <Image width="100%" src={image || imagePlaceholder} />
          {matches && (
            <Center className={classes.matchesContainer}>
              <CustomText variant="h6" sx={{ fontWeight: 600 }}>
                Matches: {matches}
              </CustomText>
            </Center>
          )}
        </Card.Section>
        <Flex
          direction="column"
          gap={6}
          mt={smallCard ? 0 : 16}
          justify={smallCard && 'center'}
          className={cx({ [classes.smallCardContainer]: smallCard })}
        >
          <CustomText
            variant="h5"
            sx={{ width: smallCard && '90%', lineHeight: '120%' }}
            lineClamp={2}
          >
            {title}
          </CustomText>
          <Flex
            justify={smallCard && 'space-between'}
            direction={matches || smallCard ? 'row' : 'column'}
            gap={matches ? 16 : 2}
          >
            <CustomText variant="h6" sx={{ fontWeight: 600 }}>
              Published: <span>{getCountryAdjustedDate(publishDate)}</span>
            </CustomText>
            <CustomText variant="h6" sx={{ fontWeight: 600 }}>
              Source: <span>{source}</span>
            </CustomText>
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
}

export default CompetitorContentCard;
