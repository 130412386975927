import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  tableWrapper: {
    width: rem(560),
    background: theme.colors.white[3],
  },
  wrapperScrollClassName: {
    height: rem(272),
  },
  tableRow: {
    background: theme.colors.white[2],
  },
  guidedModalControl: {
    padding: 0,

    '&:hover': {
      background: 'none',
    },
  },
  guidedModalContent: {
    padding: 0,
  },
  guidedModalLabel: {
    paddingTop: 0,
    paddingBottom: rem(12),
  },
  guidedModalItem: {
    paddingBottom: rem(12),
    marginBottom: rem(16),
  },
  guidedModalLinkGroup: {
    cursor: 'pointer',
    borderRadius: rem(8),
    padding: `${rem(4)} ${rem(8)}`,

    '& svg': {
      minWidth: rem(18),
      width: rem(18),
      minHeight: rem(18),
      height: rem(18),
      display: 'none',
    },

    '&:hover': {
      background: theme.colors.purple[0],

      '& svg': {
        display: 'block',
      },
    },
  },
  guidedModalContainer: {
    '&:last-child .mantine-Accordion-item': {
      borderBottom: 'none',
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  guidedModalStepCircle: {
    width: rem(4),
    height: rem(4),
    background: theme.colors.red[0],
    borderRadius: '50%',
  },
  blankCircle: {
    width: rem(18),
    height: rem(18),
    border: `1.5px solid ${theme.colors.black[0]}`,
    borderRadius: '50%',
  },
  modalContent: {
    borderRadius: rem(20),
  },
  bold: {
    fontWeight: 700,
    fontSize: rem(16),
    fontFamily: 'Manrope',
  },
  tickContainer: {
    '& svg': {
      display: 'block !important',
      width: rem(19),
      height: rem(19),
      marginRight: rem(-2),

      '& path': {
        stroke: theme.colors.black[0],
      },
    },
  },
}));
