import React from 'react';
import { Group, rem, TextInput } from '@mantine/core';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg';
import { useStyle } from './SearchInput.style';
import CustomText from '../Typography/CustomText/CustomText';

function SearchInput({ title, ...rest }) {
  const { classes } = useStyle();

  return (
    <>
        {title ? (
            <Group sx={{ flexGrow: 1 }}>
                <CustomText variant="h3" sx={{ marginTop: 10 }}>
                    {title}
                </CustomText>
                <TextInput
                    classNames={{
                      icon: classes.inputIcon,
                      input: classes.input,
                      root: classes.inputRoot,
                    }}
                    icon={<SearchIcon style={{width: rem(16), height: rem(16)}} />}
                    {...rest}
                />
            </Group>
        ) : (
            <TextInput
                classNames={{
                  icon: classes.inputIcon,
                  input: classes.input,
                  root: classes.inputRoot,
                }}
                icon={<SearchIcon style={{width: rem(16), height: rem(16)}} />}
                {...rest}
            />
        )}
    </>
  );
}

export default SearchInput;
