import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  filterDropdownPlaceholder: {
    background: 'none',
    outline: 'none',
    border: 'none',
    color: '#000',
    fontFamily: 'Manrope',
    fontSize: rem(16),
    display: 'block',
  },
}));
