// Header.jsx
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Group, Header } from '@mantine/core';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile-circle-icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings-icon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout-icon.svg';
import { useStyle } from './Header.style';
import CustomText from '../Typography/CustomText/CustomText';
import { LoginButton } from '../buttons/LoginButton';
import { SignupButton } from '../buttons/SignupButton';
import { auth0config } from '../../config';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import { resetCompetitorState } from '../../features/competitorContentSlice';
import { resetSettingsState } from '../../features/settingsSlice';
import { resetLayoutState } from '../../features/layoutSlice';
import { resetProfileState } from '../../features/profileSlice';
import { mapTitlesFromURL } from '../../utils';
import OrgSelectionMenu from './OrgSelectionMenu/OrgSelectionMenu';

function HeaderMenu() {
  const { isAuthenticated, user, logout } = useAuth0();
  const dispatch = useDispatch();
  const { classes } = useStyle();
  const { logoutUri } = auth0config;
  const navigate = useNavigate();

  const menuOptions = useMemo(
    () => [
      {
        title: 'Your Account',
        icon: <ProfileIcon />,
        onClick: () => navigate('/account'),
      },
      {
        title: 'Settings',
        icon: <SettingsIcon />,
        onClick: () => navigate('/settings'),
      },
      {
        title: 'Log out',
        icon: <LogoutIcon />,
        onClick: () => handleLogout(),
      },
    ],
    [],
  );

  const handleLogout = () => {
    dispatch(resetLayoutState());
    dispatch(resetCompetitorState());
    dispatch(resetProfileState());
    dispatch(resetSettingsState());

    logout({
      logoutParams: {
        returnTo: logoutUri,
      },
    });
  };

  return (
    <Header className={classes.header}>
      <Flex h="100%" justify="space-between" align="center">
        <CustomText variant="h3" order={1}>
          {mapTitlesFromURL(window.location.href) || 'Dashboard'}
        </CustomText>
        {isAuthenticated ? (
          <Group spacing={48}>
            <OrgSelectionMenu />
            <CustomDropdown
              menuOptions={menuOptions}
              targetItem={
                <>
                  <img src={user.picture} className="profile-icon" />
                  <CustomText variant="h4">{user.nickname}</CustomText>
                </>
              }
            />
          </Group>
        ) : (
          <Flex>
            <LoginButton />
            <SignupButton />
          </Flex>
        )}
      </Flex>
    </Header>
  );
}

export default HeaderMenu;
