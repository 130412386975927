import React, { useMemo, useState } from 'react';
import {
  Box, Flex, Group, Stack, UnstyledButton,
} from '@mantine/core';
import CustomText from '../../../../../Typography/CustomText/CustomText';
import { ReactComponent as TickCircleIcon } from '../../../../../../assets/icons/tick-circle-icon.svg';
import { useStyle } from './ExistingAddressForm.style';
import EditableInputGroup from './EditableInputGroup';

function ExistingAddressForm({ form }) {
  const [addressEditable, setAddresEditable] = useState(false);
  const [billingAddressEditable, setBillingAddresEditable] = useState(false);

  const { classes } = useStyle();

  const isAddressSame = useMemo(() => {
    const addressKeyPairs = [
      ['addressLineFirst', 'billingAddressLineFirst'],
      ['addressLineSecond', 'billingAddressLineSecond'],
      ['addressLineThird', 'billingAddressLineThird'],
      ['state', 'billingState'],
      ['postCode', 'billingPostCode'],
    ];

    return addressKeyPairs.every(([address, billingAddress]) => {
      const addressValue = form.values[address];
      const billingAddressValue = form.values[billingAddress];

      return addressValue === billingAddressValue && !!billingAddressValue;
    });
  }, [form]);

  return (
    <Group spacing={42} mt={32} align="flex-start">
      <Stack spacing={8} sx={{ flex: 2 }}>
          <Flex justify="space-between">
              <CustomText variant="h4">
                  Contact Address
              </CustomText>
              <UnstyledButton onClick={() => setAddresEditable((prevState) => !prevState)}>
                  <CustomText variant="caption1" sx={(theme) => ({ color: theme.colors.red[0] })}>
                      {addressEditable ? 'Save' : 'Edit'}
                  </CustomText>
              </UnstyledButton>
          </Flex>
          <EditableInputGroup
            keys={['addressLineFirst', 'addressLineSecond', 'addressLineThird', 'state', 'postCode']}
            form={form}
            disabled={!addressEditable}
          />
      </Stack>
      <Stack spacing={8} sx={{ flex: 2 }}>
          <Flex justify="space-between">
              <CustomText variant="h4">
                  Billing Address
              </CustomText>
              <UnstyledButton onClick={() => setBillingAddresEditable((prevState) => !prevState)}>
                  <CustomText variant="caption1" sx={(theme) => ({ color: theme.colors.red[0] })}>
                      {billingAddressEditable ? 'Save' : 'Edit'}
                  </CustomText>
              </UnstyledButton>
        </Flex>
        {isAddressSame && !billingAddressEditable ? (
            <Group spacing={2} align="center">
                <Box className={classes.tickIconContainer}>
                    <TickCircleIcon />
                </Box>
                <CustomText variant="body2">
                    Same as contact address
                </CustomText>
            </Group>
        ) : (
            <EditableInputGroup
                keys={['billingAddressLineFirst', 'billingAddressLineSecond', 'billingAddressLineThird', 'billingState', 'billingPostCode']}
                form={form}
                disabled={!billingAddressEditable}
            />
        )}
      </Stack>
    </Group>
  );
}

export default ExistingAddressForm;
