import React, { forwardRef, memo } from 'react';

// eslint-disable-next-line react/display-name
const ContactListsMultiSelectItem = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ label, onMouseEnter, ...other }, ref) => {
    return (
      <div ref={ref} {...other} style={{ whiteSpace: 'initial' }}>
        {label}
      </div>
    );
  },
);

export default memo(ContactListsMultiSelectItem);
