import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Center,
  Group,
  rem,
  Stack,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import MainButton from '../../../buttons/buttons';
import { ReactComponent as ArrangeSquareIcon } from '../../../../assets/icons/arrange-square-icon.svg';
import { ReactComponent as TickSquareIcon } from '../../../../assets/icons/tick-square-icon.svg';
import { ReactComponent as TriangleExclamationIcon } from '../../../../assets/icons/triangle-exclamation-icon.svg';
import CustomText from '../../../Typography/CustomText/CustomText';
import { useStyle } from './IntegrationWrapper.style';
import BasicModal from '../../../Modals/BasicModal';
import { useCallback } from 'react';
import CustomTitle from '../../../Typography/CustomTitle/CustomTitle';
import CustomInput from '../../../CustomInput/CustomInput';
import { addPropertyTitles, validationRexexList } from '../constants';
import useRadarApi from '../../../../hooks/useRadarApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../../features/layoutSlice';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/cross-icon.svg';
import useIntegrations from '../../../../hooks/useIntegrations';
import SkeletonContainer from '../../../SkeletonContainer';

function IntegrationWrapper({
  children,
  image,
  onConnect,
  connected,
  disabledText,
  propertyKey,
  property,
  customInputComponent,
  useCustomComponentAsExtra,
  onEdit,
  notFinished,
  onlyButton,
  editModalOpenedExternal,
  handleEditButtonClickExternal,
  handleBackButtonClick,
  customRefetch,
  loading,
  snackbarText,
  allowReconnect,
  ...rest
}) {
  const [opened, setOpened] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [editInputValue, setEditInputValue] = useState(null);
  const { classes, cx } = useStyle();
  const dispatch = useDispatch();
  const { patchIntegration } = useRadarApi();
  const { refetch } = useIntegrations();

  const isPropertyValid = useCallback(
    (property) => {
      if (!validationRexexList[propertyKey]) return false;
      return validationRexexList[propertyKey].test(property);
    },
    [propertyKey],
  );

  const propertyTitle = useMemo(
    () => addPropertyTitles[propertyKey],
    [propertyKey],
  );

  const handleClick = useCallback(() => {
    if (!propertyTitle) {
      onConnect();
      return;
    }
    setOpened(true);
  }, [onConnect, propertyTitle]);

  const closeEditModal = useCallback(
    (value) => {
      if (handleEditButtonClickExternal) {
        handleEditButtonClickExternal(value);
      }
      setEditModalOpened(value);
    },
    [handleEditButtonClickExternal],
  );

  const handleEditButtonClick = useCallback(() => {
    setEditModalOpened(true);

    if (handleEditButtonClickExternal) {
      handleEditButtonClickExternal(true);
    }

    if (customRefetch) {
      customRefetch();
    } else {
      refetch();
    }
  }, [refetch, customRefetch, handleEditButtonClickExternal]);

  const handleConnection = useCallback(() => {
    onConnect(inputValue);
  }, [onConnect, inputValue]);

  const handleEditing = useCallback(() => {
    if (onEdit) {
      const keepOpened = onEdit();

      if (!keepOpened) {
        closeEditModal(false);
      }
      return;
    }

    patchIntegration(property?.id, {
      [propertyKey]: editInputValue,
    })
      .then(() => {
        dispatch(
          setSnackbar({
            variant: 'success',
            description:
              snackbarText?.success ||
              'Your changes have been successfully updated',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setSnackbar({
            variant: 'error',
            description: snackbarText?.error || 'Something went wrong...',
          }),
        );
      });
  }, [
    onConnect,
    editInputValue,
    property,
    propertyKey,
    patchIntegration,
    onEdit,
  ]);

  const editable = useMemo(() => {
    return property?.[propertyKey] || customInputComponent;
  }, [property, propertyKey, customInputComponent]);

  useEffect(() => {
    setEditInputValue(property?.[propertyKey]);
  }, [property, propertyKey]);

  return (
    <Box
      className={cx(classes.wrapper, {
        [classes.outlineRed]: notFinished && connected,
        [classes.hiddenWrapper]: onlyButton,
      })}
    >
      {notFinished && connected && (
        <Box className={classes.errorIconWrapper}>
          <Tooltip
            label="This intergration is incomplete and needs additional info"
            color="red.0"
          >
            <Box>
              <TriangleExclamationIcon />
            </Box>
          </Tooltip>
        </Box>
      )}
      <Stack spacing={32} align="center">
        {image}
        {children && (
          <Center>
            <Box maw={550}>{children}</Box>
          </Center>
        )}
        {loading ? (
          <SkeletonContainer height={42} width={185} radius={12} />
        ) : (
          <>
            {connected ? (
              <Stack spacing={6}>
                {!allowReconnect ? (
                  <Group spacing={8}>
                    <TickSquareIcon />
                    <CustomText variant="body2">Connected</CustomText>
                  </Group>
                ) : (
                  <MainButton
                    smallPadding
                    onClick={handleClick}
                    icon={disabledText ? null : <ArrangeSquareIcon />}
                    disabled={disabledText}
                  >
                    <CustomText variant="body2">
                      {disabledText || 'Reconnect'}
                    </CustomText>
                  </MainButton>
                )}
                {editable && (
                  <UnstyledButton
                    className={classes.editButton}
                    onClick={handleEditButtonClick}
                  >
                    <CustomText variant="body2">Edit</CustomText>
                  </UnstyledButton>
                )}
              </Stack>
            ) : (
              <MainButton
                smallPadding
                onClick={handleClick}
                icon={disabledText ? null : <ArrangeSquareIcon />}
                disabled={disabledText}
              >
                <CustomText variant="body2">
                  {disabledText || 'Connect'}
                </CustomText>
              </MainButton>
            )}
          </>
        )}
      </Stack>
      {/* Set property modal */}
      {propertyTitle && (
        <BasicModal
          withCloseButton={false}
          opened={opened}
          onClose={() => setOpened(false)}
        >
          <Box
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              top: rem(16),
              right: rem(16),
            }}
            onClick={() => setOpened(false)}
          >
            <Center
              h={24}
              w={24}
              sx={{ borderRadius: '50%', border: '1.5px solid #000' }}
            >
              <CrossIcon />
            </Center>
          </Box>
          <Stack w={rem(455)}>
            <Stack spacing={8}>
              <CustomTitle
                order={1}
                sx={{ fontSize: rem(20), textAlign: 'center' }}
              >
                Integration Configuration
              </CustomTitle>
              <CustomText variant="body1" sx={{ textAlign: 'center' }}>
                Please enter the required configuration details below:
              </CustomText>
            </Stack>
            <Stack spacing={4}>
              <CustomText variant="body2">
                Enter your {propertyTitle}
              </CustomText>
              <CustomInput
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                {...rest}
              />
            </Stack>
            <Center>
              <MainButton
                smallPadding
                onClick={handleConnection}
                sx={{ width: rem(203) }}
                disabled={!isPropertyValid(inputValue)}
              >
                Connect
              </MainButton>
            </Center>
          </Stack>
        </BasicModal>
      )}
      {/* Edit property modal */}
      <BasicModal
        withCloseButton={false}
        opened={
          editModalOpenedExternal === undefined
            ? editModalOpened
            : editModalOpenedExternal
        }
        onClose={() => closeEditModal(false)}
        contentClassName={classes.modalContent}
      >
        <Box
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: rem(16),
            right: rem(16),
          }}
          onClick={() => closeEditModal(false)}
        >
          <Center
            h={24}
            w={24}
            sx={{ borderRadius: '50%', border: '1.5px solid #000' }}
          >
            <CrossIcon />
          </Center>
        </Box>
        <Stack w={rem(455)}>
          <Stack spacing={8}>
            <CustomTitle
              order={1}
              sx={{ fontSize: rem(20), textAlign: 'center' }}
            >
              Integration Configuration
            </CustomTitle>
            <CustomText variant="body1" sx={{ textAlign: 'center' }}>
              Modify any configuration settings as needed:
            </CustomText>
          </Stack>
          {customInputComponent && !useCustomComponentAsExtra ? (
            <>{customInputComponent}</>
          ) : (
            <Stack spacing={0}>
              <Stack spacing={4}>
                <CustomText variant="body2">
                  Enter your {propertyTitle}
                </CustomText>
                <CustomInput
                  value={editInputValue}
                  onChange={(e) => setEditInputValue(e.target.value)}
                  {...rest}
                />
              </Stack>
              {customInputComponent && useCustomComponentAsExtra && (
                <>{customInputComponent}</>
              )}
            </Stack>
          )}
          <Center>
            <Group>
              <MainButton
                smallPadding
                onClick={handleEditing}
                sx={{ width: rem(203) }}
                disabled={
                  !isPropertyValid(editInputValue) && !customInputComponent
                }
              >
                Save
              </MainButton>
              {handleBackButtonClick && (
                <UnstyledButton
                  className={classes.backButton}
                  onClick={handleBackButtonClick}
                >
                  Back
                </UnstyledButton>
              )}
            </Group>
          </Center>
        </Stack>
      </BasicModal>
    </Box>
  );
}

export default IntegrationWrapper;
