import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  planContainer: {
    width: rem(365),
    background: theme.colors.white[3],
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: rem(20),
    padding: rem(24),
  },
  upgradeButton: {
    width: '100%',
    background: theme.colors.yellow[1],

    '&:hover': {
      background: theme.colors.yellow[1],
    },

    '& div': {
      color: '#000',
    },
  },
  outlinedCancelButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: rem(38),
    padding: '8px 16px',
    border: `1px solid ${theme.colors.black[0]}`,
    borderRadius: '100px',
  },
}));
