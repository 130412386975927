import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  input: {
    width: rem(287),
    background: theme.colors.white[2],
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: 0,
    padding: `${rem(8)} ${rem(24)}`,

    '&::placeholder': {
      fontFamily: 'Manrope',
      fontSize: rem(14),
      fontWeight: 400,
      color: theme.colors.gray[4],
    },

    '&:disabled': {
      background: theme.colors.white[2],
      color: theme.colors.black[0],
      opacity: 1,
    },
  },
  inputDefaultDisable: {
    '&:disabled': {
      color: `${theme.colors.gray[4]} !important`,
    }
  },
  firstInput: {
    width: rem(255),
    borderRadius: `${rem(62)} 0 0 ${rem(62)}`,
    borderRight: 'none',

    '&::placeholder': {
      fontWeight: 600,
    },
  },
  lastInput: {
    borderRadius: `0 ${rem(62)} ${rem(62)} 0`,
    borderLeft: 'none',

    '&::placeholder': {
      fontWeight: 500,
    },
  },
  inputWithBorder: {
    borderRight: `${rem(1)} solid ${theme.colors.gray[3]}`,
  },
}));
