import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  filterDropdownContainer: {
    right: rem(41),
    left: 'auto !important',
    transform: `translateY(${rem(8)})`,
    border: 'none',
    padding: 0,
    zIndex: '100 !important',
  },
}));
