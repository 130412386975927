/*
  ***********************************************************************************************
  *****************************************  IMPORTANT  *****************************************
  ***********************************************************************************************

  INFO: any additional ENV vars added to a .env file also need added to vite.config.js
*/

const atcConfig = {
  wwwUrl: process.env.VITE_WWW_BASE_URL,
  delayBeforeRedirection: process.env.VITE_DELAY_BEFORE_REDIRECTION || 3000,
};

const auth0config = {
  tenantUri: process.env.VITE_AUTH0_TENANT_URI,
  clientId: process.env.VITE_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.origin}/auth-callback`,
  audience: process.env.VITE_AUTH0_AUDIENCE,
  appOrigin: `${window.location.origin}`,
  debug: true,
  logoutUri: `${window.location.origin}/logout`,
  claimsNamespace: process.env.VITE_AUTH0_CLAIMS_NAMESPACE,
};

const datadogConfig = {
  applicationId: process.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: process.env.VITE_DATADOG_CLIENT_TOKEN,
  site: process.env.VITE_DATADOG_SITE,
  service: process.env.VITE_DATADOG_SERVICE,
  env: process.env.VITE_DATADOG_ENV,
  version: process.env.VITE_DATADOG_VERSION,
};

const stripeConfig = {
  publicKey: process.env.VITE_STRIPE_PUBLIC_KEY,
};

const appCuesConfig = {
  accountId: process.env.VITE_APP_CUES_ACCOUNT_ID,
};

const apiConfig = {
  baseUrl: process.env.VITE_API_BASE_URL,
};

const contactsFeature = {
  hideInput: true,
};

const passportControlConfig = {
  contentUtilizationRedirectPath: `/onboard-exit-1`,
  testFlightRedirectPath: `/onboard-exit-1`,
  accountAlreadyExistsPath: `/existing-account`,
  baseWWWUrl: `${atcConfig.wwwUrl}`,
  delayBeforeRedirection: atcConfig.delayBeforeRedirection,
};

const redirectionConfig = {
  holdingPattern: `${atcConfig.wwwUrl}/holding-pattern`,
};

const settings = {
  environment: process.env.VITE_ENVIRONMENT,
  integrations: {
    googleAnalytics: false,
    hubspot: true,
    pardot: true,
  },
};


export {
  atcConfig,
  auth0config,
  datadogConfig,
  stripeConfig,
  appCuesConfig,
  apiConfig,
  contactsFeature,
  passportControlConfig,
  redirectionConfig,
  settings,
};
