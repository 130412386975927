import React, { useMemo } from "react";
import { rem, Skeleton } from "@mantine/core";

function SkeletonContainer({ width, height, radius, amount, className }) {
  const skeletons = useMemo(() => {
    return new Array(amount).fill(null).map((_, index) => {
      return (
        <Skeleton key={index} width={rem(width)} height={rem(height)} radius={radius} className={`${className || "skeleton-"}${index}`} />
      );
    });
  }, [width, height, radius, amount, className]);

  return <>{skeletons}</>;
}

export default SkeletonContainer;
