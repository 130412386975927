import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  noCompetitorsBox: {
    background: 'rgba(215, 214, 242, 0.3)',
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: rem(20),
    padding: rem(38),
    boxShadow: `0 ${rem(4)} ${rem(12)} rgba(54, 66, 71, 0.21)`,
  },
}));
