import React from 'react';
import { Box, rem } from '@mantine/core';
import { VictoryPolarAxis, VictoryChart, VictoryScatter } from 'victory';

const MOCKED_DATA = [
  {
    x: 15,
    y: 7,
    extraData: {
      label: 'Architecture',
      engagement: '78% Engagement',
      topicIncidence: '64 Topic incidence',
    },
  },
  {
    x: 10,
    y: 40,
    extraData: {
      label: 'Architecture',
      engagement: '78% Engagement',
      topicIncidence: '64 Topic incidence',
    },
  },
  {
    x: 12,
    y: 30,
    extraData: {
      label: 'Architecture',
      engagement: '78% Engagement',
      topicIncidence: '64 Topic incidence',
    },
  },
  {
    x: 23,
    y: 27,
    extraData: {
      label: 'Architecture',
      engagement: '78% Engagement',
      topicIncidence: '64 Topic incidence',
    },
  },
  {
    x: 45,
    y: 30,
    extraData: {
      label: 'Architecture',
      engagement: '78% Engagement',
      topicIncidence: '64 Topic incidence',
    },
  },
  {
    x: 31,
    y: 44,
    extraData: {
      label: 'Architecture',
      engagement: '78% Engagement',
      topicIncidence: '64 Topic incidence',
    },
  },
  {
    x: 10,
    y: 25,
    extraData: {
      label: 'Architecture',
      engagement: '78% Engagement',
      topicIncidence: '64 Topic incidence',
    },
  },
]

function PolarScatterChart({ data, activePointData, setActivePointData, xDomain, yDomain }) {
  return (
    <Box
      w={390}
      h={390}
    >
      <VictoryChart
        width={850}
        height={850}
        domain={{ x: [0, xDomain], y: [0, yDomain] }}
      >
        <VictoryPolarAxis
          dependentAxis
          tickFormat={() => null}
          style={{
            axis: { stroke: '#ED7470', opacity: 0.5, strokeWidth: rem(2) },
          }}
        />
        <VictoryPolarAxis
          dependentAxis
          tickFormat={() => null}
          axisAngle={90}
          style={{
            axis: { stroke: '#ED7470', opacity: 0.5, strokeWidth: rem(2) },
          }}
        />
        <VictoryPolarAxis
          dependentAxis
          tickFormat={() => null}
          axisAngle={180}
          style={{
            axis: { stroke: '#ED7470', opacity: 0.5, strokeWidth: rem(2) },
          }}
        />
        <VictoryPolarAxis
          dependentAxis
          tickFormat={() => null}
          axisAngle={270}
          style={{
            axis: { stroke: '#ED7470', opacity: 0.5, strokeWidth: rem(2) },
          }}
        />
        <VictoryScatter
          w={390}
          h={390}
          style={{
            data: {
              fill: ({ datum }) =>
                datum.x === activePointData.valueX &&
                datum.y === activePointData.valueY
                  ? '#F6C25D'
                  : '#ED7470',
              filter: ({ datum }) =>
                `drop-shadow(0px 0px 16px ${
                  datum.x === activePointData.valueX &&
                  datum.y === activePointData.valueY
                    ? '#F6C25D'
                    : '#FB706C'
                })`,
              zIndex: 100,
              cursor: 'pointer',
            },
          }}
          data={data || MOCKED_DATA}
          events={[
            {
              target: 'data',
              eventHandlers: {
                onClick: () => [
                  {
                    target: 'data',
                    mutation: (props) => {
                      setActivePointData((prevState) => {
                        return {
                          ...prevState,
                          valueX: props.datum.x,
                          valueY: props.datum.y,
                          tooltipData: props.datum.extraData,

                        }
                      });
                    },
                  },
                ],
              },
            },
          ]}
          size={9}
        />
      </VictoryChart>
    </Box>
  );
}

export default PolarScatterChart;
