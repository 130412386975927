import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  accordionControl: {
    display: 'flex',
    flexGrow: 2,
    background: 'transparent',
    padding: 0,

    '&:hover': {
      borderColor: 'transparent',
    },
  },
  topicItemsContainer: {
    justifyContent: 'flex-start',
    flexGrow: 2,
    maxHeight: rem(37),
    maxWidth: rem(900),
    overflow: 'hidden',
    transition: '0.5s',
  },
  topicItemsContainerExpanded: {
    maxHeight: '100vh',
  },
  dropdown: {
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    padding: 0,
  },
  dropdownFullSizeContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minWidth: rem(73),
    minHeight: rem(34),
  },
  dropdownFullSize: {
    width: `${rem(265)} !important`,
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    background: theme.colors.white[0],
    borderTop: 'none',
    borderRadius: '0 0 8px 8px',
    padding: `${rem(12)} 0 0 0`,
    transform: 'translateX(-70%)',
    left: '0 !important',

    '& button': {
      paddingLeft: rem(16),
    },
  },
}));
