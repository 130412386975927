import { Box, rem } from '@mantine/core';
import React, { useMemo, useCallback, useState } from 'react';
import { useEffect } from 'react';
import CustomText from '../../Typography/CustomText/CustomText';
import CustomSelectionMenuItem from './CustomSelectionMenuItem';

function CustomSelectionBody({
  currentMenuOptions,
  selectedItemTitles,
  onItemSelect,
  setOpened,
  getExternalStyles,
  wihtoutCheckboxes,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = useCallback((option) => {
    setSelectedOption(option);
  }, []);

  useEffect(() => {
    if (!selectedOption) return;
    onItemSelect(selectedOption);
    setSelectedOption(null);
  }, [selectedOption]);

  const menuOptionsList = useMemo(() => {
    if (!currentMenuOptions) return;

    if (currentMenuOptions.length === 0) {
      return (
        <Box pb={rem(8)}>
          <CustomText
            variant="h5"
            sx={{ textAlign: 'center', fontWeight: 500 }}
          >
            No filters found
          </CustomText>
        </Box>
      );
    }

    return currentMenuOptions.map((option, index) => {
      return (
        <CustomSelectionMenuItem
          key={`custom-selection-${index}`}
          option={option}
          selectedItemTitles={selectedItemTitles}
          onItemSelect={handleChange}
          setOpened={setOpened}
          getExternalStyles={getExternalStyles}
          wihtoutCheckboxes={wihtoutCheckboxes}
        />
      );
    });
  }, [currentMenuOptions, selectedItemTitles, onItemSelect]);

  return <>{menuOptionsList}</>;
}

export default CustomSelectionBody;
