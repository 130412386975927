import React, { useMemo, useState } from 'react';
import {
  Box, Flex, Group, Stack, UnstyledButton,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import InputGroup from '../../AddCompetitor/InputGroup/InputGroup';
import { useStyle } from './ContentSourceInputGroup.style';
import CustomText from '../../Typography/CustomText/CustomText';

const inputGroups = [
  [
    {
      placeholder: 'Homepage',
      name: 'homepage',
      static: true,
    },
    {
      placeholder: 'https/www.competitorwebsite.com',
      name: 'homeURL',
    },
  ],
  [
    {
      placeholder: 'Blog',
      name: 'blog',
      static: true,
    },
    {
      placeholder: 'www.site.com/blog',
      name: 'blogURL',
    },
  ],
  [
    {
      placeholder: 'Resources',
      name: 'resources',
      static: true,
    },
    {
      placeholder: 'www.site.com/resources',
      name: 'resourcesURL',
    },
  ],
  [
    {
      placeholder: 'About',
      name: 'about',
      static: true,
    },
    {
      placeholder: 'www.site.com/about',
      name: 'aboutURL',
    },
  ],
  [
    {
      placeholder: 'Product',
      name: 'product',
      static: true,
    },
    {
      placeholder: 'www.site.com/product',
      name: 'productURL',
    },
  ],
];

function ContentSourceGroup({ initialValues, onEdit }) {
  const [editable, setEditable] = useState(false);
  const { classes } = useStyle();

  const form = useForm({
    initialValues: {
      ...initialValues,
    },
  });

  const inputGroupsList = useMemo(() => inputGroups.map((inputGroup) => (
      <InputGroup
        key={inputGroup[0].name}
        inputs={inputGroup}
        firstInputClassName={classes.firstInputClassName}
        disabled={!editable}
        form={form}
      />
  )), [editable, form]);

  return (
    <form onSubmit={form.onSubmit(onEdit)}>
      <Box className={classes.sectionContainerBottom}>
          <Box maw="fit-content">
            <Flex justify="space-between" mb={8}>
              <Group spacing={105}>
                <CustomText variant="h4">
                  Site section
                </CustomText>
                <CustomText variant="h4">
                  Page
                </CustomText>
              </Group>
              <UnstyledButton onClick={() => setEditable((prevState) => !prevState)}>
                <CustomText variant="caption1" sx={(theme) => ({ color: theme.colors.red[0] })}>
                  {editable ? 'Save' : 'Edit'}
                </CustomText>
              </UnstyledButton>
            </Flex>
            <Stack spacing={8}>
                {inputGroupsList}
            </Stack>
          </Box>
      </Box>
    </form>
  );
}

export default ContentSourceGroup;
