import { useEffect, useState } from 'react';
import useFetch from './useFetch';
import useJwtToken from './useJwtToken';
import useRadarApi from './useRadarApi';

import logger from '../utils/logger';

const useIntegrations = () => {
  const [hubspotDetails, setHubspotDetails] = useState(null);
  const [pardotDetails, setPardotDetails] = useState(null);
  const [marketoDetails, setMarketoDetails] = useState(null);
  const [googleAnalyticsDetails, setGoogleAnalyticsDetails] = useState(null);

  const { loading, token } = useJwtToken();
  const { getIntegrations } = useRadarApi();
  const [fetchIntegrations, integrationsLoading] = useFetch(getIntegrations);

  const fetchWrapper = async () => {
    if (!token) return;
    const data = await fetchIntegrations();

    logger.debug('integrations data', data);

    setHubspotDetails(data?.hubspot);
    setPardotDetails(data?.pardot);
    setMarketoDetails(data?.marketo);
    setGoogleAnalyticsDetails(data?.googleAnalytics);
  };

  useEffect(() => {
    if (!loading) {
      fetchWrapper();
    }
  }, [loading]);

  return {
    hubspotDetails,
    pardotDetails,
    marketoDetails,
    googleAnalyticsDetails,
    loading: integrationsLoading,
    refetch: fetchWrapper,
  };
};

export default useIntegrations;
