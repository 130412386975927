import React from 'react';
import { Box, rem } from '@mantine/core';
import CustomText from '../../../../components/Typography/CustomText/CustomText';

function PeopleAlsoAskItem({question, isLast}) {
  return (
    <Box
      sx={(theme) => ({
        borderBottom:
          !isLast &&
          `1px solid ${theme.colors.gray[3]}`,
        padding: `${rem(10)} ${rem(23)} ${rem(10)} 0`,
      })}
    >
      <CustomText variant="body2">{question}</CustomText>
    </Box>
  );
}

export default PeopleAlsoAskItem;
