import { Box } from '@mantine/core';
import React from 'react';

function LoginWrapper({ children, ...rest }) {
  return (
    <Box w={656} mx="auto" {...rest}>
      {children}
    </Box>
  );
}

export default LoginWrapper;
