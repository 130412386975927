import { Box } from "@mantine/core";
import { useStyle } from "../RadarChart.style";

const BACKGROUND_BLUR = 0 //now BACKGROUND_BLUR is disabled
const BLUR = 0 //now BLUR is disabled

const BlurQuadrants = ({getIsQuadrantActive, updateActiveQuadrant}) => {
  const { classes } = useStyle()

  return (
    <>
      <Box
        h={200}
        w={200}
        className={classes.chartHiddenPart}
        sx={{
          backdropFilter: !getIsQuadrantActive(2) && `BACKGROUND_BLUR(${BACKGROUND_BLUR}px)`,
          background: !getIsQuadrantActive(2) && `rgba(12, 10, 68, ${BLUR})`,
          transform: 'translate(-50%, 50%)',
          borderRadius: '0px 0px 0px 100%',
          zIndex: getIsQuadrantActive(2) && 0,
        }}
        onClick={() => updateActiveQuadrant(2)}
      />
      <Box
        h={200}
        w={200}
        className={classes.chartHiddenPart}
        sx={{
          backdropFilter: !getIsQuadrantActive(3) && `BACKGROUND_BLUR(${BACKGROUND_BLUR}px)`,
          background: !getIsQuadrantActive(3) && `rgba(12, 10, 68, ${BLUR})`,
          transform: 'translate(-50%, -50%)',
          borderRadius: '100% 0px 0px 0px',
          zIndex: getIsQuadrantActive(3) && 0,
        }}
        onClick={() => updateActiveQuadrant(3)}
      />
      <Box
        h={200}
        w={200}
        className={classes.chartHiddenPart}
        sx={{
          backdropFilter: !getIsQuadrantActive(4) && `BACKGROUND_BLUR(${BACKGROUND_BLUR}px)`,
          background: !getIsQuadrantActive(4) && `rgba(12, 10, 68, ${BLUR})`,
          transform: 'translate(50%, 50%)',
          borderRadius: '0px 0px 100% 0px',
          zIndex: getIsQuadrantActive(4) && 0,
        }}
        onClick={() => updateActiveQuadrant(4)}
      />
      <Box
        h={200}
        w={200}
        className={classes.chartHiddenPart}
        sx={{
          backdropFilter: !getIsQuadrantActive(1) && `BACKGROUND_BLUR(${BACKGROUND_BLUR}px)`,
          background: !getIsQuadrantActive(1) && `rgba(12, 10, 68, ${BLUR})`,
          transform: 'translate(50%, -50%)',
          borderRadius: '0px 100% 0px 0px',
          zIndex: getIsQuadrantActive(1) && 0,
        }}
        onClick={() => updateActiveQuadrant(1)}
      />
    </>
  );
};

export default BlurQuadrants;
