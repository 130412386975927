import { useCallback } from 'react';

import {
  getContents,
  createContent,
  getCompetitorAnalysisTimeSeries,
  getCompetitorAnalysisMetrics,
  getCompetitorsContents,
  getCompetitorsTopics,
  getCompetitorMentions,
  getContentSummary,
  getContentInteractions,
  getContentRelatedArticles,
  getContentRelatedSocialPosts,
  updateContentInteractions,
  getContacts,
  getContactsSummary,
  getAccounts,
  getAccountsSummary,
  updateSocialEnrichments,
  getHubspotAuthURL,
  getPardotAuthURL,
  getGoogleAnalyticsAuthURL,
  getContentsToPromote,
  getContentsUtilization,
  getContentDetails,
  getContentDetailsRelatedContent,
  getAudienceRelevance,
  getTopicSummaries,
  getIncidenceAndEngagement,
  getTopicInterestedContacts,
  getTopicEngagementRate,
  getTopicCoverageScore,
  getTopicDetailContent,
  getTopicOtherAudienceInterests,
  getTopicPeopleAlsoAsk,
  getTopicContactMatches,
  enrichTopic,
  getRadarContentToPromote,
  getRadarTopicsToWriteAbout,
  getRadarTopicsIncidenceAndEngagement,
  getTopicValue,
  getRadarPersonalizationScore,
  getRadarContentInfluencedRevenue,
  getRadarNewPersonalizedEngagementsScore,
  getRadarPersonalizedContentEngagementRate,
  getCredits,
  getIntegrations,
  patchIntegration,
  getHubspotContactLists,
  patchContentDetails,
  getPardotContactLists,
  getSubscriptionPlans,
  getActiveSubscriptionPlan,
  createAccount,
  getStripeClientSecret,
  getOrganizationSettings,
  updateOrganizationSettings,
  updateSubscriptionPlan,
  getPortalSession,
  getUsersFromTeam,
  addUserToTeam,
  deleteUserFromTeam,
  getMemberships,
  updateUserContext,
} from '../services/radarApiService.js';
import { apiConfig } from '../config.js';
import useJwtToken from './useJwtToken.js';

const useRadarApi = () => {
  const { token } = useJwtToken();

  const apiBaseUrl = apiConfig.baseUrl;

  const getContentsWithAuth = useCallback(
    async (queryParams) => getContents(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getContentsToPromoteWithAuth = useCallback(
    async (queryParams) => getContentsToPromote(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getContentsUtilizationWithAuth = useCallback(
    async (queryParams) =>
      getContentsUtilization(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getContentDetailsWithAuth = useCallback(
    async (id, queryParams) =>
      getContentDetails(token, apiBaseUrl, id, queryParams),
    [apiBaseUrl, token],
  );

  const getContentDetailsRelatedContentWithAuth = useCallback(
    async (id, queryParams) =>
      getContentDetailsRelatedContent(token, apiBaseUrl, id, queryParams),
    [apiBaseUrl, token],
  );

  const createContentWithAuth = useCallback(
    async (data) => createContent(token, apiBaseUrl, data),
    [apiBaseUrl, token],
  );

  const getContactsWithAuth = useCallback(
    async (queryParams) => getContacts(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getContactsSummaryWithAuth = useCallback(
    async (queryParams) => getContactsSummary(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getAccountsWithAuth = useCallback(
    async (queryParams) => getAccounts(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getAccountsSummaryWithAuth = useCallback(
    async (queryParams) => getAccountsSummary(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const updateContactSocialEnrichmentsWithAuth = useCallback(
    async (body) => updateSocialEnrichments(token, apiBaseUrl, body),
    [apiBaseUrl, token],
  );

  const getCompetitorAnalysisTimeSeriesWithAuth = useCallback(
    async (queryParams) =>
      getCompetitorAnalysisTimeSeries(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getCompetitorAnalysisMetricsWithAuth = useCallback(
    async (queryParams) =>
      getCompetitorAnalysisMetrics(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getCompetitorsContentsWithAuth = useCallback(
    async (queryParams) =>
      getCompetitorsContents(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getCompetitorsTopicsWithAuth = useCallback(
    async (queryParams) => getCompetitorsTopics(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getCompetitorMentionsWithAuth = useCallback(
    async (queryParams) =>
      getCompetitorMentions(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getContentSummaryWithAuth = useCallback(
    async (queryParams) => getContentSummary(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );
  const getContentInteractionsWithAuth = useCallback(
    async (queryParams) =>
      getContentInteractions(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );
  const getContentRelatedArticlesWithAuth = useCallback(
    async (queryParams) =>
      getContentRelatedArticles(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );
  const getContentRelatedSocialPostsWithAuth = useCallback(
    async (queryParams) =>
      getContentRelatedSocialPosts(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getHubspotAuthURLWithAuth = useCallback(
    async (queryParams) => getHubspotAuthURL(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getPardotAuthURLWithAuth = useCallback(
    async (queryParams) => getPardotAuthURL(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getGoogleAnalyticsAuthURLWithAuth = useCallback(
    async (queryParams) =>
      getGoogleAnalyticsAuthURL(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const updateContentInteractionsWithAuth = useCallback(
    async (body) => updateContentInteractions(token, apiBaseUrl, body),
    [apiBaseUrl, token],
  );

  const getAudienceRelevanceWithAuth = useCallback(
    async (queryParams) => getAudienceRelevance(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getTopicsSummariesWithAuth = useCallback(
    async (queryParams) => getTopicSummaries(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getIncidenceAndEngagementWithAuth = useCallback(
    async (queryParams) =>
      getIncidenceAndEngagement(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getTopicInterestedContactsWithAuth = useCallback(
    async (id, queryParams) =>
      getTopicInterestedContacts(token, apiBaseUrl, id, queryParams),
    [apiBaseUrl, token],
  );

  const getTopicEngagementRateWithAuth = useCallback(
    async (id, queryParams) =>
      getTopicEngagementRate(token, apiBaseUrl, id, queryParams),
    [apiBaseUrl, token],
  );

  const getTopicCoverageScoreWithAuth = useCallback(
    async (id) => getTopicCoverageScore(token, apiBaseUrl, id),
    [apiBaseUrl, token],
  );

  const getTopicValueWithAuth = useCallback(
    async (id) => getTopicValue(token, apiBaseUrl, id),
    [apiBaseUrl, token],
  );

  const getTopicDetailContentWithAuth = useCallback(
    async (id, queryParams) =>
      getTopicDetailContent(token, apiBaseUrl, id, queryParams),
    [apiBaseUrl, token],
  );

  const getTopicOtherAudienceInterestsWithAuth = useCallback(
    async (id) => getTopicOtherAudienceInterests(token, apiBaseUrl, id),
    [apiBaseUrl, token],
  );

  const getTopicPeopleAlsoAskWithAuth = useCallback(
    async (id) => getTopicPeopleAlsoAsk(token, apiBaseUrl, id),
    [apiBaseUrl, token],
  );

  const getTopicContactMatchesWithAuth = useCallback(
    async (id) => getTopicContactMatches(token, apiBaseUrl, id),
    [apiBaseUrl, token],
  );

  const enrichTopicWithAuth = useCallback(
    async (body) => enrichTopic(token, apiBaseUrl, body),
    [apiBaseUrl, token],
  );

  const getRadarContentToPromoteWithAuth = useCallback(
    async () => getRadarContentToPromote(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const getRadarTopicsToWriteAboutWithAuth = useCallback(
    async () => getRadarTopicsToWriteAbout(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const getRadarTopicsIncidenceAndEngagementWithAuth = useCallback(
    async (queryParams) =>
      getRadarTopicsIncidenceAndEngagement(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getRadarPersonalizationScoreWithAuth = useCallback(
    async (queryParams) =>
      getRadarPersonalizationScore(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getRadarContentInfluencedRevenueWithAuth = useCallback(
    async (queryParams) =>
      getRadarContentInfluencedRevenue(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getRadarNewPersonalizedEngagementsScoreWithAuth = useCallback(
    async (queryParams) =>
      getRadarNewPersonalizedEngagementsScore(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getRadarPersonalizedContentEngagementRateWithAuth = useCallback(
    async (queryParams) =>
      getRadarPersonalizedContentEngagementRate(token, apiBaseUrl, queryParams),
    [apiBaseUrl, token],
  );

  const getCreditsWithAuth = useCallback(
    async () => getCredits(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const getIntegrationsWithAuth = useCallback(
    async () => getIntegrations(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const getHubspotContactListsWithAuth = useCallback(
    async () => getHubspotContactLists(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const getPardotContactListsWithAuth = useCallback(
    async () => getPardotContactLists(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const patchIntegrationWithAuth = useCallback(
    async (id, body) => patchIntegration(token, apiBaseUrl, id, body),
    [apiBaseUrl, token],
  );

  const patchContentDetailsWithAuth = useCallback(
    async (id, body) => patchContentDetails(token, apiBaseUrl, id, body),
    [apiBaseUrl, token],
  );

  const getSubscriptionPlansWithAuth = useCallback(
    async () => getSubscriptionPlans(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const updateSubscriptionPlansWithAuth = useCallback(
    async (body) => updateSubscriptionPlan(token, apiBaseUrl, body),
    [apiBaseUrl, token],
  );

  const createAccountWithAuth = useCallback(
    async (body) => createAccount(token, apiBaseUrl, body),
    [apiBaseUrl, token],
  );

  const getActiveSubscriptionPlanWithAuth = useCallback(
    async (orgId) => getActiveSubscriptionPlan(token, apiBaseUrl, orgId),
    [apiBaseUrl, token],
  );

  const getStripeClientSecretWithAuth = useCallback(
    async () => getStripeClientSecret(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const getOrganizationSettingsWithAuth = useCallback(
    async () => getOrganizationSettings(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const updateOrganizationSettingsWithAuth = useCallback(
    async (body) => updateOrganizationSettings(token, apiBaseUrl, body),
    [apiBaseUrl, token],
  );

  const getPortalSessionWithAuth = useCallback(
    async () => getPortalSession(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const getUsersFromTeamWithAuth = useCallback(
    async () => getUsersFromTeam(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const addUserToTeamWithAuth = useCallback(
    async (body) => addUserToTeam(token, apiBaseUrl, body),
    [apiBaseUrl, token],
  );

  const deleteUserFromTeamWithAuth = useCallback(
    async (userId) => deleteUserFromTeam(token, apiBaseUrl, userId),
    [apiBaseUrl, token],
  );

  const getMembershipsWithAuth = useCallback(
    async () => getMemberships(token, apiBaseUrl),
    [apiBaseUrl, token],
  );

  const updateUserContextWithAuth = useCallback(
    async (body) => updateUserContext(token, apiBaseUrl, body),
    [apiBaseUrl, token],
  );

  return {
    getContents: getContentsWithAuth,
    getContentsToPromote: getContentsToPromoteWithAuth,
    getContentsUtilization: getContentsUtilizationWithAuth,
    getContentDetails: getContentDetailsWithAuth,
    getContentDetailsRelatedContent: getContentDetailsRelatedContentWithAuth,
    createContent: createContentWithAuth,
    getCompetitorAnalysisTimeSeries: getCompetitorAnalysisTimeSeriesWithAuth,
    getCompetitorAnalysisMetrics: getCompetitorAnalysisMetricsWithAuth,
    getCompetitorsContents: getCompetitorsContentsWithAuth,
    getCompetitorsTopics: getCompetitorsTopicsWithAuth,
    getCompetitorMentions: getCompetitorMentionsWithAuth,
    getContentSummary: getContentSummaryWithAuth,
    getContentInteractions: getContentInteractionsWithAuth,
    getContentRelatedArticles: getContentRelatedArticlesWithAuth,
    getContentRelatedSocialPosts: getContentRelatedSocialPostsWithAuth,
    updateContentInteractions: updateContentInteractionsWithAuth,
    getContacts: getContactsWithAuth,
    getContactsSummary: getContactsSummaryWithAuth,
    getAccounts: getAccountsWithAuth,
    getAccountsSummary: getAccountsSummaryWithAuth,
    updateSocialEnrichments: updateContactSocialEnrichmentsWithAuth,
    getHubspotAuthURL: getHubspotAuthURLWithAuth,
    getPardotAuthURL: getPardotAuthURLWithAuth,
    getGoogleAnalyticsAuthURL: getGoogleAnalyticsAuthURLWithAuth,
    getAudienceRelevance: getAudienceRelevanceWithAuth,
    getTopicSummaries: getTopicsSummariesWithAuth,
    getIncidenceAndEngagement: getIncidenceAndEngagementWithAuth,
    getTopicInterestedContacts: getTopicInterestedContactsWithAuth,
    getTopicEngagementRate: getTopicEngagementRateWithAuth,
    getTopicCoverageScore: getTopicCoverageScoreWithAuth,
    getTopicValue: getTopicValueWithAuth,
    getTopicDetailContent: getTopicDetailContentWithAuth,
    getTopicOtherAudienceInterests: getTopicOtherAudienceInterestsWithAuth,
    getTopicPeopleAlsoAsk: getTopicPeopleAlsoAskWithAuth,
    getTopicContactMatches: getTopicContactMatchesWithAuth,
    enrichTopic: enrichTopicWithAuth,
    getRadarContentToPromote: getRadarContentToPromoteWithAuth,
    getRadarTopicsToWriteAbout: getRadarTopicsToWriteAboutWithAuth,
    getRadarTopicsIncidenceAndEngagement:
      getRadarTopicsIncidenceAndEngagementWithAuth,
    getRadarPersonalizationScore: getRadarPersonalizationScoreWithAuth,
    getRadarContentInfluencedRevenue: getRadarContentInfluencedRevenueWithAuth,
    getRadarNewPersonalizedEngagementsScore:
      getRadarNewPersonalizedEngagementsScoreWithAuth,
    getRadarPersonalizedContentEngagementRate:
      getRadarPersonalizedContentEngagementRateWithAuth,
    getCredits: getCreditsWithAuth,
    getIntegrations: getIntegrationsWithAuth,
    getHubspotContactLists: getHubspotContactListsWithAuth,
    getPardotContactLists: getPardotContactListsWithAuth,
    patchIntegration: patchIntegrationWithAuth,
    patchContentDetails: patchContentDetailsWithAuth,
    getSubscriptionPlans: getSubscriptionPlansWithAuth,
    updateSubscriptionPlan: updateSubscriptionPlansWithAuth,
    createAccount: createAccountWithAuth,
    getActiveSubscriptionPlan: getActiveSubscriptionPlanWithAuth,
    getStripeClientSecret: getStripeClientSecretWithAuth,
    getOrganizationSettings: getOrganizationSettingsWithAuth,
    updateOrganizationSettings: updateOrganizationSettingsWithAuth,
    getPortalSession: getPortalSessionWithAuth,
    getUsersFromTeam: getUsersFromTeamWithAuth,
    addUserToTeam: addUserToTeamWithAuth,
    deleteUserFromTeam: deleteUserFromTeamWithAuth,
    getMemberships: getMembershipsWithAuth,
    updateUserContext: updateUserContextWithAuth,
  };
};

export default useRadarApi;
