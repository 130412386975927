import { Box, Flex, rem, Stack } from '@mantine/core';
import { useState } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import MainButton from '../../../components/buttons/buttons';
import CompetitorContentCard from '../../../components/CompetitorContentCard/CompetitorContentCard';
import SkeletonContainer from '../../../components/SkeletonContainer';
import TableWithSorting from '../../../components/TableWithSorting/TableWithSorting';
import CustomText from '../../../components/Typography/CustomText/CustomText';
import useJwtToken from '../../../hooks/useJwtToken';
import useNavigateWithState from '../../../hooks/useNavigateWithState';
import useRadarApi from '../../../hooks/useRadarApi';
import useTable from '../../../hooks/useTable';
import { useStyle } from '../ContactsPage.style';

import logger from '../../../utils/logger';

const AccountRowExpanded = ({ id, data, firstColumnData }) => {
  const [accountsSummary, setAccountsSummary] = useState(null);
  const [accountsSummaryLoading, setAccountsSummaryLoading] = useState(true);
  const { getAccountsSummary } = useRadarApi();
  const { loading } = useJwtToken();
  const { classes } = useStyle();
  const { getTableData } = useTable();
  const navigateWithState = useNavigateWithState()

  const tableData = useMemo(() => {
    return getTableData(
      accountsSummary?.topics || [],
      ['_id', 'Topic name', 'Interested contacts'],
      [
        {
          columnSpan: 0,
        },
        {
          columnSpan: 6,
        },
        {
          columnSpan: 6,
        },
      ],
      true,
    );
  }, [accountsSummary]);

  const generateContentCards = useCallback(() => {
    if (!accountsSummary) return;

    const elements = [];

    accountsSummary.contents.forEach((content, index) => {
      elements.push(
        <CompetitorContentCard
          key={index}
          image={content.imageUrl}
          title={content.title}
          publishDate={content.publishDate}
          source={content.articleType}
          smallCard
          onClick={() => navigateWithState(`/contents/detail/${content.id}`)}
        />,
      );
    });

    return elements;
  }, [accountsSummary]);

  const fetchAccountsSummary = useCallback(async () => {
    try {
      const data = await getAccountsSummary(id);
      setAccountsSummary(data);
      setAccountsSummaryLoading(false);
    } catch (error) {
      setAccountsSummaryLoading(false);
      logger.error('Failed to fetch accounts summary data:', error);
    }
  }, [getAccountsSummary, id]);

  useEffect(() => {
    if (!loading) {
      fetchAccountsSummary();
    }
  }, [loading]);

  return (
    <Box className={classes.rowExpandedAccount}>
      <Stack spacing={8}>
        <CustomText variant="h4">Top Topics for Organization</CustomText>
        <Flex>
          <Box w="50%">
            <TableWithSorting
              tableData={tableData}
              loading={!accountsSummary}
              withScroll
              wrapperClassName={classes.tableWrapper}
              rowClassName={classes.tableRow}
            />
          </Box>
          <Flex w="50%" justify="flex-end">
            <MainButton
              sx={{ height: rem(36) }}
              onClick={() => data.onClick(firstColumnData?.title)}
            >
              See account Contacts
            </MainButton>
          </Flex>
        </Flex>
      </Stack>
      <Stack mt={22} mb={8}>
        <CustomText variant="h4">
          Recommended Content for Organization
        </CustomText>
        <Flex sx={{ rowGap: 16, columnGap: 12, flexWrap: 'wrap' }}>
          {!accountsSummaryLoading ? (
            <>{generateContentCards()}</>
          ) : (
            <SkeletonContainer width={340} height={90} radius={12} amount={6} />
          )}
        </Flex>
      </Stack>
    </Box>
  );
};

export default AccountRowExpanded;
