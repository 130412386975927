import React from 'react';
import { Box } from '@mantine/core';
import { useStyle } from './Container.style';

function Container({ children, className, sx }) {
  const { classes, cx } = useStyle();

  return (
    <Box className={cx(classes.container, className)} sx={sx}>
      {children}
    </Box>
  );
}

export default Container;
