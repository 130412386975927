import React from 'react'
import { Box, Center, Flex, Group, rem, Stack, UnstyledButton } from '@mantine/core'
import CustomTitle from '../../Typography/CustomTitle/CustomTitle'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg';
import { ReactComponent as ArrowLeftBoldIcon } from '../../../assets/icons/arrow-left-bold-icon.svg';
import { useStyle } from './GuidedTourModal.style'
import MainButton from '../../buttons/buttons';
import CustomText from '../../Typography/CustomText/CustomText';
import { useSelector } from 'react-redux';

function GuidedTourModal({children, title, opened, onClick, onClose}) {
  const { classes } = useStyle()
  const tourActive = useSelector((state) => state.profile.guide.tourActive);

  return (
    <>
      {(opened && tourActive) && (
        <Box className={classes.wrapper}>
          <Flex justify="space-between" className={classes.heading}>
            <CustomTitle order={1} sx={{fontSize: rem(20)}}>
              {title}
            </CustomTitle>
            <UnstyledButton onClick={onClose}>
              <CloseIcon style={{width: rem(24), height: rem(24)}} />
            </UnstyledButton>
          </Flex>
          <Box px={24} pt={16} pb={24}>
            <Stack spacing={24}>
              <Box>
                {children}
              </Box>
              {onClick && (
                <MainButton
                  smallPadding
                  reversed
                  sx={{width: "fit-content"}}
                  onClick={onClick}
                >
                    <Group spacing={8}>
                        <CustomText variant="body2">
                            Continue
                        </CustomText>
                        <Box sx={{
                            "& path": {
                                stroke: "#fff"
                            }
                        }}>
                            <Center>
                                <ArrowLeftBoldIcon style={{transform: "rotate(180deg)"}} />
                            </Center>
                        </Box>
                    </Group>
                </MainButton>
              )}
            </Stack>
          </Box>
        </Box>
      )}
    </>
  )
}

export default GuidedTourModal