import dayjs from 'dayjs';
import React, { useRef, useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { convertRemToPixels } from '../../utils';
import { TooltipComponentCallback } from './TooltipComponent';

import logger from '../../utils/logger';

const createDataset = (companyName, companyData, companyColor, gradient) => ({
  label: companyName,
  fill: 'origin',
  lineTension: 0.1,
  pointRadius: 0,
  backgroundColor: gradient,
  borderColor: companyColor,
  data: companyData,
  pointHoverRadius: convertRemToPixels(0.313),
  pointHoverBackgroundColor: '#0C0A44',
  pointHoverBorderColor: '#ED7470',
  pointHoverBorderWidth: convertRemToPixels(0.125),
});

const LineChartWithBackground = ({
  labels,
  chartData,
  secondProperty,
  dateList,
  getTooltipLabel,
}) => {
  const [datasets, setDatesets] = useState([]);
  const lineRef = useRef(null);

  useEffect(() => {
    if (!lineRef.current) return;

    const getGradient = (item) => {
      const gradient = lineRef.current.ctx.createLinearGradient(
        0,
        lineRef.current.chartArea.bottom,
        0,
        lineRef.current.chartArea.top,
      );
      gradient.addColorStop(0, item.gradient.endColor);
      gradient.addColorStop(1, item.gradient.startColor);

      return gradient;
    };
    setDatesets(
      chartData.map((item) =>
        createDataset(item.name, item.data, item.chartColor, getGradient(item)),
      ),
    );
  }, [chartData]);

  const getTooltipDate = (data) => {
    const dataIndex = chartData[0].data.findIndex(
      (el) => el === parseInt(data[0]),
    );
    return dayjs(dateList[dataIndex]).format('DD MMM YYYY');
  };

  const getTooltipValue = (data) => {
    if (getTooltipLabel) {
      return getTooltipLabel(data);
    }
    return data;
  };

  const getDataMaxCount = () => {
    if(!chartData[0].data?.length) return 0
    return chartData[0].data.reduce((acc, curr) => {
      if (curr > acc) {
        return curr;
      }
      return acc;
    });
  };

  const options = {
    maintainAspectRatio: false,
    clip: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,
        external: (context) =>
          TooltipComponentCallback(
            context,
            getTooltipDate,
            secondProperty,
            getTooltipValue,
            '#0C0A44',
            true,
          ),
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: '#0F0E24',
          font: {
            size: convertRemToPixels(0.625),
            family: 'Manrope',
            weight: 500,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        max: getDataMaxCount() > 2 ? getDataMaxCount() : 5,
        ticks: {
          color: '#0F0E24',
          autoSkip: false,
          steps: 10,
          font: {
            size: convertRemToPixels(0.625),
            family: 'Manrope',
            weight: 500,
          },
          callback: function(value) {
            if (Math.floor(value) === value) {
                return value;
            }
          }
        },
      },
    },
  };

  const data = { labels, datasets };

  logger.info('line data', data);

  return <Line ref={lineRef} data={data} options={options} />;
};

export default LineChartWithBackground;
