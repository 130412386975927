import { useCallback, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';

const useFetchContentDetails = (jwtTokenLoading, urlDateParams, contentId) => {
  const { getContentDetails } = useRadarApi();
  const [contentDetails, setContentDetails] = useState({});
  const [fetchContentDetails, loading] = useFetch(getContentDetails, contentId);

  const fetchWrapper = useCallback(async () => {
    const data = await fetchContentDetails({
      dateFilter: JSON.stringify(urlDateParams),
    });
    setContentDetails(data);
  }, [fetchContentDetails, getContentDetails, urlDateParams]);

  useEffect(() => {
    if (!jwtTokenLoading) {
      fetchWrapper();
    }
  }, [jwtTokenLoading, JSON.stringify(urlDateParams)]);

  return [contentDetails, loading];
};

export default useFetchContentDetails;
