import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import useFetch from "../../../hooks/useFetch";

const useFetchTopicsDetails = (jwtTokenLoading, id, getTopicsFunc, urlDateParams) => {
  const [topicsData, setTopicsData] = useState([]);
  const [fetchFunc, loading] = useFetch(getTopicsFunc, id);

  const fetchWrapper = useCallback(async () => {
    let params = '';

    if(urlDateParams) {
        params = {
            dateFilter: JSON.stringify(urlDateParams)
        }
    }

    const data = await fetchFunc(params);
    setTopicsData(data)
  }, [fetchFunc, getTopicsFunc, JSON.stringify(urlDateParams)]);

  useEffect(() => {
    if (!jwtTokenLoading) {
      fetchWrapper();
    }
  }, [jwtTokenLoading, JSON.stringify(urlDateParams)]);

  return [topicsData, loading];
};

export default useFetchTopicsDetails;
