import React from 'react';
import { Box, Flex, rem, Stack } from '@mantine/core';
import { useStyle } from './BigStatsContainner.style';
import StatsDifference from '../../TableWithSorting/components/StatsDifference/StatsDifference';
import CustomTitle from '../../Typography/CustomTitle/CustomTitle';
import CustomText from '../../Typography/CustomText/CustomText';
import SkeletonContainer from '../../SkeletonContainer';

function BigStatsContainer({
  children,
  percentValue,
  withoutChangeIndicator,
  positive,
  title,
  description,
  height,
  showWithoutValue,
  loading,
  ...rest
}) {
  const { classes } = useStyle();

  return (
    <Box h={rem(height)} className={classes.statsContainer}>
      <Flex
        h="100%"
        sx={{ flexDirection: 'column', justifyContent: 'space-between' }}
      >
        {loading ? (
          <>
            <SkeletonContainer width={75} height={40} amount={1} />
            <SkeletonContainer width={200} height={50} amount={1} />
            <SkeletonContainer width={200} height={30} amount={1} />
          </>
        ) : (
          <>
          <Box w="fit-content" h={40}>
            {!withoutChangeIndicator && (
                <StatsDifference
                  difference={percentValue}
                  positive={positive}
                  textVariant="h5"
                  customClassName={classes.statDifference}
                  showWithoutValue={showWithoutValue}
                  {...rest}
                />
            )}
            </Box>
            <CustomTitle order={0} sx={{ fontSize: rem(64) }}>
              {title}
            </CustomTitle>
            <Stack spacing={0}>
              <CustomText variant="h5">{description}</CustomText>
              {children && <>{children}</>}
            </Stack>
          </>
        )}
      </Flex>
    </Box>
  );
}

export default BigStatsContainer;
