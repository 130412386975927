import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  card: {
    height: rem(141),
    width: rem(236),
    justifyContent: "center",
    alignItems: "center",
    border: `${rem(1)} solid ${theme.colors.white[1]}`,
    borderRadius: rem(20),
    cursor: "pointer",
    background: theme.colors.white[2],

    "&:hover": {
        boxShadow: "0px 4px 12px 0px rgba(54, 66, 71, 0.21)",
    }
  }
}));
