import React from 'react';
import { ReactComponent as RadarIcon } from '../../assets/icons/radar-icon.svg';
import { ReactComponent as TopicsIcon } from '../../assets/icons/topics-icon.svg';
import { ReactComponent as ContactsIcon } from '../../assets/icons/contacts-icon.svg';
import { ReactComponent as ContentsIcon } from '../../assets/icons/contents-icon.svg';
import { ReactComponent as CompetitionIcon } from '../../assets/icons/competition-icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings-sidebar-icon.svg';
import { ReactComponent as UpgradeIcon } from '../../assets/icons/upgrade-icon.svg';
import { ReactComponent as QuickStartIcon } from '../../assets/icons/quick-start-icon.svg';

export const SidebarData = {
  top: [
    {
      title: 'Quick Start',
      path: '/quick-start',
      icon: <QuickStartIcon />,
      key: 'quick-start'
    },
    {
      title: 'Radar',
      path: '/',
      icon: <RadarIcon />,
      key: 'radar'
    },
    {
      title: 'Topics',
      path: '/topics',
      icon: <TopicsIcon />,
      key: 'topics'
    },
    {
      title: 'Contacts',
      path: '/contacts',
      icon: <ContactsIcon />,
      key: 'contacts'
    },
    {
      title: 'Content',
      path: '/contents',
      icon: <ContentsIcon />,
      key: 'content'
    },
    {
      title: 'Competition',
      path: '/competitors',
      icon: <CompetitionIcon />,
      key: 'competitors'
    },
  ],
  bottom: [
    // {
    //   title: 'Getting Started',
    //   path: '/getting-started',
    //   icon: <GettingStartedIcon />,
    // },
    {
      title: 'Settings',
      path: '/settings',
      icon: <SettingsIcon />,
      key: 'settings'
    },
    {
      title: 'Upgrade',
      path: '/plan?upgrade=true',
      icon: <UpgradeIcon />,
      renderAsButton: true,
    },
  ],
};
