export const TABS = ['People', 'Accounts'];

export const LIST_KEY = 'list';

export const PARAMS_KEYS = [
  'sortBy',
  'sortDirection',
  'filter[company]',
  'filter[industry]',
  'filter[stage]',
  'filter[jobTitle]',
  'filter[customer]',
  'list',
  'insights',
  'contacts',
  'tab',
];

export const DEFAULT_URL_PARAMS = {
  sortBy: '',
  sortDirection: '',
  'filter[company]': [],
  'filter[industry]': [],
  'filter[stage]': [],
  'filter[jobTitle]': [],
  'filter[customer]': [],
  list: '',
  insights: [],
  contacts: [],
  tab: [],
};

export const DEFAULT_CONTACTS_FILTER_OPTIONS = [
  {
    title: 'Company',
    options: [],
    key: 'filter[company]',
    distinctValuesKey: 'uniqueCompanies',
  },
  // {
  //   title: 'Industry',
  //   options: [],
  //   key: 'filter[industry]',
  //   distinctValuesKey: 'uniqueIndustries',
  // },
  // {
  //   title: 'Stage',
  //   options: [],
  //   key: 'filter[stage]',
  //   distinctValuesKey: 'uniqueStages',
  // },
  {
    title: 'Lists',
    options: [],
    key: 'list',
    distinctValuesKey: 'uniqueListIds',
  },
  {
    title: 'Job Titles',
    options: [],
    key: 'filter[jobTitle]',
    distinctValuesKey: 'uniqueJobTitles',
  },
  {
    options: [],
    distinctValuesKey: 'insightsCountRange',
  },
];

export const DEFAULT_ACCOUNTS_FILTER_OPTIONS = [
  {
    title: 'Company',
    options: [],
    key: 'filter[company]',
    distinctValuesKey: 'uniqueCompanies',
  },
  {
    title: 'Industry',
    options: [],
    key: 'filter[industry]',
    distinctValuesKey: 'uniqueIndustries',
  },
  {
    title: 'Stage',
    options: [],
    key: 'filter[stage]',
    distinctValuesKey: 'uniqueStages',
  },
  {
    title: 'Job Titles',
    options: [],
    key: 'filter[jobTitle]',
    distinctValuesKey: 'uniqueJobTitles',
  },
  {
    options: [],
    distinctValuesKey: 'insightsCountRange',
  },
  {
    options: [],
    distinctValuesKey: 'contactsCountRange',
  },
];
