import { Box, Center, Flex, rem, Stack, UnstyledButton } from '@mantine/core'
import React from 'react'
import MainButton from '../../buttons/buttons'
import CustomText from '../../Typography/CustomText/CustomText'
import CustomTitle from '../../Typography/CustomTitle/CustomTitle'
import { useStyle } from './GuideModalWrapper.style'

function GuideModalWrapper({title, children, prevButton, nextButton, onConfirm, onCancel}) {
  const { classes, cx } = useStyle()

  return (
    <Box>
        <Stack spacing={24}>
            <CustomTitle order={1} sx={{fontSize: rem(20)}}>
                {title}
            </CustomTitle>
            {children}
            <Flex justify="space-between">
                <UnstyledButton
                    onClick={onCancel}
                    className={cx(classes.modalButton, classes.secondaryButton)}
                >
                    <Center>
                        <CustomText variant="body2">
                            {prevButton || "Back"}
                        </CustomText>
                    </Center>
                </UnstyledButton>
                <MainButton
                    onClick={onConfirm}
                    smallPadding
                    customClassName={classes.modalButton}
                >
                    {nextButton || "Continue"}
                </MainButton>
            </Flex>
        </Stack>
    </Box>
  )
}

export default GuideModalWrapper