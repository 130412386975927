import { Box, rem, Stack } from '@mantine/core';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import MainButton from '../../buttons/buttons';
import ContentWithImage from '../../ContentWithImage/ContentWithImage';
import Heading from '../../Heading';
import CustomText from '../../Typography/CustomText/CustomText';
import InputGroup from '../components/InputGroup/InputGroup';
import Stepper from '../components/Stepper/Stepper';

const SITE_URL_TEMPLATE = 'www.site.com';

function AddSiteSection({
  children,
  website,
  dispatchFunc,
  onContinue,
  title,
  description,
  stepIndex,
}) {
  const dispatch = useDispatch();
  const [siteSections, setSiteSection] = useState([]);

  useEffect(() => {
    setSiteSection([
      {
        leftValue: 'Blog',
        rightValue: website || SITE_URL_TEMPLATE + '/blog',
      },
      {
        leftValue: 'Resources',
        rightValue: website || SITE_URL_TEMPLATE + '/resources',
      },
      {
        leftValue: 'About',
        rightValue: website || SITE_URL_TEMPLATE + '/about',
      },
      {
        leftValue: 'Product',
        rightValue: website || SITE_URL_TEMPLATE + '/product',
      },
      {
        leftValue: 'Privacy',
        rightValue: website || SITE_URL_TEMPLATE + '/privacy',
      },
    ]);
  }, [website]);

  const onChange = useCallback(
    (value, key, index) => {
      const newState = [...siteSections];
      newState[index][key] = value;
      setSiteSection(newState);
    },
    [siteSections],
  );

  const onAdd = useCallback(() => {
    setSiteSection((prevState) => [
      ...prevState,
      {
        leftValue: '',
        rightValue: '',
      },
    ]);
  }, [siteSections]);

  const onDelete = useCallback(
    (index) => {
      setSiteSection((prevState) =>
        prevState.filter((_, sectionIndex) => sectionIndex !== index),
      );
    },
    [siteSections],
  );

  const handleContinueClick = useCallback(() => {
    dispatch(
      dispatchFunc({
        sections: siteSections,
      }),
    );
    onContinue();
  }, [onContinue, dispatch, siteSections]);

  return (
    <ContentWithImage
      content={
        <Stack mt={rem(88)} w={rem(500)} spacing={40}>
          <Stepper activeIndex={stepIndex} stepsAmount={3} />
          <Heading
            spacing={24}
            small
            align="left"
            title={title}
            description={description}
          />
        </Stack>
      }
      image={
        <Stack spacing={rem(61)} mr={rem(106)} mb={rem(88)}>
          <InputGroup
            inputs={siteSections}
            onChange={onChange}
            onAdd={onAdd}
            onDelete={onDelete}
            leftTitle="Site section"
            rightTitle="Page"
          />
          <Box ml="auto">
            {children ? (
              <>{children}</>
            ) : (
              <MainButton
                hugePadding
                sx={{ width: rem(283) }}
                onClick={handleContinueClick}
              >
                <CustomText variant="bodyLarge" sx={{ fontSize: rem(18) }}>
                  Let’s go!
                </CustomText>
              </MainButton>
            )}
          </Box>
        </Stack>
      }
    />
  );
}

export default AddSiteSection;
