/* eslint-disable max-len */
import { useMemo } from 'react';
import { pathBlocklist } from '../utils';

import logger from '../utils/logger';

export const REDIRECTION_OPTIONS = {
  noRedirection: 'no-redirection',
  contentUtilizationRedirection: 'content-utilization',
  holdingPatternRedirection: 'holding-pattern',
  passportControl: 'passport-control',
  ccCaptureRedirection: 'cc-capture',
  planSelection: 'plan'
};

const isUrlBlocked = () => pathBlocklist.includes(`/${window.location.path}`);

const useFlowRedirection = (
  subscriptionStatus,
  loading,
  permissions,
  activeSubscriptionPlan,
  hubspotDetails = undefined,
) => {
  const jwtTokenLoading = () => loading;
  const orgIsSubscribed = () => subscriptionStatus === 'ACTIVE';
  const userIsTestFlightUser = () => permissions.includes('role:test-flight');
  const userIsContentUtilizationUser = () => permissions.includes('role:content-utilization');
  const userCanAccessRadar = () => permissions.includes('role:full-access');
  const userIsAPreboardingUser = () => userIsTestFlightUser() || userIsContentUtilizationUser();
  const orgIsPreboarding = () => !hubspotDetails || hubspotDetails.connected === false;
  const orgIsBoarded = () => hubspotDetails?.connected === true;
  const currentlyInPassportControl = () => window.location.path === REDIRECTION_OPTIONS.passportControl;
  const subscriptionRequiringCCCapture = () => subscriptionStatus === 'PAST_DUE' || subscriptionStatus === undefined;
  const freeTrialIsExpired = () => subscriptionStatus === 'PAUSED'

  const shouldNotRedirect = () => jwtTokenLoading()
    || (orgIsSubscribed() && userCanAccessRadar())
    || (isUrlBlocked() && userIsAPreboardingUser() && orgIsPreboarding());
  const shouldRedirectToPassportControl = () => userIsAPreboardingUser() && orgIsPreboarding();
  const shouldRedirectToContentUtilization = () => currentlyInPassportControl() && userIsContentUtilizationUser() && orgIsBoarded();
  const shouldRedirectToHoldingPattern = () => userIsAPreboardingUser() && orgIsBoarded();

  const shouldRedirectToCCCapture = () => !userIsAPreboardingUser() && subscriptionRequiringCCCapture() && !isUrlBlocked();
  const shouldRedirectToPlanSelection = () => !userIsAPreboardingUser() && freeTrialIsExpired() && !isUrlBlocked();

  const redirectionFlow = useMemo(() => {
    // eslint-disable-next-line max-len
    logger.debug(`redirectionFlow => subscriptionStatus: ${subscriptionStatus}, loading: ${loading}, permissions: ${permissions}, activeSubscriptionPlan: ${JSON.stringify(activeSubscriptionPlan)}, hubspotDetails: ${JSON.stringify(hubspotDetails)}`);

    if (shouldNotRedirect()) return REDIRECTION_OPTIONS.noRedirection;
    if (shouldRedirectToPassportControl()) return REDIRECTION_OPTIONS.passportControl;
    if (shouldRedirectToContentUtilization()) return REDIRECTION_OPTIONS.contentUtilizationRedirection;
    if (shouldRedirectToHoldingPattern()) return REDIRECTION_OPTIONS.holdingPatternRedirection;
    if (shouldRedirectToCCCapture()) return REDIRECTION_OPTIONS.ccCaptureRedirection
    if (shouldRedirectToPlanSelection()) return REDIRECTION_OPTIONS.planSelection

    return REDIRECTION_OPTIONS.noRedirection;
  }, [
    subscriptionStatus,
    loading,
    permissions,
    activeSubscriptionPlan,
    hubspotDetails,
  ]);

  return [redirectionFlow];
};

export default useFlowRedirection;
