import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Group,
  Loader,
  LoadingOverlay,
  rem,
  Stack,
  Tabs,
  UnstyledButton,
} from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackNagination from '../../components/BackNavigation/BackNagination';
import AddCompetitor from '../../components/AddCompetitor/AddCompetitor';
import OutlineTabs from '../../components/OutlineTabs/OutlineTabs';
import ContentSourceTab from '../../components/settings/ContentSourceTab/ContentSourceTab';
import CustomText from '../../components/Typography/CustomText/CustomText';
import { setActiveLinkKey, setHeaderTitle } from '../../features/layoutSlice';
import { useStyle } from './SettingsPage.style';
import IntegrationsTab from '../../components/settings/IntegrationsTab/IntegrationsTab';
import GuidedTourModal from '../../components/GuidedTour/GuidedTourModal/GuidedTourModal';
import GuidedTour from '../../components/GuidedTour/GuidedTour';
import useGuideModal from '../../hooks/useGuideModal';
import { getCompetitorsSettingsSteps } from '../../features/guidedTourSteps/competitorsSettingsPage';
import BasicModal from '../../components/Modals/BasicModal';
import PaymentModalWrapper from '../../components/upgradePlanComponents/PaymentModalWrapper';
import PlanPageComponent from '../../components/PlanPageComponent/PlanPageComponent';
import UpdatePlanPayment from '../../components/upgradePlanComponents/paymentComponents/UpgradePlanPayment/UpdatePlanPayment';
import SuccessfulPaymentModal from '../../components/Modals/SuccessfulPaymentModal';
import BlurredLayout from '../../components/BlurredLayout/BlurredLayout';
import useURLParams from '../../hooks/useURLParams';
import useOrganizationSettings from './hooks/useOrganizationSettings';
import CustomCheckbox from '../../components/Checkbox';
import CustomTitle from '../../components/Typography/CustomTitle/CustomTitle';
import MainButton from '../../components/buttons/buttons';
import useActivePlan from '../../components/upgradePlanComponents/hooks/useActivePlan';
import usePaymentPortalSession from './hooks/usePaymentPortalSession';
import useJwtToken from '../../hooks/useJwtToken';

const TABS = ['Integrations', 'Content Sources', 'Competitors', 'Payments'];

function SettingsPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading: jwtTokenLoading } = useJwtToken();
  const addedCompetitors = useSelector((state) => state.settings.competitors);
  const { organizationSettings, updateOrganizationSettings } =
    useOrganizationSettings();
  const { url, setUrl, retrievePaymentUrl } = usePaymentPortalSession();
  const [activeSubscriptionPlan] = useActivePlan();
  const [competitorsState, setCompetitorsState] = useState([]);
  const [selectedPlanData, setSelectedPlanData] = useState({});
  const [paymentModalIndex, setPaymentModalIndex] = useState(null);
  const [syncRecommendedContent, setSyncRecommendedContent] = useState(false);
  const [orgSettingsModalOpen, setOrgSettingsModalOpen] = useState(false);
  const { showGuide, stepIndex, guideModalIndex } = useSelector(
    (state) => state.profile.guide.competitorsSettings,
  );
  const { showGuide: showGuideCompetitors } = useSelector(
    (state) => state.profile.guide.competitors,
  );
  const loading = useSelector((state) => state.settings.loading);
  const { handleGuideModalClick } = useGuideModal(
    'competitorsSettings',
    stepIndex,
  );
  const fromCompetitors = useMemo(() => state?.editCompetitors, [state]);
  const competitorEditing = useMemo(
    () => competitorsState.some((state) => !!state),
    [competitorsState],
  );

  const { classes } = useStyle();
  const { getParamFromURL } = useURLParams();

  const showGuidedTour = () => {
    return (
      showGuide &&
      !showGuideCompetitors &&
      getParamFromURL('tab') === 'Competitors'
    );
  };

  const generateCompetitors = useCallback(() => {
    if (!Array.isArray(addedCompetitors)) return;
    const elements = [];

    addedCompetitors.forEach((competitor, index) => {
      elements.push(
        <AddCompetitor
          key={index}
          competitorData={competitor}
          selectedCompetitor
          withTitles={index === 0}
          withoutSeparator={index === addedCompetitors.length - 1}
          setEditing={(state) =>
            setCompetitorsState((prevState) => {
              const newState = [...prevState];
              newState[index] = state;

              return newState;
            })
          }
        />,
      );
    });

    return elements;
  }, [addedCompetitors, competitorsState]);

  useEffect(() => {
    if (organizationSettings?.syncRecommendedContent) {
      setSyncRecommendedContent(organizationSettings.syncRecommendedContent);
    }
  }, [organizationSettings]);

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        headerTitle: 'Settings',
      }),
    );
    dispatch(
      setActiveLinkKey({
        activeLinkKey: 'Settings',
      }),
    );
  }, []);

  useEffect(() => {
    if (
      url &&
      (getParamFromURL('tab') || '').toLowerCase() === TABS[3].toLowerCase()
    ) {
      setUrl(null);
      window.open(url, '_self');
    }
  }, [url]);

  useEffect(() => {
    if (
      !jwtTokenLoading &&
      (getParamFromURL('tab') || '').toLowerCase() === TABS[3].toLowerCase()
    ) {
      retrievePaymentUrl();
    }
  }, [getParamFromURL('tab'), jwtTokenLoading]);

  return (
    <>
      <Box pos="relative">
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          loaderProps={{ color: '#228be6' }}
        />
        {fromCompetitors && (
          <Box mb={26}>
            <BackNagination href={-1} title="Back to competition" />
          </Box>
        )}
        <OutlineTabs
          tabsItems={TABS}
          defaultValue={fromCompetitors ? TABS[2] : TABS[0]}
        >
          <Tabs.Panel value={TABS[0]}>
            <Box p={8} w="75%">
              <Stack spacing={4} className={classes.sectionContainer}>
                <CustomText variant="h2">Integrations</CustomText>
                <Group w="100%" spacing={128}>
                  <CustomText variant="body2">
                    Connect ATC with your data
                  </CustomText>
                  {activeSubscriptionPlan?.tier !== 'ECONOMY' && (
                    <Group>
                      <CustomText variant="body2">
                        Turn on sync recommendations:
                      </CustomText>
                      <UnstyledButton
                        onClick={() => setOrgSettingsModalOpen(true)}
                      >
                        <CustomCheckbox checked={syncRecommendedContent} />
                      </UnstyledButton>
                    </Group>
                  )}
                </Group>
              </Stack>
              <IntegrationsTab />
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value={TABS[1]}>
            <Box p={8} sx={{ position: 'relative' }}>
              <Stack spacing={4} w="75%" className={classes.sectionContainer}>
                <CustomText variant="h2">Content source</CustomText>
                <CustomText variant="body2">
                  Air Traffic Control pulls in your content every day, defines
                  what it’s about, and then power your recommendations. To get
                  your content loaded, all you need to do is indicate where that
                  content is on your website. For example, you might not want to
                  pull in and recommend your Terms and Conditions, but you would
                  want to be able to recommend your blog posts. Here, please
                  name each content section and then copy and paste where on
                  your website that content live.
                </CustomText>
              </Stack>
              <BlurredLayout blur={`blur(${rem(2)})`}>
                <CustomText variant="h2" sx={{ color: '#fff' }}>
                  coming soon...
                </CustomText>
              </BlurredLayout>
              <ContentSourceTab />
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value={TABS[2]}>
            <Box p={8} sx={{ position: 'relative' }}>
              <Stack spacing={4} w="75%">
                <CustomText variant="h2">Competitors</CustomText>
                <CustomText variant="body2">
                  Once you enter your competitors’ name, where on their site
                  they maintain content, and their LinkedIn company page, Air
                  Traffic Control will track their content production across
                  their website and LinkedIn, reveal who is engaging with them
                  on LinkedIn, and ensure you know how they are being covered in
                  the public media. Just like with your own content, it is
                  important to indicate where on their site they publish
                  marketing content. For example, you would want to include
                  airtrafficcontrol.io/blog, but not airtrafficcontrol.io.
                </CustomText>
              </Stack>
              <BlurredLayout blur={`blur(${rem(2)})`}>
                <CustomText variant="h2" sx={{ color: '#fff' }}>
                  coming soon...
                </CustomText>
              </BlurredLayout>
              {addedCompetitors.length > 0 && (
                <Box className={classes.selectedCompetitorsHeading}>
                  {competitorEditing ? (
                    <CustomText variant="h4">Editing Competitor</CustomText>
                  ) : (
                    <CustomText variant="h3">
                      Your Selected Competiors
                    </CustomText>
                  )}
                </Box>
              )}
              <Stack
                spacing={8}
                w="fit-content"
                className="selected-competitors__container"
              >
                {generateCompetitors()}
              </Stack>
              <AddCompetitor
                onContinue={() =>
                  handleGuideModalClick(
                    stepIndex === 0 ? stepIndex + 1 : stepIndex,
                  )
                }
                onAdd={() =>
                  handleGuideModalClick(
                    stepIndex === 1 ? stepIndex + 1 : stepIndex,
                  )
                }
              />
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value={TABS[3]}>
            <Stack align="center">
              <CustomText variant="h2">You are being redirected...</CustomText>
              <Loader color="#339AF0" />
            </Stack>
          </Tabs.Panel>
        </OutlineTabs>
      </Box>
      {/* Confirmation Modal */}
      <BasicModal
        withCloseButton={false}
        opened={orgSettingsModalOpen}
        onClose={() => {
          setOrgSettingsModalOpen(false);
        }}
      >
        <Stack spacing={32} w={rem(500)}>
          <CustomTitle order={1} sx={{ textAlign: 'center' }}>
            Are you sure you want to proceed with this action?
          </CustomTitle>
          <Group grow>
            <MainButton
              onClick={() => {
                updateOrganizationSettings(!syncRecommendedContent);
                setSyncRecommendedContent((prevState) => !prevState);
                setOrgSettingsModalOpen(false);
              }}
            >
              Confirm
            </MainButton>
            <UnstyledButton
              className={classes.outlinedOrgSettingsButton}
              onClick={() => setOrgSettingsModalOpen(false)}
            >
              Cancel
            </UnstyledButton>
          </Group>
        </Stack>
      </BasicModal>
      {/* Guided Tour Components */}
      <GuidedTourModal
        opened={showGuidedTour() && guideModalIndex === 0}
        title="Add/Edit competitors"
        onClick={handleGuideModalClick}
        onClose={handleGuideModalClick}
      >
        <CustomText variant="body2">
          You can find your chosen competitors in the settings pages. Here you
          can add/edit and remove competitors, and edit the content sources.
        </CustomText>
      </GuidedTourModal>
      <GuidedTour
        stepIndex={stepIndex}
        setStepIndex={handleGuideModalClick}
        run={showGuidedTour() && guideModalIndex === null}
        steps={getCompetitorsSettingsSteps(dispatch, navigate)}
      />
      {/* Upgrade Plan Modals */}
      <BasicModal
        withCloseButton={false}
        opened={stepIndex === 3}
        onClose={() => {
          handleGuideModalClick(stepIndex + 1);
          navigate('/');
        }}
        bodyClassName={classes.modalBody}
      >
        <PaymentModalWrapper
          onClose={() => {
            handleGuideModalClick(stepIndex + 1);
            navigate('/');
          }}
        >
          <PlanPageComponent
            smallOffset
            onPlanSelect={(data) => {
              setSelectedPlanData(data);
              setPaymentModalIndex(0);
            }}
          />
        </PaymentModalWrapper>
      </BasicModal>
      <BasicModal
        withCloseButton={false}
        opened={paymentModalIndex === 0}
        onClose={() => {
          handleGuideModalClick(stepIndex + 1);
          navigate('/');
        }}
        bodyClassName={classes.modalBody}
        withOverlay={false}
      >
        <PaymentModalWrapper
          onClose={() => {
            setPaymentModalIndex(null);
          }}
        >
          <Box w={1191}>
            <UpdatePlanPayment
              title={selectedPlanData.title}
              planList={selectedPlanData.planList}
              price={selectedPlanData.price}
              period={selectedPlanData.period}
              onSubmit={() => setPaymentModalIndex(1)}
            />
          </Box>
        </PaymentModalWrapper>
      </BasicModal>
      <SuccessfulPaymentModal
        paymentModalIndex={paymentModalIndex}
        setPaymentModalIndex={setPaymentModalIndex}
        title={selectedPlanData.title}
        withOverlay={false}
        onClose={() => {
          handleGuideModalClick(stepIndex + 1);
          navigate('/');
        }}
      />
    </>
  );
}

export default SettingsPage;
