import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  organisationContainer: {
    width: rem(565),
    background: theme.colors.white[0],
    margin: "0 auto",
    padding: rem(16),
    borderRadius: rem(24),
    boxShadow: "0px 4px 24px 0px rgba(59, 93, 108, 0.10)"
  },
  image: {
    borderRadius: "50%",
    height: rem(71),
    width: rem(71)
  }
}));
