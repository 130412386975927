import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { passportControlConfig } from '../../../config';

const FLOW_OPTIONS = {
  TEST_FLIGHT: 'testFlight',
  CONTENT_UTILIZATION_CAMPAIGN: 'contentUtilizationCampaign',
};

export const PassportControlRedirect = () => {
  const [searchParams] = useSearchParams();
  const { baseWWWUrl, testFlightRedirectPath, contentUtilizationRedirectPath } = passportControlConfig;
  const flow = useMemo(() => searchParams.get('flow'), [searchParams]);

  const flows = Object.values(FLOW_OPTIONS);

  useEffect(() => {
    if (flow === flows[0]) {
      window.location.assign(`${baseWWWUrl}${testFlightRedirectPath}`);
    } else {
      window.location.assign(`${baseWWWUrl}${contentUtilizationRedirectPath}`);
    }
  }, [flow]);

  return null;
};

export default PassportControlRedirect;
