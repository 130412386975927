import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  topicItemsContainer: {
    justifyContent: 'flex-start',
    flexGrow: 2,
    maxHeight: rem(37),
    maxWidth: rem(900),
    overflow: 'hidden',
    transition: '0.5s',
  },
  topicItemsContainerExpanded: {
    maxHeight: '100vh',
  },
}));
