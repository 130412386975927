import React, { useMemo } from 'react';
import { Group, rem, Stack } from '@mantine/core';
import CustomText from '../Typography/CustomText/CustomText';
import { ReactComponent as TickCircleIcon } from '../../assets/icons/tick-circle-icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/close-icon.svg';

function PlanList({ planList }) {
  const planListComponents = useMemo(() => {
    const elements = [];

    planList.forEach((item, index) => {
      elements.push((
        <Group spacing={8} key={planList.id || index} h={18}>
          {item.notInclude ? (
              <CrossIcon style={{ width: rem(16), height: rem(16) }}/>
          ) : (
            <TickCircleIcon style={{width: rem(16), height: rem(16)}} />
          )}
          <CustomText variant="body2">
            {item.title}
          </CustomText>
        </Group>
      ));
    });

    return elements;
  }, [planList]);

  return (
    <Stack>
        {planListComponents}
    </Stack>
  );
}

export default PlanList;
