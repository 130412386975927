import { useCallback, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';
import { serializeParams } from '../../../utils';

const useFetchCompetitorContents = (
  jwtTokenLoading,
  urlDateParams,
  urlParams,
  filteringParams,
  setFilteringParams,
) => {
  const [competitorsContentsData, setCompetitorsContentsData] = useState([]);
  const { getCompetitorsContents } = useRadarApi();
  const [fetchCompetitorsContentsData, loading] = useFetch(
    getCompetitorsContents,
  );

  const validateTopics = (topics) => {
    const isTopicDisabled = (topic) => {
      return !topics.some((el) => el.value === topic);
    };

    const disabledTopics = [];

    filteringParams.topics.forEach((topic) => {
      if (isTopicDisabled(topic)) {
        disabledTopics.push(`${topic}__disabled`);
      }
    });

    setFilteringParams((prevState) => ({
      ...prevState,
      extraTopics: [...prevState.extraTopics, ...disabledTopics],
    }));
  };

  const fetchWrapper = useCallback(async () => {
    const data = await fetchCompetitorsContentsData({
      ...serializeParams(urlParams),
      offset: urlParams.offset,
      dateFilter: JSON.stringify(urlDateParams),
    });

    setCompetitorsContentsData(data);
    validateTopics(data.topics);
  }, [fetchCompetitorsContentsData, getCompetitorsContents, urlDateParams]);

  useEffect(() => {
    if (!jwtTokenLoading && urlDateParams && urlParams) {
      fetchWrapper();
    }
  }, [
    jwtTokenLoading,
    JSON.stringify(urlDateParams),
    JSON.stringify({
      ...urlParams,
      tab: []
    }),
  ]);

  return [competitorsContentsData, loading];
};

export default useFetchCompetitorContents;
