import { createStyles } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  sectionContainer: {
    paddingBottom: 24,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  sectionContainerBorderTop: {
    paddingTop: 24,
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },
}));
