import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  statBox: {
    background: theme.colors.gray[2],
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: 20,
    padding: rem(24),
    flexGrow: 1,

    '& span': {
      color: theme.colors.gray[4],
    },
  },
  cardsContainer: {
    flexWrap: 'wrap',
    columnGap: 24,
    rowGap: 16,
    justifyContent: 'center',
  },
  modalButton: {
    width: 220,
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: 20,
    padding: `${rem(21)} ${rem(16)} ${rem(19)}`,
  },
  modalContent: {
    borderRadius: 20,
    minWidth: rem(503),
  },
  modaBody: {
    minHeight: 242,
    display: 'flex',
    padding: 24,
  },
}));
