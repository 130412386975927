import { Box } from '@mantine/core';
import React from 'react';
import { useStyle } from './CustomRadio.style';

function CustomRadio({ value, selected, onSelect }) {
  const { classes, cx } = useStyle();

  return (
    <Box className={classes.radioOuter} onClick={() => onSelect(value)}>
        <Box className={cx(classes.radioInner, { [classes.radioInnerSelected]: selected })} />
    </Box>
  );
}

export default CustomRadio;
