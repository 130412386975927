import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles({
  h0: {
    fontSize: rem(64),
    fontWeight: 700,
  },
  h1: {
    fontSize: rem(26),
    fontWeight: 700,
  },
});
