import { useState, useCallback, useMemo } from 'react';
import { Box, Flex, Group, rem, Stack } from '@mantine/core';
import useRadarApi from '../../hooks/useRadarApi.js';
import useJwtToken from '../../hooks/useJwtToken.js';
import TableWithSorting from '../../components/TableWithSorting/TableWithSorting.jsx';
import { useStyle } from './ContentsPage.style.jsx';
import StatsChart from '../../components/StatsChart/StatsChart.jsx';
import CustomText from '../../components/Typography/CustomText/CustomText.jsx';
import TitleWithTooltip from '../../components/TitleWithTooltip/TitleWithTooltip.jsx';
import TimePeriodSelection from '../../components/filtering/TimePeriodSelection/TimePeriodSelection.jsx';
import useURLParams from '../../hooks/useURLParams.js';
import FilterBar from '../../components/filtering/FilterBar/FilterBar.jsx';
import TabsWithCards from '../../components/TabsWithCards/TabsWithCards.jsx';
import { useDispatch, useSelector } from 'react-redux';
import GuidedTourModal from '../../components/GuidedTour/GuidedTourModal/GuidedTourModal.jsx';
import GuidedTour from '../../components/GuidedTour/GuidedTour.jsx';
import { setGuide } from '../../features/profileSlice.js';
import useGuideModal from '../../hooks/useGuideModal.js';
import { getContentSteps } from '../../features/guidedTourSteps/contentsPage';
import { serializeParams } from '../../utils.js';
import useTable from '../../hooks/useTable.js';
import useStateFromURL from '../../hooks/useStateFromURL.js';
import useTabsWithContent from '../../hooks/useTabsWithContent.js';
import useFetchTabsWithContent from '../../hooks/useFetchTabsWithContent.js';
import useFetchContentUtilization from './hooks/useFetchContentUtilization.js';
import useFetchContentToPromote from './hooks/useFetchContentToPromote.js';
import useChartMetrics from './hooks/useChartMetrics.js';
import { DEFAULT_DATE_FILTER_PARAMS } from '../../components/filtering/TimePeriodSelection/constants.js';
import MainButton from '../../components/buttons/buttons.jsx';

const SORTING_OPTIONS = [
  {
    title: 'Newest',
    sortDirection: 'desc',
    sortBy: 'publishDate',
  },
  {
    title: 'Oldest',
    sortDirection: 'asc',
    sortBy: 'publishDate',
  },
  {
    title: 'High Engagement',
    sortDirection: 'desc',
    sortBy: 'engagementScore',
  },
  {
    title: 'Low Engagement',
    sortDirection: 'asc',
    sortBy: 'engagementScore',
  },
  {
    title: 'Alphabetically A-Z',
    sortDirection: 'desc',
    sortBy: 'title',
  },
  {
    title: 'Alphabetically Z-A',
    sortDirection: 'asc',
    sortBy: 'title',
  },
  {
    title: 'Low Matches',
    sortDirection: 'asc',
    sortBy: 'matches',
  },
  {
    title: 'High Matches',
    sortDirection: 'desc',
    sortBy: 'matches',
  },
];

const DEFAULT_URL_PARAMS = {
  ...DEFAULT_DATE_FILTER_PARAMS,
  'filter[source]': [],
  sortBy: 'publishDate',
  sortDirection: 'desc',
  'searchBy[title]': '',
};
const PARAMS_KEYS = [
  'sortBy',
  'sortDirection',
  'filter[source]',
  'dateFilter',
  'searchBy[title]',
];

const CONTENT_TABS = {
  CONTENT: 'Your Content',
  SYNDICATED_CONTENT: 'Syndicated Content',
};

const tabNames = Object.keys(CONTENT_TABS);
const tabValues = Object.values(CONTENT_TABS);

const defaultTab = tabValues[0];

function ContentsPage() {
  //filters and custom hooks
  const [activeTab, setActiveTab] = useState(defaultTab);
  const { getContents } = useRadarApi();
  const { loading } = useJwtToken(); // Get the loading state from the useJwtToken hook
  const { classes } = useStyle();
  const { showGuide, stepIndex, guideModalIndex } = useSelector(
    (state) => state.profile.guide.content,
  );

  const { handleGuideModalClick } = useGuideModal('content', stepIndex);
  const { getTableData } = useTable();
  const dispatch = useDispatch();

  const {
    urlParams,
    urlDateParams,
    updateUrlParams,
    updateDateParam,
    getValidatedDateFilter,
  } = useURLParams(PARAMS_KEYS, DEFAULT_URL_PARAMS);

  const [filteringParams, setFilteringParams] = useStateFromURL(
    PARAMS_KEYS,
    urlParams,
    true,
  );

  const fetchContent = useFetchTabsWithContent(
    null,
    getContents,
    serializeParams(urlParams),
  );
  const [relatedContentData, setOffset, activeContentData] = useTabsWithContent(
    tabValues,
    tabNames,
    tabValues.findIndex((el) => el === activeTab),
    (params) => fetchContent(params),
    [],
    serializeParams(urlParams),
  );

  const [contentUtilization, contentUtilizationLoading] =
    useFetchContentUtilization(loading, getValidatedDateFilter());
  const [contentToPromote, contentToPromoteLoading] =
    useFetchContentToPromote(loading);
  const [
    chartLabels,
    chartTitle,
    chartData,
    dateList,
    getTooltipTitle,
    dateRange,
  ] = useChartMetrics(contentUtilization, urlDateParams);

  const tableData = useMemo(() => {
    const data = contentToPromote.map((el) => ({
      content: {
        title: el.title,
        imageUrl: el.imageUrl,
      },
      id: el.id,
      matches: el.matches,
    }));

    return getTableData(
      data,
      ['Content', '_id', 'Matches'],
      [
        {
          columnSpan: 8,
          useContent: true,
          withoutSorting: true,
        },
        {
          columnSpan: 0,
        },
        {
          columnSpan: 4,
          withoutSorting: true,
        },
      ],
      true,
    );
  }, [contentToPromote]);

  const handleTabChange = useCallback((value) => {
    setActiveTab(value), window.dispatchEvent(new Event('resize'));
  });

  const tableHoverOptions = useMemo(() => ({
    navigateTo: '/contents/detail/',
  }));

  const cardsFallbackElement = useMemo(() => {
    if (
      activeTab === tabValues[1] &&
      !relatedContentData?.[1]?.content?.length
    ) {
      const handleClick = () => {
        window.location = 'mailto:support@airtrafficcontrol.io'
      }

      return (
        <Stack align="center" w={rem(400)}>
          <CustomText variant="h2">No related content found</CustomText>
          <CustomText variant="h3" align="center">
            To get your syndicated content topics added, email us at
            support@airtrafficcontrol.io.
          </CustomText>
          <MainButton onClick={handleClick}>Email us</MainButton>
        </Stack>
      );
    }
  }, [activeTab, relatedContentData]);

  return (
    <Box>
      {activeTab === defaultTab && (
        <Group
          spacing={18}
          sx={{ justifyContent: 'center', flexWrap: 'nowrap' }}
        >
          <Stack spacing={8}>
            <TitleWithTooltip title="Content to promote" />
            <TableWithSorting
              tableData={tableData}
              loading={contentToPromoteLoading}
              withScroll
              wrapperClassName={classes.tableWrapper}
              rowClassName={classes.tableRow}
              hoverOptions={tableHoverOptions}
            />
          </Stack>
          <Stack spacing={8}>
            <Flex justify="space-between" align="center">
              <TitleWithTooltip
                title="Content Utilization"
                active={stepIndex === 1}
                modalTitle="What is Content Utilization?"
                modalContent={
                  <CustomText variant="body2">
                    It’s pretty common to focus on sharing our newest content,
                    but your older content is often times the most relevant
                    content for a customer. This content utilization report
                    helps you see how much of your content has been sent out to
                    your customers.
                    <br />
                    <br />
                    If the number is low, that’s an indication that there’s an
                    opportunity to use more Air Traffic Control content
                    recommendations in your marketing efforts because those
                    recommendations pull from across all your content to
                    recommend the best stuff for each customer. As your
                    utilization rate goes up, you’ll very likely see your
                    engagement rate increase, as well.
                  </CustomText>
                }
                onClose={() =>
                  handleGuideModalClick(stepIndex === 1 ? 2 : stepIndex)
                }
              />
              <Box mb={-16}>
                <TimePeriodSelection
                  updateDateFilter={updateDateParam}
                  initialFetchPeriodKey={urlDateParams?.dateKey}
                />
              </Box>
            </Flex>
            <StatsChart
              title={chartTitle}
              statsValue={contentUtilization.changePercentage || 0}
              chartData={chartData}
              chartLabels={chartLabels}
              dateList={dateList}
              loading={contentUtilizationLoading}
              getTooltipLabel={getTooltipTitle}
              tooltipLabel="% Content Utilization"
              height="100%"
              gap={24}
              containerClassName={classes.chartContainer}
            >
              <Stack spacing={0} pb={6}>
                <Group spacing={4} align="flex-end">
                  <CustomText variant="h6" sx={{ fontWeight: 600 }}>
                    Based on
                  </CustomText>
                  <CustomText variant="h2" sx={{ marginBottom: rem(-5) }}>
                    {contentUtilization.contentDelivered || 0}/
                    {contentUtilization.contentCount || 0}
                  </CustomText>
                </Group>
                <CustomText variant="h6" sx={{ fontWeight: 600 }}>
                  Content items utilized {dateRange}
                </CustomText>
              </Stack>
            </StatsChart>
          </Stack>
        </Group>
      )}
      <Box mt={activeTab === defaultTab ? 22 : 0}>
        <FilterBar
          filterByData={(activeContentData?.distinctValues?.source || []).map(
            (el) => ({ title: el }),
          )}
          title="Your Articles"
          paramKeys={{
            filterBy: 'filter[source]',
            searchBy: 'searchBy[title]',
          }}
          filteringParams={filteringParams}
          setFilteringParams={setFilteringParams}
          placeholder="Search content"
          sortingOptions={SORTING_OPTIONS}
          setParams={updateUrlParams}
          urlParams={urlParams}
          onApply={(params) => {
            setOffset(0);
            updateUrlParams({
              ...params,
            });
          }}
          onReset={() => {
            const params = {
              ...DEFAULT_URL_PARAMS,
              ...urlDateParams,
            };
            updateUrlParams(params);
            setFilteringParams(params);
          }}
        />
      </Box>
      <Box mt={10}>
        <TabsWithCards
          tabs={tabValues}
          tabsData={relatedContentData}
          withShowing
          withScroll
          value={activeTab}
          onTabChange={handleTabChange}
          fallbackElement={cardsFallbackElement}
          onClick={() =>
            dispatch(
              setGuide({
                key: 'content',
                value: {
                  showGuide: false,
                },
              }),
            )
          }
        />
      </Box>
      {/* Guided Tour Components */}
      <GuidedTourModal
        opened={showGuide && guideModalIndex === 0}
        title="Content"
        onClick={handleGuideModalClick}
        onClose={handleGuideModalClick}
      >
        <CustomText variant="body2">
          Here you can find a catalogue of your content, learn insights about
          their performance and control the content that is being sent out to
          your audience.
        </CustomText>
      </GuidedTourModal>
      <GuidedTour
        stepIndex={stepIndex}
        setStepIndex={handleGuideModalClick}
        run={showGuide && guideModalIndex === null}
        steps={getContentSteps(dispatch)}
      />
    </Box>
  );
}

export default ContentsPage;
