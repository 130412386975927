import React, { useCallback, useState } from 'react';
import {
  Box,
  Center,
  Flex,
  rem,
  Skeleton,
  Tabs,
  UnstyledButton,
} from '@mantine/core';
import CompetitorContentCard from '../CompetitorContentCard/CompetitorContentCard';
import OutlineTabs from '../OutlineTabs/OutlineTabs';
import CustomText from '../Typography/CustomText/CustomText';
import { useStyle } from './TabsWithCards.style';
import InfiniteScroll from 'react-infinite-scroll-component';
import BlurredLayout from '../BlurredLayout/BlurredLayout';
import useURLParams from '../../hooks/useURLParams';
import useNavigateWithState from '../../hooks/useNavigateWithState';

function TabsWithCards({
  tabs,
  tabsData,
  withScroll,
  tabMinWidth,
  loadAll,
  withShowing,
  onClick,
  onClickLink,
  value,
  fallbackElement,
  ...rest
}) {
  const navigateWithState = useNavigateWithState();
  const { classes, cx } = useStyle();
  const { urlState } = useURLParams();
  const [currentTab, setCurrentTab] = useState(tabs ? tabs[0] : '');

  const handleClick = useCallback(
    (data) => {
      let executeAction = true;

      const preventAction = () => {
        executeAction = false;
      };

      if (onClick) {
        onClick(data, preventAction);
      }

      if (!executeAction) return;
      navigateWithState(`${onClickLink || '/contents/detail'}/${data?.id}`);
    },
    [navigateWithState, onClick, onClickLink, urlState],
  );

  const generateCards = useCallback(
    (cardsData, cardAmount, hideMatches) => {
      const elements = [];

      const slicedCardData = cardAmount
        ? cardsData.slice(0, cardAmount)
        : cardsData;

      slicedCardData.forEach((cardData, index) => {
        elements.push(
          <CompetitorContentCard
            key={index}
            image={cardData.imageUrl}
            title={cardData.title}
            publishDate={cardData.publishDate}
            source={cardData.source}
            matches={hideMatches ? null : cardData.matches || 'none'}
            onClick={() => handleClick(cardData)}
            customClassName={`card-${index}`}
          />,
        );
      });

      return elements;
    },
    [handleClick],
  );

  const getCardList = useCallback(
    (tab, children) => {
      if (loadAll) {
        return (
          <InfiniteScroll
            dataLength={tab?.content?.length || 0}
            next={() => tab.setContentAmount((prevState) => prevState + 16)}
            hasMore={tab?.totalItems > tab.contentAmount + 16}
            loader={
              <CustomText
                variant="caption1"
                sx={{ textAlign: 'center', marginTop: rem(4) }}
              >
                Loading...
              </CustomText>
            }
            style={{ maxHeight: rem(534) }}
          >
            {children}
          </InfiniteScroll>
        );
      }
      return children;
    },
    [loadAll, tabsData],
  );

  const getShowMore = useCallback((tab) => {
    if (!tab.totalItems) {
      return (
        tab.content.length > 8 &&
        tab.content.length > tab.contentAmount &&
        !loadAll
      );
    }

    return tab.totalItems > tab.contentAmount;
  }, []);

  const generateTabs = useCallback(() => {
    const elements = [];

    tabsData.forEach((tab, index) => {
      const showMore = getShowMore(tab);

      elements.push(
        <>
          <Flex justify="flex-end" className={classes.showingText}>
            {withShowing && tabs[index] === (value || currentTab) ? (
              <CustomText
                variant="body2"
                sx={(theme) => ({ color: theme.colors.gray[4] })}
              >
                Showing: {(tab?.totalItems || 0).toLocaleString('en-US')}
              </CustomText>
            ) : null}
          </Flex>
          <Tabs.Panel key={index} value={tab.tabValue}>
            <Box sx={{ position: 'relative' }}>
              {tab.blurredComponent && (
                <BlurredLayout>{tab.blurredComponent}</BlurredLayout>
              )}
              {getCardList(
                tab,
                <Flex
                  className={cx(classes.cardsContainer, {
                    [classes.containerWithScroll]: withScroll && !loadAll,
                  })}
                >
                  {!tab.loading ? (
                    <>
                      {tab.content.length ? (
                        <>
                          {generateCards(
                            tab.content,
                            loadAll ? null : tab.contentAmount,
                            tab?.hideMatches,
                          )}
                          {showMore && (
                            <Center w="100%">
                              <UnstyledButton
                                onClick={() =>
                                  tab.setContentAmount(
                                    (prevState) => prevState + 8,
                                  )
                                }
                              >
                                <CustomText
                                  variant="h5"
                                  sx={(theme) => ({
                                    fontWeight: 500,
                                    textDecoration: 'underline',
                                    color: theme.colors.red[0],
                                    margin: `${rem(6)} 0`,
                                  })}
                                >
                                  See more
                                </CustomText>
                              </UnstyledButton>
                            </Center>
                          )}
                        </>
                      ) : (
                        <Center>
                          <CustomText variant="h2">
                            {fallbackElement ? (
                              <>{fallbackElement}</>
                            ) : (
                              <>No related content found</>
                            )}
                          </CustomText>
                        </Center>
                      )}
                    </>
                  ) : (
                    <>
                      <Skeleton width="23%" height={228} radius={20} />
                      <Skeleton width="23%" height={228} radius={20} />
                      <Skeleton width="23%" height={228} radius={20} />
                      <Skeleton width="23%" height={228} radius={20} />
                    </>
                  )}
                </Flex>,
              )}
            </Box>
          </Tabs.Panel>
        </>,
      );
    });

    return elements;
  }, [tabsData, value, currentTab]);

  return (
    <OutlineTabs
      value={value || currentTab}
      onTabChange={setCurrentTab}
      tabsItems={tabs}
      tabMinWidth={tabMinWidth}
      {...rest}
    >
      <Box sx={{ position: 'relative' }}>{generateTabs()}</Box>
    </OutlineTabs>
  );
}

export default TabsWithCards;
