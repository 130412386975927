import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  topicItem: {
    background: theme.colors.white[3],
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: 100,
    padding: `${rem(6)} ${rem(12)}`,
    cursor: 'pointer',
  },
  topicItemActive: {
    background: theme.colors.yellow[0],
    borderColor: theme.colors.yellow[0],
  },
}));
