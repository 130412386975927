import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  sectionContainerBorderTop: {
    paddingTop: rem(24),
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    borderColor: theme.colors.gray[3],
    borderRadius: 100,
    padding: `${rem(8)} ${rem(8)} ${rem(8)} ${rem(24)} !important`,
    minHeight: rem(40),
  },
  inviteMemberInputWrapper: {
    margin: `${rem(8)} 0`,
  },
  inviteMemberInput: {
    width: rem(460),
  },
  memberImage: {
    width: rem(40),
    height: rem(40),
    borderRadius: '50%',
  },
  inviteMemberInputSmall: {
    width: rem(225),
  },
  inputError: {
    color: theme.colors.red[0],
    marginTop: rem(-2),
    marginLeft: rem(6),
  },
  cancelButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: rem(38),
    padding: '8px 16px',
    border: `1px solid ${theme.colors.black[0]}`,
    borderRadius: '100px',
  },
}));
