import React from 'react';
import { rem, Stack } from '@mantine/core';
import CustomText from './Typography/CustomText/CustomText';
import CustomTitle from './Typography/CustomTitle/CustomTitle';

function Heading({ title, description, spacing, titleSize, small, align }) {
  return (
    <Stack spacing={spacing || 16}>
      <CustomTitle
        order={0}
        sx={{
          fontSize: rem(titleSize || 48),
          lineHeight: '110%',
          textAlign: align || 'center',
        }}
      >
        {title}
      </CustomTitle>
      <CustomText
        variant={small ? 'bodySmall' : 'bodyLarge'}
        sx={{ textAlign: align || 'center' }}
      >
        {description}
      </CustomText>
    </Stack>
  );
}

export default Heading;
