import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  inputLabel: {
    display: 'flex',
    marginBottom: rem(6),

    '& span': {
      color: theme.colors.red[0],
    },
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    borderColor: theme.colors.gray[3],
    borderRadius: rem(20),
    padding: `${rem(10)} ${rem(16)} !important`,
    minHeight: rem(40),
  },
  inputError: {
    color: theme.colors.red[0],
  },
  withFlex: {
    flex: 2,
  },
}));
