import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Center, Stack, rem } from '@mantine/core';
import { ReactComponent as LogoIcon } from '../assets/icons/ATC-logo-colored.svg';
import CustomText from '../components/Typography/CustomText/CustomText';
import CustomTitle from '../components/Typography/CustomTitle/CustomTitle';
import useRadarApi from '../hooks/useRadarApi';
import MainButton from '../components/buttons/buttons';
import { ReactComponent as ArrangeSquareIcon } from '../assets/icons/arrange-square-icon.svg';
import useJwtToken from '../hooks/useJwtToken';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useIntegrations from '../hooks/useIntegrations';

function HubspotContentUtilization() {
  const { getHubspotAuthURL } = useRadarApi();
  const { permissions } = useJwtToken();
  const navigate = useNavigate();
  const { hubspotDetails } = useIntegrations();
  const [searchParams] = useSearchParams();

  const [showPage, setShowPage] = useState(false);

  const successfullyConnected = useMemo(() => {
    if (searchParams.get('isSuccess')) {
      return true;
    } else if (hubspotDetails?.connected) {
      return true;
    }
    return false;
  }, [searchParams, hubspotDetails]);

  const handleHubspotConnection = useCallback(async () => {
    const url = await getHubspotAuthURL({
      redirectPath: 'hubspot-content-utilization?isSuccess=true',
      metadata: JSON.stringify({hubspotSyncAllContacts:true}),
    });

    window.location.href = url;
  }, [getHubspotAuthURL]);

  useEffect(() => {
    if (
      !permissions?.length ||
      (!hubspotDetails && (permissions || []).includes('read:settings'))
    )
      return;

    if (!permissions.includes('role:content-utilization')) {
      navigate('/');
    } else if (!successfullyConnected) {
      setShowPage(true);
    }
  }, [permissions, hubspotDetails]);

  useEffect(() => {
    if (successfullyConnected && hubspotDetails?.id) {
      window.location.href =
        'https://www.airtrafficcontrol.io/content-utilization-next-steps';
    }
  }, [successfullyConnected, hubspotDetails]);

  return (
    <>
      {showPage && (
        <Center h="100vh">
          <Stack spacing={42}>
            <Stack
              spacing={48}
              align="center"
              w={rem(successfullyConnected ? 510 : 420)}
            >
              <LogoIcon
                className="logo-icon"
                style={{ width: rem(217), height: rem(72) }}
              />
              <CustomTitle order={0}>
                {successfullyConnected ? 'Connected' : 'Welcome'}
              </CustomTitle>
              <CustomText variant="h2" sx={{ textAlign: 'center' }}>
                {!successfullyConnected && (
                  <>
                    Almost there! Click below to sync your HubSpot account and
                    we'll get started analyzing your content utilization.
                  </>
                )}
              </CustomText>
            </Stack>
            {!successfullyConnected && (
              <MainButton
                onClick={handleHubspotConnection}
                icon={<ArrangeSquareIcon />}
                sx={{ height: rem(48) }}
              >
                <CustomText variant="body1">Connect</CustomText>
              </MainButton>
            )}
          </Stack>
        </Center>
      )}
    </>
  );
}

export default HubspotContentUtilization;
