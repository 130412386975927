import { useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';
import useJwtToken from '../../../hooks/useJwtToken';

const useOrgSelection = () => {
  const [memberships, setMemberships] = useState([]);
  const [currentActiveOrg, setCurrentActiveOrg] = useState(null);
  const { getMemberships, updateUserContext } = useRadarApi();
  const { loading, orgId } = useJwtToken();
  const [fetchUsermemberships, membershipsLoading] = useFetch(getMemberships);

  const updateCurrentActiveOrg = (currentOrgId, data) => {
    const activeOrg = (data || []).find(
      (membership) => membership?.orgId === currentOrgId,
    );
    setCurrentActiveOrg(activeOrg);
  };

  const fetchWrapper = async () => {
    const data = await fetchUsermemberships();

    if (data) {
      const formattedData = Object.keys(data).map((key) => ({
        orgId: key,
        name: data[key],
      }));
      setMemberships(formattedData);
      updateCurrentActiveOrg(orgId, formattedData);
    }
  };

  const updateUserOrg = async (newOrgId) => {
    await updateUserContext({ orgId: newOrgId });
    updateCurrentActiveOrg(newOrgId, memberships);
  };

  useEffect(() => {
    if (!loading) {
      fetchWrapper();
    }
  }, [loading]);

  return { memberships, membershipsLoading, updateUserOrg, currentActiveOrg };
};

export default useOrgSelection;
