import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  segmentRoot: {
    height: rem(35),
    padding: 0,
    background: theme.colors.white[2],
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    maxWidth: 'fit-content',
  },
  label: {
    margin: 0,
    padding: '7px 24px',
    fontFamily: 'Manrope',
    fontSize: rem(14),
    marginLeft: -2,
    fontWeight: 600,
    color: theme.colors.blue[1],
  },
  control: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '0 !important',

    '&:last-child': {
      '& .mantine-SegmentedControl-label': {
        marginLeft: -4,
      },
    },
  },
  controlActive: {
    '& .mantine-SegmentedControl-label': {
      color: theme.colors.white[0],
    },
    '& .mantine-SegmentedControl-label:hover': {
      color: theme.colors.white[0],
    },
  },
  indicator: {
    height: '34px !important',
    borderRadius: 100,
    marginTop: '3.5px',
    background: theme.colors.blue[0],
  },
  chartContainer: {
    position: 'relative',
    width: '100%',
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    background: theme.colors.white[0],
    borderRadius: rem(20),
    padding: rem(16),
    marginTop: rem(16),
  },
  noChartsBox: {
    position: 'absolute',
    height: rem(186),
    width: rem(445),
    background: 'rgba(215, 214, 242, 0.3)',
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: rem(20),
    padding: rem(72),
    boxShadow: `0 ${rem(4)} ${rem(12)} rgba(54, 66, 71, 0.21)`,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  companiesContainer: {
    height: rem(550),
    width: '120%',
    right: rem(50),
    overflow: 'scroll',
    marginLeft: rem(-20),
    paddingLeft: rem(20),
    "&::-webkit-scrollbar": {
      display: "none"
    },

    /* Hide scrollbar for IE, Edge and Firefox */
    scrollbarWidth: "none",
    msOverflowStyle: "none"
  },
  menuRoot: {
    width: `${rem(175)} !important`,
    padding: `${rem(14)} 0`,
    border: 'none',
    borderRadius: rem(8),
  },
  datePickerRoot: {
    padding: `0 ${rem(8)}`,
    transform: 'translateY(-10%)',
  },
  datePickerHeader: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  datePickerMonthCell: {
    '&:last-of-type': {
      '& .mantine-DatePicker-day': {
        borderRadius: '0 4px 4px 0',
      },
    },

    '&:first-of-type': {
      '& .mantine-DatePicker-day': {
        borderRadius: '4px 0 0 4px',
      },
    },

    '& [data-with-spacing="true"]': {
      padding: 0,
    },

    '& .mantine-DatePicker-day': {
      height: 32,
      width: 42,
      fontSize: 12,
      fontWeight: 400,
      fontFamily: 'Manrope',
      padding: '0 5px',
    },

    '& [data-in-range="true"]': {
      background: '#D7D6F2 !important',
      color: `${theme.colors.black[0]} !important`,
    },

    '& [data-in-range="true"]:hover': {
      background: '#D7D6F2  !important',
    },

    '& [data-selected="true"]': {
      background: `${theme.colors.blue[0]} !important`,
      color: `${theme.colors.white[0]} !important`,
    },

    '& [data-selected="true"]: hover': {
      background: `${theme.colors.blue[0]} !important`,
    },
  },
  datePickerWeekday: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: 'Manrope',
    textAlign: 'center',
  },
  selectedDateContainer: {
    background: theme.colors.white[1],
    borderRadius: 4,
    padding: '5px 9px',
  },
  segmentControlLabel: {
    flexWrap: "nowrap"
  },
  segmentControlLabelLocked: {
    "& .mantine-Text-root": {
      color: theme.colors.gray[4]
    },

    "& path": {
      stroke: theme.colors.gray[4]
    }
  }
}));
