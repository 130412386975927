import React, { memo, useState } from 'react';
import { Box, rem, Skeleton, Tabs } from '@mantine/core';
import { useCallback } from 'react';
import TableWithSorting from '../../../../components/TableWithSorting/TableWithSorting';
import { useStyle } from './ContactsTable.style';
import { ReactComponent as ChevronDownIcon } from '../../../../assets/icons/chevronDown-icon.svg';
import { useEffect } from 'react';
import { useRef } from 'react';
import useTable from '../../../../hooks/useTable';
import { convertRemToPixels } from '../../../../utils';
import PeopleRowExpanded from '../PeopleRow';
import AccountRowExpanded from '../AccountsRow';
import useSorting from '../../../../hooks/useSorting';
import { TABS } from '../../constant';

const SORT_OPTIONS = {
  'First name': 'firstName',
  'Last name': 'lastName',
  Email: 'email',
  Company: 'company',
  Insights: 'insights',
  Industry: 'industry',
  Recommendations: 'recommendations',
  Contacts: 'contacts',
  'Organization name': 'name',
};

function ContactsTable({
  totalItems,
  urlParams,
  updateUrlParams,
  stepIndex,
  contactsContent,
  accountsContent,
  contactsOffset,
  accountOffset,
  setContactsOffset,
  setAccountOffset,
  contactsLoading,
  accountsLoading,
  setSelectedContactsIndexes,
  handleGuideModalClick,
  activeTab,
  onAccountsButtonClick,
  ...rest
}) {
  const scrollRef = useRef(null);
  const [openedRows, setOpenedRows] = useState([])
  const { getTableData } = useTable();
  const { classes, cx } = useStyle();
  const handleSorting = useSorting(urlParams, updateUrlParams, SORT_OPTIONS);

  const getInfinteScrollOptions = useCallback(
    (content, setOffset, disabled) => {
      const offset = activeTab === TABS[0] ? contactsOffset : accountOffset

      return {
        dataLength: content?.length,
        next: () => setOffset((prevState) => prevState + 15),
        hasMore: offset + 15 < totalItems,
        loader: <Skeleton width="100%" height={rem(48)} radius={8} />,
        disabled: disabled || false,
      };
    },
    [contactsContent, accountsContent, contactsOffset, accountOffset, totalItems],
  );

  const getHoverOptions = useCallback(
    (component) => {
      return {
        onClick: () => {
          if (stepIndex === 0 || stepIndex === 1) {
            handleGuideModalClick(stepIndex + 1);
          }
        },
        withoutHoverEffect: true,
        dropdownElement: component,
        data: {
          onClick: onAccountsButtonClick,
        },
        hoverElement: (
          <Box className={classes.hoverItem}>
            <ChevronDownIcon style={{ width: rem(14), height: rem(8) }} />
          </Box>
        ),
      };
    },
    [stepIndex, onAccountsButtonClick],
  );

  useEffect(() => {
    if(openedRows.length >= 2 && stepIndex === 1) {
      handleGuideModalClick(stepIndex + 1)
    }
  }, [openedRows])

  useEffect(() => {
    if (!scrollRef?.current) return;

    let initialScrollTop = scrollRef.current.scrollTop;

    const onScroll = () => {
      if (
        Math.abs(initialScrollTop - scrollRef.current.scrollTop) > 10 &&
        stepIndex === 1
      ) {
        handleGuideModalClick(stepIndex + 1);
      }
    };

    scrollRef.current.addEventListener('scroll', onScroll);
  }, [stepIndex]);

  return (
    <>
      {activeTab === TABS[0] ? (
        <Tabs.Panel value={TABS[0]}>
          <TableWithSorting
            key="contacts-table"
            withScroll
            scrollRef={scrollRef}
            withSelect
            loading={contactsLoading}
            tableData={getTableData(
              contactsContent,
              [
                'First name',
                'Last name',
                'Email',
                'Company',
                'Insights',
                '_id',
                'Recommendations',
              ],
              [
                {
                  columnSpan: 2,
                  key: 'firstName',
                },
                {
                  columnSpan: 2,
                  key: 'lastName',
                },
                {
                  columnSpan: 3,
                  key: 'email',
                },
                {
                  columnSpan: 2,
                  key: 'company',
                  withoutAlignment: true,
                  withoutSorting: true
                },
                {
                  columnSpan: 1,
                  key: 'insights',
                  alignCenter: true,
                },
                {
                  columnSpan: 0,
                },
                {
                  columnSpan: 2,
                  key: 'recommendations',
                  alignCenter: true,
                  thWidth: convertRemToPixels(7.75),
                },
              ],
            )}
            setSelected={setSelectedContactsIndexes}
            wrapperClassName={cx(
              classes.tableWrapper,
              classes.tableWrapperWithoutBorder,
            )}
            wrapperScrollClassName={classes.tableWrapperScroll}
            rowClassName={classes.tableRow}
            onSort={handleSorting}
            errorText="Contacts not found"
            setExternalOpenedRows={setOpenedRows}
            infiniteScrollOptions={getInfinteScrollOptions(
              contactsContent,
              setContactsOffset,
            )}
            hoverOptions={getHoverOptions(PeopleRowExpanded)}
            {...rest}
          />
        </Tabs.Panel>
      ) : (
        <Tabs.Panel value={TABS[1]}>
          <TableWithSorting
            key="accounts-table"
            withScroll
            loading={accountsLoading}
            tableData={getTableData(
              accountsContent,
              ['Organization name', '_id', 'Contacts', 'Industry', 'Insights'],
              [
                {
                  columnSpan: 4,
                  key: 'name',
                  withoutAlignment: true,
                },
                {
                  columnSpan: 0,
                },
                {
                  columnSpan: 2,
                  key: 'contacts',
                },
                {
                  columnSpan: 3,
                  key: 'industry',
                },
                {
                  columnSpan: 2,
                  key: 'insights',
                },
              ],
            )}
            wrapperClassName={cx(
              classes.tableWrapper,
              classes.tableWrapperWithoutBorder,
            )}
            wrapperScrollClassName={classes.tableWrapperScroll}
            rowClassName={classes.tableRow}
            onSort={handleSorting}
            errorText="Accounts not found"
            infiniteScrollOptions={getInfinteScrollOptions(
              accountsContent,
              setAccountOffset,
              true,
            )}
            hoverOptions={getHoverOptions(AccountRowExpanded)}
          />
        </Tabs.Panel>
      )}
    </>
  );
}

export default memo(ContactsTable);
