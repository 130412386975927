import { Box, Group, Progress, rem } from '@mantine/core';
import React from 'react';
import CustomText from '../../../../components/Typography/CustomText/CustomText';

function OtherAudienceInterestsItem({ item, color }) {
  return (
    <Group spacing={21}>
      <Group spacing={16}>
        <Progress
          color={color}
          value={Math.round(item?.incidence * 100 * 100) / 100 || 100}
          styles={(theme) => ({
            root: {
              height: rem(12),
              width: rem(375),
              background: theme.colors.white[1],
            },
          })}
        />
        <CustomText variant="h5">
          {Math.round(item?.incidence * 100 * 100) / 100 || 0}%
        </CustomText>
      </Group>
      <Group spacing={12}>
        <Box
          sx={{
            height: rem(10),
            width: rem(10),
            background: color,
            borderRadius: '50%',
          }}
        />
        <CustomText variant="h5">{item?.value}</CustomText>
      </Group>
    </Group>
  );
}

export default OtherAudienceInterestsItem;
