import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Box, Flex, rem } from '@mantine/core';
import CustomText from '../../Typography/CustomText/CustomText';
import { useStyle } from './CustomSelectionMenu.style';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevronDown-icon.svg';
import CustomSelectionBody from './CustomSelectionBody';

function CustomSelectionMenu({
  menuOptions,
  title,
  selectedItemTitles,
  onItemSelect,
  maxHeight,
  height,
  getExternalStyles,
  externalMenuTargetStyles,
  externalMenuDropdownStyles,
  wihtoutCheckboxes,
  setState,
}) {
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions && menuOptions.slice(0, Math.min(51, menuOptions.length)),
  );
  const [entireMenuOptions, setEntireMenuOptions] = useState(menuOptions);
  const [tempMenuOptionsState, setTempMenuOptionsState] = useState([]);
  const [canUpdateOptions, setCanUpdateOptions] = useState(true);
  const [opened, setOpened] = useState(false);
  const wrapperRef = useRef(null);
  const { classes, cx } = useStyle();

  useEffect(() => {
    if (setState) {
      setState(opened);
    }
  }, [opened]);

  const handleMenu = (value) => {
    setOpened(value);

    if (value) setCanUpdateOptions(false);
    else {
      setCanUpdateOptions(true);
    }
  };

  const loadMoreOptions = useCallback(() => {
    const lengthDelta = menuOptions.length - currentMenuOptions.length;
    setCurrentMenuOptions(
      menuOptions.slice(
        0,
        currentMenuOptions.length + (lengthDelta >= 50 ? 50 : lengthDelta),
      ),
    );
  }, [menuOptions, currentMenuOptions]);

  const toggleMenu = useCallback(() => {
    if (opened) {
      setCurrentMenuOptions(
        menuOptions && menuOptions.slice(0, Math.min(101, menuOptions.length)),
      );
      setEntireMenuOptions(menuOptions);
    }
    handleMenu(!opened);
  }, [opened]);

  useEffect(() => {
    const handleClick = (e) => {
      if (wrapperRef.current && wrapperRef.current.contains(e.target)) return;
      handleMenu(false);
    };

    document.addEventListener('click', handleClick);

    return () => {
      removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    const handleMouseLeave = () => {
      handleMenu(false);
    };

    if (wrapperRef?.current) {
      wrapperRef.current.addEventListener('mouseleave', handleMouseLeave);
    }
  }, [opened]);

  useEffect(() => {
    if (canUpdateOptions || entireMenuOptions.length !== menuOptions.length) {
      setCurrentMenuOptions(
        menuOptions && menuOptions.slice(0, Math.min(51, menuOptions.length)),
      );
      setEntireMenuOptions(menuOptions);
    } else {
      setTempMenuOptionsState(menuOptions);
    }
  }, [menuOptions]);

  useEffect(() => {
    if (canUpdateOptions && tempMenuOptionsState?.length) {
      setCurrentMenuOptions(
        tempMenuOptionsState.slice(0, Math.min(51, menuOptions.length)),
      );
    }
  }, [canUpdateOptions]);

  return (
    <Box className={cx(classes.menuRoot, 'menu-target')} ref={wrapperRef}>
      <Box
        className={cx(classes.menuTarget, 'menu-target')}
        onClick={toggleMenu}
        sx={{ ...(externalMenuTargetStyles || {}) }}
      >
        <Flex
          w="100%"
          justify="space-between"
          align="center"
          className="menu-target"
        >
          <CustomText variant="body2" className="menu-target">
            {title}
          </CustomText>
          <ChevronDown
            style={{
              width: rem(14),
              height: rem(8),
              transform: `rotate(${opened ? 180 : 0}deg)`,
            }}
          />
        </Flex>
      </Box>
      {opened && (
        <Box
          className={cx(classes.menuDropdown, 'dropdown-wrapper', {
            [classes.menuDropdownOverflowScroll]: menuOptions?.length <= 50,
          })}
          sx={{ maxHeight, ...(externalMenuDropdownStyles || {}) }}
        >
          {menuOptions?.length > 50 ? (
            <>
              <InfiniteScroll
                dataLength={currentMenuOptions?.length}
                next={loadMoreOptions}
                hasMore={menuOptions?.length > currentMenuOptions?.length}
                height={height || 500}
                loader={<CustomText variant="caption1">Loading...</CustomText>}
                scrollableTarget="dropdown-wrapper"
              >
                <CustomSelectionBody
                  currentMenuOptions={currentMenuOptions}
                  selectedItemTitles={selectedItemTitles}
                  onItemSelect={onItemSelect}
                  setOpened={handleMenu}
                  getExternalStyles={getExternalStyles}
                  wihtoutCheckboxes={wihtoutCheckboxes}
                />
              </InfiniteScroll>
            </>
          ) : (
            <CustomSelectionBody
              currentMenuOptions={currentMenuOptions}
              selectedItemTitles={selectedItemTitles}
              onItemSelect={onItemSelect}
              setOpened={handleMenu}
              getExternalStyles={getExternalStyles}
              wihtoutCheckboxes={wihtoutCheckboxes}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default CustomSelectionMenu;
