import React from 'react';
import { TextInput } from '@mantine/core';
import { useStyle } from './EditableInput.style';

function EditableInput({ ...rest }) {
  const { classes } = useStyle();

  return (
    <TextInput
        classNames={{
          input: classes.addressInput,
        }}
        {...rest}
    />
  );
}

export default EditableInput;
