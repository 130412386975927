import { createStyles } from '@mantine/core';

export const useStyle = createStyles(() => ({
  TimelineWrapper: {
    "& .mantine-Timeline-itemBullet[data-active]": {
        borderColor: '#228be6'
    },
    "& .mantine-Timeline-item[data-active]:before": {
        borderColor: '#228be6'
    }
  }
}));
