import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  addMoreButton: {
    height: rem(40),
    background: theme.colors.yellow[1],
    borderRadius: rem(122),
    padding: `0 ${rem(16)} 0 ${rem(8)}`,

    '&:hover svg': {
      transform: 'rotate(90deg)',
    },
  },

  inputContainer: {
    position: "relative",

    "& svg": {
        position: "absolute",
        right: rem(24),
        top: rem(12),
        transform: 'rotate(45deg)',
        cursor: "pointer"
    }
  }
}));
