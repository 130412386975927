import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentCompetitorContent: null,
  defaultActiveTab: 0,
};

export const competitorContentSlice = createSlice({
  name: 'competitorContent',
  initialState,
  reducers: {
    setCurrentCompetitorContent: (
      state,
      { payload: { currentCompetitorContent } },
    ) => {
      state.currentCompetitorContent = currentCompetitorContent;
    },
    setDefaultActiveTab: (state, { payload: { activeTab } }) => {
      state.defaultActiveTab = activeTab;
    },
    resetCompetitorState: () => {
      return initialState;
    },
  },
});

export const {
  setCurrentCompetitorContent,
  setDefaultActiveTab,
  resetCompetitorState,
} = competitorContentSlice.actions;

export default competitorContentSlice.reducer;
