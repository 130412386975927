import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useURLParams from './useURLParams';

const useNavigateWithState = () => {
  const { urlState } = useURLParams();
  const navigate = useNavigate();
  const location = useLocation()

  const navigateWithState = useCallback((href) => {
    navigate(href, {
      state: {
        prevUrl: [
          ...(urlState?.prevUrl || []),
          location.pathname + location.search,
        ],
      },
    });
  }, [urlState, location, navigate]);

  return navigateWithState
};

export default useNavigateWithState;
