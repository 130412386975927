import { Flex, TextInput } from '@mantine/core';
import React, { useCallback } from 'react';
import CustomText from '../../Typography/CustomText/CustomText';
import { useStyle } from './InputGroup.style';

function InputGroup({
  inputs,
  updateState,
  disabled,
  firstInputClassName,
  form,
  useDefaultDisableStyles,
  ...rest
}) {
  const { classes, cx } = useStyle();

  const generateInputs = useCallback(() => {
    return inputs.map((input, index) => {
      const labelElement = input.label && (
        <CustomText variant="h5">{input.label}</CustomText>
      );

      return (
        <TextInput
          key={input.placeholder}
          value={input.static ? input.placeholder : input.value}
          label={labelElement}
          placeholder={input.placeholder}
          classNames={{
            input: cx(classes.input, {
              [classes.inputDefaultDisable]: useDefaultDisableStyles,
              [classes.lastInput]: index === inputs.length - 1,
              [firstInputClassName]: index === 0,
              [classes.firstInput]: index === 0,
              [classes.inputWithBorder]: inputs.length === 2,
            }),
          }}
          onInput={(e) => updateState(e, input.name)}
          disabled={input.static || disabled}
          {...(form && !input.static ? form.getInputProps(input.name) : {})}
          {...rest}
        />
      );
    });
  }, [inputs, disabled, form, useDefaultDisableStyles]);

  return <Flex className="add-competitor-group">{generateInputs()}</Flex>;
}

export default InputGroup;
