import React from 'react';
import { Box, Group, rem, Stack } from '@mantine/core';
import { useMemo } from 'react';
import EnrichmentAction from '../../../../components/BlurredLayout/actions/EnrichmentAction';
import BlurredLayout from '../../../../components/BlurredLayout/BlurredLayout';
import Container from '../../../../components/Container/Container';
import SkeletonContainer from '../../../../components/SkeletonContainer';
import CustomText from '../../../../components/Typography/CustomText/CustomText';
import OtherAudienceInterestsItem from './OtherAudienceInterestsItem';
import { useParams } from 'react-router-dom';
import useEnrichTopic from '../../hooks/useEnrichTopic';

function OtherAudienceInterests({
  topicOtherAudienceInterests,
  loading,
}) {
  const { topicId } = useParams();
  const enrichAction = useEnrichTopic(topicId);

  const otherAudience = useMemo(() => {
    if (!topicOtherAudienceInterests) return;

    const otherAudienceColors = [
      '#FF2D2D',
      '#FF5959',
      '#FF8282',
      '#FFB1B1',
      '#F5D3D3',
    ];

    return topicOtherAudienceInterests.map((item, index) => (
      <OtherAudienceInterestsItem
        key={index}
        item={item}
        color={otherAudienceColors[index]}
      />
    ));
  }, [topicOtherAudienceInterests, rem]);

  return (
    <Container
      className="other-audience"
      sx={{
        width: '100%',
        padding: rem(24),
      }}
    >
      <Stack spacing={21}>
        <CustomText variant="h4">Other Audience Interests</CustomText>
        <Stack spacing={21} sx={{ position: 'relative' }}>
          {!topicOtherAudienceInterests?.length && (
            <BlurredLayout>
              <EnrichmentAction
                onSuccess={enrichAction}
                credits={1}
                remainingKey="topicReportScore"
              />
            </BlurredLayout>
          )}
          <Box mih={rem(193)}>
            <Stack spacing={21}>
              {loading ? (
                <Group>
                  <Stack spacing={21}>
                    <SkeletonContainer height={13} width={400} amount={5} />
                  </Stack>
                  <Stack spacing={21}>
                    <SkeletonContainer height={13} width={70} amount={5} />
                  </Stack>
                </Group>
              ) : (
                <>{otherAudience}</>
              )}
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
}

export default OtherAudienceInterests;
