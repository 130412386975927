import React, { useRef } from 'react';
import { getElementAtEvent, Line } from 'react-chartjs-2';
import { convertRemToPixels } from '../../utils';
import { TooltipComponentCallback } from './TooltipComponent';

import logger from '../../utils/logger';

const createDataset = (companyName, companyData, companyColor) => ({
  label: companyName,
  fill: false,
  lineTension: 0.1,
  backgroundColor: companyColor,
  borderColor: companyColor,
  pointBorderColor: companyColor,
  pointBackgroundColor: companyColor,
  pointBorderWidth: convertRemToPixels(0.063),
  pointHoverRadius: convertRemToPixels(0.313),
  pointHoverBackgroundColor: companyColor,
  pointHoverBorderColor: companyColor,
  pointHoverBorderWidth: convertRemToPixels(0.125),
  pointRadius: convertRemToPixels(0.188),
  pointHitRadius: convertRemToPixels(0.625),
  data: companyData,
});

/* TODO: the lowest resolution of the data is by day, so for a given time period if a
company has no data for a day, we must set the y axis to 0 */
const LineChart = ({
  labels, companyData, categoryType, unit, setActiveCompetitorName,
}) => {
// Create datasets for each company
  const datasets = companyData.map((company) => createDataset(company.name, company.data, company.chartColor));

  const lineRef = useRef(null);

  const onClick = (event) => {
    try {
      const elem = getElementAtEvent(lineRef.current, event);
      const data = datasets[elem[0].datasetIndex];
      setActiveCompetitorName(data?.label);
    } catch {
      setActiveCompetitorName(null);
    }
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,
        external: (context) => TooltipComponentCallback(context, categoryType, unit),
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#0F0E24',
          font: {
            size: convertRemToPixels(0.625),
            family: 'Manrope',
            weight: 500,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#0F0E24',
          font: {
            size: convertRemToPixels(0.625),
            family: 'Manrope',
            weight: 500,
          },
        },
      },
    },
  };

  const data = { labels, datasets };

  logger.info('line data', data);

  return (
    <Line ref={lineRef} data={data} options={options} onClick={onClick} />
  );
};

export default LineChart;
