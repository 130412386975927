// SignupButton.jsx
import React, { useState } from 'react';
import AlertComponent from '../Alert';
import './Buttons.css';

export const SignupButton = () => {
  const [showAlert, setShowAlert] = useState(false);

  const handleSignUp = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <AlertComponent
        showAlert={showAlert}
        onClose={handleCloseAlert}
        title="Signup is not implemented!"
        description="You cannot signup to ATC currently."
      />
      <button
        className="button__sign-up button__spacing"
        onClick={handleSignUp}
      >
        Sign Up
      </button>
    </>
  );
};
