import React, { useCallback, useMemo } from 'react';
import { Group, rem } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left-icon.svg';
import CustomText from '../Typography/CustomText/CustomText';
import useURLParams from '../../hooks/useURLParams';
import { mapTitlesFromURL } from '../../utils';

function BackNagination({ href, locationState, title, onClick }) {
  const navigate = useNavigate();
  const { urlState } = useURLParams()

  const handleExternalClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const handleClick = useCallback(() => {
    handleExternalClick();
    // navigation is used to go back to the prev route when href is -1

    navigate(urlState.prevUrl?.[urlState?.prevUrl?.length - 1] || href, { state: {
      prevUrl: (urlState.prevUrl || []).slice(0, -1),
      ...(locationState?.state || {})
    }, replace: true });
  }, [href, handleExternalClick, urlState]);

  const linkTitle = useMemo(() => {
    if(!urlState?.prevUrl) {
      return title
    }

    const titleFromURL = mapTitlesFromURL(urlState.prevUrl?.[urlState?.prevUrl?.length - 1] || '')
    return !titleFromURL ? title : `Back to ${titleFromURL}`
  }, [title, urlState])

  return (
    <>
      {href === -1 ? (
        <Link onClick={handleClick} className="back-navigation">
          <Group spacing={8}>
            <ArrowLeftIcon style={{width: rem(25), height: rem(25)}} />
            <CustomText variant="body1">
              {linkTitle}
            </CustomText>
          </Group>
        </Link>
      ) : (
        <Link
          to={urlState?.prevUrl?.[urlState?.prevUrl?.length - 1] || href}
          state={{
            prevUrl: (urlState?.prevUrl || []).slice(0, -1),
            ...(locationState?.state || {})
          }}
          className="back-navigation"
          onClick={handleExternalClick}
        >
          <Group spacing={8}>
            <ArrowLeftIcon style={{width: rem(25), height: rem(25)}} />
            <CustomText variant="body1">
              {linkTitle}
            </CustomText>
          </Group>
        </Link>
      )}
    </>
  );
}

export default BackNagination;
