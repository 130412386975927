import React, { useState, useMemo, useCallback } from 'react';
import { Box, Flex, Group, LoadingOverlay, Stack } from '@mantine/core';
import CustomTitle from '../Typography/CustomTitle/CustomTitle';
import CustomSegmentControl from '../SegmentControl/SegmentControl';
import { useStyle } from './PlanPageComponent.style';
import PlanCard from './PlanCard/PlanCard';
import useFetchPlans from '../upgradePlanComponents/hooks/useFetchPlans';
import SkeletonContainer from '../SkeletonContainer';
import {
  calculatePercentageSavings,
  getPlanDescription,
  planTierToTitleMap,
} from '../upgradePlanComponents/utils';
import useActivePlan from '../upgradePlanComponents/hooks/useActivePlan';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../features/layoutSlice';
import OrgSelectionMenu from '../header/OrgSelectionMenu/OrgSelectionMenu';
import CustomText from '../Typography/CustomText/CustomText';
import { useNavigate } from 'react-router-dom';

const PLAN_PERIODS = ['MONTHLY', 'ANNUAL'];
const PLAN_ORDER = ['ECONOMY', 'BUSINESS_CLASS', 'FIRST_CLASS'];

function PlanPageComponent({ onPlanSelect, smallOffset, redirectionFlow }) {
  const [planPeriod, setPlanPeriod] = useState(PLAN_PERIODS[0]);
  const [loading, setLoading] = useState(false);
  const [subscriptionPlans, subscriptionPlansLoading, updateSubscriptionPlan] =
    useFetchPlans();
  const [activeSubscriptionPlan, _, fetchActivePlan] = useActivePlan();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { classes } = useStyle();

  const handleSelection = useCallback(
    async (planData) => {
      setLoading(true);
      updateSubscriptionPlan(planData?.id)
        .then(() => {
          onPlanSelect(planData);
          if (redirectionFlow) {
            const MAX_FETCH_ATTEMPTS = 3;
            const ATTEMPT_DELAY = 5000;
            let currentAttempt = 0;

            const callbackOnSubscriptionChange = () => {
              const economyPlanTitle = planTierToTitleMap[PLAN_ORDER[0]];
              if (planData.title === economyPlanTitle) {
                navigate('/');
              } else if (
                PLAN_ORDER.some(
                  (plan) => planTierToTitleMap[plan] === planData.title,
                )
              ) {
                navigate(
                  `/capture-cc?flow=trialEnd&plan=${encodeURIComponent(
                    planData.title,
                  )}`,
                );
              }
            };

            const checkForSubscriptionUpdate = async (resolve, reject) => {
              if (currentAttempt === MAX_FETCH_ATTEMPTS) {
                reject(
                  `The subscription was not updated after max number of attempts, ${MAX_FETCH_ATTEMPTS}`,
                );
              }
              try {
                const newActivePlan = await fetchActivePlan();
                if (newActivePlan?.planId === planData?.id) {
                  resolve();
                }
              } catch (e) {
                reject(e);
              }
              currentAttempt++;
            };
            new Promise((resolve, reject) => {
              const intervalId = setInterval(
                () =>
                  checkForSubscriptionUpdate(
                    (message) => resolve({ intervalId, message }),
                    (message) => reject({ intervalId, message }),
                  ),
                ATTEMPT_DELAY,
              );
              checkForSubscriptionUpdate(
                (message) => resolve({ intervalId, message }),
                (message) => reject({ intervalId, message }),
              );
            })
              .then(({ intervalId }) => {
                clearInterval(intervalId);
                callbackOnSubscriptionChange();
              })
              .catch(({ intervalId, message }) => {
                clearInterval(intervalId);
                console.error(message);
                dispatch(
                  setSnackbar({
                    variant: 'error',
                    description: 'Something went wrong...',
                  }),
                );
              })
              .finally(() => {
                setLoading(false);
              });
          }
        })
        .catch((e) => {
          console.error(e);
          dispatch(
            setSnackbar({
              variant: 'error',
              description: 'Something went wrong...',
            }),
          );
        })
        .finally(() => {
          if (!redirectionFlow) {
            setLoading(false);
          }
        });
    },
    [onPlanSelect, updateSubscriptionPlan, redirectionFlow],
  );

  const planCardsList = useMemo(() => {
    if (!subscriptionPlans || !activeSubscriptionPlan) return;

    const elements = [];

    const subscriptionPlansByPeriod = subscriptionPlans.filter(
      (plan) => plan.paymentTerms === planPeriod,
    );

    const sortedSubscriptionPlan = [];

    PLAN_ORDER.forEach((currentPlanTier) => {
      sortedSubscriptionPlan.push(
        subscriptionPlansByPeriod.find(
          (plan) => plan?.tier === currentPlanTier,
        ),
      );
    });

    sortedSubscriptionPlan.forEach((rawPlanData) => {
      const planData = getPlanDescription(rawPlanData, activeSubscriptionPlan);

      elements.push(
        <PlanCard
          key={planData.id}
          planData={planData}
          monthlyPeriod={planPeriod === PLAN_PERIODS[0]}
          onSelect={handleSelection}
          redirectionFlow={redirectionFlow}
        />,
      );
    });

    return elements;
  }, [subscriptionPlans, planPeriod, activeSubscriptionPlan, handleSelection]);

  return (
    <Box>
      <Box className={classes.loadingOverlayContainer}>
        <LoadingOverlay visible={loading} zIndex={1000} />
      </Box>
      <Stack spacing={smallOffset ? 8 : 27} mb={smallOffset ? 16 : 24}>
        <Flex justify="space-between">
          <CustomTitle order={1}>Choose a plan that works for you</CustomTitle>
          {redirectionFlow && (
            <Group>
              <CustomText sx={{ fontWeight: '700' }}>
                Switch Account:
              </CustomText>
              <OrgSelectionMenu />
            </Group>
          )}
        </Flex>
        <Flex justify="flex-end">
          <CustomSegmentControl
            value={planPeriod}
            onChange={setPlanPeriod}
            data={[
              { value: PLAN_PERIODS[0], label: 'Monthly' },
              {
                value: PLAN_PERIODS[1],
                label: `Yearly (Save ${
                  subscriptionPlansLoading
                    ? 'Loading...'
                    : subscriptionPlans
                    ? `${calculatePercentageSavings(subscriptionPlans)}%`
                    : 'Loading...'
                })`,
              },
            ]}
            rootClassName={classes.segmentRoot}
            indicatorClassName={classes.indicator}
          />
        </Flex>
      </Stack>
      <Flex gap={24} justify="center">
        {subscriptionPlansLoading ? (
          <SkeletonContainer
            width={365}
            height={610}
            amount={3}
            radius="1.25rem"
          />
        ) : (
          <>{planCardsList}</>
        )}
      </Flex>
    </Box>
  );
}

export default PlanPageComponent;
