import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  buttonContainer: {
    width: rem(90),
    height: rem(40),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background: 'rgba(252, 252, 252, 0.90)',
    border: `${rem(1)} solid ${theme.colors.yellow[1]}`,
    borderRadius: rem(122),
    transition: '0.2s',
  },
  inputContainer: {
    width: rem(500),

    "& button": {
        height: rem(38),
    }
  },
  input: {
    height: rem(40),
    border: "none",
    background: "none",

    "&::placeholder": {
        fontSize: rem(16),
        fontFamily: "Manrope",
        color: theme.colors.black[0]
    }
  },
  inputRoot: {
    width: "80%",
  }
}));
