import React from 'react';
import { Box } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import Heading from '../../Heading';
import ContentWithVideo from '../components/ContentWithVideo/ContentWithVideo';
import { useStyle } from './RequestPending.style';
import arrowBottom from '../../../assets/icons/arrow-bottom.png';

function RequestPending() {
  const navigate = useNavigate();
  const { classes } = useStyle();

  return (
    <Box pb={101}>
      <ContentWithVideo
        fontSize={16}
        spacing={24}
        title={
          <>
            Request sent to <br /> Shoreditch Design
          </>
        }
        description={
          <>
            We’ve notified your teammates that you’d like to join the radar, you
            will get a login link in your inbox once they have accepted.
            <br />
            <br />
            While your wait, why not check out the video below on how Air
            Traffic Control can help your business grow through personalized
            content?
          </>
        }
        content={
          <Box className={classes.secondaryHeadingContainer}>
            <img src={arrowBottom} className={classes.arrow} />
            <Heading
              titleSize={32}
              small
              title="While you wait...."
              description={
                <>
                  See how Air Traffic Control can help your business grow
                  through <br /> personalized content.
                </>
              }
            />
          </Box>
        }
        buttonText="Back to home"
        onClick={() => navigate('/')}
      />
    </Box>
  );
}

export default RequestPending;
