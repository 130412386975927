import { Box, Flex, Group, RangeSlider, Stack } from '@mantine/core';
import React, { useMemo } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/cross-icon.svg';
import CustomCheckbox from '../../../../components/Checkbox';
import CustomText from '../../../../components/Typography/CustomText/CustomText';
import { LIST_KEY, TABS } from '../../constant';
import { useStyle } from '../../ContactsPage.style';
import useRangeFilters from '../../hooks/useRangeFilters';
import FilterPanelDropdown from '../../../TopicPage/components/FilterPanelDropdown';

function FilterDropdown({
  fields,
  opened,
  setOpened,
  updateFilter,
  setFilteringParams,
  filteringParams,
  filtersControlRef,
  filteringOptions,
  activeTab,
  selectedListsId,
  hubspotDetails,
}) {
  const [isInsightsRangeActive, setIsInsightsRangeActive] = useState(false);
  const [isContactsRangeActive, setIsContactsRangeActive] = useState(false);
  const [filterOpened, setFilterOpened] = useState(false);
  const [getRangeFilterMinAndMax] = useRangeFilters(
    activeTab,
    filteringOptions,
  );

  const { classes, cx } = useStyle();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClick = ({ target }) => {
      if (
        dropdownRef.current.contains(target) ||
        filtersControlRef.current.contains(target)
      )
        return;
      setOpened(false);
      setIsInsightsRangeActive(false);
      setIsContactsRangeActive(false);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [opened]);

  const distinctLists = useMemo(() => {
    const relevantLists = (filteringOptions || []).find(option => option?.key === LIST_KEY)?.options

    if (
      relevantLists &&
      hubspotDetails?.availableContactLists
    ) {
      const filterableList = [];

      relevantLists.forEach((listId) => {
        const filterableListItem = hubspotDetails.availableContactLists.find(
          (el) => el.listId === listId,
        );
        if (filterableListItem) {
          filterableList.push({
            listId: filterableListItem.listId,
            title: filterableListItem.name,
          });
        }
      });

      return filterableList
    }
    return [];
  }, [filteringOptions, hubspotDetails])

  return (
    <Box className={classes.filterDropdown} ref={dropdownRef}>
      <Box
        className={cx(classes.closeButton, 'filter__close-icon')}
        onClick={() => setOpened(false)}
      >
        <CrossIcon />
      </Box>
      <Stack spacing={8}>
        <CustomText variant="h3">Filter</CustomText>
        <CustomText>
          {activeTab === TABS[0] ? <>Contact Lists</> : <>Attributes</>}
        </CustomText>
        <Flex gap={16} sx={{ flexWrap: 'wrap' }}>
          {fields}
          {activeTab === TABS[0] && (
            <FilterPanelDropdown
              filterByData={distinctLists || []}
              filterOpened={filterOpened}
              setFilterOpened={setFilterOpened}
              filteringParams={filteringParams}
              setFilteringParams={setFilteringParams}
              hubspotDetails={hubspotDetails}
              selectedListsId={selectedListsId}
              onlyDropdown
            />
          )}
        </Flex>
      </Stack>
      <Group mt={16} spacing={58}>
        {activeTab === TABS[2] && (
          <Stack spacing={8}>
            <CustomText variant="h5">Account type</CustomText>
            <Group>
              <Group
                spacing={8}
                onClick={() => {
                  updateFilter('filter[customer][]', 'Customer');
                }}
              >
                <CustomCheckbox
                  checked={(
                    filteringParams?.['filter[customer][]'] || []
                  ).includes('Customer')}
                />
                <CustomText variant="body2">Customer</CustomText>
              </Group>
              <Group
                spacing={8}
                onClick={() => {
                  updateFilter('filter[customer][]', 'Ex-customer');
                }}
              >
                <CustomCheckbox
                  checked={(
                    filteringParams?.['filter[customer][]'] || []
                  ).includes('Ex-customer')}
                />
                <CustomText variant="body2">Ex-Customer</CustomText>
              </Group>
            </Group>
          </Stack>
        )}
        <Stack spacing={24} w="50%">
          <Group sx={{ flexWrap: 'nowrap' }}>
            {activeTab === TABS[1] && (
              <Box w="100%">
                <Stack spacing={24}>
                  <CustomText variant="h5">Number of contacts</CustomText>
                  <RangeSlider
                    size={4}
                    thumbSize={12}
                    labelAlwaysOn
                    min={getRangeFilterMinAndMax('contactsCountRange').min}
                    max={getRangeFilterMinAndMax('contactsCountRange').max}
                    minRange={1}
                    value={
                      filteringParams?.contacts?.length
                        ? filteringParams.contacts
                        : [
                            getRangeFilterMinAndMax('contactsCountRange').min,
                            getRangeFilterMinAndMax('contactsCountRange').max,
                          ]
                    }
                    onChange={(props) => {
                      setFilteringParams((prevState) => ({
                        ...prevState,
                        contacts: props,
                      }));
                      setIsContactsRangeActive(true);
                    }}
                    classNames={{
                      track: classes.rangeTrack,
                      thumb: cx(classes.rangeThumb, {
                        [classes.rangeThumbActive]: isContactsRangeActive,
                      }),
                      bar: cx(classes.rangeBar, {
                        [classes.rangeBarActive]: isContactsRangeActive,
                      }),
                      label: classes.rangeLabel,
                    }}
                  />
                </Stack>
              </Box>
            )}
            <Box w="100%">
              <Stack spacing={24}>
                <CustomText variant="h5">Number of insights</CustomText>
                <RangeSlider
                  size={4}
                  thumbSize={12}
                  labelAlwaysOn
                  min={getRangeFilterMinAndMax('insightsCountRange').min}
                  max={getRangeFilterMinAndMax('insightsCountRange').max}
                  minRange={1}
                  value={
                    filteringParams?.insights?.length
                      ? filteringParams.insights
                      : [
                          getRangeFilterMinAndMax('insightsCountRange').min,
                          getRangeFilterMinAndMax('insightsCountRange').max,
                        ]
                  }
                  onChange={(props) => {
                    setFilteringParams((prevState) => ({
                      ...prevState,
                      insights: props,
                    }));
                    setIsInsightsRangeActive(true);
                  }}
                  classNames={{
                    track: classes.rangeTrack,
                    thumb: cx(classes.rangeThumb, {
                      [classes.rangeThumbActive]: isInsightsRangeActive,
                    }),
                    bar: cx(classes.rangeBar, {
                      [classes.rangeBarActive]: isInsightsRangeActive,
                    }),
                    label: classes.rangeLabel,
                  }}
                />
              </Stack>
            </Box>
          </Group>
        </Stack>
      </Group>
    </Box>
  );
}

export default FilterDropdown;
