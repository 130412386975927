import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  cardsContainer: {
    flexWrap: 'wrap',
    columnGap: 24,
    rowGap: 16,
    justifyContent: 'center',
  },
  paginationControl: {
    minWidth: 'fit-content',
    border: 'none',
    background: theme.colors.gray[2],
    color: theme.colors.gray[4],
    padding: `${0} ${rem(4)}`,

    '&[data-active="true"]': {
      color: theme.colors.black[0],
    },
  },
}));
