import React, { useMemo } from 'react';
import {
  Box, Flex, Group, Stack, TextInput, UnstyledButton,
} from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import CustomText from '../../../Typography/CustomText/CustomText';
import { useStyle } from './CardForm.style';
import MainButton from '../../../buttons/buttons';
import PaymentMethodSelection from './components/PaymentMethodSelection';
import NewAddressForm from './components/NewAddressForm/NewAddressForm';
import ExistingAddressForm from './components/ExistingAddressForm/ExistingAddressForm';

function CardForm({
  externalForm, withMethodSelection, withAddress, onClose, onSubmit, addressExist,
}) {
  const internalForm = useForm({
    initialValues: {
      name: '',
      cardNumber: '',
      expirationDate: '',
      cvv: '',
      addressLineFirst: 'Rosie Guy',
      addressLineSecond: '35 Luke Street,',
      addressLineThird: 'London,',
      state: 'United Kingdom,',
      postCode: 'EC2A 4LH',
      billingAddressLineFirst: 'Rosie Guy',
      billingAddressLineSecond: '35 Luke Street,',
      billingAddressLineThird: 'London,',
      billingState: 'United Kingdom,',
      billingPostCode: 'EC2A 4LH',
    },
    validate: {
      name: isNotEmpty('Name must not be empty'),
      cardNumber: isNotEmpty('Card Number must not be empty'),
      expirationDate: isNotEmpty('Expiration Date must not be empty'),
      cvv: isNotEmpty('CVV must not be empty'),
      addressLineFirst: isNotEmpty('Address line 1 must not be empty'),
      addressLineSecond: isNotEmpty('Address line 2 must not be empty'),
      state: isNotEmpty('State must not be empty'),
      postCode: isNotEmpty('Zip code must not be empty'),
    },
  });
  const form = useMemo(() => externalForm || internalForm, [externalForm, internalForm]);
  const { classes } = useStyle();

  return (
    <Box w={455}>
        <form onSubmit={form.onSubmit(onSubmit)}>
            {withMethodSelection && (
                <PaymentMethodSelection />
            )}
            <Stack>
                <TextInput
                    placeholder="Miss Rosie Guy"
                    label={<CustomText variant="body2">Name on card</CustomText>}
                    withAsterisk
                    classNames={{
                      input: classes.input,
                      label: classes.inputLabel,
                      error: classes.inputError,
                    }}
                    {...form.getInputProps('name')}
                />
                <TextInput
                    placeholder="**** **** **** 0066"
                    label={<CustomText variant="body2">Card number</CustomText>}
                    withAsterisk
                    classNames={{
                      input: classes.input,
                      label: classes.inputLabel,
                      error: classes.inputError,
                    }}
                    {...form.getInputProps('cardNumber')}
                />
                <Flex justify="space-between" gap={16}>
                    <TextInput
                        placeholder="MM/YY"
                        label={<CustomText variant="body2">Expiration date</CustomText>}
                        withAsterisk
                        classNames={{
                          input: classes.input,
                          label: classes.inputLabel,
                          root: classes.withFlex,
                          error: classes.inputError,
                        }}
                        {...form.getInputProps('expirationDate')}
                    />
                    <TextInput
                        placeholder="123"
                        label={<CustomText variant="body2">CCV Code</CustomText>}
                        withAsterisk
                        classNames={{
                          input: classes.input,
                          label: classes.inputLabel,
                          root: classes.withFlex,
                          error: classes.inputError,
                        }}
                        {...form.getInputProps('cvv')}
                    />
                </Flex>
            </Stack>
            {withAddress && (
                // TODO: When the user authentication will be implemented, display the form depending on user address status
                <>
                    {addressExist ? (
                        // NewAddressForm is used when user address is not determined
                        <ExistingAddressForm form={form} />
                    ) : (
                        // ExistingAddressForm is used when user address is determined
                        <NewAddressForm form={form} />
                    )}
                </>
            )}
            {!externalForm && (
                <Group spacing={32} mt={withAddress ? 32 : 56}>
                    <MainButton smallPadding type="submit">
                        <CustomText variant="body2">
                            Confirm
                        </CustomText>
                    </MainButton>
                    {onClose && (
                        <UnstyledButton onClick={onClose}>
                            <CustomText variant="body2" sx={(theme) => ({ color: theme.colors.gray[4] })}>
                                Cancel
                            </CustomText>
                        </UnstyledButton>
                    )}
                </Group>
            )}
        </form>
    </Box>
  );
}

export default CardForm;
