import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  statsContainer: {
    minWidth: rem(268),
    background: theme.colors.white[3],
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: rem(20),
    padding: rem(24),
  },
  statDifference: {
    height: rem(40),
    padding: rem(8),

    '& svg': {
      height: `${rem(24)} !important`,
    },
  },
}));
