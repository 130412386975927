import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useRadarApi from "../../../hooks/useRadarApi";

const useFetchAnalysisMetricsData = (jwtTokenLoading, urlDateParams) => {
    const [competitorAnalysisMetricsData, setCompetitorAnalysisMetricsData] = useState([]);
    const { getCompetitorAnalysisMetrics } = useRadarApi()
    const [fetchAnalysisTimeMetricsData, loading] = useFetch(getCompetitorAnalysisMetrics);

    const fetchWrapper = useCallback(async () => {
      const data = await fetchAnalysisTimeMetricsData({
        dateFilter: JSON.stringify(urlDateParams),
      });
      setCompetitorAnalysisMetricsData(data);
    }, [fetchAnalysisTimeMetricsData, getCompetitorAnalysisMetrics, urlDateParams]);

    useEffect(() => {
      if (!jwtTokenLoading) {
        fetchWrapper();
      }
    }, [jwtTokenLoading, JSON.stringify(urlDateParams)]);

    return [competitorAnalysisMetricsData, loading]
}

export  default useFetchAnalysisMetricsData