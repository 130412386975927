import { createStyles } from '@mantine/core';

export const useStyle = createStyles(() => ({
  tickIconContainer: {
    display: 'flex',
    height: 18,

    '& svg': {
      width: 18,
      height: 18,
    },

    '& path': {
      stroke: '#000',
    },
  },
}));
