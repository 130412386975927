import React, { useCallback, useState } from 'react';
import { Box, Group, Flex, Stack, UnstyledButton } from '@mantine/core';
import CustomText from '../Typography/CustomText/CustomText';
import { useStyle } from './TagsList.style';
import SkeletonContainer from '../SkeletonContainer';

function TagsList({ data, title, loading }) {
  const [interestsExpanded, setInterestsExpanded] = useState(false);
  const { classes, cx } = useStyle();

  const generateInterests = useCallback(() => {
    if (!data) return;
    const elements = [];

    if ((!data.length ?? 0) > 0) {
      return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <CustomText
            variant="h4"
            sx={{
              fontWeight: 500,
            }}
          >
            Articles not found
          </CustomText>
        </Box>
      );
    }

    data.forEach((interest, index) => {
      const isObject = typeof interest === 'object';

      elements.push(
        <Box
          key={isObject ? interest.id : index}
          className={cx(classes.interestItem, `tag-${index}`)}
        >
          <CustomText variant="caption1">
            {isObject ? interest.value : interest}
          </CustomText>
        </Box>,
      );
    });

    return elements;
  }, [data]);

  return (
    <Stack spacing={13}>
      <CustomText variant="h4" className={!data?.length ? 'tag-0' : ''}>
        {title}
      </CustomText>
      <Flex gap={16}>
        <Flex span={11} mx={data?.length === 0 ? 'auto' : 0}>
          <Group
            spacing={8}
            className={classes.interestContainer}
            sx={{
              maxHeight: interestsExpanded ? '1000vh' : null,
            }}
          >
            {loading && (
              <Group spacing={8}>
                <SkeletonContainer
                  width={98}
                  height={25}
                  radius={100}
                  amount={8}
                />
              </Group>
            )}
            {!loading && <>{generateInterests()}</>}
          </Group>
        </Flex>
        {!loading && data?.length > 8 && (
          <Flex miw="fit-content" h={29}>
            <UnstyledButton
              miw="fit-content"
              onClick={() => setInterestsExpanded((prevState) => !prevState)}
            >
              <CustomText variant="body2">
                {interestsExpanded ? (
                  <>See less</>
                ) : (
                  <>See all ({data.length})</>
                )}
              </CustomText>
            </UnstyledButton>
          </Flex>
        )}
      </Flex>
    </Stack>
  );
}

export default TagsList;
