import { Loader, Stack } from '@mantine/core';
import CustomText from '../../components/Typography/CustomText/CustomText';
import { getTimeLine } from './common';

export const testFlightUserData = {
  beforeIntegration: {
    title: (
      <Stack align="center">
        <CustomText variant="h2" sx={{ textAlign: 'center' }}>
          Please wait while we get you through passport control
        </CustomText>
        <Loader color="#F6C25D" />
      </Stack>
    ),
    description: <>{getTimeLine(0)}</>,
  },
  afterEmailVerification: {
    title: (
        <CustomText variant="h2" sx={{ textAlign: 'center' }}>
          Please connect your hubspot
        </CustomText>
    ),
    description: <>{getTimeLine(2)}</>,
  },
  afterIntegration: {
    title: 'Connected',
    // description: (
    //   <CustomText variant="h2" sx={{ textAlign: 'center' }}>
    //     keep in touch
    //   </CustomText>
    // ),
  },
};
