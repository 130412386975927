import { Group } from '@mantine/core';
import React from 'react';
import BigStatsContainer from '../../../components/statsContainers/BigStatsContainer/BigStatsContainer';
// import BlurredLayout from '../../../components/BlurredLayout/BlurredLayout';
// import SmallStatsContainer from '../../../components/statsContainers/SmallStatsContainer/SmallStatsContainer';
import InfoList from './InfoList/InfoList';
// import ConnectAnalyticsAction from '../../../components/BlurredLayout/actions/ConnectAnalyticsAction';

function ContentStats({contentDetails, loading}) {
  return (
    <Group spacing={24} sx={{ flexWrap: 'nowrap' }}>
      <InfoList contentDetails={contentDetails} loading={loading} />
      <Group spacing={24} sx={{ flexWrap: 'nowrap' }}>
        <BigStatsContainer
          withoutChangeIndicator
          percentValue={
            contentDetails?.analytics?.contentInfluencedRevenue
              ?.changePercentage || 0
          }
          title={`$${
            contentDetails?.analytics?.contentInfluencedRevenue?.value || 0
          }`}
          description="Content Influenced Revenue"
          height={252}
          loading={loading}
        />
        {/* <Box
          sx={{
            columnGap: rem(24),
            rowGap: rem(16),
            display: "grid",
            gridTemplateColumns: "auto auto",
            position: 'relative',
          }}
        >
          <BlurredLayout>
              <ConnectAnalyticsAction />
          </BlurredLayout>
          <SmallStatsContainer
            title={contentDetails?.analytics?.engagements || 0}
            description="Engagments"
            loading={loading}
          />
          <SmallStatsContainer
            title={`${contentDetails?.analytics?.averageScrollDepth || 0}%`}
            description="Avg. Scroll depth"
            loading={loading}
          />
          <SmallStatsContainer
            title={`${contentDetails?.analytics?.bounceRate || 0}%`}
            description="Bounce rate"
            loading={loading}
          />
          <SmallStatsContainer
            title={contentDetails?.analytics?.averageTimeOnPage || 0}
            description="Avg. Time on page"
            loading={loading}
          />
        </Box> */}
      </Group>
    </Group>
  );
}

export default ContentStats;