import { Box, Flex, Group, LoadingOverlay, rem, Stack } from '@mantine/core';
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import PolarScatterChart from '../charts/PolarScatterChart';
import CustomText from '../Typography/CustomText/CustomText';
import { ReactComponent as QuestionIcon } from '../../assets/icons/question-icon.svg';
import { useStyle } from './RadarChart.style';
import TitleWithTooltip from '../TitleWithTooltip/TitleWithTooltip';
import BlurQuadrants from './components/BlurQuadrants';

function RadarChart({ data, tooltipActive, onModalClose, loading }) {
  const [activeTitleIndex, setActiveTitleIndex] = useState(null);
  const [activeQuadrant, setActiveQuadrant] = useState(null);
  const [activePointData, setActivePointData] = useState({
    valueX: null,
    valueY: null,
    x: null,
    y: null,
    tooltipData: {
      label: null,
      engagement: null,
      topicIncidence: null,
    },
  });
  const chartContainerRef = useRef(null);
  const { classes, cx } = useStyle();

  const updateActiveQuadrant = useCallback(
    (value) => {
      setActivePointData((prevState) => ({
        ...prevState,
        valueX: null,
        valueY: null,
      }));
      setActiveQuadrant((prevState) => (prevState === value ? null : value));
    },
    [activeQuadrant],
  );

  const updateActiveTitleIndex = useCallback(
    (index) => {
      if (activeTitleIndex === index) {
        setActiveTitleIndex(null);
      } else {
        setActiveTitleIndex(index);
      }
    },
    [activeTitleIndex],
  );

  const getIsTitleActive = useCallback(
    (index) => {
      return activeTitleIndex === index && activeTitleIndex !== null;
    },
    [activeTitleIndex],
  );

  const getIsQuadrantActive = useCallback(
    (value, defualtValue = true) =>
      activeQuadrant === null ? defualtValue : activeQuadrant === value,
    [activeQuadrant],
  );

  useEffect(() => {
    const handleClick = (e) => {
      if (
        typeof e.target.className === 'string' &&
        e.target.className.split(' ')[0] === 'radar-chart__container'
      ) {
        setActiveQuadrant(null);
        setActivePointData((prevState) => ({
          ...prevState,
          valueX: null,
          valueY: null,
        }));
      } else {
        const {
          x: containerX,
          y: containerY,
          width,
          height,
        } = chartContainerRef.current.getBoundingClientRect();

        const [x, y] = [e.clientX - containerX, e.clientY - containerY];

        if (e.target.tagName === 'svg') {
          setActivePointData((prevState) => ({
            ...prevState,
            valueX: null,
            valueY: null,
          }));

          if (activeQuadrant !== null) {
            setActiveQuadrant(null);
            return;
          }

          if (x > width / 2) {
            if (y > height / 2) {
              setActiveQuadrant(4);
            } else {
              setActiveQuadrant(1);
            }
          } else if (y > height / 2) {
            setActiveQuadrant(2);
          } else {
            setActiveQuadrant(3);
          }
        } else if (e.target.tagName === 'path') {
          // if the difference between old and new cords isless than delat then there is no need to update it
          const coordsDelta = 1;

          if (
            Math.abs(activePointData.x - x) < coordsDelta &&
            activePointData.x &&
            Math.abs(activePointData.y - y) < coordsDelta &&
            activePointData.y
          )
            return;
          setActivePointData((prevState) => ({
            ...prevState,
            x,
            y,
          }));
        }
      }
    };
    chartContainerRef.current.addEventListener('click', handleClick);

    return () => {
      if (!chartContainerRef.current) return;
      chartContainerRef.current.removeEventListener('click', handleClick);
    };
  }, [activePointData]);

    const xDomain = useMemo(() => {
      if(!data?.length) return
      let maxX = data[0]?.incidence

      data.forEach(el => {
        if(el?.incidence > maxX) {
          maxX = el?.incidence
        }
      });

      return maxX
    }, [data])

    const yDomain = useMemo(() => {
      if(!data?.length) return
      let maxY = data[0]?.engagement

      data.forEach(el => {
        if(el?.engagement > maxY) {
          maxY = el?.engagement
        }
      });

      return maxY
    }, [data])

  return (
    <Stack spacing={8}>
      <Flex justify="space-between" align="center">
        <TitleWithTooltip
          title="Topic radar"
          active={tooltipActive}
          onClose={onModalClose}
          modalTitle="What is the Topic Radar?"
          modalContent={
            <>
              <Stack>
                <CustomText variant="body2">
                  The Topic Radar lets you quickly assess whether the topics
                  you’re creating content about are the same things your
                  audience is engaging with.
                  <br />
                  <br />
                  Along the X axis is the ratio of engagements with a topic
                  relative to how often a topic was written about.
                  <br />
                  <br />
                  For example, if a topic was written about 5 times, but engaged
                  with 10 times, that topic’s ratio would be 2:1.
                  <br />
                  <br />
                  Along the Y axis is how many times you’ve written about a topic.
                  <br />
                  <br />
                  So, in the top right quadrant, these are topics where you spend
                  a lot of your effort and it’s paying off.
                  <br />
                  <br />
                  The top left quadrant are topics you write about often, but
                  don’t generate much engagement.
                  <br />
                  <br />
                  The bottom left quadrant are topics you rarely write about and
                  don’t perform very well when you do.
                  <br />
                  <br />
                  Lastly, the bottom right quadrant are probably your best
                  opportunities as these are topics you don’t write about very often,
                  but generate a lot of engagement.
                  <br />
                  <br />
                  You can click on any quadrant to filter the topic list below.
                </CustomText>
              </Stack>
            </>
          }
        />
        <CustomText variant="h6" sx={{ fontWeight: 600 }}>
          Hover on a dot to view stats
        </CustomText>
      </Flex>
      <Box
        className={cx(classes.chartContainer, 'radar-chart__container')}
        ref={chartContainerRef}
      >
        {loading && (
          <LoadingOverlay
            visible
            zIndex={50}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{color: "#228be6"}}
          />
        )}
        {/* Chart tooltip on click */}
        {(activePointData.valueX !== null || activePointData.valueY !== null) ? (
          <Box
            className={classes.chartTooltip}
            sx={{ left: activePointData.x, top: activePointData.y }}
          >
            <Stack spacing={2}>
              <CustomText variant="h5" sx={{ fontSize: rem(12) }}>
                {activePointData.tooltipData.label}
              </CustomText>
              <CustomText variant="body2" sx={{ fontSize: rem(11) }}>
                {activePointData.tooltipData.engagement}
              </CustomText>
              <CustomText variant="body2" sx={{ fontSize: rem(11) }}>
                {activePointData.tooltipData.topicIncidence}
              </CustomText>
            </Stack>
          </Box>
        ) : null}
        {/* Labels for quadrants */}
        {getIsTitleActive(1) ? (
          <Box
            className={classes.chartLabel}
            sx={{ top: 24, right: 24 }}
            onClick={() => updateActiveTitleIndex(1)}
          >
            <CustomText
              variant="h6"
              sx={(theme) => ({
                color: theme.colors.white[3],
                lineHeight: rem(14),
              })}
            >
              Topics you write about a lot get great engagement
            </CustomText>
          </Box>
        ) : (
          <Box
            className={classes.chartQuestionLabel}
            sx={{ top: 24, right: 24 }}
            onClick={() => updateActiveTitleIndex(1)}
          >
            <QuestionIcon style={{ width: rem(16), height: rem(16) }} />
          </Box>
        )}
        {getIsTitleActive(2) ? (
          <Box
            className={classes.chartLabel}
            sx={{ bottom: 24, left: 24 }}
            onClick={() => updateActiveTitleIndex(2)}
          >
            <CustomText
              variant="h6"
              sx={(theme) => ({
                color: theme.colors.white[3],
                lineHeight: rem(14),
              })}
            >
              Topics rarely written about and gets bad engagement
            </CustomText>
          </Box>
        ) : (
          <Box
            className={classes.chartQuestionLabel}
            sx={{ bottom: 24, left: 24 }}
            onClick={() => updateActiveTitleIndex(2)}
          >
            <QuestionIcon style={{ width: rem(16), height: rem(16) }} />
          </Box>
        )}
        {getIsTitleActive(3) ? (
          <Box
            className={classes.chartLabel}
            sx={{ top: 24, left: 24 }}
            onClick={() => updateActiveTitleIndex(3)}
          >
            <CustomText
              variant="h6"
              sx={(theme) => ({
                color: theme.colors.white[3],
                lineHeight: rem(14),
              })}
            >
              Topics rarely written about, but gets great engagement
            </CustomText>
          </Box>
        ) : (
          <Box
            className={classes.chartQuestionLabel}
            sx={{ top: 24, left: 24 }}
            onClick={() => updateActiveTitleIndex(3)}
          >
            <QuestionIcon style={{ width: rem(16), height: rem(16) }} />
          </Box>
        )}
        {getIsTitleActive(4) ? (
          <Box
            className={classes.chartLabel}
            sx={{ bottom: 24, right: 24 }}
            onClick={() => updateActiveTitleIndex(4)}
          >
            <CustomText
              variant="h6"
              sx={(theme) => ({
                color: theme.colors.white[3],
                lineHeight: rem(14),
              })}
            >
              Topics written about a lot with poor engagement
            </CustomText>
          </Box>
        ) : (
          <Box
            className={classes.chartQuestionLabel}
            sx={{ bottom: 24, right: 24 }}
            onClick={() => updateActiveTitleIndex(4)}
          >
            <QuestionIcon style={{ width: rem(16), height: rem(16) }} />
          </Box>
        )}
        <BlurQuadrants
          getIsQuadrantActive={getIsQuadrantActive}
          updateActiveQuadrant={updateActiveQuadrant}
        />
        <Stack
          spacing={8}
          sx={{
            alignItems: 'center',
            position: 'absolute',
            top: rem(23),
          }}
        >
          <CustomText
            variant="h6"
            sx={(theme) => ({
              color: theme.colors.white[2],
            })}
          >
            High Engagement Rate
          </CustomText>
          <Box w={2} h={33} bg="#ED7470" opacity="0.5" />
        </Stack>
        <Group
          spacing={8}
          sx={{
            alignItems: 'center',
            position: 'absolute',
            right: rem(11),
          }}
        >
          <Box w={28} h={2} bg="#ED7470" opacity="0.5" />
          <CustomText
            variant="h6"
            sx={(theme) => ({
              color: theme.colors.white[2],
            })}
          >
            High Topic
            <br />
            Incidence
          </CustomText>
        </Group>
        <Stack
          spacing={8}
          sx={{
            alignItems: 'center',
            position: 'absolute',
            bottom: rem(23),
          }}
        >
          <Box w={2} h={33} bg="#ED7470" opacity="0.5" />
          <CustomText
            variant="h6"
            sx={(theme) => ({
              color: theme.colors.white[2],
            })}
          >
            Low Engagement Rate
          </CustomText>
        </Stack>
        <Group
          spacing={8}
          sx={{
            alignItems: 'center',
            position: 'absolute',
            left: rem(14),
          }}
        >
          <CustomText
            variant="h6"
            sx={(theme) => ({
              color: theme.colors.white[2],
            })}
          >
            Low Topic
            <br />
            Incidence
          </CustomText>
          <Box w={28} h={2} bg="#ED7470" opacity="0.5" />
        </Group>
        <Box>
          <PolarScatterChart
            data={(data || []).map((el) => ({
              x: el.incidence,
              y: el.engagement,
              extraData: {
                label: el.value,
                engagement: `${el.engagement} Engagement`,
                topicIncidence: `${el.incidence} Topic Incidence`,
              },
            }))}
            xDomain={xDomain}
            yDomain={yDomain}
            activePointData={activePointData}
            setActivePointData={setActivePointData}
          />
        </Box>
      </Box>
    </Stack>
  );
}

export default RadarChart;
