import { Group, UnstyledButton } from '@mantine/core';
import React from 'react';
import { ReactComponent as CircleCross } from '../../../../assets/icons/circle-cross-icon.svg';
import CustomText from '../../../Typography/CustomText/CustomText';
import { useStyle } from './AddMoreButton.style';

function AddMoreButton({onClick, text, ...rest}) {
  const { classes } = useStyle()

  return (
    <UnstyledButton
      className={classes.addMoreButton}
      onClick={onClick}
      {...rest}
    >
      <Group spacing={4}>
        <CircleCross />
        <CustomText variant="bodySmall" sx={{ fonntWeight: 500 }}>
          {text}
        </CustomText>
      </Group>
    </UnstyledButton>
  );
}

export default AddMoreButton;
