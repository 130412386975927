import { Box } from '@mantine/core'
import React from 'react'
import { useStyle } from './BlurredLayout.style'

function BlurredLayout({children, blur}) {
  const { classes } = useStyle()

  return (
    <Box className={classes.layout} sx={{backdropFilter: blur}}>
      {children}
    </Box>
  )
}

export default BlurredLayout