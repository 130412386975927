import { Loader, Stack } from '@mantine/core';
import CustomText from '../../components/Typography/CustomText/CustomText';
import { getTimeLine } from './common';

export const contentUtilizationUserData = {
  beforeIntegration: {
    title: (
      <Stack align="center">
        <CustomText variant="h2" sx={{ textAlign: 'center' }}>
          Please wait while we get you through passport control
        </CustomText>
        <Loader color="#F6C25D" />
      </Stack>
    ),
    description: <>{getTimeLine(0)}</>,
  },
  afterEmailVerification: {
    title: (
        <CustomText variant="h2" sx={{ textAlign: 'center' }}>
          Please connect your hubspot
        </CustomText>
    ),
    description: <>{getTimeLine(2)}</>,
  },
  afterIntegration: {
    title: 'Connected',
    // description: (
    //   <CustomText variant="h2" sx={{ textAlign: 'center' }}>
    //     Great! Our robots are getting to <br />
    //     work and we'll send you an email in 48 hours
    //     <br /> with your content utilization score. If you <br />
    //     have any questions in the meantime, <br />
    //     drop us a{' '}
    //     <a href="mailto:nzeckets@airtrafficcontrol.io">
    //       {' '}
    //       <span style={{ fontFamily: 'Manrope', color: '#007bff' }}>line</span>
    //     </a>{' '}
    //     or poke around our{' '}
    //     <a href="https://www.airtrafficcontrol.io">
    //       <span style={{ fontFamily: 'Manrope', color: '#007bff' }}>
    //         website
    //       </span>
    //     </a>
    //   </CustomText>
    // ),
  },
};
