import React, { useMemo } from 'react';
import { Box, rem, Stack } from '@mantine/core';
import DoubleInput from './DoubleInput';
import AddMoreButton from '../AddMoreButton/AddMoreButton';
import CustomText from '../../../Typography/CustomText/CustomText';
import { useStyle } from './InputGroup.style';

function InputGroup({
  leftTitle,
  rightTitle,
  inputs,
  onAdd,
  ...rest
}) {
  const { classes } = useStyle()

  const doubleInputs = useMemo(() => {
    return inputs.map((input, index) => (
      <DoubleInput
        rightLabel={
          index === 0 ? (
            <CustomText
              variant="h2"
              sx={(theme) => ({
                fontSize: rem(24),
                color: theme.colors.white[2],
              })}
            >
              {rightTitle}
            </CustomText>
          ) : null
        }
        leftLabel={
          index === 0 ? (
            <CustomText
              variant="h2"
              sx={(theme) => ({
                fontSize: rem(24),
                color: theme.colors.white[2],
              })}
            >
              {leftTitle}
            </CustomText>
          ) : null
        }
        key={`double-input-${index}`}
        leftValue={input.leftValue}
        rightValue={input.rightValue}
        index={index}
        {...rest}
      />
    ));
  }, [inputs, rest]);

  return (
    <Stack className={classes.inputsContainer}>
      {doubleInputs}
      {onAdd && (
        <Box>
          <AddMoreButton text="Add" onClick={onAdd} />
        </Box>
      )}
    </Stack>
  );
}

export default InputGroup;
