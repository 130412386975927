import { useCallback } from 'react';

import logger from '../utils/logger';

const useFetchTabsWithContent = (id, fetchFunc, extraParams = {}) => {
  const fetchTopicDetailContent = useCallback(
    async ({ offset, setLoading, articleType }) => {
      try {
        const params = {
          limit: 8,
          offset: offset,
          'filter[contentType]': articleType,
          ...extraParams
        };
        let data;
        if (id) {
          data = await fetchFunc(id, params);
        } else {
          data = await fetchFunc(params);
        }
        setLoading(false);

        return data;
      } catch (error) {
        setLoading(false);
        logger.error(`Failed to fetch ${articleType} content data:`, error);
      }
    },
    [fetchFunc, JSON.stringify(extraParams), id],
  );

  return fetchTopicDetailContent;
};

export default useFetchTabsWithContent;
