import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { auth0config } from '../config';

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const {
    tenantUri: domain, clientId, audience, redirectUri,
  } = auth0config;

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      authorizationParams={{
        audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
      scope="openid profile email"
    >
      {children}
    </Auth0Provider>
  );
};
