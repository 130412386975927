import './GettingStartedPage.css';

function GettingStartedPage() {
  return (
      <div>
        <h1>Welcome to the ATC Radar GettingStarted</h1>
      </div>
  );
}

export default GettingStartedPage;
