// PassportControl.jsx
import React, { useMemo } from 'react';
import { Box, Center, Stack, rem } from '@mantine/core';
import { ReactComponent as LogoIcon } from '../../assets/icons/ATC-logo-colored.svg';
import CustomText from '../../components/Typography/CustomText/CustomText';
import CustomTitle from '../../components/Typography/CustomTitle/CustomTitle';
import MainButton from '../../components/buttons/buttons';
import { ReactComponent as ArrangeSquareIcon } from '../../assets/icons/arrange-square-icon.svg';
import Snackbar from '../../components/Snackbar';
import { useStyle } from '../../features/passportControlLanding/styles';
import { testFlightUserData } from '../../features/passportControlLanding/testFlightUser';
import { contentUtilizationUserData } from '../../features/passportControlLanding/contentUtilizationUser';
import useQueryParams from './hooks/useQueryParams';
import useAuthenticationFlow from './hooks/useAuthenticationFlow';
import useHubspotFlow from './hooks/useHubspotFlow';

const FLOW_OPTIONS = {
  TEST_FLIGHT: 'testFlight',
  CONTENT_UTILIZATION_CAMPAIGN: 'contentUtilizationCampaign',
};

const PassportControl = () => {
  const { classes } = useStyle();
  const params = useQueryParams();
  const {
    flow,
    emailVerification,
    isSuccess: successfullyConnected,
  } = params;

  useAuthenticationFlow(params);

  const { handleHubspotConnection, hubspotDetails } = useHubspotFlow({
    flow,
    successfullyConnected
  });

  const activeFlowData =
    flow === FLOW_OPTIONS.TEST_FLIGHT
      ? testFlightUserData
      : contentUtilizationUserData;

  const currentTitle = successfullyConnected
    ? activeFlowData.afterIntegration.title
    : emailVerification
    ? activeFlowData.afterEmailVerification.title
    : activeFlowData.beforeIntegration.title;

  const currentDescription = successfullyConnected
    ? activeFlowData.afterIntegration.description
    : emailVerification
    ? activeFlowData.afterEmailVerification.description
    : activeFlowData.beforeIntegration.description;

  const integrationExists = useMemo(() => {
    //check if integration object has defined connected field
    return typeof hubspotDetails?.connected === "boolean"
  }, [hubspotDetails])

  return (
    <>
      <Center h="100vh">
        <Stack spacing={42}>
          <Stack
            spacing={48}
            align="center"
            w={rem(successfullyConnected ? 510 : 420)}
          >
            <LogoIcon
              className="logo-icon"
              style={{ width: rem(217), height: rem(72) }}
            />
            <CustomTitle order={0}>{currentTitle}</CustomTitle>
            <Box className={classes.TimelineWrapper}>
              <CustomText variant="h2" sx={{ textAlign: 'center' }}>
                {currentDescription}
              </CustomText>
            </Box>
          </Stack>
          {!successfullyConnected && (
            <MainButton
              onClick={handleHubspotConnection}
              icon={<ArrangeSquareIcon />}
              sx={{ height: rem(48) }}
              disabled={!emailVerification || !integrationExists}
            >
              <CustomText variant="body1">Connect</CustomText>
            </MainButton>
          )}
        </Stack>
      </Center>
      <Snackbar />
    </>
  );
};

export default PassportControl;
