import React, { useMemo, useState } from 'react';
import { Box, Flex, Group, rem, Stack, UnstyledButton } from '@mantine/core';
import { ReactComponent as FirstIcon } from '../../../assets/icons/get-started-first-icon.svg';
import { ReactComponent as SecondIcon } from '../../../assets/icons/get-started-second-icon.svg';
import { ReactComponent as ThirdIcon } from '../../../assets/icons/get-started-third-icon.svg';
import { ReactComponent as HubspotLogo } from '../../../assets/icons/hubspot-logo.svg';
import { ReactComponent as MailchimpLogo } from '../../../assets/icons/mailchimp-logo.svg';
import { ReactComponent as SalesforceLogo } from '../../../assets/icons/salesforce-logo.svg';
import { ReactComponent as ConstantContactLogo } from '../../../assets/icons/constant-contact-logo.svg';
import { ReactComponent as HubspotActiveLogo } from '../../../assets/icons/hubspot-active-logo.svg';
import { ReactComponent as SalesforceActiveLogo } from '../../../assets/icons/salesforce-active-logo.svg';
import { ReactComponent as ConstantContactActiveLogo } from '../../../assets/icons/constant-contact-active-logo.svg';
import ContentWithImage from '../../ContentWithImage/ContentWithImage';
import CustomText from '../../Typography/CustomText/CustomText';
import ToolCard from './ToolCard';
import CustomTitle from '../../Typography/CustomTitle/CustomTitle';
import MainButton from '../../buttons/buttons';
import BasicModal from '../../Modals/BasicModal';
import LoginInput from '../../LoginInput/LoginInput';
import { isEmail, useForm } from '@mantine/form';
import { useCallback } from 'react';
import CircularCheckbox from '../../CircularCheckbox/CircularCheckbox';
import ImageContent from '../components/ImageContent/ImageContent';
import preparationFirstImage from '../../../assets/images/preparation-first-image.png';
import preparationSecondImage from '../../../assets/images/preparation-second-image.png';
import preparationThirdImage from '../../../assets/images/preparation-third-image.png';
import { useStyle } from './GetStartedComponent.style';
import logger from '../../../utils/logger';

const CARDS = [
  {
    imageUrl: preparationThirdImage,
    title: 'Brit Awards 2023: Date, line-up and how to watch',
  },
  {
    imageUrl: preparationSecondImage,
    title: 'Brit Awards 2023: Date, line-up and how to watch',
  },
  {
    imageUrl: preparationFirstImage,
    title: 'Brit Awards 2023: Date, line-up and how to watch',
  },
];

function GetStartedComponent({
  onMainButtonClick,
  handleHubspotAuth,
  handleMailchimpAuth,
  handleSalesforceAuth,
  handleConstantContactAuth,
  setPageIndex,
}) {
  const [modalIndex, setModalIndex] = useState(null);
  const form = useForm({
    initialValues: {
      email: '',
      tool: '',
      marketingEmails: true,
      storedData: false,
    },
    validate: {
      email: isEmail("Are you sure that you've entered your email correctly?"),
      storedData: (value) => (value === true ? null : ''),
    },
  });
  const { classes } = useStyle()

  const toolsData = useMemo(
    () => [
      {
        image: <HubspotLogo style={{width: rem(142), height: rem(63)}} />,
        activeImage: <HubspotActiveLogo style={{width: rem(142), height: rem(63)}} />,
        onClick: handleHubspotAuth,
        borderColor: "#ED7470"
      },
      {
        image: <MailchimpLogo style={{width: rem(157), height: rem(69)}} />,
        onClick: handleMailchimpAuth,
        borderColor: "#F6D917"
      },
      {
        image: <SalesforceLogo style={{width: rem(157), height: rem(69)}} />,
        activeImage: <SalesforceActiveLogo style={{width: rem(157), height: rem(69)}} />,
        onClick: handleSalesforceAuth,
        borderColor: "#05A1DF"
      },
      {
        image: <ConstantContactLogo style={{width: rem(157), height: rem(69)}} />,
        activeImage: <ConstantContactActiveLogo style={{width: rem(157), height: rem(69)}} />,
        onClick: handleConstantContactAuth,
        borderColor: "#1856EC"
      },
    ],
    [
      handleHubspotAuth,
      handleMailchimpAuth,
      handleSalesforceAuth,
      handleConstantContactAuth,
    ],
  );

  const tools = useMemo(() => {
    return toolsData.map((data, index) => (
      <ToolCard
        key={`tool-card-${index}`}
        image={data.image}
        activeImage={data.activeImage}
        borderColor={data.borderColor}
        onClick={data.onClick}
      />
    ));
  }, [toolsData]);

  const checkboxes = useMemo(() => {
    const checkboxesData = [
      {
        title: 'Opt in to marketing emails',
        formKey: 'marketingEmails',
      },
      {
        title: 'I consent to my data being stored',
        formKey: 'storedData',
      },
    ];

    return checkboxesData.map((data, index) => (
      <Group
        spacing={8}
        key={`checkbox-login-${index}`}
        sx={{ cursor: 'pointer' }}
        onClick={() =>
          form.setValues({
            [data.formKey]: !form.values[data.formKey],
          })
        }
      >
        <CircularCheckbox
          {...form.getInputProps(data.formKey, { type: 'checkbox' })}
        />
        <CustomText variant="bodyLarge" sx={{ fontSize: 16 }}>
          {data.title}
        </CustomText>
      </Group>
    ));
  }, [form]);

  const handleSubmition = useCallback((values) => {
    setModalIndex(2);
    logger.info(values)
  }, []);

  const handleClose = useCallback(() => {
    setModalIndex(null);
    setPageIndex(2);
  }, []);

  return (
    <>
      <ContentWithImage
        content={
          <Stack spacing={56} mt={100} w={500}>
            <Stack spacing={8}>
              <CustomTitle order={0} sx={{ fontSize: rem(48) }}>
                Let’s get started!
              </CustomTitle>
              <CustomText variant="bodyLarge" sx={{ fontSize: rem(16) }}>
                Select the tool you would like to connect below.
              </CustomText>
            </Stack>
            <Flex gap={24} sx={{ flexWrap: 'wrap' }}>
              {tools}
            </Flex>
            <Stack spacing={20} align="center">
              <MainButton
                onClick={onMainButtonClick}
                hugePadding
                sx={{ height: rem(60), width: rem(283) }}
              >
                <CustomText
                  variant="body2"
                  sx={{ fontSize: rem(18), fontWeight: 500 }}
                >
                  I’ve been invited
                </CustomText>
              </MainButton>
              <UnstyledButton onClick={() => setModalIndex(1)}>
                <CustomText variant="body2" sx={{ fontSize: 16 }}>
                  I don’t see my tools
                </CustomText>
              </UnstyledButton>
            </Stack>
          </Stack>
        }
        image={
          <Box className={classes.imageContainer}>
            <Box className={classes.imageWrapper}>
              <ImageContent
                title="Top music reads handpicked for you"
                company="Your company"
                name="Beyonce"
                email="yourcompany@gmail.com"
                cards={CARDS}
                redButtons
                huge
              />
            </Box>
            <FirstIcon className={classes.imageFirst} />
            <SecondIcon className={classes.imageSecond} />
            <Box className={classes.imageWrapper}>
              <ThirdIcon className={classes.imageThird} />
            </Box>
          </Box>
        }
      />
      <BasicModal
        withCloseButton={false}
        opened={modalIndex === 0}
        onClose={() => setModalIndex(null)}
      >
        <Box px={80} py={32}>
          <Stack spacing={40} align="center" w={rem(645)}>
            <Stack align="center">
              <CustomTitle order={0} sx={{ fontSize: rem(48) }}>
                This isn’t goodbye!
              </CustomTitle>
              <CustomText variant="bodyLarge" sx={{ textAlign: 'center' }}>
                Sign up to our mailing list to get free insights and you’ll be
                the first to know when you can integrate an account with us.
              </CustomText>
            </Stack>
            <Stack w="100%">
              <LoginInput
                placeholder="Email address"
                height={rem(55)}
                {...form.getInputProps('email')}
              />
              <LoginInput
                placeholder="What tool do you currently use? (optional)"
                height={rem(55)}
                {...form.getInputProps('tool')}
              />
            </Stack>
            <Flex justify="center" gap={64}>
              {checkboxes}
            </Flex>
            <MainButton
              hugePadding
              onClick={form.onSubmit(handleSubmition)}
              sx={{ width: rem(234), height: 60 }}
            >
              <CustomText
                variant="body2"
                sx={{ fontSize: rem(18), fontWeight: 500 }}
              >
                Submit
              </CustomText>
            </MainButton>
          </Stack>
        </Box>
      </BasicModal>
      <BasicModal
        withCloseButton={false}
        opened={modalIndex === 1}
        onClose={handleClose}
      >
        <Box px={80} py={32}>
          <Stack spacing={40} align="center" w={645}>
            <Stack align="center">
              <CustomTitle order={0} sx={{ fontSize: rem(48) }}>
                Thanks!
              </CustomTitle>
              <CustomText variant="bodyLarge" sx={{ textAlign: 'center' }}>
                Look out for our emails with the best in class marketing content
                and insights!
              </CustomText>
            </Stack>
            <MainButton
              onClick={handleClose}
              hugePadding
              sx={{ width: rem(234), height: 60 }}
            >
              <CustomText
                variant="body2"
                sx={{ fontSize: rem(18), fontWeight: 500 }}
              >
                Close
              </CustomText>
            </MainButton>
          </Stack>
        </Box>
      </BasicModal>
    </>
  );
}

export default GetStartedComponent;
