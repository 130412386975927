import { useCallback, useState } from 'react';

import logger from '../utils/logger';

const useFetch = (fetchFunc, id) => {
  const [loading, setLoading] = useState(true)

  const fetchData = useCallback(
    async (params, fetchMore) => {
      try {
        if(!fetchMore) {
            setLoading(true)
        }
        let data;

        if (id) {
          data = await fetchFunc(id, params);
        } else {
          data = await fetchFunc(params);
        }

        setLoading(false);
        return data
      } catch (error) {
        setLoading(false);
        logger.error(`Failed to fetch data:`, error);
      }
    },
    [fetchFunc, id],
  );

  return [fetchData, loading];
};

export default useFetch;
