import { useCallback } from 'react';

const useSorting = (urlParams, updateUrlParams, sortByOptions) => {
  const handleSorting = useCallback(
    (title) => {
      const sortBy = sortByOptions[title];

      updateUrlParams({
        offset: 0,
        sortBy,
        sortDirection:
          urlParams.sortBy === sortBy && urlParams.sortDirection === 'desc'
            ? 'asc'
            : 'desc',
      });
    },
    [urlParams, sortByOptions],
  );

  return handleSorting;
};

export default useSorting;
