import React, { useMemo } from 'react';
import { Flex, rem } from '@mantine/core';
import { BiMinusCircle } from 'react-icons/bi';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/circle-arrow-up-icon.svg';
import CustomText from '../../../Typography/CustomText/CustomText';
import { useStyle } from './StatsDifference.style';

const GREEN = '#D4F2D3';
const RED = '#FFC0BE';
const YELLOW = '#F6C25D';

function StatsDifference({
  difference,
  positive,
  unchanged,
  customClassName,
  textVariant,
  showWithoutValue,
  withoutPercentage,
}) {
  const { classes, cx } = useStyle();

  const backgroundColor = useMemo(() => {
    if (difference === 0 || unchanged) {
      return YELLOW;
    }
    return positive ? GREEN : RED;
  }, [positive, difference]);

  const showValue = useMemo(() => {
    if (showWithoutValue) return false;

    return !difference && difference !== 0;
  }, [showWithoutValue, positive, difference]);

  return (
    <Flex
      justify="center"
      align="center"
      className={cx(classes.container, customClassName, {
        [classes.blurContainer]: showValue,
      })}
      gap={6}
      sx={{
        background: backgroundColor,
        filter: showValue && 'blur(2px)',
      }}
    >
      {(difference !== 0 && !unchanged) ? (
        <ArrowUp
          style={{
            transform: !positive && 'rotate(180deg)',
            height: '100%',
            width: 'fit-content',
          }}
        />
      ) : (
        <BiMinusCircle />
      )}
      {difference !== undefined && (
        <CustomText
          variant={textVariant || 'caption1'}
          sx={{ minWidth: rem(25) }}
        >
          {difference}
          {withoutPercentage ? '' : '%'}
        </CustomText>
      )}
    </Flex>
  );
}

export default StatsDifference;
