import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeCard: null,
  startModalOpened: true,
  guide: {
    tourActive: false,
    topics: {
      showGuide: true,
      stepIndex: 0,
      guideModalIndex: 0,
    },
    topicDetails: {
      showGuide: true,
      stepIndex: 0,
      guideModalIndex: 0,
    },
    content: {
      showGuide: true,
      stepIndex: 0,
      guideModalIndex: 0,
    },
    contentDetails: {
      showGuide: true,
      stepIndex: 0,
      guideModalIndex: 0,
    },
    contacts: {
      showGuide: true,
      stepIndex: 0,
      guideModalIndex: 0,
    },
    addTags: {
      showGuide: true,
    },
    competitors: {
      showGuide: true,
      stepIndex: 0,
      guideModalIndex: 0,
    },
    competitorsContent: {
      showGuide: true,
      stepIndex: 0,
      guideModalIndex: 0,
    },
    competitorsSettings: {
      showGuide: true,
      stepIndex: 0,
      guideModalIndex: 0,
    },
  },
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setActiveCard: (state, { payload: { activeCard } }) => {
      state.activeCard = activeCard;
    },
    setTourActive: (state, { payload: { active } }) => {
      state.guide.tourActive = active;
    },
    setStartModalOpened: (state, { payload: { opened } }) => {
      state.startModalOpened = opened;
    },
    setGuide: (state, { payload: { key, value } }) => {
      state.guide = {
        ...state.guide,
        [key]: {
          ...state.guide[key],
          ...value,
        },
      };
    },
    resetProfileState: () => {
      return initialState;
    },
  },
});

export const {
  setActiveCard,
  setGuide,
  setTourActive,
  setStartModalOpened,
  resetProfileState,
} = profileSlice.actions;

export default profileSlice.reducer;
