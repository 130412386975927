import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  button: {
    '& .profile-icon': {
      height: rem(30),
      width: rem(30),

    },
    '& svg': {
      transition: '0.5s',
    },
  },
  dropdown: {
    padding: `${rem(14)} ${rem(8)}`,
    border: 'none',
    borderRadius: 8,
  },
  item: {
    padding: `${rem(8)}`,
  },
}));
