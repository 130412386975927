import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  wrapper: {
    "& .mantine-InputWrapper-error": {
      paddingLeft: rem(15)
    }
  },
  button: {
    height: rem(60),
    width: rem(288),
    zIndex: 100
  }
}));
