import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  card: {
    height: 'fit-content',
    width: rem(255),
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: rem(20),
    overflow: 'hidden',
    cursor: 'pointer',

    '& img': {
      height: `${rem(120)} !important`,
    },

    '& .mantine-Paper-root': {
      padding: `${rem(12)} ${rem(12)} ${rem(20)}`,
    },

    '& .mantine-Card-cardSection': {
      position: 'relative',
    },

    '& span': {
      fontFamily: 'Manrope',
      fontSize: rem(12),
      fontWeight: 600,
      color: theme.colors.gray[4],
    },
  },
  cardSmall: {
    minHeight: rem(90),
    height: rem(90),
    width: rem(340),
    borderRadius: rem(12),

    '& img': {
      height: `${rem(90)} !important`,
      width: `${rem(90)} !important`,
      borderRadius: rem(12),
    },

        '& .mantine-Card-cardSection': {
            margin: 0,
            marginTop: "0 !important"
        },

    '& .mantine-Paper-root': {
      display: 'flex',
      height: rem(90),
      padding: 0,
      gap: rem(16),
    },
  },
  matchesContainer: {
      position: "absolute",
      bottom: -5,
      width: "fit-content",
      background: "rgba(252, 252, 252, 0.8)",
      backdropFilter: "blur(2px)",
      borderRadius: `0 ${rem(20)} 0 0`,
      padding: `${rem(6)} ${rem(12)} ${rem(6)} ${rem(16)}`
  },
  smallCardContainer: {
      width: "100%",
      paddingRight: rem(16)
  }
}))
