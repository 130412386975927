import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import useFetch from "../../../hooks/useFetch";

const useFetchDashboard = (jwtTokenLoading, getDashboardFunc, urlDateParams) => {
  const [dashboardData, setDashboardData] = useState([]);
  const [fetchFunc, loading] = useFetch(getDashboardFunc);

  const fetchWrapper = useCallback(async () => {
    let params = '';

    if(urlDateParams) {
        params = {
            dateFilter: JSON.stringify(urlDateParams)
        }
    }

    const data = await fetchFunc(params);
    setDashboardData(data)
  }, [fetchFunc, getDashboardFunc, JSON.stringify(urlDateParams)]);

  useEffect(() => {
    if (!jwtTokenLoading) {
      fetchWrapper();
    }
  }, [jwtTokenLoading, JSON.stringify(urlDateParams)]);

  return [dashboardData, loading];
};

export default useFetchDashboard;
