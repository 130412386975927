window.global = window;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { datadogRum } from '@datadog/browser-rum';
import { Provider } from 'react-redux';

import { appCues } from './services/appCuesService';
import { Auth0ProviderWithNavigate } from './providers/Auth0ProviderWithNavigate';
import { theme } from '../theme';
import App from './App';
import './index.css';
import { store } from './store/store';
import { GlobalStyles } from './globalStyles';
import { datadogConfig, appCuesConfig } from './config';

if (datadogConfig.applicationId) {
  datadogRum.init({
    applicationId: datadogConfig.applicationId,
    clientToken: datadogConfig.clientToken,
    site: datadogConfig.site,
    service: datadogConfig.service,
    env: datadogConfig.env,
    version: datadogConfig.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

if (appCuesConfig.accountId) {
  appCues.init(appCuesConfig.accountId);
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider withNormalizeCSS withGlobalStyles theme={theme}>
        <GlobalStyles />
        <Router>
          <Auth0ProviderWithNavigate>
            <App />
          </Auth0ProviderWithNavigate>
        </Router>
      </MantineProvider>
    </Provider>
  </React.StrictMode>,
);
