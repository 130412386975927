// hooks/useQueryParams.js
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { sanitizeParam } from '../../../utils';

const useQueryParams = () => {
  const [searchParams] = useSearchParams();

  const params = useMemo(() => {
    const getParam = (key) =>
      sanitizeParam(DOMPurify.sanitize(searchParams.get(key)));

    return {
      firstName: getParam('firstName'),
      lastName: getParam('lastName'),
      email: getParam('email'),
      orgName: getParam('orgName'),
      orgDomain: getParam('orgDomain'),
      flow: getParam('flow'),
      emailVerification: searchParams.get('emailVerification'),
      loginToExtractData: searchParams.get('loginToExtractData'),
      preserveLogin: searchParams.get('preserveLogin'),
      isSuccess: searchParams.get('isSuccess') === 'true',
    };
  }, [searchParams]);

  return params;
};

export default useQueryParams;
