import React, { useMemo } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentWithVideo from '../../components/accountCreation/components/ContentWithVideo/ContentWithVideo';
import FoundTeammates from '../../components/accountCreation/FoundTeammates/FoundTeammates';
import GetStartedComponent from '../../components/accountCreation/GetStartedComponent/GetStartedComponent';
import imagePlaceholder from '../../assets/icons/placeholder.png';
import useRadarApi from '../../hooks/useRadarApi';
import IntegrateContacts from '../../components/accountCreation/InegrateContacts/IntegrateContacts';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCompetitorIndex,
  setSignupPageIndex,
  setUserSections,
} from '../../features/layoutSlice';
import GetUpComponent from '../../components/accountCreation/GetUpComponent/GetUpComponent';
import QuestionWrapper from '../../components/accountCreation/QuestionComponents/QuestionWrapper';
import ConnectAnalytics from '../../components/accountCreation/QuestionComponents/ConnectAnalytics';
import EnterAnalyticsId from '../../components/accountCreation/QuestionComponents/EnterAnalyticsId';
import AddWebsiteComponent from '../../components/accountCreation/AddWebsiteComponent/AddWebsiteComponent';
import AddSiteSection from '../../components/accountCreation/AddSiteSections/AddSiteSection';
import RecommendationsSection from '../../components/accountCreation/RecommendationsSection/RecommendationsSection';
import TrackCompetitor from '../../components/accountCreation/TrackCompetitor/TrackCompetitor';
import ButtonGroup from '../../components/accountCreation/components/ButtonGroup/ButtonGroup';
import SelectTopics from '../../components/accountCreation/SelectTopics/SelectTopics';
import PreparationSection from '../../components/accountCreation/PreparationSection/PreparationSection';
import RequestPending from '../../components/accountCreation/RequestPending/RequestPending';
import OrganizeContent from '../../components/accountCreation/OrganizeContent/OrganizeContent';
import Signup from '../../components/accountCreation/Signup/Signup';

const TEAMMATES_DATA = {
  organisationName: 'Shoreditch Design Studio',
  email: 'andrew@shoreditchdesignstudio.com',
  image: imagePlaceholder,
};

const CONTACTS = [
  'Contact list 1',
  'Contact list 2',
  'Contact list 3',
  'Contact list 4',
  'Contact list 5',
  'Contact list 6',
  'Contact list 7',
  'Contact list 8',
  'Contact list 9',
  'Contact list 10',
  'Contact list 11',
];

const USERNAME = 'Katie';

function SignupPage() {
  const [selectedContacts, setSelectedContacts] = useState([]);
  const navigate = useNavigate();
  const { getHubspotAuthURL } = useRadarApi();
  const dispatch = useDispatch();
  const pageIndex = useSelector((state) => state.layout.signupPageIndex);
  const website = useSelector((state) => state.layout.userWebsite);
  const competitorWebsite = useSelector(
    (state) => state.layout.userCompetitors,
  );
  const competitorIndex = useSelector((state) => state.layout.competitorIndex);

  const setPageIndex = useCallback(
    (index) => {
      dispatch(
        setSignupPageIndex({
          pageIndex: index,
        }),
      );
    },
    [dispatch],
  );

  const handleHubspotAuth = useCallback(() => {
    getHubspotAuthURL();
    setPageIndex(2);
  }, [getHubspotAuthURL]);

  const handleMailchimpAuth = useCallback(() => {
    //TODO: implement auth using Mailchimp
  }, []);

  const handleSalesforceAuth = useCallback(() => {
    //TODO: implement auth using Salesforce
  }, []);

  const handleConstantContactAuth = useCallback(() => {
    //TODO: implement auth using Constant Contact
  }, []);

  const onContinue = useCallback(() => {
    setPageIndex(pageIndex + 1);
  }, [pageIndex]);

  const handleAddExtraCompetitor = useCallback(() => {
    setPageIndex(14);
    dispatch(
      setCompetitorIndex({
        index: competitorIndex + 1,
      }),
    );
  }, [pageIndex, dispatch, competitorIndex]);

  const signupPage = useMemo(() => {
    switch (pageIndex) {
      case 0:
        return <Signup onContinue={onContinue} />;
      case 1:
        return (
          <GetStartedComponent
            onMainButtonClick={() => navigate('/login')}
            setPageIndex={setPageIndex}
            handleHubspotAuth={handleHubspotAuth}
            handleMailchimpAuth={handleMailchimpAuth}
            handleSalesforceAuth={handleSalesforceAuth}
            handleConstantContactAuth={handleConstantContactAuth}
          />
        );

      case 2:
        return (
          <ContentWithVideo
            title="See it for yourself... the power of Air Traffic Control"
            description="See how Air Traffic Control can help your business grow through personalized content."
            onClick={() => navigate('/')}
            buttonText="Back to home"
          />
        );
      case 3:
        return (
          <FoundTeammates
            organisationName={TEAMMATES_DATA.organisationName}
            email={TEAMMATES_DATA.email}
            image={TEAMMATES_DATA.image}
            setPageIndex={setPageIndex}
          />
        );
      case 4:
        return <RequestPending />;
      case 5:
        return (
          <IntegrateContacts
            contacts={CONTACTS}
            selectedContacts={selectedContacts}
            setSelectedContacts={setSelectedContacts}
            onContinue={onContinue}
          />
        );
      case 6:
        return (
          <ContentWithVideo
            title={<>Welcome to Air Traffic Control, {USERNAME}</>}
            description="Check out this video to learn about how we can help your business through personalized content."
            onClick={onContinue}
            buttonText="Continue"
          />
        );
      case 7:
        return (
          <GetUpComponent
            name="Rosie Guy"
            tags="[tag 1] [tag 2] [tag 3]"
            onContinue={onContinue}
          />
        );
      case 8:
        return (
          <QuestionWrapper
            title={
              <>
                Is your content run by <br /> Hubspot CMS?
              </>
            }
            description="We use this to gather the content for your audience."
            mainText="Yes"
            secondaryText="No"
            onMainButtonClick={() => setPageIndex(12)}
            onSecondaryButtonClick={onContinue}
            reversed
          />
        );
      case 9:
        return (
          <ConnectAnalytics
            onContinue={onContinue}
            onSkip={() => setPageIndex(11)}
          />
        );
      case 10:
        return <EnterAnalyticsId onContinue={onContinue} onSkip={onContinue} />;
      case 11:
        return <AddWebsiteComponent onContinue={onContinue} />;
      case 12:
        return (
          <AddSiteSection
            onContinue={onContinue}
            title="Select the content you want to include"
            description={
              <>
                Not all content is marketing content, and this is your
                opportunity to setup which parts of your website you want to be
                able to
                <br />
                personalize to customers.
                <br />
                <br />
                For example, you want to keep your news, blogs, but probably not
                your privacy page!
              </>
            }
            stepIndex={0}
            dispatchFunc={setUserSections}
            website={website}
          />
        );

      case 13:
        return <OrganizeContent onContinue={onContinue} />;

      case 14:
        return <RecommendationsSection onContinue={onContinue} />;
      case 15:
        return <TrackCompetitor onContinue={onContinue} />;
      case 16:
        return (
          <AddSiteSection
            title="Direct us to your competitors content"
            description={
              <>
                Not all content is marketing content, tell us which parts of
                your competitors website we should be hold their marketing
                content.
                <br />
                <br />
                For example, their new or blogs pages but probably not their
                privacy page!
              </>
            }
            stepIndex={2}
            dispatchFunc={setUserSections}
            website={competitorWebsite[competitorIndex]}
          >
            <ButtonGroup
              mainText="Continue"
              secondaryText="Add another competitor"
              onMainButtonClick={onContinue}
              onSecondaryButtonClick={handleAddExtraCompetitor}
              width={[250, 237]}
              color="white"
            />
          </AddSiteSection>
        );
      case 17:
        return <SelectTopics onContinue={onContinue} />;
      case 18:
        return <PreparationSection />;
    }
  }, [
    pageIndex,
    navigate,
    handleHubspotAuth,
    handleMailchimpAuth,
    handleSalesforceAuth,
    handleConstantContactAuth,
    selectedContacts,
  ]);

  return <>{signupPage}</>;
}

export default SignupPage;
