import React from 'react';
import { Checkbox } from '@mantine/core';
import { useStyle } from './CircularCheckbox.style';
import { ReactComponent as TickIcon } from '../../assets/icons/tick-icon.svg';

function CircularCheckbox({customClassName, ...rest}) {
  const { classes, cx } = useStyle();

  return (
    <Checkbox
      icon={TickIcon}
      classNames={{ input: cx(classes.checkbox, customClassName) }}
      {...rest}
    />
  );
}

export default CircularCheckbox;
