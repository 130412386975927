import React from 'react';
import { SegmentedControl } from '@mantine/core';
import { useStyle } from './SegmentControl.style';

function CustomSegmentControl({
  value,
  onChange,
  data,
  rootClassName,
  indicatorClassName,
  labelClassName,
  controlClassName,
  controlActiveClassName
}) {
  const { classes, cx } = useStyle();

  return (
    <SegmentedControl
      value={value}
      onChange={onChange}
      fullWidth
      data={data}
      radius="lg"
      size="md"
      classNames={{
        root: cx(classes.segmentRoot, rootClassName),
        label: cx(classes.label, labelClassName),
        indicator: cx(classes.indicator, indicatorClassName),
        controlActive: cx(classes.controlActive, controlActiveClassName),
        control: cx(classes.control, controlClassName),
      }}
    />
  );
}

export default CustomSegmentControl;
