import { Box, Center, Grid, Group, rem, Skeleton, Stack } from '@mantine/core';
import React, { useState, memo } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import CustomCheckbox from '../Checkbox';
import CustomText from '../Typography/CustomText/CustomText';
import HeadTH from './components/HeadTH/HeadTH';
import { useStyle } from './TableWithSorting.style';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableRow from './components/TableRow/TableRow';
import useNavigateWithState from '../../hooks/useNavigateWithState';

function TableWithSorting({
  tableData,
  withSelect,
  withScroll,
  scrollRef,
  hoverOptions,
  setSelected,
  loading,
  errorText,
  wrapperClassName,
  wrapperScrollClassName,
  rowClassName,
  onSort,
  infiniteScrollOptions,
  allSelected,
  setAllSelected,
  setExternalOpenedRows,
  bindSelection,
}) {
  const { classes, cx } = useStyle();
  const [sortedData, setSortedData] = useState(tableData?.content);
  const [sortType, setSortType] = useState(null);
  const [sortReversed, setSortReversed] = useState(false);
  const [selection, setSelection] = useState([]);
  const [openedRows, setOpenedRows] = useState([]);
  const navigateWithState = useNavigateWithState();

  useEffect(() => {
    if (setSelected) {
      setSelected(selection);
    }
  }, [selection, setSelected]);

  useEffect(() => {
    if(setExternalOpenedRows) {
      setExternalOpenedRows(openedRows)
    }
  }, [openedRows])

  useEffect(() => {
    if (bindSelection) {
      bindSelection(setSelection);
    }
  }, []);

  useEffect(() => {
    setSortedData(tableData.content);
    if (tableData.content.length === 15 && hoverOptions?.dropdownElement) {
      setOpenedRows([]);
    }
  }, [JSON.stringify(tableData)]);

  const toggleRow = useCallback((rowIndex) => {
    setSelection((prevState) =>
      prevState.includes(rowIndex)
        ? prevState.filter((item) => item !== rowIndex)
        : [...prevState, rowIndex],
    );
  }, []);

  const toggleAll = () => {
    setSelection((prevState) =>
      prevState.length === tableData.content.length
        ? []
        : tableData.content.map((_, index) => index),
    );
    if (setAllSelected) {
      if (allSelected) {
        setSelection([]);
      }
      setAllSelected((prevState) => !prevState);
    }
  };

  const setSorting = (title, useRange) => {
    if (onSort) {
      onSort(title);
      return;
    }

    const reversed = sortType === title ? !sortReversed : false;
    const sortingKey = useRange ? 'value' : 'title';
    setSortReversed(reversed);
    setSortType(title);
    setSortedData((prevState) =>
    prevState.sort((a, b) => {
      const columnIndex = tableData.head.findIndex((el) => el === title);

      // Primary sorting based on the specified column and sortingKey
      const keyA = a[columnIndex][sortingKey];
      const keyB = b[columnIndex][sortingKey];

      if (keyB > keyA) {
        return reversed ? 1 : -1;
      } else if (keyB < keyA) {
        return reversed ? -1 : 1;
      } else {  // values are equal, secondary sort on 'title' field
        const titleColumnIndex = a[0]?.title ? 0 : 1;

        const titleA = a[titleColumnIndex].title.toString().toLowerCase();
        const titleB = b[titleColumnIndex].title.toString().toLowerCase();

        if (titleA > titleB) {
          return reversed ? -1 : 1;
        } else if (titleA < titleB) {
          return reversed ? 1 : -1;
        } else {
          return 0;  // titles are equal, no change in order
        }
      }
    }),
  );
  };

  const generateHeadElements = useCallback(() => {
    const elements = [];

    tableData.head.forEach((item, index) => {
      const isFirst = index === 0 && withSelect;
      let span = 12 / tableData.head.length;
      let withSorting = true;
      let useRange = null;

      if (tableData?.headOptions) {
        useRange = tableData.headOptions[index]?.useRange;
        withSorting = !tableData?.headOptions[index]?.withoutSorting;
        const columnSpan = tableData.headOptions[index]?.columnSpan;
        span = columnSpan || columnSpan === 0 ? columnSpan : span;
      }

      if (span === 0) return;

      elements.push(
        <Grid.Col key={index} span={span} p={0}>
          <Group pl={isFirst ? 11 : 0}>
            {isFirst && (
              <CustomCheckbox
                size={16}
                checked={
                  selection.length === tableData.content.length || allSelected
                }
                indeterminate={
                  selection.length > 0 &&
                  selection.length !== tableData.content.length &&
                  !allSelected
                }
                onChange={toggleAll}
              />
            )}
            <HeadTH
              key={index}
              title={item}
              withSorting={withSorting}
              setSortType={() => withSorting && setSorting(item, useRange)}
              onClick={tableData?.headOptions?.onClick}
            />
          </Group>
        </Grid.Col>,
      );
    });

    return elements;
  }, [sortedData, sortType, sortReversed, selection]);

  const handleClick = useCallback(
    (e, index, id) => {
      if (e.target.tagName === 'INPUT') return;

      if (hoverOptions.onClick) {
        hoverOptions.onClick();
      }

      if (hoverOptions.navigateTo) {
        navigateWithState(`${hoverOptions.navigateTo}${id || ''}`);
      } else if (hoverOptions.dropdownElement) {
        setOpenedRows((prevState) => {
          const openedRowIndex = prevState.findIndex(
            (rowIndex) => rowIndex === index,
          );

          if (openedRowIndex === -1) {
            return [...prevState, index];
          }

          return prevState.filter((_, rowIndex) => rowIndex !== openedRowIndex);
        });
      }
    },
    [openedRows, navigateWithState, hoverOptions],
  );

  const generateRows = useCallback(() => {
    if (!sortedData) return;

    const elements = [];
    const maxNumbersLengthArray = new Array(sortedData[0].length).fill(0);

    sortedData.forEach((row, rowIndex) => {
      sortedData[rowIndex].forEach(({ title }, columnIndex) => {
        if (
          typeof title === 'number' &&
          title > maxNumbersLengthArray[columnIndex]
        ) {
          maxNumbersLengthArray[columnIndex] = title;
        }
      });
    });

    sortedData.forEach((row, index) => {
      elements.push(
        <TableRow
          key={index}
          index={index}
          row={row}
          hoverOptions={hoverOptions}
          rowClassName={rowClassName}
          opened={openedRows.includes(index)}
          tableData={tableData}
          maxNumbersLengthArray={maxNumbersLengthArray}
          withSelect={withSelect}
          selection={selection}
          allSelected={allSelected}
          toggleRow={toggleRow}
          handleClick={handleClick}
          rootRef={scrollRef}
          infiniteScrollOptions={infiniteScrollOptions}
        />,
      );
    });

    return elements;
  }, [sortedData, selection, openedRows, navigateWithState, allSelected]);

  return (
    <Box className={cx(classes.wrapper, wrapperClassName, 'sorting-table')}>
      <Grid mb={4} className={classes.headContainer}>
        {generateHeadElements()}
      </Grid>
      <Stack
        spacing={4}
        className={
          (cx({
            [classes.wrapperScroll]: withScroll,
            [wrapperScrollClassName]: withScroll,
            [classes.alignCenter]: !sortedData?.length && !loading
          }))
        }
        ref={scrollRef}
        id="table-wrapper"
      >
        {loading !== true ? (
          <>
            {sortedData.length ? (
              <>
                {infiniteScrollOptions ? (
                  <InfiniteScroll
                    dataLength={infiniteScrollOptions.dataLength}
                    next={infiniteScrollOptions.next}
                    hasMore={infiniteScrollOptions.hasMore}
                    loader={infiniteScrollOptions.loader}
                    scrollableTarget="table-wrapper"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: rem(4),
                    }}
                  >
                    {generateRows()}
                  </InfiniteScroll>
                ) : (
                  <>{generateRows()}</>
                )}
              </>
            ) : (
              <Center>
                <CustomText variant="h2">{errorText}</CustomText>
              </Center>
            )}
          </>
        ) : (
          <Stack spacing={4}>
            <Skeleton width="100%" height="48px" />
            <Skeleton width="100%" height="48px" />
            <Skeleton width="100%" height="48px" />
            <Skeleton width="100%" height="48px" />
            <Skeleton width="100%" height="48px" />
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default memo(TableWithSorting);
