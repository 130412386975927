import React from 'react';
import { Box, Group, rem, Stack } from '@mantine/core';
import Heading from '../../Heading';
import CustomText from '../../Typography/CustomText/CustomText';
import { useStyle } from './FoundTeammates.style';
import LoginWrapper from '../LoginWrapper';
import ButtonGroup from '../components/ButtonGroup/ButtonGroup';

function FoundTeammates({ organisationName, email, image, setPageIndex }) {
  const { classes } = useStyle();

  return (
    <LoginWrapper pt={rem(88)}>
      <Stack spacing={40}>
        <Heading
          title="We found your teammates!"
          description={
            <>
              It looks like you already have team members
              <br />
              using Air Traffic Control.
            </>
          }
        />
        <Stack spacing={24}>
          <Box className={classes.organisationContainer}>
            <Group spacing={24}>
              <img src={image} className={classes.image} />
              <Stack spacing={2}>
                <CustomText variant="h2" sx={{ fontSize: rem(24) }}>
                  {organisationName}
                </CustomText>
                <CustomText variant="bodyLarge">{email}</CustomText>
              </Stack>
            </Group>
          </Box>
          <CustomText variant="bodySmall" sx={{ textAlign: 'center' }}>
            Joining the team will automatically integrate you into the
            dashboard,
            <br />
            giving you instant access to your teams personalised content.
          </CustomText>
        </Stack>
        <ButtonGroup
          mainText="Request to join"
          secondaryText="Create own account"
          onMainButtonClick={() => setPageIndex(4)}
          onSecondaryButtonClick={() => setPageIndex(5)}
        />
      </Stack>
    </LoginWrapper>
  );
}

export default FoundTeammates;
