import React, { useState } from 'react';
import { Group, Stack, UnstyledButton } from '@mantine/core';
import CustomRadio from '../../../../../CustomRadio/CustomRadio';
import CustomText from '../../../../../Typography/CustomText/CustomText';
import AddressInputGroup from './AddressInputGroup';

function NewAddressForm({ form }) {
  const [sameBillingAddress, setSameBillingAddress] = useState(true);

  return (
    <Stack spacing={40} mt={32}>
      <Stack spacing={8}>
        <CustomText variant="h4">Contact Address</CustomText>
        <CustomText variant="body2">Type your contact address below</CustomText>
        <AddressInputGroup
          keys={[
            'addressLineFirst',
            'addressLineSecond',
            'addressLineThird',
            'state',
            'postCode',
          ]}
          form={form}
        />
      </Stack>
      <Stack spacing={8}>
        <CustomText variant="h4">Billing address</CustomText>
        <UnstyledButton
          onClick={() => setSameBillingAddress((prevState) => !prevState)}
        >
          <Group spacing={4}>
            <CustomRadio selected={sameBillingAddress} />
            <CustomText variant="body2">Same as contact address</CustomText>
          </Group>
        </UnstyledButton>
        {!sameBillingAddress && (
          <AddressInputGroup
            keys={[
              'billingAddressLineFirst',
              'billingAddressLineSecond',
              'billingAddressLineThird',
              'billingState',
              'billingPostCode',
            ]}
            form={form}
          />
        )}
      </Stack>
    </Stack>
  );
}

export default NewAddressForm;
