import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: rem(520),
    border: `${rem(7)} solid ${theme.colors.black[1]}`,
    borderRadius: rem(16),
    background: theme.colors.white[0],
    padding: `${rem(7)} ${rem(12)} 0 ${rem(1)}`,
    overflow: "hidden",
    zIndex: 10,
  },
  wrapperHuge: {
    width: rem(812),
    height: rem(464)
  },
  navItem: {
    width: rem(130),
    marginRight: rem(8),
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: `0 ${rem(8)} ${rem(8)} 0`,
    padding: `${rem(3)} ${rem(11)}`
  },
  navItemActive: {
    background: theme.colors.purple[1]
  },
  input: {
    position: "relative",
    width: "100%",
    height: rem(20),
    border: "none",
    background: theme.colors.white[4],
    borderRadius: rem(55),

    "& svg": {
        position: "absolute",
        width: rem(15),
        height: rem(15),
        top: "50%",
        left: rem(6),
        transform: "translateY(-50%)",

        "& path": {
            stroke: theme.colors.gray[6],
            strokeWidth: rem(2)
        }
    }
  },
  emptyIcon: {
    height: rem(22),
    width: rem(22),
    background: theme.colors.white[4],
    borderRadius: "50%",
    boxShadow: "0px 0px 0.5457846522331238px 0px rgba(0, 0, 0, 0.12) inset"
  },
  contentContainer: {
    height: "100%",
    width: "100%",
    background: theme.colors.white[4],
    padding: `${rem(16)} ${rem(32)}`
  },
  contentLine: {
    height: rem(5),
    width: "100%",
    background: theme.colors.purple[1],
    borderRadius: rem(100)
  },
}));
