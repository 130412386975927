import React from 'react';
import { Modal } from '@mantine/core';
import { useStyle } from './BasicModal.style';

function BasicModal({ children, bodyClassName, contentClassName, ...rest }) {
  const { classes, cx } = useStyle();

  return (
    <Modal
        centered
        classNames={{
          content: cx(classes.modalContent, contentClassName),
          body: cx(classes.modaBody, bodyClassName),
          inner: classes.modalInner
        }}
        {...rest}
    >
        {children}
    </Modal>
  );
}

export default BasicModal;
