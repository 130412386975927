import { useCallback, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';

const useRecommendedContacts = (jwtTokenLoading) => {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const { getContacts } = useRadarApi();
  const [fetchContacts, loading] = useFetch(getContacts);

  const fetchWrapper = useCallback(async () => {
    const data = await fetchContacts({
      limit: 10,
      sortBy: 'insights',
      sortDirection: 'desc',
      // sortDirection: 'desc', // INFO: this appears to be crashing UI
    });
    setContacts(data || {});
    setSelectedContact(data?.content?.[0]);
  }, [fetchContacts, getContacts]);

  useEffect(() => {
    if (!jwtTokenLoading) {
      fetchWrapper();
    }
  }, [jwtTokenLoading]);

  return { contacts, loading, selectedContact, setSelectedContact };
};

export default useRecommendedContacts;
