import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Group, Stack, Box, Image, rem } from '@mantine/core';
import Papa from 'papaparse';
import { useDispatch, useSelector } from 'react-redux';
import useRadarApi from '../hooks/useRadarApi.js';
import useJwtToken from '../hooks/useJwtToken.js';
import CustomText from '../components/Typography/CustomText/CustomText';
import CustomTitle from '../components/Typography/CustomTitle/CustomTitle';
import { ReactComponent as LinkedInIcon } from '../assets/icons/linkedin-icon.svg';
import imagePlaceholder from '../assets/icons/placeholder.png';
import TableWithSorting from '../components/TableWithSorting/TableWithSorting';
import BackNagination from '../components/BackNavigation/BackNagination';
import { useStyle } from './CompetitorsContentDetailPage.style.jsx';
import { setDefaultActiveTab } from '../features/competitorContentSlice.js';
import EnrichFlow from '../components/Modals/EnrichFlow.jsx';
import DownloadsMatchesFlow from '../components/Modals/DownloadsMatchesFlow.jsx';
import TabsWithCards from '../components/TabsWithCards/TabsWithCards.jsx';
import GuidedTourModal from '../components/GuidedTour/GuidedTourModal/GuidedTourModal.jsx';
import GuidedTour from '../components/GuidedTour/GuidedTour.jsx';
import useGuideModal from '../hooks/useGuideModal.js';
import { getCompetitorsContentSteps } from '../features/guidedTourSteps/competitionContentPage.jsx';
import { getCountryAdjustedDate } from '../utils.js';
import useURLParams from '../hooks/useURLParams.js';
import { setGuide } from '../features/profileSlice.js';

import logger from '../utils/logger.js';

const TABS = ['Articles', 'Social posts'];

function CompetitorsDetailPage() {
  const [contentSummary, setContentSummary] = useState([]);
  const [contentInteractions, setContentInteractions] = useState([]);
  const [contentInteractionsLoading, setContentInteractionsLoading] =
    useState(true);
  const [contentRelatedArticles, setContentRelatedArticles] = useState([]);
  const [contentRelatedArticlesLoading, setContentRelatedArticlesLoading] =
    useState(true);
  const [contentRelatedSocialPosts, setContentRelatedSocialPosts] = useState(
    [],
  );
  const [
    contentRelatedSocialPostsLoading,
    setContentRelatedSocialPostsLoading,
  ] = useState(true);
  const [relatedContentCount, setRelatedContentCount] = useState(0);
  const [relatedArticlesCardsAmount, setRelatedArticlesCardsAmount] =
    useState(8);
  const [socialPostsCardsAmount, setSocialPostsCardsAmount] = useState(8);
  const [
    selectedContentInteractionsIndexes,
    setSelectedContentInteractionsIndexes,
  ] = useState([]);

  const relatedContentData = useMemo(
    () => [
      {
        tabValue: TABS[0],
        content: contentRelatedArticles,
        contentAmount: relatedArticlesCardsAmount,
        setContentAmount: setRelatedArticlesCardsAmount,
        loading: contentRelatedArticlesLoading,
      },
      {
        tabValue: TABS[1],
        content: contentRelatedSocialPosts,
        contentAmount: socialPostsCardsAmount,
        setContentAmount: setSocialPostsCardsAmount,
        loading: contentRelatedSocialPostsLoading,
      },
    ],
    [
      contentRelatedArticlesLoading,
      contentRelatedSocialPostsLoading,
      relatedArticlesCardsAmount,
      socialPostsCardsAmount,
    ],
  );

  const {
    getContentSummary,
    getContentInteractions,
    getContentRelatedArticles,
    getContentRelatedSocialPosts,
    updateContentInteractions,
  } = useRadarApi();

  const { loading, permissions } = useJwtToken();

  const { articleId } = useParams();
  const dispatch = useDispatch();
  const { showGuide, stepIndex, guideModalIndex } = useSelector(
    (state) => state.profile.guide.competitorsContent,
  );
  const { handleGuideModalClick } = useGuideModal(
    'competitorsContent',
    stepIndex,
  );
  const { classes, cx } = useStyle();
  const navigate = useNavigate();
  const { urlState } = useURLParams();

  const allowedToEnrich = useMemo(() => {
    return permissions.includes('write:competitors');
  }, [permissions]);

  const tableData = useMemo(() => {
    const table = {
      head: [
        'First name',
        'Last name',
        'Company',
        'Reaction',
        'Current contact',
        'Profile link',
      ],
      headOptions: [
        {
          withoutSorting: true,
        },
        {
          withoutSorting: true,
        },
        null,
        null,
        null,
        {
          withoutSorting: true,
          LinkIcon: LinkedInIcon,
        },
      ],
      content: [],
    };

    contentInteractions.forEach((row) => {
      let tableRowData = [];

      Object.keys(row).forEach((columnDataKey) => {
        if (columnDataKey === 'fullName') {
          tableRowData.push({
            title: row[columnDataKey].split(' ')[0],
          });
          tableRowData.push({
            title: row[columnDataKey].split(' ')[1],
          });
        } else {
          tableRowData.push({
            title: row[columnDataKey],
          });
        }
      });

      table.content.push(tableRowData);
      tableRowData = [];
    });

    return table;
  }, [contentInteractions]);

  const fetchContentSummary = useCallback(async () => {
    try {
      const data = await getContentSummary({ articleId });
      setContentSummary(data);
    } catch (error) {
      logger.error('Failed to fetch content summary data:', error);
    }
  }, [getContentSummary, articleId]);

  const fetchContentInteractions = useCallback(async () => {
    try {
      const data = await getContentInteractions({ articleId });
      setContentInteractions(data);
      setContentInteractionsLoading(false);
    } catch (error) {
      setContentInteractionsLoading(false);
      logger.error('Failed to fetch content interactions data:', error);
    }
  }, [getContentInteractions, articleId]);

  const fetchContentRelatedArticles = useCallback(async () => {
    try {
      const data = await getContentRelatedArticles({ articleId });
      setContentRelatedArticles(data);
      setContentRelatedArticlesLoading(false);
    } catch (error) {
      setContentRelatedArticlesLoading(false);
      logger.error('Failed to fetch content related articles data:', error);
    }
  }, [getContentRelatedArticles, articleId]);

  const fetchContentRelatedSocialPosts = useCallback(async () => {
    try {
      const data = await getContentRelatedSocialPosts({ articleId });
      setContentRelatedSocialPosts(data);
      setContentRelatedSocialPostsLoading(false);
    } catch (error) {
      setContentRelatedSocialPostsLoading(false);
      logger.error(
        'Failed to fetch content related social posts data:',
        error,
      );
    }
  }, [getContentRelatedSocialPosts, articleId]);

  useEffect(() => {
    if (!loading) {
      fetchContentSummary();
      fetchContentInteractions();
      fetchContentRelatedArticles();
      fetchContentRelatedSocialPosts();
    }
  }, [
    fetchContentSummary,
    fetchContentInteractions,
    fetchContentRelatedArticles,
    fetchContentRelatedSocialPosts,
    loading,
    articleId,
  ]);

  useEffect(() => {
    setRelatedContentCount(
      contentRelatedArticles.length + contentRelatedSocialPosts.length,
    );
  }, [contentRelatedArticles, contentRelatedSocialPosts]);

  useEffect(() => {
    logger.info('contentSummary:', contentSummary);
    logger.info('contentInteractions:', contentInteractions);
    logger.info('contentRelatedArticles:', contentRelatedArticles);
    logger.info('contentRelatedSocialPosts:', contentRelatedSocialPosts);
    logger.info('relatedContentCount:', relatedContentCount);
  }, [
    contentSummary,
    contentInteractions,
    contentRelatedArticles,
    contentRelatedSocialPosts,
    relatedContentCount,
  ]);

  const exportToCsv = (fields, data, filename) => {
    const dataForPapa = data.map((obj) => {
      const result = {};
      fields.forEach((field) => {
        result[field] = obj[field];
      });
      return result;
    });

    const csv = Papa.unparse(dataForPapa);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  };

  const onLinkedinExportClick = () => {
    const fields = [
      'email',
      'firstName',
      'lastName',
      'jobTitle',
      'employeeCompany',
      'country',
      'appleIdfa',
      'googleAid',
    ];

    const selectedContentInteractions = selectedContentInteractionsIndexes.map(
      (index) => contentInteractions[index],
    );

    const data = selectedContentInteractions.map((interaction) => ({
      email: interaction.email,
      firstName: interaction.fullName.split(' ')[0],
      lastName: interaction.fullName.split(' ')[1],
      jobTitle: interaction.jobTitle,
      employeeCompany: interaction.company,
      // Add other fields as necessary
    }));

    exportToCsv(fields, data, 'linkedin_audience.csv');
  };

  const onFullReportExportClick = () => {
    const fields = [
      'firstName',
      'lastName',
      'org/company',
      'title',
      'email',
      'linkedin profile url',
      'reaction type',
      'comment',
    ];

    const selectedContentInteractions = selectedContentInteractionsIndexes.map(
      (index) => contentInteractions[index],
    );

    const data = selectedContentInteractions.map((interaction) => ({
      email: interaction.email,
      firstName: interaction.fullName.split(' ')[0],
      lastName: interaction.fullName.split(' ')[1],
      'org/company': interaction.company,
      title: interaction.jobTitle,
      'linkedin profile url': interaction.profileLink,
      'reaction type': interaction.reaction,
      comment: interaction.comment,
    }));

    exportToCsv(fields, data, 'full_report.csv');
  };

  const credits = useMemo(() => {
    const interactions =
      contentSummary?.reactions +
      contentSummary?.comments -
      contentInteractions.length;

    if (Number.isNaN(interactions)) {
      return false;
    }

    return interactions;
  }, [contentSummary, contentInteractions]);

  const handleSuccessfulEnrichCallback = useCallback(() => {
    updateContentInteractions({ articleId, numberOfInteractions: credits });
  }, [credits, articleId, updateContentInteractions]);

  const showInteractions = useMemo(() => {
    if (!contentSummary?.reactions && !contentSummary?.comments) return false;
    else if (
      !contentSummary?.interactions?.updatePending &&
      !contentInteractions?.length
    )
      return false;

    return true;
  }, [contentSummary, contentInteractions]);

  useEffect(() => {
    dispatch(setGuide({
      key: 'competitorsContent',
      value: {
        showGuide: false
      }
    }))
  }, [])

  useEffect(() => {
    if(showInteractions && showGuide === false) {
      if(showGuide === false) {
        dispatch(setGuide({
          key: 'competitorsContent',
          value: {
            showGuide: true
          }
        }))
      }
    }
  }, [showInteractions, showGuide])

  return (
    <div className="competitors-detail-page">
      <Box>
        <BackNagination
          title="Back to content"
          href={urlState?.prevUrl || '/competitors'}
          locationState={{
            state: {
              tabIndex: 1,
            },
          }}
          onClick={() =>
            dispatch(
              setDefaultActiveTab({
                activeTab: 1,
              }),
            )
          }
        />
        <Flex gap={24} mt={16}>
          <Image
            src={contentSummary?.imageUrl || imagePlaceholder}
            width={rem(292)}
            height={rem(292)}
            radius={20}
          />
          <Stack spacing={24} sx={{ flexGrow: 2 }}>
            <Box className={cx(classes.statBox, 'stat-box')}>
              <Stack w="95%">
                <CustomText variant="h3" lineClamp={2}>
                  {contentSummary?.title}
                </CustomText>
                <Flex justify="space-between">
                  <Group>
                    <CustomText variant="h5">
                      <span>{contentSummary?.competitorName?.name}</span>
                    </CustomText>
                  </Group>
                  <Group>
                    <CustomText variant="h5">
                      Published:{' '}
                      <span>
                        {getCountryAdjustedDate(contentSummary?.publishDate)}
                      </span>
                    </CustomText>
                  </Group>
                  <Group>
                    <CustomText variant="h5">
                      Link:{' '}
                      <a href={contentSummary?.link} target="__blank">
                        View Link
                      </a>
                    </CustomText>
                  </Group>
                  {contentSummary?.interaction?.lastUpdatedAt && (
                    <Group>
                      <CustomText variant="h5">
                        Interactions Last Update At:{' '}
                        <span>
                          {getCountryAdjustedDate(
                            contentSummary?.interaction?.lastUpdatedAt,
                          )}
                        </span>
                      </CustomText>
                    </Group>
                  )}
                </Flex>
              </Stack>
            </Box>
            <Flex justify="space-between" gap={24}>
              <Box className={classes.statBox}>
                <Stack spacing={4}>
                  <CustomTitle order={1} sx={{ fontSize: 40 }}>
                    {contentSummary?.reactions || 0}
                  </CustomTitle>
                  <CustomText variant="h5">Reactions</CustomText>
                </Stack>
              </Box>
              <Box className={classes.statBox}>
                <Stack spacing={4}>
                  <CustomTitle order={1} sx={{ fontSize: 40 }}>
                    {contentSummary?.comments || 0}
                  </CustomTitle>
                  <CustomText variant="h5">Comments</CustomText>
                </Stack>
              </Box>
              <Box className={classes.statBox}>
                <Stack spacing={4}>
                  <CustomTitle order={1} sx={{ fontSize: 40 }}>
                    {relatedContentCount}
                  </CustomTitle>
                  <CustomText variant="h5">Related content</CustomText>
                </Stack>
              </Box>
            </Flex>
          </Stack>
        </Flex>
        <Flex justify="space-between" align="center" mt={24} mb={8}>
          <CustomText variant="h3">
            {showInteractions ? 'Interactions' : 'Your related content'}
          </CustomText>
          <Group spacing={16}>
            {/* Load interactions modal flow */}
            <EnrichFlow
              title="Load Interactions"
              onSuccess={handleSuccessfulEnrichCallback}
              credits={allowedToEnrich ? credits : 0}
              remainingKey="linkedInEnrichmentScore"
            />
            {/* Export contacts modal flow */}
            <DownloadsMatchesFlow
              onLinkedinExportClick={onLinkedinExportClick}
              onFullReportExportClick={onFullReportExportClick}
              disabled={!selectedContentInteractionsIndexes.length}
            />
          </Group>
        </Flex>
        {showInteractions && (
          <TableWithSorting
            tableData={tableData}
            withScroll
            withSelect
            setSelected={setSelectedContentInteractionsIndexes}
            loading={contentInteractionsLoading}
            errorText="Interactions requested. Check back in an hour for results."
          />
        )}
        {showInteractions && (
          <Flex mt={24} mb={16}>
            <CustomText variant="h3">Your related content</CustomText>
          </Flex>
        )}
        <TabsWithCards tabs={TABS} tabsData={relatedContentData} />
      </Box>
      {/* Guided Tour Components */}
      <GuidedTourModal
        opened={showGuide && guideModalIndex === 0}
        title="Competitor Content"
        onClick={handleGuideModalClick}
        onClose={handleGuideModalClick}
      >
        <CustomText variant="body2">
          These reports give you an overview of your competitors content item.
        </CustomText>
      </GuidedTourModal>
      <GuidedTour
        stepIndex={stepIndex}
        setStepIndex={handleGuideModalClick}
        run={showGuide && guideModalIndex === null}
        steps={getCompetitorsContentSteps(dispatch, navigate)}
      />
    </div>
  );
}

export default CompetitorsDetailPage;
