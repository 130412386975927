import React, { useRef } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto';
import { TooltipComponentCallback } from './TooltipComponent';
import { convertRemToPixels } from '../../utils';

import logger from '../../utils/logger';

// Create a chart dataset for a company
const createDataset = (companyName, companyData, companyColor) => ({
  label: companyName,
  backgroundColor: companyColor,
  borderColor: companyColor,
  borderWidth: 1,
  hoverBackgroundColor: companyColor,
  hoverBorderColor: companyColor,
  data: companyData,
});

const BarChart = ({
  labels, companyData, categoryType, unit, setActiveCompetitorName, redraw, setRedraw,
}) => {
  // Create datasets for each company
  const datasets = companyData.map((company) => createDataset(company.name, company.data, company.chartColor));
  const barRef = useRef(null);

  const onClick = (event) => {
    try {
      setRedraw(false);
      const elem = getElementAtEvent(barRef.current, event);
      const data = datasets[elem[0].datasetIndex];
      setActiveCompetitorName(data?.label);
    } catch {
      setActiveCompetitorName(null);
    }
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,
        external: (context) => TooltipComponentCallback(context, categoryType, unit),
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#0F0E24',
          font: {
            size: convertRemToPixels(0.625),
            family: 'Manrope',
            weight: 500,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#0F0E24',
          font: {
            size: convertRemToPixels(0.625),
            family: 'Manrope',
            weight: 500,
          },
        },
      },
    },
  };

  const data = { labels, datasets };

  logger.info('bar data', data, barRef);

  return (
    <Bar ref={barRef} redraw={redraw} data={data} options={options} onClick={onClick}/>
  );
};

export default BarChart;
