import { Stack } from "@mantine/core"
import CustomText from "../../components/Typography/CustomText/CustomText"
import { setGuide } from "../profileSlice"

export const getTopicsSteps = (dispatch) => ([
    {
        content: <>
            <Stack spacing={8}>
                <CustomText variant="h6" sx={{fontWeight: 600, lineHeight: "120%"}}>
                  Below are a list of topics that you should consider writing more about to engage more of your audience.
                </CustomText>
                <CustomText variant="h6" sx={{fontWeight: 600, lineHeight: "120%"}}>
                  Click a topic to get a detailed report.
                </CustomText>
            </Stack>
        </>,
        target: ".table-header__Contacts",
        placement: "top-start",
        disableBeacon: true,
        offset: -12,
        withoutNavigation: true,
        withProgress: true,
        disabledPrevButton: true,
    },
    {
      content: `
        Click here to learn about Audience relevance 
      `,
      target: ".Audience_Relevance-tooltip",
      placement: "right",
      withoutNavigation: true,
      offset: 0
    },
    {
      content: `
        Click on any section now to filter the topics 
        you are interested in to inform your content creation needs.
      `,
      target: ".guided-tour__anchor",
      placement: "right",
      withoutNavigation: true,
      customBeacon: true,
      offset: 10
    },
    {
      content: <>
        Below are a list of topics that you should consider writing more about to engage more of your audience.
        You can click on these topics to get a more detailed report.
        <br />
        <br />
        But first let me tell you about your topic radar.
      </>,
      target: ".sorting-table",
      placement: "top",
      xOffset: 150,
      offset: 0
    },
    {
      content: `
        Click here to learn about the Topic radar
      `,
      target: ".Topic_radar-tooltip",
      placement: "right",
      withoutNavigation: true,
      offset: 0
    },
    {
      content: <>
        Scroll around the radar to understand each quadrant.
        <br />
        You can also click on topics on the radar to get a
        <br  />
        detailed report.
      </>,
      target: ".radar-chart__container",
      placement: "top-start",
      xOffset: -20,
      offset: 0
    },
    {
      content: <>
        You can also search for specific topics in the
        <br />
        search bar
      </>,
      target: ".mantine-Input-input",
      placement: "top",
      xOffset: "110",
      offset: -20,
      onClick: () => window.scrollTo({top: 400, behavior: 'smooth'})
    },
    {
      content: `
        Or use the filter to narrow your results 
      `,
      target: ".radar-chart__container",
      placement: "top",
      xOffset: -110,
      offset: 0,
      disableScrolling: true,
      onClick: () => dispatch(setGuide({
        key: 'topics',
        value: {
            showGuide: false
        }
      }))
    },
])