import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, rem, Stack } from '@mantine/core';
import BasicModal from '../BasicModal';
import GuideModalWrapper from './GuideModalWrapper';
import CustomText from '../../Typography/CustomText/CustomText';
import guideImageFirst from '../../../assets/addTagsGuideImages/first-step.png';
import guideImageSecond from '../../../assets/addTagsGuideImages/second-step.png';
import guideImageThird from '../../../assets/addTagsGuideImages/third-step.png';
import guideImageFourth from '../../../assets/addTagsGuideImages/fourth-step.png';
import guideImageFifth from '../../../assets/addTagsGuideImages/fifth-step.png';
import { useDispatch } from 'react-redux';
import { setGuide } from '../../../features/profileSlice';

const STEPS = [
  {
    title: 'Start sending personalized email content',
    content: (
      <>
        To start sending personalized email content to your audience you will
        <br />
        need to first add some ATC custom tags into your email templates.
        <br />
        Follow this easy step-by-step guide to learn how.
        <br />
        <br />
        We recommend opening your HubSpot account and following the
        <br />
        steps as you go through the guide.
      </>
    ),
    prevButton: 'I’ll add them in later',
  },
  {
    content: (
      <>
        Once you have your HubSpot account open, open your contacts and choose a
        list that you have
        <br />
        integrated with us.
        <br />
        <br />
        You will now see columns for recommended headlines, images and links
        next too each contact
        <br />
        name and they are numbered 1, 2 and 3, these show you which
        <br />
        image and link is related to which headline for example:
        <br />
        <br />
        Headline 1, Image 1 and Link 1 are all related to the same piece of
        content.
      </>
    ),
    image: guideImageFirst,
    imageHeight: 344,
  },
  {
    content: (
      <>
        To add content to your email templates, go to marketing in the top
        navigation bar and click email.
        <br />
        Then click, create email button or choose an email template you already
        have.
      </>
    ),
    image: guideImageSecond,
    imageHeight: 344,
  },
  {
    content: (
      <>
        Once you have your template or new email open, write your email copy.
        <br />
        <br />
        When you’re ready to add the recommended content, click personalize in
        the tool bar.
        <br />
        Change the type to “Contact”, then select “ACT Headline 1” in the list.
        <br />
        Tick the box next to “Use this Property’s global default value” and
        click on insert to add your headline to the template.
      </>
    ),
    image: guideImageThird,
    imageHeight: 373,
  },
  {
    content: (
      <>
        Follow the exact same steps to add in your the related link and image to
        your template.
        <br />
        <br />
        To add a link: Personalize {'>'} change type to “contact” {'>'}
        find “ATC Link 1: {'>'} Tick “Use this Property’s global default value”{' '}
        {'>'} Insert
        <br />
        To add a Image: Personalize {'>'} change type to “contact” {'>'} find
        “ATC Image 1: {'>'}
        Tick “Use this Property’s global default value” {'>'} Insert
        <br />
        <br />
        When you are finished, click Actions {'>'} Preview.
      </>
    ),
    image: guideImageFourth,
    imageHeight: 381,
  },
  {
    content: (
      <>
        Once in preview, select “preview as a specific contact” and choose a
        contact from the list and voila!
        <br />
        You can now send this email to the contacts in the list and watch your
        engagement rate grow.
      </>
    ),
    image: guideImageFifth,
    nextButton: 'Finish',
    imageHeight: 447,
  },
];

function GuideModalGroup({ opened, setOpened }) {
  const [openedModalIndexes, setOpenedModalIndexes] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (opened) {
      setOpenedModalIndexes([0]);
    }
  }, [opened]);

  const openModal = useCallback(
    (index) => {
      setOpenedModalIndexes([...openedModalIndexes, index]);
    },
    [openedModalIndexes],
  );

  const closeModal = useCallback(
    (index) => {
      setOpenedModalIndexes(
        openedModalIndexes.filter((modalIndex) => index !== modalIndex),
      );

      if (index === 0) {
        setOpened(false);
      }
    },
    [openedModalIndexes],
  );

  const getOpened = useCallback(
    (index) => {
      return openedModalIndexes.includes(index);
    },
    [openedModalIndexes],
  );

  const stepModals = useMemo(() => {
    return STEPS.map((step, index) => {
      const handleConfirmation = () => {
        if (index === stepModals.length - 1) {
          setOpenedModalIndexes([]);
          setOpened(false);
          dispatch(
            setGuide({
              key: 'addTags',
              value: {
                showGuide: false,
              },
            }),
          );
        } else {
          openModal(index + 1);
        }
      };

      const handleCancellation = () => {
        if (index === 0) {
          dispatch(
            setGuide({
              key: 'addTags',
              value: {
                showGuide: false,
              },
            }),
          );
        }

        closeModal(index);
      };

      return (
        <BasicModal
          key={index}
          withCloseButton={false}
          opened={getOpened(index)}
          onClose={() => closeModal(index)}
          withOverlay={!step.image}
          transitionProps={
            index > 1 && {
              transition: 0,
            }
          }
        >
          <GuideModalWrapper
            title={step.title || `Step ${index}`}
            prevButton={step.prevButton}
            nextButton={step.nextButton}
            onConfirm={handleConfirmation}
            onCancel={handleCancellation}
          >
            <Box
              h={step.image ? rem(535) : 'fit-content'}
              w={step.image && rem(950)}
            >
              <Stack spacing={24}>
                <CustomText variant="body2">{step.content}</CustomText>
                {step.image && (
                  <img
                    src={step.image}
                    style={{
                      width: '100%',
                      height: rem(step.imageHeight),
                      objectFit: "contain"
                    }}
                  />
                )}
              </Stack>
            </Box>
          </GuideModalWrapper>
        </BasicModal>
      );
    });
  }, [openedModalIndexes, dispatch]);

  return <Box>{stepModals}</Box>;
}

export default GuideModalGroup;
