import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { isObjectsEqual } from '../utils';

const useURLParams = (keyList, defaultParams = {}) => {
  const [urlParams, setURLParams] = useState(null);
  const [urlDateParams, setURLDateParams] = useState(null);
  const [urlDateParamsUpdated, setURLDateParamsUpdated] = useState(null);
  const [urlParamsUpdated, setURLParamsUpdated] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [urlState, setUrlState] = useState(null);
  const location = useLocation();

  const getValidatedDateFilter = () => {
    const validatedDateFilter = {
      from: urlDateParams ? urlDateParams.from : defaultParams.from,
    };

    if (urlDateParams?.to) {
      validatedDateFilter.to = urlDateParams.to;
    }

    return validatedDateFilter;
  };

  const getParamFromURL = (paramKey) => {
    const params = [];
    const arrayKeysList = [
      'sortBy',
      'sortDirection',
      'from',
      'to',
      'dateKey',
      'tab',
    ];

    // React router v6 only allows you to retrieve one value from an array, so the solution is to go through all the search parameters.
    searchParams.forEach((value, key) => {
      if (key === paramKey) {
        params.push(value);
      }
    });

    if (!params.length) return false;
    if (params.length === 1 && arrayKeysList.includes(paramKey))
      return params[0];

    return params;
  };

  const getPrevSearchParams = () => {
    const prevSearchParams = {};

    searchParams.forEach(
      (_, key) => (prevSearchParams[key] = getParamFromURL(key)),
    );

    return prevSearchParams;
  };

  const updateUrlParams = useCallback(
    (params) => {
      const newParams = {};
      const prevSearchParams = getPrevSearchParams();

      keyList.forEach((key) => {
        if (params[key] !== undefined || searchParams.get(key)) {
          if (params[key] === 0) {
            newParams[key] = 0;
          } else {
            newParams[key] = params[key];
            if (newParams[key] === undefined) {
              newParams[key] = getParamFromURL(key);
            } else if (
              !newParams[key]?.length &&
              typeof newParams[key] !== 'number'
            ) {
              newParams[key] = [];
            }
          }
        }
      });
      if (isObjectsEqual(urlParams || {}, { ...urlParams, ...newParams }))
        return;
      setSearchParams({ ...prevSearchParams, ...newParams });

      setURLParams((prevState) => ({
        ...prevState,
        ...newParams,
      }));
    },
    [urlParams],
  );

  const updateDateParam = useCallback(
    (newDateFilter) => {
      if (!newDateFilter) return;
      const prevSearchParams = getPrevSearchParams();

      if (!newDateFilter.to) {
        delete prevSearchParams.to;
      }
      const toDate = newDateFilter.to ? { to: newDateFilter.to } : {};
      const dateFilterObject = {
        dateKey: newDateFilter.dateKey,
        from: newDateFilter.from,
        ...toDate,
      };

      setURLDateParams(dateFilterObject);

      setSearchParams({
        ...prevSearchParams,
        ...dateFilterObject,
      });
    },
    [urlDateParams],
  );

  useEffect(() => {
    setUrlState(location.state);
    if (!keyList?.length) return;
    const updatedURLParams = {};
    keyList.forEach((key) => {
      if (key === 'dateFilter') {
        const dateFilterParams = {};
        dateFilterParams.dateKey =
          getParamFromURL('dateKey') || defaultParams.dateKey;
        dateFilterParams.from = getParamFromURL('from') || defaultParams.from;
        if (getParamFromURL('to')) {
          dateFilterParams.to = getParamFromURL('to');
        }

        updateDateParam(dateFilterParams);
      } else {
        updatedURLParams[key] = getParamFromURL(key) || defaultParams[key];
      }
    });

    setURLParams(updatedURLParams);
  }, []);

  useEffect(() => {
    setURLParamsUpdated(urlParams);
    setURLDateParamsUpdated(urlDateParams);
  }, [urlParams, urlDateParams, searchParams]);

  return {
    urlParams,
    urlParamsUpdated,
    urlDateParams,
    urlDateParamsUpdated,
    urlState,
    updateUrlParams,
    updateDateParam,
    getValidatedDateFilter,
    getParamFromURL
  };
};

export default useURLParams;
