import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  statsContainer: {
    minWidth: rem(462),
    background: theme.colors.white[3],
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: rem(20),
    paddingRight: rem(16),
  },
  image: {
    height: rem(252),
    width: rem(268),
    borderRadius: rem(20),
    objectFit: 'cover',
  },
  modalContent: {
    overflowY: 'unset',
  },
  buttonsContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
