import { Text } from '@mantine/core';
import React from 'react';
import { useStyle } from './CustomText.style';

function CustomText({ variant, children, className, ...rest }) {
  const { classes, cx } = useStyle();

  return (
    <Text className={cx(classes[variant], className)} {...rest}>
        {children}
    </Text>
  );
}

export default CustomText;
