import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useOrgSelection from '../../../components/header/hooks/useOrgSelection';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';

const useRecommendations = (jwtTokenLoading, contactId) => {
  const [recommendations, setRecommendations] = useState([]);
  const { getContactsSummary } = useRadarApi();
  const { currentActiveOrg } = useOrgSelection();
  const [fetchContacts, loading] = useFetch(getContactsSummary);

  const fetchWrapper = useCallback(async () => {
    const data = await fetchContacts(contactId);
    setRecommendations(data || []);
  }, [fetchContacts, getContactsSummary, contactId]);

  const slicedArticles = useMemo(() => {
    return (recommendations?.articles || []).slice(0, 3);
  }, [recommendations]);

  const selectedTopic = useMemo(() => {
    return `The best ${currentActiveOrg?.name || '...'} news for ${dayjs().format('MMM DD')}`;
    // TODO: items in square brackets need to be dynamic based on current account and todays date
  }, [recommendations, currentActiveOrg]);

  useEffect(() => {
    if (!jwtTokenLoading && contactId) {
      fetchWrapper();
    }
  }, [jwtTokenLoading, contactId]);

  return { recommendations, loading, slicedArticles, selectedTopic };
};

export default useRecommendations;
