import './NotFoundPage.css';

function NotFoundPage() {
  return (
      <div>
        <h1>Nothing here ¯\_(ツ)_/¯</h1>
      </div>
  );
}

export default NotFoundPage;
