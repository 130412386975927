import { useEffect, useState } from 'react';
import useFetch from './useFetch';
import useJwtToken from './useJwtToken';
import useRadarApi from './useRadarApi';

const useCredits = () => {
    const [linkedInEnrichmentScore, setLinkedInEnrichmentScore] = useState(null)
    const [personalizationScore, setPersonalizationScore] = useState(null)
    const [topicReportScore, setTopicReportScore] = useState(null)

    const { loading } = useJwtToken()
    const { getCredits } = useRadarApi()
    const [fetchCredits, creditsLoading] = useFetch(getCredits)

    const fetchWrapper = async () => {
        const data = await fetchCredits()

        setLinkedInEnrichmentScore(data?.linkedInEnrichment?.remaining)
        setPersonalizationScore(data?.personalizationCreditsRemaining?.remaining)
        setTopicReportScore(data?.topicReportCreditsRemaining?.remaining)
    }

    useEffect(() => {
        if(!loading) {
            fetchWrapper()
        }
    }, [loading])

    return {
        linkedInEnrichmentScore,
        personalizationScore,
        topicReportScore,
        loading: creditsLoading,
        refetch: fetchWrapper
    }
}

export default useCredits;