import React, { useCallback, useMemo } from 'react';
import { Tabs } from '@mantine/core';
import useURLParams from '../../hooks/useURLParams';
import CustomText from '../Typography/CustomText/CustomText';
import { useStyles } from './OutlineTabs.style';
import { capitalizeFirstLetter } from '../../utils';

function OutlineTabs({
  tabsItems,
  children,
  noPadding,
  tabMinWidth,
  value,
  onTabChange,
  defaultValue,
  tabOptions = {},
  ...rest
}) {
  const { urlParams, updateUrlParams } = useURLParams(['tab'], {
    tab: defaultValue || tabsItems[0]
  });
  const { classes, cx } = useStyles();
  const activeTab = useMemo(() => capitalizeFirstLetter(value || urlParams?.tab), [value, urlParams]);

  const setActiveTab = useCallback((tab) => {
    if(tabOptions?.[tab]?.handleClick) {
      tabOptions?.[tab]?.handleClick(tab)
    }
    else if(onTabChange) {
      onTabChange(tab)
    }
    else {
      updateUrlParams({
        tab
      })
    }
  }, [onTabChange, updateUrlParams, tabOptions])

  const generateTabs = useCallback(() => {
    const elements = [];

    tabsItems.forEach((tab, index) => {
      elements.push(
        <Tabs.Tab
          key={index}
          value={tab}
          className={`tab-${tab.replace(' ', '_')}`}
          disabled={tabOptions?.[tab]?.disabled}
        >
          <CustomText variant="h4">{capitalizeFirstLetter(tab)}</CustomText>
        </Tabs.Tab>,
      );
    });

    return elements;
  }, [tabsItems, tabOptions]);

  return (
    <Tabs
      value={activeTab}
      onTabChange={setActiveTab}
      variant="outline"
      defaultValue={tabsItems[0]}
      classNames={{
        tab: classes.tab,
        tabsList: classes.tabList,
        tabLabel: classes.tabLabel,
        panel: cx(classes.panel, { [classes.noPadding]: noPadding }),
      }}
      styles={{
        tab: {
          minWidth: tabMinWidth,
        },
      }}
      {...rest}
    >
      <Tabs.List>{generateTabs()}</Tabs.List>
      {children}
    </Tabs>
  );
}

export default OutlineTabs;
