import React from 'react';
import { Group } from '@mantine/core';
import CustomText from '../../Typography/CustomText/CustomText';

const PriceContainer = ({ price, period }) => (
        <Group spacing={4}>
            <CustomText variant="h5">
                ${price}
            </CustomText>
            <CustomText variant="caption1" sx={(theme) => ({ color: theme.colors.gray[4] })}>
                per {period}
            </CustomText>
        </Group>
);

export default PriceContainer;
