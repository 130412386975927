import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  tableWrapper: {
    width: rem(560),
    minHeight: rem(339),
    background: theme.colors.white[3],
  },
  tableRow: {
    background: theme.colors.white[2],
  },
  chartContainer: {
    height: rem(339),
    margin: 0,
  },
}));
