import { Flex, Grid, Group, Progress, rem, Stack } from '@mantine/core';
import React, { useMemo } from 'react';
import CustomCheckbox from '../../../Checkbox';
import CustomText from '../../../Typography/CustomText/CustomText';
import StatsDifference from '../StatsDifference/StatsDifference';
import { useStyle } from './TableColumn.style';
import imagePlaceholder from '../../../../assets/icons/placeholder.png';
import { getFromEngagmentRange } from '../../../../utils';
import { useCallback } from 'react';

function TableColumn({
  index,
  rowIndex,
  rowItem,
  icon,
  span,
  useRange,
  useContent,
  withSelect,
  checked,
  toggleRow,
  disabled,
  maxNumbersLengthArray,
  tableData,
}) {
  const { classes, cx } = useStyle();

  const LinkIcon = useMemo(() => {
    return icon;
  });

  const isNumber = useCallback((value) => {
    const isNumberNaN = isNaN(parseInt(rowItem.title))
    return !isNumberNaN && value == parseInt(value)
  }, [])

  const offset = useMemo(() => {
    const withoutAlignment = tableData?.headOptions?.[index]?.withoutAlignment;

    if (
      isNumber(rowItem.title) &&
      !withoutAlignment &&
      !useRange
    ) {
      let headTH = null;

      if (tableData && tableData.head && index < tableData.head.length) {
        const className = tableData.head[index].replace(' ', '_');
        headTH = document.querySelector(`.table-header__${className} .mantine-Text-root`);
      }

      if (headTH === null) return tableData?.headOptions?.[index]?.thWidth / 2;
      return rem(headTH.getBoundingClientRect().width / 2);
    }
    return 0;
  }, [tableData, index, rowItem]);

  return (
    <Grid.Col
      key={index}
      className={cx(classes.rowItem, `row-${rowIndex}__item-${index}`)}
      span={span}
    >
      <Group h="100%" pl={offset} sx={{ flexWrap: 'nowrap' }}>
        {index === 0 && withSelect && (
          <CustomCheckbox
            size={16}
            checked={checked}
            disabled={disabled}
            onChange={() => toggleRow(rowIndex)}
          />
        )}
        {LinkIcon ? (
          <a href={rowItem.title} target="__blank" className={classes.linkIcon}>
            <LinkIcon />
          </a>
        ) : (
          <>
            {useRange ? (
              <Stack spacing={4} w={80}>
                <CustomText variant="body2" sx={{ lineHeight: '18px' }}>
                  {rowItem.title}
                </CustomText>
                <Progress
                  color={getFromEngagmentRange(rowItem.value)?.color}
                  value={rowItem.value}
                  classNames={{ root: classes.progress }}
                />
              </Stack>
            ) : (
              <>
                {useContent ? (
                  <Flex w="100%" h="100%" ml={-16} align="center" gap={12}>
                    <img
                      src={rowItem.imageUrl || imagePlaceholder}
                      className={classes.contentImage}
                    />
                    <CustomText
                      variant="h6"
                      lineClamp={2}
                      sx={{
                        maxWidth: 231,
                        width: '60%',
                        fontWeight: 600,
                      }}
                    >
                      {rowItem.title}
                    </CustomText>
                  </Flex>
                ) : (
                  <CustomText
                    variant="body2"
                    lineClamp={1}
                    sx={(theme) => ({
                      minWidth:
                        maxNumbersLengthArray[index] &&
                        rem(`${maxNumbersLengthArray[index]}`.length * 8),
                      color: tableData?.headOptions[index]?.contentGray
                        ? theme.colors.gray[4]
                        : '',
                      transform: offset ? 'translateX(-50%)' : '',
                    })}
                  >
                    {rowItem.title}
                  </CustomText>
                )}
              </>
            )}
          </>
        )}
        {(rowItem.difference || rowItem.difference === 0) && (
          <StatsDifference
            difference={Math.abs(rowItem.difference)}
            positive={rowItem.difference > 0}
          />
        )}
      </Group>
    </Grid.Col>
  );
}

export default TableColumn;
