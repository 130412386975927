import React from 'react';
import {
  Box,
  Group,
  rem,
  Select,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { useStyle } from './DoubleInput.style';
import { ReactComponent as CircleCross } from '../../../../assets/icons/circle-cross-icon.svg';
import CustomText from '../../../Typography/CustomText/CustomText';
import { ReactComponent as ChevronDown } from '../../../../assets/icons/chevronDown-icon.svg';

function DoubleInput({
  leftValue,
  rightValue,
  onChange,
  onDelete,
  options,
  setOptions,
  index,
  sameWidth,
  rightLabel,
  leftLabel,
  leftPlaceholder,
  rightPlaceholder,
}) {
  const { classes, cx } = useStyle();

  return (
    <Group sx={{ flexWrap: 'nowrap' }} maw={rem(500)} align="flex-end">
      <Group spacing={0} sx={{ flexWrap: 'nowrap' }} align="flex-end">
        <TextInput
          label={leftLabel}
          value={leftValue}
          onChange={(e) => onChange(e.target.value, 'leftValue', index)}
          classNames={{
            input: cx(classes.input, classes.leftInput),
            root: !sameWidth && classes.leftInputWrapper,
          }}
          placeholder={leftPlaceholder || "Page name"}
        />
        {!options ? (
          <TextInput
            label={rightLabel}
            value={rightValue}
            onChange={(e) => onChange(e.target.value, 'rightValue', index)}
            classNames={{
              input: cx(classes.input, classes.rightInput),
              root: !sameWidth && classes.rightInputWrapper,
            }}
            placeholder={rightPlaceholder || "Enter site URL"}
          />
        ) : (
          <Select
            data={options}
            value={rightValue}
            onChange={(value) => onChange(value, 'rightValue', index)}
            placeholder="Select content type"
            searchable
            creatable
            rightSection={<ChevronDown />}
            getCreateLabel={(query) => `+ Add ${query}`}
            onCreate={(query) => {
              onChange(query, 'rightValue', index)
              setOptions((prevState) => [...prevState, query]);
            }}
            classNames={{
              input: cx(classes.input, classes.rightInput),
              root: classes.rightInputWrapper,
              rightSection: classes.selectIcon,
            }}
          />
        )}
      </Group>
      {onDelete && (
        <Box className={classes.iconContainer}>
          <UnstyledButton onClick={() => onDelete(index)}>
            <CircleCross />
            <CustomText
              variant="body1"
              className="button-text"
              sx={(theme) => ({ color: theme.colors.yellow[1] })}
            >
              Remove
            </CustomText>
          </UnstyledButton>
        </Box>
      )}
    </Group>
  );
}

export default DoubleInput;
