import { Title } from '@mantine/core';
import React from 'react';
import { useStyles } from './CustomTitle.style';

function CustomTitle({ order, children, ...rest }) {
  const { classes } = useStyles();

  return (
    <Title
      order={order + 1}
      classNames={{
        root: classes[`h${order}`],
      }}
      {...rest}
    >
      {children}
    </Title>
  );
}

export default CustomTitle;
