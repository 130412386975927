import { Flex, Group } from '@mantine/core';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import CustomCheckbox from '../Checkbox';
import CustomText from '../Typography/CustomText/CustomText';
import CustomDropdown from './CustomDropdown';

function CheckBoxDropdown({
  menuOptions,
  title,
  selectedItems,
  onItemSelect,
  itemStyle,
  ...rest
}) {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const globalOptions = useMemo(
    () => ({
      setDropDownOpen,
      selectedItems,
      onItemSelect,
      itemStyle,
    }),
    [setDropDownOpen, selectedItems, onItemSelect, itemStyle],
  );

  return (
    <CustomDropdown
      menuOptions={menuOptions}
      targetItem={<CustomText variant="body2">{title}</CustomText>}
      opened={dropdownOpen}
      onChange={(state) => setDropDownOpen(state)}
      customItem={CustomItem}
      closeOnItemClick={false}
      globalOptions={globalOptions}
      {...rest}
    />
  );
}

const CustomItem = ({ itemData, globalOptions }) => {
  const [checked, setChecked] = useState(false);

  const handleClick = useCallback(() => {
    globalOptions.setDropDownOpen(true);
    globalOptions.onItemSelect(itemData);
    setChecked((prevState) => !prevState);
  }, [globalOptions, itemData]);

  useEffect(() => {
    if ((globalOptions.selectedItems?.length ?? 0) > 0) {
      const checked = globalOptions.selectedItems.reduce(
        (acc, curr) => acc || (curr.title || curr) === itemData.title,
        false
      );
      setChecked(checked);
    }
  });

  return (
    <Flex
      sx={{
        minWidth: 'fit-content',
        justifyContent: 'space-between',
        ...globalOptions.itemStyle,
      }}
      onClick={handleClick}
    >
      <Group spacing={8} sx={{ flexWrap: 'nowrap' }}>
        <CustomCheckbox checked={checked} size={17} />
        <CustomText
          variant="body2"
          sx={(theme) => ({
            color: !checked && theme.colors.gray[4],
            overflowWrap: 'anywhere',
          })}
        >
          {itemData.title}
        </CustomText>
      </Group>
    </Flex>
  );
};

export default CheckBoxDropdown;
