import React, { useCallback, useState } from 'react';
import { Box, Center, Group, Stack, UnstyledButton, rem } from '@mantine/core';
import CustomText from '../Typography/CustomText/CustomText';
import { ReactComponent as QuestionMarkIcon } from '../../assets/icons/question-mark-icon.svg';
import { useStyle } from './TitleWithTooltip.style';
import BasicModal from '../Modals/BasicModal';
import MainButton from '../buttons/buttons';
import CustomTitle from '../Typography/CustomTitle/CustomTitle';

function TitleWithTooltip({
  title,
  active,
  modalContent,
  modalTitle,
  withoutButton,
  onClose,
}) {
  const [modalOpened, setModalOpened] = useState(false);
  const { classes, cx } = useStyle();

  const handleClose = useCallback(() => {
    setModalOpened(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Group spacing={8} justify="space-between" align="center">
      {title && <CustomText variant="h3">{title}</CustomText>}
      <UnstyledButton
        className={active && classes.active}
        onClick={() => setModalOpened(true)}
      >
        <Box
          className={cx(
            classes.questionIcon,
            `${title ? title.replace(' ', '_') : 'no_title'}-tooltip`,
          )}
        >
          <QuestionMarkIcon />
        </Box>
      </UnstyledButton>
      {modalContent && (
        <BasicModal
          withCloseButton={false}
          opened={modalOpened}
          onClose={handleClose}
        >
          <Stack w={rem(455)}>
            {modalTitle && (
              <CustomTitle order={1} sx={{ fontSize: rem(20) }}>
                {modalTitle}
              </CustomTitle>
            )}
            {modalContent}
            {!withoutButton && (
              <Center>
                <MainButton
                  smallPadding
                  onClick={handleClose}
                  sx={{ width: rem(203) }}
                >
                  Got it
                </MainButton>
              </Center>
            )}
          </Stack>
        </BasicModal>
      )}
    </Group>
  );
}

export default TitleWithTooltip;
