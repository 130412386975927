import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  container: {
    position: 'relative',
    borderRadius: 20,
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    background: theme.colors.white[3],
  },
}));
