import React, { useMemo } from 'react';
import { Box, Group, Progress, rem, Stack } from '@mantine/core';
import Heading from '../../Heading';
import CustomText from '../../Typography/CustomText/CustomText';
import Stepper from '../components/Stepper/Stepper';
import LoginWrapper from '../LoginWrapper';
import { useStyle } from './PreparationSection.style';
import preparationFirstImage from '../../../assets/images/preparation-first-image.png';
import preparationSecondImage from '../../../assets/images/preparation-second-image.png';
import preparationThirdImage from '../../../assets/images/preparation-third-image.png';

const images = [
  preparationFirstImage,
  preparationSecondImage,
  preparationThirdImage,
];

function PreparationSection() {
  const { classes } = useStyle();

  const imageList = useMemo(() => {
    return images.map((image, index) => (
      <img key={`image-${index}`} src={image} className={classes.image} />
    ));
  }, []);

  return (
    <LoginWrapper pt={rem(88)} w={rem(808)}>
      <Stack spacing={rem(40)} align="center">
        <Stepper activeIndex={3} stepsAmount={3} />
        <Stack spacing={32}>
          <Heading
            title="You’re all set, Katie!"
            description={
              <>
                Bare with us while we collect your data into the radar.
                <br />
                We’ll send you an email within the next 24 hours when
                <br />
                its complete!
              </>
            }
          />
          <Stack spacing={8}>
            <CustomText
              variant="h3"
              sx={{ fontSize: rem(20), textAlign: 'center' }}
            >
              Preparing for take off.....
            </CustomText>
            <Progress
              value={50}
              color="red.0"
              size={rem(12)}
              classNames={{ root: classes.progressRoot }}
            />
          </Stack>
        </Stack>
        <Box className={classes.imageContainer}>
          <Stack spacing={24} align="center">
            <CustomText variant="bodyLarge">
              Here are some new campaign ideas made possible by ATC.
            </CustomText>
            <Group>{imageList}</Group>
          </Stack>
        </Box>
      </Stack>
    </LoginWrapper>
  );
}

export default PreparationSection;
