import { setGuide } from "../profileSlice"

export const getContactsSteps = (dispatch) => ([
    {
        content: <>
            Click on a contact from the list to see
            <br />
            more detail.
        </>,
        target: ".table-header__Email",
        placement: "top-start",
        offset: 0,
        xOffset: -5,
        withoutNavigation: true,
        disableBeacon: true,
    },
    {
      content: <>
        These tags are the top interest of this
        <br />
        contact. We gather these tags from clicks the
        <br />
        contacts have made. Then we use them to
        <br />
        recommend content for them.
      </>,
      target: ".tag-0",
      placement: "top-start",
      xOffset: 20,
      offset: 0,
      disableBeacon: true,
      disabledPrevButton: true,
      disableScrolling: true
    },
    {
      content: <>
        Sometimes we only have a few insights for a
        <br />
        contacts, which means we can’t accurately
        <br />
        recommend content for them.
        <br />
        So if you want to target specific individuals,
        <br />
        like new contacts, you can socially enrich
        <br />
        them with data...
      </>,
      target: ".table-header__Insights",
      placement: "top-start",
      xOffset: 5,
      offset: 0,
      disableBeacon: true,
      disabledPrevButton: true,
    },
    {
      content: <>
        Click here to learn about Social Enrichment
      </>,
      target: ".no_title-tooltip",
      placement: "bottom-end",
      xOffset: -5,
      offset: 0,
      withoutNavigation: true
    },
    {
        content: <>
            Click on on the Accounts tab to see
            <br />
            your accounts.
        </>,
        target: ".tab-Accounts",
        placement: "top-start",
        xOffset: 40,
        offset: 0,
        withoutNavigation: true,
        disableBeacon: true,
      },
      {
        content: <>
            Here you can find a list of accounts
            <br />
            registered with your company, the number of
            <br />
            individual contacts you have within those
            <br />
            accounts, account insights and how relevant
            <br />
            you content is to them.
        </>,
        target: ".table-header__Contacts",
        placement: "top-start",
        offset: 0,
        disableBeacon: true,
        disabledPrevButton: true,
      },
      {
        content: <>
            If you click on an account you can see more
            <br />
            detail about their interests and get a filtered
            <br />
            list of account contacts.
        </>,
        target: ".table-header__Contacts",
        placement: "top-start",
        offset: 0,
        disableBeacon: true,
        onClick: () => dispatch(setGuide({
          key: 'contacts',
          value: {
            showGuide: false
          }
        }))
      },
])