import { Box, Group, Stack, UnstyledButton } from '@mantine/core';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addNewCompetitor,
  updateCompetitor,
} from '../../features/settingsSlice';
import MainButton from '../buttons/buttons';
import CustomText from '../Typography/CustomText/CustomText';
import { useStyle } from './AddCompetitor.style';
import AddCompetitorDetails from './AddCompetitorDetails/AddCompetitorDetails';
import InputGroup from './InputGroup/InputGroup';

const competitorGeneralDataDefualtValues = {
  competitorName: '',
  competitorURL: '',
  competitorLinkedIn: '',
};

const competitorDetailDataDefaultValues = {
  homepage: '',
  homepageURL: '',
  blog: '',
  blogURL: '',
  resources: '',
  resourcesURL: '',
  about: '',
  aboutURL: '',
  product: '',
  productURL: '',
};

function AddCompetitor({
  competitorData,
  onContinue,
  onAdd,
  selectedCompetitor,
  withTitles,
  withoutSeparator,
  setEditing
}) {
  const [competitorGeneralData, setCompetitorGeneralData] = useState(
    competitorGeneralDataDefualtValues,
  );
  const [competitorDetailData, setCompetitorDetailData] = useState(
    competitorDetailDataDefaultValues,
  );
  const [editDetails, setEditDetails] = useState(false);
  const [editable, setEditable] = useState(true);
  const dispatch = useDispatch();
  const { classes, cx } = useStyle();
  const showLabels = useMemo(() => withTitles || (selectedCompetitor && editable), [withTitles, selectedCompetitor, editable])

  const inputGroup = useMemo(
    () => [
      {
        placeholder: 'Enter Competitor name',
        name: 'competitorName',
        value: competitorGeneralData.competitorName,
        label: showLabels ? 'Name' : '',
      },
      {
        placeholder: 'Paste your competitor URL here',
        name: 'competitorURL',
        value: competitorGeneralData.competitorURL,
        label: showLabels ? 'Website URL' : '',
      },
      {
        placeholder: 'Paste competitor linked in page here',
        name: 'competitorLinkedIn',
        value: competitorGeneralData.competitorLinkedIn,
        label: showLabels ? 'LinkedIn page' : '',
      },
    ],
    [competitorGeneralData, showLabels],
  );

  const updateCompetitorGeneralData = useCallback(
    (event, fieldName) => {
      setCompetitorGeneralData((prevState) => ({
        ...prevState,
        [fieldName]: event.target.value,
      }));
    },
    [competitorGeneralData],
  );

  const updateCompetitorDetailData = useCallback(
    (event, fieldName) => {
      setCompetitorDetailData((prevState) => ({
        ...prevState,
        [fieldName]: event.target.value,
      }));
    },
    [competitorDetailData],
  );

  const isCompetitorDataValid = useMemo(() => {
    let isValid = true;

    Object.values(competitorGeneralData).forEach((value) => {
      if (!value) {
        isValid = false;
      }
    });

    return isValid;
  }, [competitorGeneralData]);

  useEffect(() => {
    if(setEditing) {
      setEditing(editable)
    }
  }, [editable])

  useEffect(() => {
    if (!competitorData) return;

    setCompetitorGeneralData({
      competitorName: competitorData?.competitorName,
      competitorURL: competitorData?.competitorURL,
      competitorLinkedIn: competitorData?.competitorLinkedIn,
    });

    setCompetitorDetailData({
      homepage: competitorData?.homepage,
      homepageURL: competitorData?.homepageURL,
      blog: competitorData?.blog,
      blogURL: competitorData?.blogURL,
      resources: competitorData?.resources,
      resourcesURL: competitorData?.resourcesURL,
      about: competitorData?.about,
      aboutURL: competitorData?.aboutURL,
      product: competitorData?.product,
      productURL: competitorData?.productURL,
    });

    setEditable(false);
  }, [competitorData]);

  const addCompetitor = useCallback(() => {
    if (onAdd) {
      onAdd();
    }

    const dispatchFunc = (dispatchValue) => {
      if (!competitorData) {
        return addNewCompetitor(dispatchValue);
      }

      return updateCompetitor(dispatchValue);
    };

    dispatch(
      dispatchFunc({
        competitor: {
          ...competitorDetailData,
          ...competitorGeneralData,
        },
      }),
    );

    if (competitorData) {
      setEditable(false);
      setEditDetails(false);
      return;
    }

    setCompetitorGeneralData(competitorGeneralDataDefualtValues);
    setCompetitorDetailData(competitorDetailDataDefaultValues);
    setEditDetails(false);
  }, [competitorDetailData, competitorGeneralData, onAdd]);

  const handleContinueClick = useCallback(() => {
    if (onContinue) {
      onContinue();
    }

    setEditDetails(true);
  }, [onContinue]);

  return (
    <Box
      className={cx(classes.container, {
        [classes.selectedCompetitorContainer]: selectedCompetitor,
      })}
    >
      <Stack spacing={8}>
        {!selectedCompetitor && (
          <Stack spacing={4}>
            <CustomText variant="h3">Add a Competitor</CustomText>
            <CustomText variant="body2">
              Add a competitors name and homepage URL to track their content
            </CustomText>
          </Stack>
        )}
        <Group
          className="add-competitor__container"
          w="fit-content"
          align={withTitles ? 'flex-end' : 'center'}
        >
          <InputGroup
            inputs={inputGroup}
            updateState={updateCompetitorGeneralData}
            useDefaultDisableStyles={selectedCompetitor && editable}
            disabled={editDetails || selectedCompetitor}
          />
          {!editable && (
            <UnstyledButton
              onClick={() => setEditable(true)}
              className={cx({ [classes.editButtonWithTitles]: withTitles })}
            >
              <CustomText
                variant="caption1"
                sx={(theme) => ({ color: theme.colors.red[0] })}
              >
                Edit
              </CustomText>
            </UnstyledButton>
          )}
        </Group>
        {editDetails || (editable && selectedCompetitor) ? (
          <>
            <AddCompetitorDetails
              competitorDetailData={competitorDetailData}
              updateCompetitorData={updateCompetitorDetailData}
              onCompetitorAdd={addCompetitor}
              updateCompetitor={!!competitorData}
            />
            {!withoutSeparator && (
              <Box className={classes.editCompetitorSeparator} />
            )}
          </>
        ) : (
          <>
            {editable && (
              <MainButton
                sx={{ width: 'fit-content' }}
                disabled={!isCompetitorDataValid}
                smallPadding
                customClassName="edit-button"
                onClick={handleContinueClick}
              >
                Continue
              </MainButton>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
}

export default AddCompetitor;
