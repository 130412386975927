import Papa from 'papaparse';
import { useCallback } from 'react';

const useTopicCSV = (contacts) => {
  const exportToCsv = (fields, data, filename) => {
    const dataForPapa = data.map((obj) => {
      const result = {};
      fields.forEach((field) => {
        result[field] = obj[field];
      });
      return result;
    });

    const csv = Papa.unparse(dataForPapa);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  };

  const onLinkedinExportClick = useCallback(() => {
    const fields = [
      'email',
      'firstName',
      'lastName',
      'jobTitle',
      'employeeCompany',
      'country',
      'appleIdfa',
      'googleAid',
    ];

    if ((contacts?.length ?? 0) === 0) return;
    const data = contacts.map((contact) => ({
      email: contact.email,
      firstName: contact.firstName,
      lastName: contact.lastName,
      jobTitle: contact.jobTitle,
      employeeCompany: contact.company,
      // Add other fields as necessary
    }));

    exportToCsv(fields, data, 'linkedin_audience.csv');
  }, [contacts]);

  const onFullReportExportClick = useCallback(() => {
    const fields = [
      'firstName',
      'lastName',
      'org/company',
      'title',
      'email',
      'linkedin profile url',
      'reaction type',
      'comment',
    ];

    if ((contacts?.length ?? 0) === 0) return;
    const data = contacts.map((contact) => ({
      email: contact.email,
      firstName: contact.firstName,
      lastName: contact.lastName,
      'org/company': contact.company,
      title: contact.jobTitle,
    }));

    exportToCsv(fields, data, 'full_report.csv');
  }, [contacts]);

  return [onLinkedinExportClick, onFullReportExportClick]
};

export default useTopicCSV;
