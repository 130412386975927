import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  segmentRoot: {
    height: rem(35),
    padding: 0,
    background: theme.colors.white[2],
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    maxWidth: 'fit-content',
  },
  label: {
    margin: 0,
    padding: `${rem(7)} ${rem(24)}`,
    fontFamily: 'Manrope',
    fontSize: rem(14),
    marginLeft: rem(-2),
    fontWeight: 600,
    color: theme.colors.blue[1],
  },
  control: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '0 !important',

    '&:last-child': {
      '& .mantine-SegmentedControl-label': {
        marginLeft: rem(-4),
      },
    },
  },
  controlActive: {
    '& .mantine-SegmentedControl-label': {
      color: theme.colors.white[0],
    },
    '& .mantine-SegmentedControl-label:hover': {
      color: theme.colors.white[0],
    },
  },
  indicator: {
    height: `${rem(34)} !important`,
    borderRadius: 100,
    marginTop: `${rem(3.5)}`,
    background: theme.colors.blue[0],
  },
}));
