import React from 'react';
import { rem, Stack } from '@mantine/core';
import ContentWithImage from '../../ContentWithImage/ContentWithImage';
import MainButton from '../../buttons/buttons';
import CustomText from '../../Typography/CustomText/CustomText';
import CustomTitle from '../../Typography/CustomTitle/CustomTitle';
import imageRocket from '../../../assets/images/rocket-image.png';
import { useStyle } from './EnterEmailComponent.style';
import { isEmail, useForm } from '@mantine/form';
import { useCallback } from 'react';
import LoginInput from '../../LoginInput/LoginInput';

import logger from '../../../utils/logger';

function EnterEmailComponent({onClick}) {
  const { classes } = useStyle();
  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: isEmail("Are you sure that you've entered your email correctly?"),
    },
  });

  const handleClick = useCallback((values) => {
    logger.debug('email', values)
    onClick(values.email)
  }, [form])

  return (
    <ContentWithImage
      withLines
      content={
        <Stack spacing={24} mt={rem(88)} w={rem(500)}>
          <CustomTitle order={0} sx={{ fontSize: rem(48), lineHeight: '110%' }}>
            Login to your account
          </CustomTitle>
          <CustomText variant="bodyLarge">
            Enter your email address and we will send you a magic link to your
            inbox for quick easy sign in!
          </CustomText>
          <Stack spacing={16} className={classes.wrapper}>
            <LoginInput
              placeholder="Enter your email address"
              height={rem(60)}
              {...form.getInputProps('email')}
            />
            <MainButton
              hugePadding
              customClassName={classes.button}
              onClick={form.onSubmit(handleClick)}
            >
              <CustomText
                variant="body2"
                sx={{ fontSize: rem(18), fontWeight: 500 }}
              >
                Send my magic link
              </CustomText>
            </MainButton>
          </Stack>
        </Stack>
      }
      image={<img src={imageRocket} style={{width: rem(560), height: rem(560)}} />}
    />
  );
}

export default EnterEmailComponent;
