import React, { useCallback } from 'react';
import { Stack, UnstyledButton } from '@mantine/core';
import MainButton from '../buttons/buttons';
import CustomText from '../Typography/CustomText/CustomText';
import CustomTitle from '../Typography/CustomTitle/CustomTitle';
import BasicModal from './BasicModal';
import { useStyle } from './SuccessfulPaymentModal.style';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';

function SuccessfulPaymentModal({
  paymentModalIndex,
  setPaymentModalIndex,
  content,
  onClose,
  withoutTitle,
  ...rest
}) {
  const { classes } = useStyle();

  const handleClose = useCallback(() => {
    setPaymentModalIndex(null);

    if (onClose) {
      onClose();
    }
  }, [setPaymentModalIndex, onClose]);

  return (
    <BasicModal
      withCloseButton={false}
      opened={paymentModalIndex === 1}
      onClose={handleClose}
      bodyClassName={classes.modalBody}
      {...rest}
    >
      <UnstyledButton
        onClick={handleClose}
        className={classes.modalCloseButton}
      >
        <CloseIcon />
      </UnstyledButton>
      <Stack spacing={18} w={445} align="center">
        {!withoutTitle && (
          <CustomTitle order={1}>Payment Successful</CustomTitle>
        )}
        <CustomText variant="h3" sx={{ textAlign: 'center' }}>
          {content}
        </CustomText>
        <MainButton onClick={handleClose} smallPadding sx={{ width: 203 }}>
          <CustomText variant="body2">Continue</CustomText>
        </MainButton>
      </Stack>
    </BasicModal>
  );
}

export default SuccessfulPaymentModal;
