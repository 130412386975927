import React, { useEffect } from 'react';
import { MultiSelect, rem } from '@mantine/core';
import { useStyle } from './CustomMultiSelect.style';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg';
import { useState } from 'react';
import ValueItem from './ValueItem';

import logger from '../../utils/logger';

function CustomMultiSelect({value, setValue, ...rest}) {
  const [multiSelectSerachString, setMultiSelectSerachString] = useState('');
  const { classes } = useStyle()
  logger.debug(value)
  useEffect(() => {
    const onKeyDown = (e) => {
      // 13 is key code for enter
      if (e.keyCode === 13) {
        setValue([
          ...value,
          multiSelectSerachString,
        ]);
        setMultiSelectSerachString('');
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [multiSelectSerachString]);

  return (
    <MultiSelect
      classNames={{
        icon: classes.inputIcon,
        input: classes.input,
        root: classes.inputRoot,
        wrapper: classes.selectWrapper,
        value: classes.selectValue,
      }}
      data={value || []} // data must be an array
      value={value || []}
      onChange={(queryArray) => {
        setValue(queryArray);
      }}
      searchValue={multiSelectSerachString}
      onSearchChange={setMultiSelectSerachString}
      creatable
      getCreateLabel={(query) => `Add ${query}`}
      onCreate={(query) => {
        logger.debug(query)
        setValue([...value, query]);
        return query;
      }}
      valueComponent={ValueItem}
      clearable
      searchable
      icon={<SearchIcon style={{ width: rem(16), height: rem(16) }} />}
      {...rest}
    />
  );
}

export default CustomMultiSelect;
