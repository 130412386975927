const chartVariants = [
  {
    chartColor: '#62B18E',
    gradient: {
      startColor: 'rgba(98, 177, 142, 0.5)',
      endColor: 'rgba(217, 217, 217, 0)',
    },
  },
  {
    chartColor: '#F6C25D',
    gradient: {
      startColor: 'rgba(246, 194, 93, 0.5)',
      endColor: 'rgba(246, 194, 93, 0)',
    },
  },
  {
    chartColor: '#ED7470',
    gradient: {
      startColor: 'rgba(237, 116, 112, 0.5)',
      endColor: 'rgba(217, 217, 217, 0)',
    },
  },
];

export const getChartColors = (value) => {
  switch (true) {
    case value < 0:
      return chartVariants[2];
    case value > 0:
      return chartVariants[0];
    case value === 0:
      return chartVariants[1];
  }
};
