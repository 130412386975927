import React, { useCallback, useEffect, useState } from 'react';
import { Box, rem, Stack } from '@mantine/core';
import MainButton from '../../buttons/buttons';
import ContentWithImage from '../../ContentWithImage/ContentWithImage';
import CustomText from '../../Typography/CustomText/CustomText';
import InputGroup from '../components/InputGroup/InputGroup';
import Heading from '../../Heading';
import { useSelector } from 'react-redux';
import Stepper from '../components/Stepper/Stepper';

function OrganizeContent({ onContinue }) {
  const [contentTypes, setContentTypes] = useState([]);
  const websiteSections = useSelector((state) => state.layout.userSections);
  const [contentTypeSections, setContentTypeSections] = useState([]);

  useEffect(() => {
    setContentTypeSections(
      websiteSections.map(({ leftValue }) => ({
        leftValue,
        rightValue: '',
      })),
    );
  }, [websiteSections]);

  const onChange = useCallback(
    (value, key, index) => {
      const newState = [...contentTypeSections];
      newState[index][key] = value;

      setContentTypeSections(newState);
    },
    [contentTypeSections],
  );

  return (
    <ContentWithImage
      content={
        <Stack mt={rem(88)} w={rem(500)} spacing={40}>
          <Stepper activeIndex={1} stepsAmount={3} />
          <Heading
            spacing={24}
            small
            align="left"
            title="Organize your content"
            description={
              <>
                All your awesome content doesn’t always make sense for everyone
                you’re engaging.
                <br />
                <br />
                It’s time to setup your content categories. You might organize
                your content by who it’s for (customers or prospects), or simply
                the type of content it is (blogs or webinars).
                <br />
                <br />
                Each type of content you select will have it’s own set of
                recommendations so that you can decide what kinds of content you
                want to recommend in emails, web pages and more.
              </>
            }
          />
        </Stack>
      }
      image={
        <Stack spacing={rem(188)} mr={rem(106)} mb={rem(88)}>
          <InputGroup
            inputs={contentTypeSections}
            onChange={onChange}
            options={contentTypes}
            setOptions={setContentTypes}
          />
          <Box ml="auto">
            <MainButton
              hugePadding
              sx={{ width: rem(283) }}
              onClick={onContinue}
            >
              <CustomText variant="bodyLarge" sx={{ fontSize: rem(18) }}>
                Let’s go!
              </CustomText>
            </MainButton>
          </Box>
        </Stack>
      }
    />
  );
}

export default OrganizeContent;
