import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  input: {
    display: 'flex',
    alignItems: 'center',
    borderColor: theme.colors.gray[3],
    borderRadius: rem(20),
    padding: `${rem(10)} ${rem(16)} !important`,
    minHeight: rem(40),
  },
  payButton: {
    background: theme.colors.yellow[1],

    '&:hover': {
      background: theme.colors.yellow[1],
    },
  },
}));
