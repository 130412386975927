import React, { useState, useMemo, useCallback } from 'react';
import { Box, Stack } from '@mantine/core';
import CustomInput from '../../CustomInput/CustomInput';
import CustomText from '../../Typography/CustomText/CustomText';
import ContentSourceGroup from './ContentSourceInputGroup';
import MainButton from '../../buttons/buttons';

function ContentSourceTab() {
  const [sources, setSources] = useState([
    {
      homeURL: 'https/www.mywesbite.org',
      blogURL: 'www.site.com/blog',
      resourcesURL: 'www.site.com/resources',
      aboutURL: 'www.site.com/about',
      productURL: 'www.site.com/product',
    },
  ]);
  const [homeUrl, setHomeUrl] = useState('');

  const sourecGroupsList = useMemo(() => {
    const elements = [];

    const onSubmit = (sourceData, index) => {
      const newSources = [...sources];
      newSources.splice(index, 1, sourceData);

      setSources(newSources);
    };

    sources.forEach((source, index) => {
      elements.push((
            <ContentSourceGroup
                key={index}
                initialValues={source}
                onEdit={(sourceData) => onSubmit(sourceData, index)}
            />
      ));
    });

    return elements;
  }, [sources]);

  const addWebsite = useCallback(() => {
    setSources((prevState) => ([
      ...prevState,
      {
        homeURL: homeUrl,
        blogURL: '',
        resourcesURL: '',
        aboutURL: '',
        productURL: '',
      },
    ]));
    setHomeUrl('');
  }, [sources, homeUrl]);

  return (
    <Box>
        {sourecGroupsList}
        <Stack spacing={8}>
            <Stack spacing={4}>
                <CustomText variant="h4">
                    Have more than one website?
                </CustomText>
                <CustomText variant="body2" sx={{ color: '333' }}>
                    Add a another website to you content sources below
                </CustomText>
            </Stack>
            <Box maw={467}>
                <CustomInput
                    placeholder="Paste your homepage URL here"
                    value={homeUrl}
                    onChange={(e) => setHomeUrl(e.target.value)}
                />
            </Box>
            <MainButton
                onClick={addWebsite}
                smallPadding
                sx={{ maxWidth: 'fit-content' }}
            >
                <CustomText variant="body2">
                    Add website
                </CustomText>
            </MainButton>
        </Stack>
    </Box>
  );
}

export default ContentSourceTab;
