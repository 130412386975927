import { useEffect, useState } from 'react';

const useStateFromURL = (keys, urlParams, once) => {
  const [state, setState] = useState(null);

  useEffect(() => {
    if (!urlParams || (once && state)) return;
    if (keys.length === 1) {
      //if only 1 key is passed, update state just with value
      setState(urlParams[keys[0]]);
    } else {
      //if multiple keys are passed, update state with object
      const urlValues = {};
      keys.forEach((key) => {
          urlValues[key] = urlParams[key];
      });

      setState(urlValues);
    }
  }, [urlParams]);

  return [state, setState]
};

export default useStateFromURL;
