import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  container: {
    borderRadius: 8,
    padding: rem(5),
    position: 'relative',
    minWidth: 'fit-content'
  },
  blurContainer: {
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: '0',
      borderRadius: 5,
      background: 'black',
      opacity: 0.1,
    },
  },
}));
