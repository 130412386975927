import { Box, Flex, rem, Stack } from '@mantine/core';
import React from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown';
import FilterBar from '../../../../components/filtering/FilterBar/FilterBar';
import EnrichFlow from '../../../../components/Modals/EnrichFlow';
import TitleWithTooltip from '../../../../components/TitleWithTooltip/TitleWithTooltip';
import CustomText from '../../../../components/Typography/CustomText/CustomText';
import useRadarApi from '../../../../hooks/useRadarApi';
import { getAllFilters } from '../../utils';
import useJwtToken from '../../../../hooks/useJwtToken';
import useFilterFields from '../../hooks/useFilterFields';
import { useCallback } from 'react';
import FilterList from '../../../../components/filtering/FilterBar/Lists/FilterList/FilterList';
import useFilter from '../../../../hooks/useFilter';
import { useSelector } from 'react-redux';
import useGuideModal from '../../../../hooks/useGuideModal';
import { DEFAULT_URL_PARAMS, LIST_KEY, TABS } from '../../constant';
import { useStyle } from '../../ContactsPage.style';
import FilterDropdown from './FilterDropdown';
import { serializeParams } from '../../../../utils';
import { contactsFeature } from '../../../../config';

import logger from '../../../../utils/logger';

function CustomFilterBar({
  urlParams,
  updateUrlParams,
  activeTab,
  filteringOptions,
  filteringParams,
  setFilteringParams,
  setContactsOffset,
  setAccountOffset,
  selectedContactsIndexes,
  contactsContent,
  totalItems,
  allSelected,
  selectedListsId,
  hubspotDetails,
}) {
  const [filterOpened, setFilterOpened] = useState(false);
  const filtersControlRef = useRef(null);
  const { permissions } = useJwtToken();
  const { classes } = useStyle();
  const { updateSocialEnrichments } = useRadarApi();
  const { stepIndex } = useSelector((state) => state.profile.guide.contacts);
  const { handleGuideModalClick } = useGuideModal('contacts', stepIndex);

  const allFilters = useMemo(() => getAllFilters(urlParams), [urlParams]);

  const updateFilter = useFilter(filteringParams, setFilteringParams);
  const updateFilterURL = useFilter(filteringParams, updateUrlParams);

  const generateFilterFields = useFilterFields(
    filteringParams,
    filteringOptions,
    updateFilter,
  );

  const allowedToEnrich = useMemo(() => {
    return permissions.includes('write:contacts');
  }, [permissions]);

  const removeFilterItem = useCallback(
    (filter) => {
      const rangeKeys = ['insights', 'contacts', 'list'];
      logger.debug(filter);
      if (rangeKeys.includes(filter.key)) {
        updateUrlParams({
          ...filteringParams,
          [filter.key]: [],
        });
        setFilteringParams({
          ...filteringParams,
          [filter.key]: [],
        });
      } else {
        updateFilterURL(filter.key, filter.value);
      }
    },
    [filteringParams, urlParams],
  );

  const handleSocialEnrichmentUpdating = useCallback(() => {
    let body = {};

    if (allSelected) {
      body.personIds = 'ALL';
      body.filter = {};
      Object.keys(serializeParams(urlParams)).map((key) => {
        let rangeFilters = ['insights', 'contacts'];
        if (rangeFilters.includes(key)) {
          body.filter[key] = {
            min: urlParams[key][0],
            max: urlParams[key][1],
          };

          return;
        } else {
          if (!key.includes('filter')) return;
          const newKey = key.split('filter[')[1].split(']')[0];
          body.filter[newKey] = urlParams[key];
        }
      });

      if (selectedListsId) {
        body.filter.listId = selectedListsId;
      }
    } else {
      body.personIds = selectedContactsIndexes.map(
        (index) => contactsContent[index].id,
      );
    }

    updateSocialEnrichments(body);
  }, [
    selectedContactsIndexes,
    contactsContent,
    urlParams,
    updateSocialEnrichments,
  ]);

  const credits = useMemo(() => {
    if (allSelected) {
      return allowedToEnrich ? totalItems : 0;
    }
    return allowedToEnrich ? selectedContactsIndexes.length : 0;
  }, [allowedToEnrich, selectedContactsIndexes, allSelected, totalItems]);

  return (
    <>
      <Flex
        justify="space-between"
        mb={filterOpened ? (allFilters.length ? 274 : 250) : 0}
      >
        <Box w="100%">
          <FilterBar
            withoutInput={contactsFeature?.hideInput}
            filterByData={[{ title: '' }]}
            disabled
            placeholder={
              activeTab === TABS[0] ? 'Search contacts' : 'Search accounts'
            }
            styles={{ root: { paddingRight: 115 } }}
            setParams={updateUrlParams}
            urlParams={urlParams}
            withOffset={filterOpened}
            filtersControlGroupRef={filtersControlRef}
            onReset={() => {
              updateUrlParams({
                ...DEFAULT_URL_PARAMS,
                tab: [activeTab],
              });
              setFilteringParams({
                ...filteringParams,
                [LIST_KEY]: '',
              });
            }}
            onApply={(params) => {
              if (activeTab === TABS[0]) {
                setContactsOffset(0);
              } else {
                setAccountOffset(0);
              }
              updateUrlParams({
                ...filteringParams,
                ...params,
              });
            }}
            customFilterByComponent={
              <CustomDropdown
                opened={filterOpened}
                onChange={(value) => {
                  if (value === false) return;
                  setFilterOpened(value);
                }}
                customAnimationState={filterOpened}
                targetItem={
                  <CustomText variant="body2" className="filter-by">
                    Filter by
                  </CustomText>
                }
                gap={8}
                menuOptions={[]}
                targetStyles={{
                  minWidth: 'fit-content',
                  justifyContent: 'space-between',
                }}
                dropdownClassName={classes.filterDropdownContainer}
                customDropdownComponent={
                  <FilterDropdown
                    fields={generateFilterFields()}
                    opened={filterOpened}
                    setOpened={setFilterOpened}
                    updateFilter={updateFilter}
                    filteringParams={filteringParams}
                    setFilteringParams={setFilteringParams}
                    filtersControlGroupRef={filtersControlRef}
                    filteringOptions={filteringOptions}
                    activeTab={activeTab}
                    selectedListsId={selectedListsId}
                    hubspotDetails={hubspotDetails}
                  />
                }
              />
            }
          >
            {activeTab === TABS[0] && (
              <Flex ml={-8} gap={8} align="center">
                <EnrichFlow
                  title="Socially Enrich"
                  credits={credits}
                  onSuccess={handleSocialEnrichmentUpdating}
                  remainingKey="linkedInEnrichmentScore"
                />
                <TitleWithTooltip
                  active={stepIndex === 3}
                  modalTitle="What is social enrichment"
                  modalContent={
                    <Stack spacing={24}>
                      <CustomText variant="body2">
                        Social enrichment gathers insights about any contacts
                        you select by mining their LinkedIn profiles and
                        activities. This can be especially tool for targeting
                        new customers and other contacts you may not have many
                        insights on.
                        <br />
                        <br />
                        Here’s how it works:
                      </CustomText>
                      <Stack spacing={8}>
                        <CustomText variant="body2">
                          1. Select contacts from the list that you wish to
                          socially enrich (you can filter to narrow down your
                          results and then use the select all checkbox at the
                          top or just a few contacts)
                          <br />
                          2. Select the ‘socially enrich’ button in the top
                          right corner of the screen.
                          <br />
                          3. Confirm the amount of credits you’ll use or
                          purchase more if you don’t have enough.
                        </CustomText>
                        <Box mt={16}>
                          <CustomText variant="body2">
                            Our AI Researcher then mines LinkedIn for your
                            selected contacts. Check back in 24 hours to see the
                            new insights for your contacts.
                          </CustomText>
                        </Box>
                      </Stack>
                    </Stack>
                  }
                  onClose={() =>
                    handleGuideModalClick(
                      stepIndex === 3 ? stepIndex + 1 : stepIndex,
                    )
                  }
                />
              </Flex>
            )}
          </FilterBar>
        </Box>
      </Flex>
      {allFilters.length > 0 && (
        <FilterList
          customMargin={filterOpened && rem(-92)}
          title="Selected filters:"
          updateFilter={removeFilterItem}
          filters={allFilters}
          customClassName={classes.topicItem}
        />
      )}
      {allFilters.length === 0 && !filterOpened && <Box mt={14} />}
    </>
  );
}

export default CustomFilterBar;
