import { setDefaultActiveTab } from "../competitorContentSlice";
import { setGuide } from "../profileSlice";

export const getCompetitorsContentSteps = (dispatch, navigate) => [
  {
    content: (
      <>
        Here you can see snap shot data about the
        <br />
        content item and click the link button to view
        <br />
        it.
      </>
    ),
    target: '.stat-box',
    placement: 'top',
    offset: -10,
    disableBeacon: true,
    disabledPrevButton: true,
  },
  {
    content: (
      <>
        This table gives you a list of people who
        <br />
        interacted with the content on LinkedIn.
        <br />
        You can see where they work, what their
        <br />
        reaction is and click the LinkedIn button to
        <br />
        view their profile.
      </>
    ),
    target: '.sorting-table',
    placement: 'top',
    offset: 0,
  },
  {
    content: (
      <>
        You can then select contacts using the
        <br />
        checkboxes below.....
      </>
    ),
    target: '.mantine-Checkbox-root',
    placement: 'top-start',
    xOffset: 2,
    offset: 0,
  },
  {
    content: (
      <>
        ... and export these contacts as a Linked in
        <br />
        Custom audience file, so that you can target
        <br />
        these contacts with your own content.
      </>
    ),
    target: '.export-contacts__button',
    placement: 'left',
    offset: 0,
  },
  {
    content: (
      <>
        Here you can find a list of your own content
        <br />
        that is related to this competitors content
        <br />
        item. You can use this content to target the
        <br />
        contacts from exported list above.
      </>
    ),
    target: '.mantine-Tabs-panel',
    placement: 'top',
    offset: 0,
    onClick: () => {
        dispatch(setDefaultActiveTab({
            activeTab: 0
        }))
        dispatch(setGuide({
            key: "competitors",
            value: {
                stepIndex: 6
            }
        }))
        dispatch(setGuide({
            key: "competitorsContent",
            value: {
                showGuide: false
            }
        }))
        navigate("/competitors")
    }
  },
];
