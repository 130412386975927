import React from 'react';
import { Box, rem, Stack } from '@mantine/core';
import Heading from '../../Heading';
import LoginInput from '../../LoginInput/LoginInput';
import LoginWrapper from '../LoginWrapper';
import { useStyle } from './AddWebsiteComponent.style';
import { useCallback } from 'react';
import ButtonGroup from '../components/ButtonGroup/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { setUserWebsite } from '../../../features/layoutSlice';

function AddWebsiteComponent({ onContinue }) {
  const { classes } = useStyle();
  const dispatch = useDispatch();
  const website = useSelector((state) => state.layout.userWebsite);
  const setWebsite = useCallback(
    (newWebsite) => {
      dispatch(
        setUserWebsite({
          website: newWebsite,
        }),
      );
    },
    [dispatch],
  );

  return (
    <LoginWrapper w="fit-content" pt={rem(88)}>
      <Stack spacing={44}>
        <Heading
          title="Enter your website address below"
          description="We use this to gather the content for your audience"
        />
        <LoginWrapper w={rem(520)}>
          <Stack>
            <Box
              className={classes.inputContainer}
            >
              <LoginInput
                height={rem(55)}
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                placeholder="Paste your homepage URL here"
              />
            </Box>
          </Stack>
        </LoginWrapper>
        <ButtonGroup
          disabled={!website.length}
          mainText="Continue"
          onMainButtonClick={onContinue}
        />
      </Stack>
    </LoginWrapper>
  );
}

export default AddWebsiteComponent;
