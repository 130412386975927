import React, { useMemo, useCallback } from 'react';
import { Flex, Group, rem, Stack, Switch } from '@mantine/core';
import Heading from '../../Heading';
import { useStyle } from './IntegrateContacts.style';
import CustomText from '../../Typography/CustomText/CustomText';
import CircularCheckbox from '../../CircularCheckbox/CircularCheckbox';
import LoginWrapper from '../LoginWrapper';
import MainButton from '../../buttons/buttons';

function IntegrateContacts({
  contacts,
  selectedContacts,
  setSelectedContacts,
  onContinue,
}) {
  const { classes } = useStyle();

  const updateSelectedContacts = useCallback(
    (index) => {
      const contactIndex = selectedContacts.findIndex(
        (contact) => contact === index,
      );
      if (contactIndex === -1) {
        setSelectedContacts([...selectedContacts, index]);
      } else {
        setSelectedContacts(
          selectedContacts.filter((contact) => contact !== index),
        );
      }
    },
    [selectedContacts],
  );

  const contactList = useMemo(() => {
    if (!contacts || contacts.length === 0) return;
    return contacts.map((title, index) => (
      <Flex
        key={`integrate-contacts-item-${index}`}
        justify="space-between"
        align="center"
        className={classes.contactItem}
        onClick={() => updateSelectedContacts(index)}
      >
        <CustomText variant="bodyLarge">{title}</CustomText>
        <CircularCheckbox checked={selectedContacts.includes(index)} />
      </Flex>
    ));
  }, [contacts, classes]);

  const selectAllContacts = useCallback(() => {
    setSelectedContacts([...Array(contacts.length).keys()]);
  }, [contacts]);

  const allContactsSelected = useMemo(() => {
    return selectedContacts.length === contacts.length;
  }, [selectedContacts, contacts]);

  const handleSwitchToggle = useCallback(() => {
    if (allContactsSelected) {
      setSelectedContacts([]);
    } else {
      selectAllContacts();
    }
  }, [selectAllContacts, allContactsSelected]);

  return (
    <LoginWrapper pt={rem(88)}>
      <Stack spacing={32} align="center">
        <Heading
          title="Integrate your contacts"
          description={
            <>
              Which contact list below would you like to
              <br />
              integrate with Airtraffic control?
            </>
          }
        />
        <Stack w="100%">
          <Stack spacing={10} className={classes.contactsContainer}>
            {contactList}
          </Stack>
          <Group spacing={8} className={classes.integateAllContainer}>
            <CustomText variant="bodySmall" onClick={handleSwitchToggle}>
              Integrate all
            </CustomText>
            <Switch
              size="md"
              color="red.0"
              checked={allContactsSelected}
              onChange={handleSwitchToggle}
              classNames={{
                thumb: classes.switchThumb,
                track: classes.switchTrack,
              }}
              styles={{
                thumb: {
                  background: allContactsSelected ? '#fff !important' : '',
                },
              }}
            />
          </Group>
        </Stack>
        <MainButton
          hugePadding
          onClick={onContinue}
          sx={{ height: rem(60), width: rem(173) }}
        >
          <CustomText
            variant="body2"
            sx={{ fontSize: rem(18), fontWeight: 500 }}
          >
            Continue
          </CustomText>
        </MainButton>
      </Stack>
    </LoginWrapper>
  );
}

export default IntegrateContacts;
