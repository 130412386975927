import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  wrapper: {
    background: theme.colors.white[2],
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: rem(20),
    padding: `${rem(4)} ${rem(12)} ${rem(12)}`,
  },
  wrapperScroll: {
    maxHeight: rem(270),
    marginBottom: rem(-4),
    overflow: 'scroll',
    "&::-webkit-scrollbar": {
      display: "none"
    },

    /* Hide scrollbar for IE, Edge and Firefox */
    scrollbarWidth: "none",
    msOverflowStyle: "none"
  },
  alignCenter: {
    justifyContent: "center"
  },
  headContainer: {
    borderBottom: `${rem(1)} solid ${theme.colors.gray[3]}`,
    marginBottom: 4,
    width: `calc(100% - ${rem(12)})`,
    margin: '0 auto',
  },
  root: {
    padding: '0 12px',
  },
  rowContainer: {
    maxHeight: rem(50),
    width: '100%',
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: rem(8),
    margin: '0 auto',
    padding: `0 ${rem(16)}`,
    transition: '0.5s',

    '& .hover-item': {
      display: 'none',
    },

    '&:hover .hover-item': {
      display: 'block',
    },
  },
  row: {
    position: 'relative',
    minHeight: rem(48),
    maxHeight: rem(48),
    margin: '0 auto',
  },
  rowHover: {
    '&:hover': {
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
    },
  },
  rowItem: {
    padding: 0,
  },
  contentImage: {
    height: '100%',
    width: rem(50),
    borderRadius: 7,
  },
  checkbox: {
    borderColor: theme.colors.gray[3],

    '&:checked': {
      backgroundColor: '#228be6',
      borderColor: '#228be6',
    },
  },
  linkIcon: {
    '& svg': {
      width: rem(25),
      height: rem(25),
    },
  },
  progress: {
    background: theme.colors.white[1],
  },
}));
