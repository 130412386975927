import { useCallback, useMemo, useState } from 'react';
import { TABS } from '../constant';

const useRangeFilters = (activeTab, filteringOptions) => {
  const [contactsRangeFiltersDefault, setContactsRangeFiltersDefault] =
    useState({
      insightsCountRange: {
        min: null,
        max: null,
      },
      contactsCountRange: {
        min: null,
        max: null,
      },
    });
  const [accountsRangeFiltersDefault, setAccountsRangeFiltersDefault] =
    useState({
      insightsCountRange: {
        min: null,
        max: null,
      },
      contactsCountRange: {
        min: null,
        max: null,
      },
    });

  const rangeFiltersDefault = useMemo(
    () =>
      activeTab === TABS[0]
        ? contactsRangeFiltersDefault
        : accountsRangeFiltersDefault,
    [activeTab, contactsRangeFiltersDefault, accountsRangeFiltersDefault],
  );

  const setRangeFiltersDefault = useMemo(
    () =>
      activeTab === TABS[0]
        ? setContactsRangeFiltersDefault
        : setAccountsRangeFiltersDefault,
    [activeTab, contactsRangeFiltersDefault, accountsRangeFiltersDefault],
  );

  const getRangeFilterMinAndMax = useCallback(
    (key) => {
      const distinctValue = filteringOptions.find(
        (el) => el.distinctValuesKey === key,
      );

      if (distinctValue?.options?.min === undefined)
        return { min: 0, max: 100 };

      if (rangeFiltersDefault[key]?.min || rangeFiltersDefault[key]?.max) {
        return rangeFiltersDefault[key];
      }

      if (!distinctValue) return { min: 0, max: 100 };
      const min = distinctValue.options?.min || 0;
      const max = distinctValue.options?.max || 100;
      setRangeFiltersDefault((prevState) => ({
        ...prevState,
        [key]: {
          min,
          max,
        },
      }));
      return { min, max };
    },
    [rangeFiltersDefault, filteringOptions, activeTab],
  );

  return [getRangeFilterMinAndMax]
};

export default useRangeFilters;
