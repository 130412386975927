// DashboardPage.js

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Accordion, Box, Center, Flex, Group, rem, Stack } from '@mantine/core';
import MainButton from '../../components/buttons/buttons';
import TimePeriodSelection from '../../components/filtering/TimePeriodSelection/TimePeriodSelection';
import RadarChart from '../../components/RadarChart/RadarChart';
import BigStatsContainer from '../../components/statsContainers/BigStatsContainer/BigStatsContainer';
import TableWithSorting from '../../components/TableWithSorting/TableWithSorting';
import CustomText from '../../components/Typography/CustomText/CustomText';
import CustomTitle from '../../components/Typography/CustomTitle/CustomTitle';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left-icon.svg';
import { ReactComponent as TickCircleIcon } from '../../assets/icons/tick-circle-icon.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron-right-icon.svg';
import useURLParams from '../../hooks/useURLParams';
import { useStyle } from './DashboardPage.style';
import GuidedTourModal from '../../components/GuidedTour/GuidedTourModal/GuidedTourModal';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import GuideModalGroup from '../../components/Modals/GuideModalGroup/GuideModalGroup';

import { setActiveLinkKey, setHeaderTitle } from '../../features/layoutSlice';
import useFetchDashboard from './hooks/useFetchDashboard';
import useJwtToken from '../../hooks/useJwtToken';
import useRadarApi from '../../hooks/useRadarApi';
import useContentToPromoteTable from './hooks/useContentToPromoteTable';
import useTopicsToWriteAboutTable from './hooks/useTopicsToWriteAboutTable';
import { DEFAULT_DATE_FILTER_PARAMS } from '../../components/filtering/TimePeriodSelection/constants';
import { numberFormatter } from './utils';
import { setStartModalOpened } from '../../features/profileSlice';
import BasicModal from '../../components/Modals/BasicModal';

import logger from '../../utils/logger';

const GUIDED_TOUR_STEPS = [
  {
    key: 'firstStep',
    description: `
      Learn how to see insights and statistics about your content
    `,
    components: [
      {
        key: 'topics',
        title: 'Learn about your topics',
        reduxKeys: ['topics', 'topicDetails'],
        link: '/topics',
      },
      {
        key: 'content',
        title: 'Learn about your content',
        reduxKeys: ['content', 'contentDetails'],
        link: '/contents',
      },
    ],
  },
  {
    key: 'secondStep',
    description: `
      Add ATC tags in your email templates to start sending personalized content
    `,
    components: [
      {
        key: 'contacts',
        title: 'Learn about your contacts',
        reduxKeys: ['contacts'],
        link: '/contacts',
      },
      {
        key: 'tags',
        title: 'Add AI personalization tags to your email templates',
        reduxKeys: ['addTags'],
      },
    ],
  },
  {
    key: 'thirdStep',
    description: `
      Learn how to track and see insights about your competitors
    `,
    components: [
      {
        key: 'competitorContent',
        title: 'Learn about your competitors content',
        reduxKeys: ['competitors', 'competitorsContent'],
        link: '/competitors',
      },
      {
        key: 'addCompetitor',
        title: ' Add your competitors',
        reduxKeys: ['competitorsSettings'],
        link: '/settings?tab=Competitors',
      },
    ],
  },
];

const PARAMS_KEYS = ['dateFilter'];

function DashboardPage() {
  const [improveScoreModal, setImproveScoreModal] = useState(false);
  const [guideModalOpened, setGuideModalOpened] = useState(false);
  const navigate = useNavigate();
  const profileState = useSelector((state) => state.profile);

  const {
    getRadarContentToPromote,
    getRadarTopicsToWriteAbout,
    getRadarTopicsIncidenceAndEngagement,
    getRadarPersonalizationScore,
    getRadarContentInfluencedRevenue,
    getRadarNewPersonalizedEngagementsScore,
    getRadarPersonalizedContentEngagementRate,
  } = useRadarApi();
  const { loading } = useJwtToken();
  const { user } = useAuth0();
  const { classes } = useStyle();

  const { urlDateParams, updateDateParam, getValidatedDateFilter } =
    useURLParams(PARAMS_KEYS, {
      ...DEFAULT_DATE_FILTER_PARAMS,
    });

  const [contentToPromote, contentToPromoteLoading] = useFetchDashboard(
    loading,
    getRadarContentToPromote,
  );
  const [topicsToWriteAbout, topicsToWriteAboutLoading] = useFetchDashboard(
    loading,
    getRadarTopicsToWriteAbout,
  );
  const [incidenceAndEngagement, incidenceAndEngagementLoading] =
    useFetchDashboard(
      loading,
      getRadarTopicsIncidenceAndEngagement,
      getValidatedDateFilter(),
    );

  const [personalizationScore, personalizationScoreLoading] = useFetchDashboard(
    loading,
    getRadarPersonalizationScore,
    getValidatedDateFilter(),
  );

  const [contentInfluencedRevenue, contentInfluencedRevenueLoading] =
    useFetchDashboard(
      loading,
      getRadarContentInfluencedRevenue,
      getValidatedDateFilter(),
    );

  const [
    newPersonalizedEngagementsScore,
    newPersonalizedEngagementsScoreLoading,
  ] = useFetchDashboard(
    loading,
    getRadarNewPersonalizedEngagementsScore,
    getValidatedDateFilter(),
  );

  const [
    personalizedContentEngagementRate,
    personalizedContentEngagementRateLoading,
  ] = useFetchDashboard(
    loading,
    getRadarPersonalizedContentEngagementRate,
    getValidatedDateFilter(),
  );

  const contentToPromoteTableData = useContentToPromoteTable(contentToPromote);
  const topicsToWriteAboutTableData =
    useTopicsToWriteAboutTable(topicsToWriteAbout);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        headerTitle: 'Radar',
      }),
    );
    dispatch(
      setActiveLinkKey({
        activeLinkKey: 'Radar',
      }),
    );
  }, []);

  const handleGuidedTourModalClose = useCallback(() => {
    dispatch(
      setStartModalOpened({
        opened: false,
      }),
    );
  }, [dispatch, setStartModalOpened]);

  const guidedTourModalItems = useMemo(() => {
    return GUIDED_TOUR_STEPS.map((step, index) => {
      const stepProgress = step.components.map((component) =>
        component.reduxKeys.every(
          (key) => profileState.guide[key]?.showGuide === false,
        )
          ? 50
          : 0,
      );
      const stepProgressSum = stepProgress.reduce((acc, curr) => acc + curr, 0);

      const handleClick = (component) => {
        if (component.key === 'tags') {
          setGuideModalOpened(true);
        } else {
          navigate(component.link);
        }
      };

      return (
        <Stack
          key={step.key}
          spacing={4}
          className={classes.guidedModalContainer}
        >
          <Group spacing={8}>
            <CustomText
              variant="h5"
              sx={(theme) => ({ color: theme.colors.red[0] })}
            >
              Step {index + 1}
            </CustomText>
            {stepProgressSum && (
              <>
                <Box className={classes.guidedModalStepCircle} />
                <CustomText
                  variant="h6"
                  sx={(theme) => ({
                    color: theme.colors.red[0],
                    fontWeight: 500,
                  })}
                >
                  {stepProgressSum}% complete
                </CustomText>
              </>
            )}
          </Group>
          <Accordion.Item value={step.key}>
            <Accordion.Control>
              <CustomText variant="h5">
                Learn how to see insights and statistics about your content
              </CustomText>
            </Accordion.Control>
            <Accordion.Panel>
              {step.components.map((component, index) => (
                <Flex
                  key={component.key}
                  justify="space-between"
                  align="center"
                  className={classes.guidedModalLinkGroup}
                  onClick={() => handleClick(component)}
                >
                  <Group spacing={8} sx={{ flexWrap: 'nowrap' }}>
                    {stepProgress[index] ? (
                      <Box className={classes.tickContainer}>
                        <TickCircleIcon />
                      </Box>
                    ) : (
                      <Box className={classes.blankCircle} />
                    )}
                    <CustomText variant="body2" sx={{ maxWidth: '90%' }}>
                      {component.title}
                    </CustomText>
                  </Group>
                  <ArrowLeftIcon style={{ transform: 'rotate(180deg)' }} />
                </Flex>
              ))}
            </Accordion.Panel>
          </Accordion.Item>
        </Stack>
      );
    });
  }, [profileState, rem]);

  logger.debug('DashboardPage render');

  return (
    <Box maw={1144} mx="auto">
      <Flex justify="space-between" align="center">
        <CustomTitle order={1}>Hey {user.nickname}, welcome back!</CustomTitle>
        <Box mb={-16}>
          <TimePeriodSelection
            updateDateFilter={updateDateParam}
            initialFetchPeriodKey={urlDateParams?.dateKey}
          />
        </Box>
      </Flex>
      <Flex justify="center" gap={24} mt={14}>
        <Stack spacing={8}>
          <CustomText variant="h4">Overview</CustomText>
          <Group spacing={19} sx={{ flexWrap: 'nowrap' }}>
            <Group spacing={24}>
              <BigStatsContainer
                loading={newPersonalizedEngagementsScoreLoading}
                percentValue={newPersonalizedEngagementsScore?.changePercentage}
                title={newPersonalizedEngagementsScore?.engagements}
                description="New personalized engagements"
                height={246}
              />
              <BigStatsContainer
                loading={personalizationScoreLoading}
                showWithoutValue
                unchanged={
                  personalizationScore?.score ===
                  personalizationScore?.changeScore
                }
                positive={
                  personalizationScore?.score < personalizationScore?.changeScore
                }
                title={personalizationScore?.score}
                description="Personalization score"
                height={246}
              >
                <CustomText
                  variant="body2"
                  onClick={() => setImproveScoreModal(true)}
                  sx={(theme) => ({
                    color: theme.colors.red[1],
                    cursor: 'pointer',
                  })}
                >
                  How can I improve my score?
                </CustomText>
              </BigStatsContainer>
            </Group>
            <Group spacing={24}>
              <BigStatsContainer
                loading={personalizedContentEngagementRateLoading}
                percentValue={
                  personalizedContentEngagementRate?.changePercentage
                }
                title={`${Math.round(
                  personalizedContentEngagementRate?.engagementRate,
                )}%`}
                description={
                  <>
                    Personalized content <br /> engagement rate
                  </>
                }
                height={246}
              >
                <CustomText
                  variant="h6"
                  sx={(theme) => ({ color: theme.colors.gray[4] })}
                >
                  vs. non- personalized
                </CustomText>
              </BigStatsContainer>
              <BigStatsContainer
                loading={contentInfluencedRevenueLoading}
                percentValue={contentInfluencedRevenue?.changePercentage}
                title={`$${numberFormatter(
                  contentInfluencedRevenue?.revenue,
                  1,
                )}`}
                description="Content Influenced Revenue"
                height={246}
              />
            </Group>
          </Group>
        </Stack>
        <RadarChart
          data={incidenceAndEngagement}
          loading={incidenceAndEngagementLoading}
        />
      </Flex>
      <Center>
        <Group spacing={24} mt={32} sx={{ flexWrap: 'nowrap' }}>
          <Stack spacing={8}>
            <CustomText variant="h4">
              Recommended topics to write about
            </CustomText>
            <TableWithSorting
              errorText="No topics found"
              tableData={topicsToWriteAboutTableData}
              loading={topicsToWriteAboutLoading}
              withScroll
              wrapperClassName={classes.tableWrapper}
              wrapperScrollClassName={classes.wrapperScrollClassName}
              rowClassName={classes.tableRow}
              hoverOptions={{
                navigateTo: '/topics/detail/',
              }}
            />
          </Stack>
          <Stack spacing={8}>
            <CustomText variant="h4">Content to promote</CustomText>
            <TableWithSorting
              errorText="No content found"
              tableData={contentToPromoteTableData}
              loading={contentToPromoteLoading}
              withScroll
              wrapperClassName={classes.tableWrapper}
              wrapperScrollClassName={classes.wrapperScrollClassName}
              rowClassName={classes.tableRow}
              hoverOptions={{
                navigateTo: '/contents/detail/',
              }}
            />
          </Stack>
        </Group>
      </Center>
      <BasicModal
        opened={improveScoreModal}
        onClose={() => setImproveScoreModal(false)}
        centered
        withCloseButton={false}
        classNames={{
          content: classes.modalContent,
          body: classes.modaBody,
        }}
      >
        <Box>
          <CustomTitle
            order={1}
            sx={{ fontSize: rem(20), marginBottom: rem(16) }}
          >
            How can I improve my score?
          </CustomTitle>
          <Stack spacing={24} mb={24}>
            <Stack spacing={8}>
              <CustomText variant="body2">
                Personalization is most powerful when you’re consistently
                sharing content that is uniquely chosen for each and every of
                your contacts.
                <br />
                <br />
                If you have an <span className={classes.bold}>A</span>, that
                means your contacts have engaged with personalized content in
                the last week. Good job!
                <br />
                <br />
                If you have a <span className={classes.bold}>B</span>, your
                contacts have engaged with personalized content in the last
                month. You’ll want to find ways, like in newsletters and landing
                pages, to increase how many opportunities you have to truly
                connect with your contacts.
                <br />
                <br />
                If you have an <span className={classes.bold}>F</span>, don’t
                despair! Try using some recommended content in an email this
                week or create a personalized content hub for your customers.
              </CustomText>
            </Stack>
          </Stack>
          <MainButton
            onClick={() => setImproveScoreModal(false)}
            sx={{
              width: rem(203),
              margin: '0 auto',
            }}
          >
            Got it
          </MainButton>
        </Box>
      </BasicModal>
      {/* Guided Tour Components */}
      <GuidedTourModal
        opened={profileState?.startModalOpened}
        onClose={handleGuidedTourModalClose}
        title={
          <Stack spacing={4}>
            <CustomTitle order={1} sx={{ fontSize: rem(20) }}>
              Getting started
            </CustomTitle>
            <CustomText variant="body2">
              Follow the steps below to get started.
            </CustomText>
          </Stack>
        }
      >
        <Accordion
          chevron={
            <ChevronRightIcon
              style={{ marginBottom: rem(12), width: rem(20), height: rem(20) }}
            />
          }
          classNames={{
            control: classes.guidedModalControl,
            content: classes.guidedModalContent,
            label: classes.guidedModalLabel,
            item: classes.guidedModalItem,
          }}
          styles={{
            chevron: {
              '&[data-rotate]': {
                transform: 'rotate(90deg)',
              },
            },
          }}
        >
          {guidedTourModalItems}
        </Accordion>
      </GuidedTourModal>
      <GuideModalGroup
        opened={guideModalOpened}
        setOpened={setGuideModalOpened}
      />
    </Box>
  );
}

export default DashboardPage;
