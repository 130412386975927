import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useGuideModal from '../../../hooks/useGuideModal';
import { isObjectsEqual, serializeParams } from '../../../utils';
import { DEFAULT_URL_PARAMS, TABS } from '../constant';

const useTabHandler = (
  urlParams,
  updateUrlParams,
  setActiveTab,
  contactsFilteringParamsPreserved,
  accountsFilteringParamsPreserved,
  setPreventRefetch,
  fetchContacts,
  accountsLoading,
  contactsContent,
) => {
  const { stepIndex } = useSelector((state) => state.profile.guide.contacts);
  const { handleGuideModalClick } = useGuideModal('contacts', stepIndex);

  const handleTabChange = useCallback(
    (value) => {
      if (value === TABS[1] && stepIndex === 4) {
        handleGuideModalClick(stepIndex + 1);
      }
      setActiveTab(value);
      if (
        !isObjectsEqual(
          accountsFilteringParamsPreserved || DEFAULT_URL_PARAMS,
          contactsFilteringParamsPreserved || DEFAULT_URL_PARAMS,
        ) &&
        !accountsLoading
      ) {
        setPreventRefetch(true);
      }

      if (value === TABS[1]) {
        updateUrlParams({
          ...(accountsFilteringParamsPreserved || DEFAULT_URL_PARAMS),
          tab: [TABS[1]],
        });
      } else {
        if (!contactsContent?.length) {
          fetchContacts(false, serializeParams(contactsFilteringParamsPreserved || DEFAULT_URL_PARAMS), null, true);
        }
        updateUrlParams(contactsFilteringParamsPreserved || DEFAULT_URL_PARAMS);
      }
    },
    [
      accountsLoading,
      fetchContacts,
      urlParams,
      contactsFilteringParamsPreserved,
      accountsFilteringParamsPreserved,
      contactsContent,
      stepIndex,
      handleGuideModalClick,
    ],
  );

  return handleTabChange;
};

export default useTabHandler;
