// utils/logger.js
import { settings } from '../config';

const isProduction = settings.environment?.toLowerCase() === 'production';

const noop = () => {};

const logger = {
  info: isProduction ? noop : console.info.bind(console),
  warn: isProduction ? noop : console.warn.bind(console),
  error: console.error.bind(console), // Always allow errors to be logged
  debug: isProduction ? noop : console.debug.bind(console),
};

export default logger;
