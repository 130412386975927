import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeLinkKey: null,
  headerTitle: 'Radar',
  loginPageIndex: 0,
  signupPageIndex: 0,
  userWebsite: '',
  userSections: [],
  userCompetitors: [],
  competitorIndex: 0,
  snackbarOptions: {},
  loadingOverlay: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setActiveLinkKey: (state, { payload: { activeLinkKey } }) => {
      state.activeLinkKey = activeLinkKey;
    },
    setHeaderTitle: (state, { payload: { headerTitle } }) => {
      state.headerTitle = headerTitle;
    },
    setLoginPageIndex: (state, { payload: { pageIndex } }) => {
      state.loginPageIndex = pageIndex;
    },
    setSignupPageIndex: (state, { payload: { pageIndex } }) => {
      state.signupPageIndex = pageIndex;
    },
    setUserWebsite: (state, { payload: { website } }) => {
      state.userWebsite = website;
    },
    setUserSections: (state, { payload: { sections } }) => {
      state.userSections = sections;
    },
    setUserCompetitors: (state, { payload: { competitors } }) => {
      state.userCompetitors = competitors;
    },
    setCompetitorIndex: (state, { payload: { index } }) => {
      state.competitorIndex = index;
    },
    setSnackbar: (state, { payload }) => {
      state.snackbarOptions = payload;
    },
    setLoadingOverlay: (state, { payload }) => {
      state.loadingOverlay = payload.loadingOverlay
    },
    resetLayoutState: () => {
      return initialState;
    },
  },
});

export const {
  setActiveLinkKey,
  setHeaderTitle,
  setLoginPageIndex,
  setSignupPageIndex,
  setUserWebsite,
  setUserSections,
  setUserCompetitors,
  setCompetitorIndex,
  setSnackbar,
  setLoadingOverlay,
  resetLayoutState,
} = layoutSlice.actions;

export default layoutSlice.reducer;
