import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useGuideModal from '../../../hooks/useGuideModal';
import { changeColorOpacity } from '../../../utils';
import { AUDIENCE_CHART_OPTIONS, DEFAULT_COLORS } from '../constants';

const useActiveRelevance = (updateFilter, filteringParams) => {
  const [activeRelevance, setActiveRelevence] = useState(null);
  const [audienceChartColors, setAudienceChartColors] = useState(
    DEFAULT_COLORS,
  );
  const { stepIndex } = useSelector((state) => state.profile.guide.topics);
  const { handleGuideModalClick } = useGuideModal('topics', stepIndex);

  const updateActiveRelevance = (optionIndex) => {
    if (optionIndex === null) {
      setActiveRelevence(null);
      setAudienceChartColors((prevState) =>
        prevState.map((color) => changeColorOpacity(color, '1')),
      );
      updateFilter('activeRelevance', [], true);
      return;
    }
    updateFilter(
      'activeRelevance',
      [`Audience Relevance - ${AUDIENCE_CHART_OPTIONS[optionIndex]}`],
      true,
    );
    setActiveRelevence(AUDIENCE_CHART_OPTIONS[optionIndex]);
    setAudienceChartColors((prevState) =>
      prevState.map((color, index) =>
        changeColorOpacity(color, index === optionIndex ? '1' : '0.3'),
      ),
    );

    if (AUDIENCE_CHART_OPTIONS[optionIndex]) {
      handleGuideModalClick(stepIndex === 2 ? stepIndex + 1 : stepIndex);
    }
  };

  useEffect(() => {
    if (!filteringParams || activeRelevance !== null) return;

    setActiveRelevence((filteringParams?.activeRelevance || [])[0]);
  }, [filteringParams]);

  useEffect(() => {
    if (!filteringParams) return;

    const relevanceFilter = filteringParams.activeRelevance;

    if (relevanceFilter.length) {
      let relevanceOption = relevanceFilter[0].split(' ');
      relevanceOption = relevanceOption[relevanceOption.length - 1];
      const relevanceOptionIndex = AUDIENCE_CHART_OPTIONS.findIndex(
        (el) => el === relevanceOption,
      );

      setActiveRelevence(relevanceOption);
      setAudienceChartColors((prevState) =>
        prevState.map((color, index) =>
          changeColorOpacity(
            color,
            index === relevanceOptionIndex ? '1' : '0.3',
          ),
        ),
      );
    } else {
      setActiveRelevence(null);
      setAudienceChartColors((prevState) =>
        prevState.map((color) => changeColorOpacity(color, '1')),
      );
    }
  }, [filteringParams]);

  return [activeRelevance, updateActiveRelevance, audienceChartColors];
};
export default useActiveRelevance;
