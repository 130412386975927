import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  sectionContainer: {
    paddingBottom: 24,
    marginBottom: 24,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  selectedCompetitorsHeading: {
    marginBottom: 12,
    marginTop: 24,
    paddingTop: 24,
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },
  modalBody: {
    background: theme.colors.white[2],
  },
  outlinedOrgSettingsButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: rem(38),
    padding: '8px 16px',
    border: `1px solid ${theme.colors.black[0]}`,
    borderRadius: '100px',
  },
}));
