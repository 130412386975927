import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    height: '100%',
    background: theme.colors.white[2],
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: 20,
    padding: 24,
  },
  hiddenWrapper: {
    padding: 0,
    border: 'none',
  },
  outlineRed: {
    border: `1px solid ${theme.colors.red[0]}`,
  },
  errorIconWrapper: {
    position: 'absolute',
    top: rem(12),
    right: rem(14),
    width: rem(20),
    cursor: 'pointer',

    '& svg': {
      fill: theme.colors.red[0],
    },
  },
  editButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.gray[4],
    textDecoration: 'underline',
  },
  backButton: {
    width: rem(203),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.colors.black[0]}`,
    borderRadius: rem(100),
    padding: rem(6),

    '&:hover': {
      borderColor: theme.colors.black[0],
    },
  },
  modalInner: {
    left: 0,
  },
  modalContent: {
    borderRadius: rem(20),
    minWidth: rem(503),
    overflow: 'hidden',
  },
  modaBody: {
    display: 'flex',
    padding: rem(24),
  },
  selectedModalButton: {
    position: 'relative',
    borderColor: theme.colors.black[0],

    '&:hover': {
      borderColor: '#646cff',
    },
  },
}));
