import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  input: {
    border: "none",
    boxShadow: "0px 4px 24px 0px rgba(59, 93, 108, 0.10)",
    padding: `0 ${rem(24)}`,
    zIndex: 100,

    "&::placeholder": {
        fontSize: rem(16),
    }
  },
}));
