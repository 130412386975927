import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 8,
    background: theme.colors.red[0],
    padding: `${rem(8)} ${rem(24)} ${rem(8)} ${rem(16)}`,
    borderRadius: '100px',

    '& svg': {
      color: theme.colors.white[2],
    },

    '&:hover': {
      background: theme.colors.red[1],
    },

    '&:focus': {
      borderColor: theme.colors.red[1],
      outline: 'none',
    },
  },
  buttonDisabled: {
    cursor: 'not-allowed !important',
    background: theme.colors.gray[5],

    '&:hover': {
      background: theme.colors.gray[5],
    },
  },
  noIcon: {
    padding: `${rem(8)} ${rem(24)}`,
  },
  reversed: {
    flexDirection: 'row-reverse',
    padding: `${rem(8)} ${rem(16)} ${rem(8)} ${rem(24)}`,
  },
  smallPadding: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  hugePadding: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  secondary: {
    background: theme.colors.gray[3],
    zIndex: 10,

    '&:hover': {
      background: theme.colors.gray[3],
    },
  },
}));
