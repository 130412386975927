import logger from '../utils/logger';

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
window.global = window;

function init(accountId) {
  // Set Appcues settings
  window.AppcuesSettings = { enableURLDetection: true };

  // Create a script element
  const script = document.createElement('script');
  script.src = `//fast.appcues.com/${accountId}.js`;
  script.async = true;

  // Append the script to the body
  document.body.appendChild(script);
}

// Function to initialize Appcues for a specific user
function setUser(details) {
  if (window.Appcues) {
    window.Appcues.identify(
      details.userId,
      {
      // recommended (optional) properties

      // createdAt: 1566932390, // Unix timestamp of user signup date
      // purchasedAt: 1566932395, // Unix timestamp of account purchase date (leave null if empty)
      // planTier: "Standard", // Current user’s plan tier
      // role: "Admin", // Current user’s role or permissions
      // accountId: "1234", // Current user's account ID
      // firstName: "John", // current user's first name
      name: details.userName,
      organization: details.orgId,

      // additional suggestions

      // companyName: "Acme Corp", // Current user’s company name
      email: details.userEmail,
      // location: "90210", // a zipcode, state, or country enables location-based targeting
      // version: "2.0", // users on different versions may need to see different content
      // language: "spanish", // for multi-language applications
      // renewalDate: 1577880288 // to remind users to renew
      }
    );
  } else {
    logger.error('Appcues is not initialized yet');
  }
}

const appCues = {
  init,
  setUser
};

export { appCues };
