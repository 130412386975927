import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  chartContainer: {
    width: rem(561),
    height: rem(357),
    maxHeight: rem(357),
    padding: rem(24),
    marginTop: rem(16),
  },
  statsContainer: {
    height: '100%',
    maxWidth: rem(80),
    padding: `${rem(7)} ${rem(11)}`,
  },
  hidden: {
    overflow: "hidden"
  }
}));
