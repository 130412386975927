import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  sectionContainerBottom: {
    paddingBottom: 24,
    marginBottom: 24,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  firstInputClassName: {
    width: `${rem(180)} !important`,
  },
}));
