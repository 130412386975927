import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  menuRoot: {
    position: 'relative',
  },
  menuTarget: {
    width: rem(265),
    height: rem(34),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: 8,
    paddingLeft: 16,
    paddingRight: 16,
    cursor: 'pointer',
  },
  menuDropdown: {
    position: 'absolute',
    width: `${rem(265)} !important`,
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    background: theme.colors.white[2],
    borderTop: 'none',
    borderRadius: '0 0 8px 8px',
    padding: `${rem(8)} 0 0 0`,
    marginTop: rem(-5),
    maxHeight: rem(450),
    overflow: 'hidden',
    zIndex: 10,

    '& button': {
      paddingLeft: rem(16),
    },
  },
  menuDropdownOverflowScroll: {
    overflow: 'auto',
  },
}));
