import React, { useMemo } from 'react';
import { Box, Grid, rem } from '@mantine/core';
import CustomText from '../../Typography/CustomText/CustomText';
// import googleAnalytics from '../../../assets/icons/google-analytics-logo.png';
import hubspotLogo from '../../../assets/icons/hubspot-logo.png';
import salesforceLogo from '../../../assets/icons/salesforce-logo.png';
import IntegrationWrapper from './IntegrationWrapper/IntegrationWrapper';
import useRadarApi from '../../../hooks/useRadarApi';
import { useCallback } from 'react';
import useIntegrations from '../../../hooks/useIntegrations';
import ContactListsMultiSelect from './components/ContactListsMultiSelect';
import useContactLists from './hooks/useContactLists';
import { settings } from '../../../config';
import HubspotIntegration from './components/HubspotIntegration';
import useURLParams from '../../../hooks/useURLParams';

const DEFAULT_URL_PARAMS = {
  listIds: [],
};
const PARAMS_KEYS = ['listIds'];

function IntegrationsTab() {
  const { getPardotAuthURL, getPardotContactLists } = useRadarApi();
  const { urlParams, updateUrlParams } = useURLParams(PARAMS_KEYS, {
    ...DEFAULT_URL_PARAMS,
  });

  // eslint-disable-next-line no-unused-vars
  const { pardotDetails, hubspotDetails, loading, googleAnalyticsDetails } =
    useIntegrations();

  const {
    contactLists: pardotContactLists,
    selectedLists: selectedPardotLists,
    setSelectedLists: setSelectedPardotLists,
    patchContactLists: patchPardotContactLists,
    selectAll: selectAllPardot,
    setSelectAll: setSelectAllPardot,
    showError: showPardotError,
  } = useContactLists(
    pardotDetails?.contactLists,
    getPardotContactLists,
    pardotDetails?.syncAllContacts,
  );

  const editPardotContactLists = useCallback(() => {
    patchPardotContactLists(pardotDetails?.id);
    return true;
  }, [
    patchPardotContactLists,
    pardotDetails,
    selectedPardotLists,
    selectAllPardot,
  ]);

  const handlePardotConnection = useCallback(
    async (property) => {
      const url = await getPardotAuthURL({
        redirectPath: 'settings?tab=integrations',
        businessUnitId: property,
      });
      window.location.href = url;
    },
    [getPardotAuthURL],
  );

  // const handleGoogleAnalyticsConnection = useCallback(
  //   async (property) => {
  //     const url = await getGoogleAnalyticsAuthURL({
  //       redirectPath: 'settings?tab=integrations',
  //       propertyId: property,
  //     });
  //     window.location.href = url;
  //   },
  //   [getGoogleAnalyticsAuthURL],
  // );

  const integrationAllowed = useMemo(() => {
    if (hubspotDetails?.connected && !pardotDetails?.connected) {
      return false;
    }
    return true;
  }, [settings, hubspotDetails, pardotDetails]);

  return (
    <Grid gutter={24}>
      <Grid.Col span={12}>
        {/* <IntegrationWrapper
          image={
            <img
              src={googleAnalytics}
              style={{ width: rem(115), height: rem(40) }}
            />
          }
          onConnect={handleGoogleAnalyticsConnection}
          connected={googleAnalyticsDetails?.connected}
          propertyKey="propertyId"
          property={googleAnalyticsDetails}
          placeholder="Property ID"
          disabledText={
            !settings.integrations.googleAnalytics ? 'Connect' : null
          }
        >
          <Stack spacing={8}>
            <CustomText variant="h3" sx={{ textAlign: 'center' }}>
              Google analytics
            </CustomText>
            <CustomText
              variant="body2"
              sx={(theme) => ({
                color: theme.colors.gray[4],
                textAlign: 'center',
              })}
            >
              By connecting Google Analytics, Air Traffic Control will know how
              well all your content is performing. That helps with making better
              recommendations to your customers as well as delivering valuable
              insights on all your content and how it is impacting revenue.
            </CustomText>
          </Stack>
        </IntegrationWrapper> */}
      </Grid.Col>
      <Grid.Col span={6}>
        <HubspotIntegration
          image={<img src={hubspotLogo} width={142} height={62} />}
          customText="Connects with your content and contacts"
          listIds={urlParams?.listIds}
          urlParams={urlParams}
          updateUrlParams={updateUrlParams}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <IntegrationWrapper
          image={<img src={salesforceLogo} height={70} />}
          onConnect={handlePardotConnection}
          connected={pardotDetails?.connected}
          onEdit={editPardotContactLists}
          placeholder="Business unit ID"
          propertyKey="businessUnitId"
          property={pardotDetails}
          loading={loading}
          disabledText={
            !settings.integrations.pardot || !integrationAllowed
              ? 'Connect'
              : null
          }
          customInputComponent={
            <Box mt={rem(16)}>
              <ContactListsMultiSelect
                onChange={setSelectedPardotLists}
                data={pardotContactLists}
                value={selectedPardotLists}
                showError={showPardotError && 'Select at least one list'}
                selectAll={selectAllPardot}
                setSelectAll={setSelectAllPardot}
              />
            </Box>
          }
          useCustomComponentAsExtra
        >
          <CustomText
            variant="body2"
            sx={(theme) => ({
              color: theme.colors.gray[4],
              textAlign: 'center',
            })}
          >
            Pulls in sales and revenue data
          </CustomText>
        </IntegrationWrapper>
      </Grid.Col>
    </Grid>
  );
}

export default IntegrationsTab;
