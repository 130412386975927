import { useCallback } from "react"
import useRadarApi from "../../../hooks/useRadarApi"

const useEnrichTopic = (topicId) => {
    const { enrichTopic } = useRadarApi()

    const enrichAction = useCallback(() => {
        enrichTopic({
            topic: topicId
        })
    }, [topicId, enrichTopic])

    return enrichAction
}

export default useEnrichTopic