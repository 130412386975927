import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  segmentRoot: {
    height: rem(40),
    borderRadius: 100,
  },
  indicator: {
    height: `${rem(39)} !important`,
  },
  loadingOverlayContainer: {
    "& svg": {
      stroke: "#228be6"
    }
  }
}));
