import React, { useMemo } from 'react';
import { Box, Flex, Group, rem, Stack } from '@mantine/core';
import DoughnutChart from '../../../components/charts/DoughnutChart';
import TitleWithTooltip from '../../../components/TitleWithTooltip/TitleWithTooltip';
import CustomText from '../../../components/Typography/CustomText/CustomText';
import { convertRemToPixels } from '../../../utils';
import { useCallback } from 'react';
import ChartLabels from './ChartLabels';
import { useStyle } from '../TopicsPage.style';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import useGuideModal from '../../../hooks/useGuideModal';
import { AUDIENCE_CHART_OPTIONS, DEFAULT_COLORS_REVERSED } from '../constants';
import { convertToLowerCase } from '../utils';
import SkeletonContainer from '../../../components/SkeletonContainer';

function AudienceRelevanceChart({
  audienceChartColors,
  audienceChartData,
  activeRelevance,
  updateActiveRelevance,
  loading,
}) {
  const audienceChartRadius = useMemo(() => convertRemToPixels(12.125), []);
  const audienceContainerRef = useRef(null);
  const { classes } = useStyle();
  const { stepIndex } = useSelector((state) => state.profile.guide.topics);
  const { handleGuideModalClick } = useGuideModal('topics', stepIndex);

  const generateAudiencecTextLabel = useCallback(
    (colorIndex, label, className) => {
      return (
        <Group key={label} className={`${className || label}-label`}>
          <Box
            sx={{
              width: rem(10),
              height: rem(10),
              borderRadius: '50%',
              background: DEFAULT_COLORS_REVERSED[colorIndex],
            }}
          />
          <CustomText variant="h6" sx={{ fontWeight: 600 }}>
            {label}
          </CustomText>
        </Group>
      );
    },
    [audienceChartColors],
  );

  const generateTextLabels = useCallback(() => {
    const elements = [];
    const reversedOptions = [
      ...AUDIENCE_CHART_OPTIONS.map((el) => convertToLowerCase(el)),
    ].reverse();

    reversedOptions.forEach((option, index) => {
      elements.push(
        <>
          {generateAudiencecTextLabel(index, `${option} (${index})`, option)}
        </>,
      );
    });

    return elements;
  });

  const generateLabels = () => {
    const elements = [];
    const totalChartValue = audienceChartData.reduce((acc, curr) => acc + curr);
    const labelValuesArray = audienceChartData.map(
      (el) => Math.round(el / (totalChartValue / 100)) || 0,
    );

    audienceChartData.forEach((_, index) => {
      const labelValue = labelValuesArray[index];
      if (labelValue === 0) return;
      const labelAngle =
        ((360 / 100) *
          (labelValuesArray
            .slice(0, index)
            .reduce((acc, curr) => acc + curr, 0) +
            labelValue / 2) -
          90) *
        (Math.PI / 180);

      elements.push(
        <ChartLabels
          key={index}
          isActive={
            activeRelevance
              ? AUDIENCE_CHART_OPTIONS[index] === activeRelevance
              : true
          }
          label={labelValue}
          x={audienceChartRadius + audienceChartRadius * Math.cos(labelAngle)}
          y={audienceChartRadius + audienceChartRadius * Math.sin(labelAngle)}
          angle={labelAngle + Math.PI / 2}
        />,
      );
    });

    return elements;
  };

  useEffect(() => {
    const handleAudienceChartClick = (e) => {
      if (e.target.tagName !== 'CANVAS') {
        updateActiveRelevance(null);
      }
    };

    audienceContainerRef.current.addEventListener(
      'click',
      handleAudienceChartClick,
    );

    return () => {
      if (!audienceContainerRef.current) return;
      audienceContainerRef.current.removeEventListener(
        'click',
        handleAudienceChartClick,
      );
    };
  }, [audienceChartColors]);

  return (
    <Stack spacing={8}>
      <Flex w="35rem" justify="space-between" align="center" px={4}>
        <TitleWithTooltip
          title="Audience Relevance"
          active={stepIndex === 1}
          onClose={() => handleGuideModalClick(stepIndex === 1 ? 2 : stepIndex)}
          modalTitle="What is Audience Relevance?"
          modalContent={
            <>
              <Stack>
                <CustomText variant="body2">
                  The audience relevance chart indicates how relevant your
                  content is to your audience's interests.
                </CustomText>
                <Stack spacing={10}>
                  {generateAudiencecTextLabel(0, 'None (0)')}
                  <CustomText variant="body2">
                    The red section of your audience relevance ring indicates
                    the % of contacts for whom you have no recommended content.
                  </CustomText>
                </Stack>
                <Stack spacing={10}>
                  {generateAudiencecTextLabel(1, 'Low (1)')}
                  <CustomText variant="body2">
                    The yellow section indicates the % of contacts for whom you
                    have 1 piece of recommended content.
                  </CustomText>
                </Stack>
                <Stack spacing={10}>
                  {generateAudiencecTextLabel(2, 'Medium (2)')}
                  <CustomText variant="body2">
                    The orange section indicates the % of contacts for whom you
                    have 2 pieces of recommended content.
                  </CustomText>
                </Stack>
                <Stack spacing={10}>
                  {generateAudiencecTextLabel(3, 'High ( 3+)')}
                  <CustomText variant="body2">
                    The green section indicates the % of contacts for whom you 3
                    or more pieces of recommended content.
                  </CustomText>
                </Stack>
              </Stack>
            </>
          }
        />
        <CustomText variant="h6">Click on section to filter results</CustomText>
      </Flex>
      <Box className={classes.chartContainer} ref={audienceContainerRef}>
        <Box className={classes.chartOuterBorder}>
          {generateLabels()}
          <Box h="22.5rem" w="22.5rem" sx={{ position: 'relative' }}>
            <Box className={classes.chartInnerBorder}>
            <Box
            className="guided-tour__anchor"
            sx={{
              position: 'absolute',
              left: rem(-10),
              top: 0,
              height: rem(5),
              width: rem(5),
              zIndex: 1000,
            }}
          />
            </Box>
            <CustomText variant="h5" className={classes.chartInnerText}>
              {loading ? "Loading" : "Your Audience"}
            </CustomText>
            <DoughnutChart
              labels={AUDIENCE_CHART_OPTIONS}
              chartData={audienceChartData}
              chartColors={audienceChartColors}
              onSegmentSelection={updateActiveRelevance}
            />
            {loading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <SkeletonContainer radius="50%" height={360} width={360} />
                <Box className={classes.loadingInner} />
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Stack spacing={8}>
            <CustomText
              variant="h6"
              sx={{ fontWeight: 600, textAlign: 'center' }}
            >
              Relevance (recommended content items):
            </CustomText>
            <Group>{generateTextLabels()}</Group>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}

export default AudienceRelevanceChart;
