import { serializeParams } from "../../utils";
import { LIST_KEY } from "./constant";

export const convertRangeParam = (key, urlParams) => {
  if (!urlParams[key].length) return {};
  return {
    [key]: [],
    [`filter[${key}][min]`]: urlParams[key][0],
    [`filter[${key}][max]`]: urlParams[key][1],
  };
};

export const isUrlEmpty = (urlParams) => {
    if (!urlParams) return true;
    return Object.keys(serializeParams(urlParams)).reduce(
      (acc, curr) => acc && !curr.includes('filter'),
      true,
    );
}

export const getAllFilters = (urlParams) => {
    if (!urlParams) return [];
    const filterList = [];
    const ignoreList = ['sortBy', 'sortDirection'];
    const rangeKeys = ['insights', 'contacts'];

    Object.keys(urlParams).forEach((key) => {
      if (key === 'tab') return;
      else if(key === LIST_KEY && urlParams[key]?.length > 0) {
        filterList.push({
          key,
          value: urlParams[key]
        })
        return
      }
      if (urlParams[key].length > 0 && !ignoreList.includes(key)) {
        if (rangeKeys.includes(key)) {
          const insightsValue = `
            ${urlParams[key][0] ? urlParams[key][0] + ' ≤' : ''} ${
            key.charAt(0).toUpperCase() + key.slice(1)
          } ${urlParams[key][1] ? '≤ ' + urlParams[key][1] : ''}
          `;
          filterList.push({
            key,
            value: insightsValue,
          });
          return;
        } else {
          urlParams[key].forEach((item) => {
            filterList.push({
              key,
              value: item,
            });
          });
        }
      }
    });
    return filterList;
  };