import { Box, Flex, rem, Stack } from '@mantine/core';
import React, { useMemo } from 'react';
import EnrichmentAction from '../../../../components/BlurredLayout/actions/EnrichmentAction';
import BlurredLayout from '../../../../components/BlurredLayout/BlurredLayout';
import Container from '../../../../components/Container/Container';
import SkeletonContainer from '../../../../components/SkeletonContainer';
import CustomText from '../../../../components/Typography/CustomText/CustomText';
import { ReactComponent as QuestionMarkIcon } from '../../../../assets/icons/question-mark-icon.svg';
import PeopleAlsoAskItem from './PeopleAlsoAskItem';
import useEnrichTopic from '../../hooks/useEnrichTopic';
import { useParams } from 'react-router-dom';

function PeopleAlsoAsk({ topicPeopleAlsoAsk, loading }) {
  const { topicId } = useParams();
  const enrichAction = useEnrichTopic(topicId);

  const alsoAskQuestions = useMemo(() => {
    if (!topicPeopleAlsoAsk) return;

    return topicPeopleAlsoAsk.map((question, index) => (
      <PeopleAlsoAskItem
        key={index}
        question={question}
        isLast={index === topicPeopleAlsoAsk.length - 1}
      />
    ));
  }, [topicPeopleAlsoAsk, rem]);

  return (
    <Container
      sx={{
        minWidth: 'fit-content',
        maxHeight: rem(288),
        padding: rem(24),
      }}
    >
      <Flex mb={8} justify="space-between">
        <CustomText variant="h4">People also ask</CustomText>
        <QuestionMarkIcon style={{ width: rem(16), height: rem(16) }} />
      </Flex>
      <Box sx={{ position: 'relative' }}>
        {!topicPeopleAlsoAsk?.length && (
          <BlurredLayout>
            <EnrichmentAction
              onSuccess={enrichAction}
              credits={1}
              remainingKey="topicReportScore"
            />
          </BlurredLayout>
        )}
        <Box mih={rem(210)} miw={rem(315)}>
          {loading ? (
            <Stack spacing={21} pt={8}>
              <SkeletonContainer height={21} width={315} amount={5} />
            </Stack>
          ) : (
            <>{alsoAskQuestions}</>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default PeopleAlsoAsk;
