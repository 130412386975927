import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  inputIcon: {
    paddingLeft: rem(20),
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    borderColor: theme.colors.gray[3],
    borderRadius: 100,
    padding: `${rem(8)} ${rem(24)} ${rem(8)} calc(2.25rem + ${rem(8)}) !important`,
    minHeight: rem(40),

    "&:disabled": {
      background: theme.colors.white[3]
    }
  },
  inputRoot: {
    height: rem(40),
    maxWidth: '100%',
    display: 'flex',
    flexGrow: '2 !important',

    '& .mantine-Input-wrapper': {
      width: '100%',
    },
  },
}));
