import { Box, Flex, rem, Stack } from '@mantine/core';
import React from 'react';
import LineChartWithBackground from '../charts/LineChartWithBackground';
import Container from '../Container/Container';
import SkeletonContainer from '../SkeletonContainer';
import StatsDifference from '../TableWithSorting/components/StatsDifference/StatsDifference';
import CustomText from '../Typography/CustomText/CustomText';
import CustomTitle from '../Typography/CustomTitle/CustomTitle';
import { useStyle } from './StatsChart.style';
import { useMemo } from 'react';
import { getChartColors } from './untils';

function StatsChart({
  children,
  title,
  subtitle,
  statsValue,
  chartData,
  chartLabels,
  dateList,
  getTooltipLabel,
  loading,
  tooltipLabel,
  gap,
  containerClassName,
}) {
  const { classes, cx } = useStyle();

  const isInfinity = useMemo(() => statsValue == 'Infinity', [statsValue]);

  const positive = useMemo(() => {
    if (isInfinity) return true;
    return statsValue > 0;
  }, [statsValue]);

  const difference = useMemo(() => {
    if (isInfinity)
      return (
        <Flex mt={rem(-3)} justify="center" align="center">
          <CustomText variant="h2">∞</CustomText>
        </Flex>
      );
    return Math.abs(statsValue);
  }, [statsValue]);

  const modifiedData = useMemo(() => {
    return [
      {
        ...chartData[0],
        ...getChartColors(statsValue),
      },
    ];
  }, [chartData, statsValue]);

  return (
    <Container className={cx(classes.chartContainer, containerClassName)}>
      <Stack>
        <Flex justify="space-between" align="center" w="100%">
          <Stack spacing={8} w="100%">
            <Flex justify="space-between" align="center" gap={gap}>
              {loading ? (
                <Stack spacing={4} className={classes.hidden}>
                  <SkeletonContainer width={300} height={20} />
                  <SkeletonContainer width={500} height={20} />
                </Stack>
              ) : (
                <>
                  <CustomTitle order={0} sx={{ fontSize: rem(48) }}>
                    {title}
                  </CustomTitle>
                  <Box h={40}>
                    <StatsDifference
                      difference={difference}
                      positive={positive}
                      customClassName={classes.statsContainer}
                      textVariant="h5"
                      withoutPercentage={isInfinity}
                    />
                  </Box>
                  {children && <>{children}</>}
                </>
              )}
            </Flex>
            <CustomText variant="h4">{subtitle}</CustomText>
          </Stack>
        </Flex>
        <Box w="100%" h={rem(196)} className={classes.hidden}>
          {loading ? (
            <SkeletonContainer width={500} height={220} />
          ) : (
            <LineChartWithBackground
              chartData={modifiedData}
              labels={chartLabels}
              secondProperty={tooltipLabel}
              dateList={dateList}
              getTooltipLabel={getTooltipLabel}
            />
          )}
        </Box>
      </Stack>
    </Container>
  );
}

export default StatsChart;
