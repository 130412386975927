import React, { useMemo } from 'react';
import ImageContentWrapper from './ImageContentWrapper';
import websiteHeader from '../../../../assets/images/website-header.png';
import { Box, Group, rem, Stack } from '@mantine/core';
import CustomText from '../../../Typography/CustomText/CustomText';
import ContentCard from './ContentCard/ContentCard';
import { useStyle } from './ImageContent.style';

function ImageContentWebsite({ title, cards }) {
  const { classes } = useStyle()
  const cardList = useMemo(() => {
    return cards.map((card, index) => (
      <ContentCard
        key={`content-card-${index}`}
        card={card}
      />
    ));
  });

  return (
    <ImageContentWrapper>
      <Stack spacing={26} mt={-8}>
        <img src={websiteHeader} />
        <Stack spacing={7} pl={101}>
          <Box maw={490} mb={10}>
            <CustomText
              variant="bodyLarge"
              sx={{ fontSize: rem(35), lineHeight: rem(35), fontFamily: "Roboto" }}
            >
              {title}
            </CustomText>
          </Box>
          <Box className={classes.contentLine} sx={{ width: rem(485) }} />
          <Box className={classes.contentLine} sx={{ width: rem(400) }} />
          <Box className={classes.contentLine} sx={{ width: rem(400) }} />
        </Stack>
        <Group spacing={24} pl={101}>
            {cardList}
        </Group>
      </Stack>
    </ImageContentWrapper>
  );
}

export default ImageContentWebsite;
