import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  container: {
    width: rem(608),
    margin: '0 auto',
    paddingTop: rem(88)
  },
  image: {
    width: rem(670),
    height: rem(400),
  },
  videoContainer: {
    position: "relative"
  },
  watchVideoButton: {
    height: rem(56),
    position: "absolute",
    top: `calc(50% - ${rem(30)})`,
    left: `calc(50% + ${rem(30)})`,
    background: theme.colors.white[0],
    borderRadius: rem(12),
    padding: rem(16),
    transform: "translate(-50%, -50%)"
  },
  videoModal: {
    padding: "0 !important",
  },
  videoModalContent: {
    borderRadius: "0 !important"
  }
}));
