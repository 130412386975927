import React, { useState } from 'react';
import {
  Box, Flex, Group, Stack, TextInput, UnstyledButton,
} from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { isNotEmpty, useForm } from '@mantine/form';
import MainButton from '../../../buttons/buttons';
import CustomText from '../../../Typography/CustomText/CustomText';
import PlanList from '../../PlanList';
import CardForm from '../CardForm/CardForm';
import { useStyle } from './UpdatePlanPayment.style';
import { ReactComponent as LockIcon } from '../../../../assets/icons/lock-icon.svg';
import EditableInput from '../EditableInput/EditableInput';
import PriceContainer from '../PriceContainer';

function UpdatePlanPayment({
  title, planList, price, period, onSubmit,
}) {
  const [emailEditable, setEmailEditable] = useState(false);
  const { classes } = useStyle();
  const { user } = useAuth0();

  const form = useForm({
    initialValues: {
      name: '',
      cardNumber: '',
      expirationDate: '',
      cvv: '',
      addressLineFirst: '',
      addressLineSecond: '',
      addressLineThird: '',
      state: '',
      postCode: '',
      billingAddressLineFirst: '',
      billingAddressLineSecond: '',
      billingAddressLineThird: '',
      billingState: '',
      billingPostCode: '',
      taxId: '',
      email: user.email,
    },
    validate: {
      name: isNotEmpty('Name must not be empty'),
      cardNumber: isNotEmpty('Card Number must not be empty'),
      expirationDate: isNotEmpty('Expiration Date must not be empty'),
      cvv: isNotEmpty('CVV must not be empty'),
      addressLineFirst: isNotEmpty('Address line 1 must not be empty'),
      addressLineSecond: isNotEmpty('Address line 2 must not be empty'),
      state: isNotEmpty('State must not be empty'),
      postCode: isNotEmpty('Zip code must not be empty'),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
        <Group spacing={123} align="flex-start" pl={36} pr={60} pb={24}>
            <Stack spacing={50}>
                <CardForm
                    externalForm={form}
                    withAddress
                    withMethodSelection
                />
                <Stack spacing={8}>
                    <CustomText variant="h4">
                        Tax settings
                    </CustomText>
                    <Flex justify="space-between">
                        <CustomText variant="body2">
                            Registered VAT/GST/Tax ID
                        </CustomText>
                        <CustomText variant="caption1" sx={(theme) => ({ color: theme.colors.gray[4] })}>
                            optional
                        </CustomText>
                    </Flex>
                    <TextInput
                        classNames={{
                          input: classes.input,
                        }}
                        {...form.getInputProps('taxId')}
                    />
                    <Group spacing={32}>
                        <MainButton>
                            <CustomText variant="body2">
                                Save
                            </CustomText>
                        </MainButton>
                        <UnstyledButton>
                            <CustomText variant="body2" sx={(theme) => ({ color: theme.colors.gray[4] })}>
                                Cancel
                            </CustomText>
                        </UnstyledButton>
                    </Group>
                </Stack>
            </Stack>
            <Stack spacing={0}>
                <Stack spacing={21} w={455}>
                    <Stack spacing={8} mb={3}>
                        <CustomText variant="h4">
                            Purchase summary
                        </CustomText>
                        <CustomText variant="body2">
                            Billed Monthly
                        </CustomText>
                    </Stack>
                    <Flex justify="space-between">
                        <CustomText variant="h5">
                            {title}
                        </CustomText>
                        <PriceContainer price={price} period={period} />
                    </Flex>
                    <PlanList planList={planList} />
                    <Flex justify="space-between" pb={16} sx={(theem) => ({ borderBottom: `1px solid ${theem.colors.gray[3]}` })}>
                        <CustomText variant="h5">
                            Tax
                        </CustomText>
                        <PriceContainer price={23.45} period={period} />
                    </Flex>
                    <Flex justify="space-between" pb={16}>
                        <CustomText variant="h5">
                            Total
                        </CustomText>
                        <PriceContainer price={price + 23.45} period={period} />
                    </Flex>
                </Stack>
                <Stack spacing={24} mt={8}>
                    <MainButton type="submit" customClassName={classes.payButton}>
                        <Group spacing={8}>
                            <LockIcon />
                            <CustomText variant="body1" sx={{ color: '#000' }}>
                                Pay now
                            </CustomText>
                        </Group>
                    </MainButton>
                    <Box maw={455}>
                        <CustomText variant="caption1" sx={(theme) => ({ color: theme.colors.gray[4] })}>
                            By selecting “Pay now”, I understand that I am enrolling in a subscription
                            plan and I authorize Air Traffic Control to charge my payment method each
                            month at the then-current list price (plus applicable taxes) unless I cancel.
                            I can cancel anytime by going to my Payment page in my Account.
                        </CustomText>
                    </Box>
                    <Group spacing={24} align="flex-start">
                        <Stack spacing={2} miw={200}>
                            <CustomText variant="caption1" sx={(theme) => ({ color: theme.colors.gray[4] })}>
                                Your receipt will be sent to
                            </CustomText>
                            <EditableInput
                                disabled={!emailEditable}
                                {...form.getInputProps('email')}
                            />
                        </Stack>
                        <Box mt={-9}>
                            <UnstyledButton onClick={() => setEmailEditable((prevState) => !prevState)}>
                                <CustomText variant="caption1" sx={(theme) => ({ color: theme.colors.red[0] })}>
                                    {emailEditable ? 'Save' : 'Edit'}
                                </CustomText>
                            </UnstyledButton>
                        </Box>
                    </Group>
                </Stack>
            </Stack>
        </Group>
    </form>
  );
}

export default UpdatePlanPayment;
