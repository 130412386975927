import { useCallback, useMemo, useState } from 'react';
import {
  DEFAULT_ACCOUNTS_FILTER_OPTIONS,
  DEFAULT_CONTACTS_FILTER_OPTIONS,
  TABS,
} from '../constant';

const useFilterOptions = (activeTab) => {
  const [contactsFilterOptions, setContactsFilterOptions] = useState(
    DEFAULT_CONTACTS_FILTER_OPTIONS,
  );
  const [accountsFilterOptions, setAccountsFilterOptions] = useState(
    DEFAULT_ACCOUNTS_FILTER_OPTIONS,
  );

  const filteringOptions = useMemo(
    () =>
      activeTab === TABS[0] ? contactsFilterOptions : accountsFilterOptions,
    [activeTab, accountsFilterOptions, contactsFilterOptions],
  );

  const updateFilterOptions = useCallback(
    (options, updateFunc, contentType) => {
      const filteringOptionsBasedOnType =
        contentType === 'accounts'
          ? accountsFilterOptions
          : contactsFilterOptions;
      const newFilterOptions = [...filteringOptionsBasedOnType];
      Object.keys(options).forEach((optionKey) => {
        const currentFilterOptionIndex = filteringOptionsBasedOnType.findIndex(
          (el) => el.distinctValuesKey === optionKey,
        );
        if (currentFilterOptionIndex === -1) return;
        newFilterOptions[currentFilterOptionIndex].options = options[optionKey];
      });
      updateFunc(newFilterOptions);
    },
    [],
  );

  return [
    filteringOptions,
    updateFilterOptions,
    contactsFilterOptions,
    setContactsFilterOptions,
    accountsFilterOptions,
    setAccountsFilterOptions,
  ];
};

export default useFilterOptions;
