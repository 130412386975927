import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  coverageScoreItem: {
    borderRadius: rem(8),
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    background: theme.colors.white[2],
    padding: rem(10),
  },
  containerClassName: {
    width: rem(365),
  },
  modalInner: {
    left: 0,
  },
  modalContent: {
    borderRadius: rem(20),
    minWidth: rem(503),
  },
  modaBody: {
    minHeight: rem(242),
    minWidth: rem(503),
    display: 'flex',
    padding: rem(24),
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));
