import { Box } from '@mantine/core';
import React from 'react';
import { useStyle } from './ImageContent.style';

function ImageContentWrapper({ children, huge }) {
  const { classes, cx } = useStyle();

  return (
    <Box className={cx(classes.wrapper, { [classes.wrapperHuge]: huge })}>
      {children}
    </Box>
  );
}

export default ImageContentWrapper;
