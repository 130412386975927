import { useCallback } from 'react';

const useFilter = (filters, updateFunc) => {
  const updateFilter = useCallback(
    (key, filterValue, withoutModification) => {
      if (withoutModification) {
        updateFunc({
          ...filters,
          [key]: filterValue,
        });
        return;
      }

      const filterItemIndex = filters[key].findIndex(
        (el) => el === filterValue,
      );

      if (filterItemIndex === -1) {
        updateFunc({
          ...filters,
          [key]: [...filters[key], filterValue],
        });
      } else {
        updateFunc({
          ...filters,
          [key]: filters[key].filter((_, index) => index !== filterItemIndex),
        });
      }
    },
    [filters, updateFunc],
  );

  return updateFilter;
};

export default useFilter;
