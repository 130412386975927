import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  dropdownTarget: {
    width: rem(265),
    height: rem(34),
    justifyContent: 'space-between',
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: 8,
  },
  dropdown: {
    width: `${rem(265)} !important`,
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    background: theme.colors.white[2],
    borderTop: 'none',
    borderRadius: '0 0 8px 8px',
    padding: `${rem(16)} 0 0 0`,
    marginTop: rem(-15),
    maxHeight: rem(450),
    overflow: 'scroll',

    '& button': {
      paddingLeft: rem(16),
    },
  },
  filterDropdown: {
    width: `calc(100vw - ${rem(300)})`,
    background: theme.colors.white[2],
    boxShadow: '0px 4px 12px rgba(54, 66, 71, 0.21)',
    borderRadius: rem(8),
    padding: rem(16),
  },
  filterDropdownContainer: {
    right: rem(41),
    left: 'auto !important',
    transform: `translateY(${rem(8)})`,
    border: 'none',
    padding: 0,
    zIndex: '100 !important',
  },
  closeButton: {
    position: 'absolute',
    right: rem(18),
    height: rem(25),
    width: rem(25),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `${rem(1.5)} solid ${theme.colors.black[0]}`,
    borderRadius: '50%',
    cursor: 'pointer',

    '& svg': {
      height: rem(15),
      width: rem(15),
    },
  },
  rangeTrack: {
    '&::before': {
      background: theme.colors.white[1],
    },
  },
  rangeThumb: {
    background: theme.colors.gray[4],
  },
  rangeThumbActive: {
    background: theme.colors.yellow[1],
  },
  rangeBar: {
    background: theme.colors.gray[4],
  },
  rangeBarActive: {
    background: theme.colors.yellow[1],
  },
  rangeLabel: {
    fontSize: rem(12),
    fontFamily: 'Manrope',
    color: theme.colors.gray[4],
    fontWeight: 400,
    padding: 0,
    top: 0,
    transform: 'translateY(-100%)',
  },
  topicItem: {
    height: rem(32),
    padding: `0 ${rem(12)}`,
  },
  tableWrapperWithoutBorder: {
    border: 'none',
  },
  tableWrapper: {
    background: theme.colors.white[3],
  },
  tableRow: {
    background: theme.colors.white[2],
  },
  hoverItem: {
    position: 'absolute',
    right: rem(18),
    top: '50%',
    transform: 'translateY(-50%)',
    transition: '0.5s',
  },
  rowExpanded: {
    padding: `${rem(16)} ${rem(8)} ${rem(24)} ${rem(30)}`,
  },
  rowExpandedAccount: {
    padding: `${rem(16)} 0 ${rem(8)} ${rem(16)}`,
  },
  interestItem: {
    background: theme.colors.purple[0],
    borderRadius: 100,
    padding: `${rem(5)} ${rem(14)}`,
  },
  interestContainer: {
    maxHeight: rem(29),
    overflow: 'hidden',
    transition: '0.5s',
  },
  tableWrapperScroll: {
    maxHeight: rem(750),
  },
}));
