import React from 'react';
import { Flex, Stack } from '@mantine/core';
import CustomInput from '../../../../../CustomInput/CustomInput';
import CustomText from '../../../../../Typography/CustomText/CustomText';

function AddressInputGroup({ keys, form }) {
  return (
    <Stack spacing={8}>
        <CustomInput
            placeholder="Address line 1"
            {...form.getInputProps(keys[0])}
        />
        <CustomInput
            placeholder="Address line 2"
            {...form.getInputProps(keys[1])}
        />
        <CustomInput
            placeholder="Address line 3 (optional*)"
            {...form.getInputProps(keys[2])}
        />
        <Flex justify="space-between" gap={16}>
            <Stack spacing={8} sx={{ flex: 2 }}>
                <CustomText variant="body2">
                    State
                </CustomText>
                <CustomInput
                    placeholder="Type your State here"
                    {...form.getInputProps(keys[3])}
                />
            </Stack>
            <Stack spacing={8} sx={{ flex: 2 }}>
                <CustomText variant="body2">
                    Zip Code
                </CustomText>
                <CustomInput
                    placeholder="Type your zip code here"
                    {...form.getInputProps(keys[4])}
                />
            </Stack>
        </Flex>
    </Stack>
  );
}

export default AddressInputGroup;
