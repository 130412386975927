import { rem } from '@mantine/core';

const getOrCreateTooltip = (chart, tooltip, isUpsideDown) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.minWidth = rem(156);
    tooltipEl.style.borderRadius = '5px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    if (isUpsideDown) {
      tooltipEl.style.transform = 'translate(-50%, 50%)';
    } else {
      tooltipEl.style.transform = 'translate(-50%, -120%)';
    }
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.zIndex = '10';

    const container = document.createElement('div');
    container.style.margin = '0px';

    tooltipEl.appendChild(container);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const TooltipComponentCallback = (
  context,
  firstProperty,
  secondProperty,
  secondPropertyFunc,
  customBackground,
  isUpsideDown,
) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, tooltip, isUpsideDown);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.labelColors[0].borderColor.includes('0.3')) return;

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tooltipBody = document.createElement('div');
    bodyLines.forEach((body) => {
      const bodyContainsTwoProperties = body[0].split(':').length === 2;

      const colors = tooltip.labelColors[0];
      tooltipBody.style.background = customBackground || colors.borderColor;
      tooltipBody.style.padding = `${rem(8)} ${rem(16)}`;
      tooltipBody.style.borderRadius = '5px';
      tooltipBody.style.display = 'flex';
      tooltipBody.style.justifyContent = 'center';
      tooltipBody.style.flexDirection = 'column';
      tooltipBody.style.boxShadow = '2px 4px 6px rgba(15, 14, 36, 0.15)';
      tooltipBody.style.zIndex = '-100';
      tooltipBody.style.position = 'initial';

      const companyNameContainer = document.createElement('h4');
      let firstPropertyWrapper = firstProperty;
      if (typeof firstProperty === 'function') {
        firstPropertyWrapper = firstProperty(body[0].split(':'));
      }
      const companyNameValue = document.createTextNode(
        bodyContainsTwoProperties
          ? body[0].split(':')[0]
          : firstPropertyWrapper,
      );

      companyNameContainer.style.fontSize = rem(12);
      companyNameContainer.style.fontWeight = 600;
      companyNameContainer.style.fontFamily = 'Manrope';
      companyNameContainer.style.marginBottom = '2px';

      const volumeContainer = document.createElement('h4');
      let volumeValue = null;
      if (bodyContainsTwoProperties) {
        volumeValue = document.createTextNode(
          `${secondProperty}: ${body[0].split(':')[1]}`,
        );
      } else {
        volumeValue = document.createTextNode(
          `${secondPropertyFunc(body[0].split(':')[0])}${secondProperty}`,
        );
      }

      volumeContainer.style.fontSize = rem(11);
      volumeContainer.style.marginBottom = '2px';
      volumeContainer.style.fontWeight = 500;
      volumeContainer.style.fontFamily = 'Manrope';

      let categoryContainer = null;
      let volumeContent = null;

      if (bodyContainsTwoProperties) {
        categoryContainer = document.createElement('h4');
        volumeContent = document.createTextNode(firstProperty);

        categoryContainer.style.fontSize = rem(11);
        categoryContainer.style.marginBottom = '2px';
        categoryContainer.style.fontWeight = 500;
        categoryContainer.style.fontFamily = 'Manrope';

        categoryContainer.appendChild(volumeContent);
        tooltipBody.appendChild(categoryContainer);
      }

      companyNameContainer.appendChild(companyNameValue);
      volumeContainer.appendChild(volumeValue);

      const arrowElement = document.createElement('div');
      arrowElement.style.width = '0';
      arrowElement.style.height = '0';
      arrowElement.style.borderTop = `${rem(12)} solid ${
        customBackground || colors.borderColor
      }`;
      arrowElement.style.borderLeft = `${rem(12)} solid transparent`;
      arrowElement.style.borderRight = `${rem(12)} solid transparent`;
      arrowElement.style.position = 'absolute';
      if (isUpsideDown) {
        arrowElement.style.top = 0;
        arrowElement.style.transform = 'translate(-50%, -98%) rotate(180deg)';
      } else {
        arrowElement.style.bottom = 0;
        arrowElement.style.transform = 'translate(-50%, 98%)';
      }
      arrowElement.style.left = '50%';
      arrowElement.style.zIndex = '100';

      tooltipBody.appendChild(arrowElement);
      tooltipBody.appendChild(companyNameContainer);
      tooltipBody.appendChild(volumeContainer);
    });

    const tooltipRoot = tooltipEl.querySelector('div');

    // Remove old children
    while (tooltipRoot.firstChild) {
      tooltipRoot.firstChild.remove();
    }

    // Add new children
    tooltipRoot.appendChild(tooltipBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
  tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
};
