import { useCallback, useMemo } from 'react';
import { mapLabelsForChart } from '../../../utils';

const STATS_CHART_DATA = [
  {
    label: 'Label',
    chartColor: '#ED7470',
    gradient: {
      startColor: 'rgba(237, 116, 112, 0.5)',
      endColor: 'rgba(217, 217, 217, 0)',
    },
  },
];

const useChartMetrics = (topicCoverageScore) => {
  const getChartLabels = useCallback((data) => {
    const timeSeries = data?.timeSeries;
    if ((timeSeries?.length ?? 0) === 0)
      return ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
    return mapLabelsForChart(timeSeries);
  }, []);

  const getChartData = useCallback((data) => {
    const timeSeries = data?.timeSeries;
    if (!timeSeries) return STATS_CHART_DATA;
    return [
      {
        ...STATS_CHART_DATA[0],
        data: timeSeries.map((el) => el.count),
      },
    ];
  }, []);

  const score = useMemo(() => {
    return topicCoverageScore?.score * 100 || 0;
  }, [topicCoverageScore]);

  return [getChartLabels, getChartData, score]
};

export default useChartMetrics;
