import { Box, Flex, rem, Stack } from '@mantine/core';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import React from 'react';
import MainButton from '../../buttons/buttons';
import Heading from '../../Heading';
import LoginInput from '../../LoginInput/LoginInput';
import CustomText from '../../Typography/CustomText/CustomText';
import LoginWrapper from '../LoginWrapper';
import { useStyle } from './Signup.style';

function Signup({onContinue}) {
  const { classes } = useStyle();
  const form = useForm({
    initialValues: {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        company: ''
    },
    validate: {
        firstName: isNotEmpty("Please enter your first name"),
        lastName: isNotEmpty("Please enter your last name"),
        username: isNotEmpty("Please enter your username"),
        email: isEmail("Are you sure that you've entered your email correctly?"),
        company: isNotEmpty("Please enter your company")

    }
  })

  return (
    <LoginWrapper pt={88}>
      <Stack spacing={32}>
        <Heading
          title={
            <>
              Create your free <br /> account
            </>
          }
        />
        <Stack>
          <Flex gap={16}>
            <LoginInput
              placeholder="First name"
              flexGrow
              customClassName={classes.input}
              {...form.getInputProps('firstName')}
            />
            <LoginInput
              placeholder="Last name"
              flexGrow
              customClassName={classes.input}
              {...form.getInputProps('lastName')}
            />
          </Flex>
          <LoginInput placeholder="Username" customClassName={classes.input} {...form.getInputProps('username')} />
          <LoginInput placeholder="Email" customClassName={classes.input} {...form.getInputProps('email')} />
          <LoginInput placeholder="Company" customClassName={classes.input} {...form.getInputProps('company')} />
        </Stack>
        <Box mx="auto">
          <MainButton hugePadding sx={{ width: rem(278) }} onClick={form.onSubmit(onContinue)}>
            <CustomText variant="bodyLarge" sx={{ fontSize: rem(18) }}>
              Sign up
            </CustomText>
          </MainButton>
        </Box>
      </Stack>
    </LoginWrapper>
  );
}

export default Signup;
