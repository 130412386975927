import React from 'react';
import CustomInput from '../CustomInput/CustomInput';
import { useStyle } from './LoginInput.style';

function LoginInput({ customClassName, height, flexGrow, ...rest }) {
  const { classes, cx } = useStyle();

  return (
    <CustomInput
      customClassName={cx(classes.input, customClassName)}
      styles={{
        input: {
            height
        },
        root: {
          flexGrow: flexGrow ? 1 : ''
        }
      }}
      {...rest}
    />
  );
}

export default LoginInput;
