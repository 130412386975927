import React from 'react';
import { Flex, rem, UnstyledButton } from '@mantine/core';
import MainButton from '../../../buttons/buttons';
import CustomText from '../../../Typography/CustomText/CustomText';
import { useStyle } from './ButtonGroup.style';

function ButtonGroup({
  mainText,
  secondaryText,
  onMainButtonClick,
  onSecondaryButtonClick,
  reversed,
  width,
  color,
  ...rest
}) {
  const { classes } = useStyle();

  return (
    <>
      {(mainText || secondaryText) && (
        <Flex
          justify="center"
          gap={16}
          sx={{ flexDirection: reversed ? 'row-reverse' : 'row' }}
        >
          {secondaryText && (
            <UnstyledButton
              className={classes.secondaryButton}
              onClick={onSecondaryButtonClick}
              sx={{width: width ? rem(width[0]) : "", color}}
              {...rest}
            >
              <CustomText variant="body1" sx={{ fontSize: rem(18) }}>
                {secondaryText}
              </CustomText>
            </UnstyledButton>
          )}
          <MainButton
            hugePadding
            onClick={onMainButtonClick}
            sx={{ height: rem(60), width: rem(width ? width[1] : 234), color }}
            {...rest}
          >
            <CustomText variant="body1" sx={{ fontSize: rem(18) }}>
              {mainText}
            </CustomText>
          </MainButton>
        </Flex>
      )}
    </>
  );
}

export default ButtonGroup;
