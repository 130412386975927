import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  interestItem: {
    background: theme.colors.purple[0],
    borderRadius: 100,
    padding: `${rem(5)} ${rem(14)}`,
  },
  interestContainer: {
    maxHeight: rem(29),
    overflow: 'hidden',
    transition: '0.5s',
    position: "relative",

    '& .invisible': {
      visibility: "hidden"
    }
  },
}));
