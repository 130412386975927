import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useRadarApi from "../../../hooks/useRadarApi";

const useFetchContentUtilization = (jwtTokenLoading, urlDateParams) => {
    const [contentUtilization, setContentUtilization] = useState({})
    const { getContentsUtilization } = useRadarApi()
    const [fetchContentUtilization, loading] = useFetch(getContentsUtilization);

    const fetchWrapper = useCallback(async () => {
      const data = await fetchContentUtilization({
        dateFilter: JSON.stringify(urlDateParams),
      });
      setContentUtilization(data || {});
    }, [fetchContentUtilization, getContentsUtilization, urlDateParams]);

    useEffect(() => {
      if (!jwtTokenLoading && urlDateParams) {
        fetchWrapper();
      }
    }, [jwtTokenLoading, JSON.stringify(urlDateParams)]);

    return [contentUtilization, loading]
}

export default useFetchContentUtilization