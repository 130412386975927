import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { mapLabelsForChart } from '../../../utils';

const STATS_CHART_DATA = [
  {
    label: 'Label',
    chartColor: '#62B18E',
    gradient: {
      startColor: 'rgba(98, 177, 142, 0.5)',
      endColor: 'rgba(217, 217, 217, 0)',
    },
  },
];

const useChartMetrics = (contentUtilization, urlDateParams) => {
  const chartLabels = useMemo(() => {
    const timeSeries = contentUtilization?.timeSeries;
    if ((timeSeries?.length ?? 0) === 0)
      return ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];

    return mapLabelsForChart(timeSeries);
  }, [contentUtilization]);

  const chartTitle = useMemo(() => {
    const title =
      contentUtilization.contentDelivered /
      (contentUtilization.contentCount / 100);
    if (isNaN(title)) {
      return '0%';
    }
    return `${Math.round(title)}%`;
  }, [contentUtilization]);

  const chartData = useMemo(() => {
    const timeSeries = contentUtilization?.timeSeries;
    if (!timeSeries) return STATS_CHART_DATA;
    return [
      {
        ...STATS_CHART_DATA[0],
        data: timeSeries.map((el) => el.count),
      },
    ];
  }, [contentUtilization]);

  const dateList = useMemo(() => {
    const timeSeries = contentUtilization?.timeSeries;
    if (!timeSeries) return;
    return timeSeries.map((el) => el.timestamp);
  }, [contentUtilization]);

  const getTooltipTitle = useCallback(
    (data) => {
      return Math.round(data / (contentUtilization.contentCount / 100));
    },
    [contentUtilization],
  );

  const dateRange = useMemo(() => {
    if (!urlDateParams) return;
    let { from, to } = urlDateParams;

    if (to) {
      const fromDate = dayjs(from);
      const endDate = dayjs(to);
      return `from ${fromDate.format('MMM')} ${fromDate.format(
        'DD',
      )} to ${endDate.format('MMM')} ${endDate.format('DD')}`;
    }

    return `in the past ${Math.abs(dayjs(to).diff(dayjs(from), 'day'))} days`;
  }, [urlDateParams]);

  return [
    chartLabels,
    chartTitle,
    chartData,
    dateList,
    getTooltipTitle,
    dateRange,
  ];
};

export default useChartMetrics;
