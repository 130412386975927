import { Box } from '@mantine/core';
import { useCallback } from 'react';
import CustomSelectionMenu from '../../../components/CustomDropdown/CustomSelectionMenu/CustomSelectionMenu';

const useFilterFields = (filteringParams, filteringOptions, updateFilter) => {
  const generateFilterFields = useCallback(() => {
    if (!filteringParams) return;
    const elements = [];
    if (!filteringOptions.length) return;

    const keyBlackList = ['list']

    filteringOptions.forEach((filter, index) => {
      if(keyBlackList.includes(filter?.key)) return
      if ((filter.options.length ?? 0) === 0 || !filter.title) return;
      elements.push(
        <Box sx={{ position: 'relative' }} key={index}>
          <CustomSelectionMenu
            title={filter.title}
            selectedItemTitles={filteringParams[filter.key]}
            onItemSelect={(value) => updateFilter(filter.key, value.title)}
            menuOptions={filter.options.map((el) => ({ title: el }))}
            withoutAnimation
          />
        </Box>,
      );
    });

    return elements;
  }, [filteringParams, filteringOptions]);

  return generateFilterFields;
};

export default useFilterFields;
