import React, { useEffect, useMemo, useRef, useState } from 'react';
import { rem } from '@mantine/core';
import useOrgSelection from '../hooks/useOrgSelection';
import CustomSelectionMenu from '../../CustomDropdown/CustomSelectionMenu/CustomSelectionMenu';
import { useStyle } from './OrgSelectionMenu.style';
import { setLoadingOverlay } from '../../../features/layoutSlice';

function OrgSelectionMenu() {
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [selectionMenuOpened, setSelectionMenuOpened] = useState(true);
  const { classes } = useStyle();
  const searchingInputRef = useRef(null)
  const { memberships, membershipsLoading, updateUserOrg, currentActiveOrg } =
    useOrgSelection();

  const handleClick = async (newOrgId) => {
    setLoadingOverlay({LoadingOverlay: true})
    await updateUserOrg(newOrgId);
    setLoadingOverlay({LoadingOverlay: false})
    window.location.href = '/';
  };

  useEffect(() => {
    if(!selectionMenuOpened && searchingInputRef?.current) {
      searchingInputRef.current.blur()
    }
  }, [selectionMenuOpened])

  const selectionMenuTitleValue = useMemo(() => {
    if (selectionMenuOpened) {
      return filterSearchValue;
    }

    return currentActiveOrg?.name;
  }, [currentActiveOrg, selectionMenuOpened, filterSearchValue]);

  const filteredOptions = useMemo(() => {
    return (memberships || [])
      .map((membership) => ({
        title: membership?.name,
        ...membership,
      }))
      .filter((membership) =>
        (membership?.title || '')
          .toLowerCase()
          .includes(filterSearchValue.toLowerCase()),
      )
      .sort((a, b) => {
        const aIsSelected = currentActiveOrg?.orgId === a?.orgId;
        const bIsSelected = currentActiveOrg?.orgId === b?.orgId;
        if (aIsSelected && bIsSelected) return a.title > b.title ? 1 : -1;
        else if (aIsSelected) {
          return -1;
        } else if (bIsSelected) {
          return 1;
        }

        return a.title > b.title ? 1 : -1;
      });
  }, [memberships, filterSearchValue, currentActiveOrg]);

  return (
    <>
      {((membershipsLoading || currentActiveOrg) && memberships?.length > 1) && (
        <CustomSelectionMenu
          title={
            <input
              className={classes.filterDropdownPlaceholder}
              placeholder="Search..."
              value={selectionMenuTitleValue}
              onChange={(e) => setFilterSearchValue(e.target.value)}
              ref={searchingInputRef}
            />
          }
          selectedItemTitles={currentActiveOrg?.name || ''}
          onItemSelect={(value) => handleClick(value?.orgId)}
          menuOptions={filteredOptions}
          withoutAnimation
          maxHeight={rem(300)}
          height={300}
          wihtoutCheckboxes
          setState={setSelectionMenuOpened}
          externalMenuTargetStyles={{border: "none"}}
          externalMenuDropdownStyles={{borderRadius: rem(8), border: "1px solid #DDD"}}
        />
      )}
    </>
  );
}

export default OrgSelectionMenu;
