import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  topic: {
    height: rem(40),
    border: `${rem(1)} solid ${theme.colors.yellow[0]}`,
    borderRadius: rem(122),
    background: 'rgba(252, 252, 252, 0.90)',
    padding: `0 ${rem(16)}`,
    cursor: 'pointer',

    '& svg': {
      width: rem(24),
      heigth: rem(24),
      transform: 'rotate(45deg)',
      strokeWidth: rem(1.5),
    },
  },
  selectedTopic: {
    background: theme.colors.yellow[0],
  },
}));
