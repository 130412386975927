import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Flex } from '@mantine/core';
import { useStyle } from './ToolCard.style';

function ToolCard({ image, activeImage, borderColor, onClick }) {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);
  const containerRef = useRef(null);
  const { classes } = useStyle();

  const handleClick = useCallback(() => {
    onClick();
    setClicked(true);
  }, [onClick, borderColor]);

  useEffect(() => {
    if (!containerRef?.current) return;

    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    containerRef.current.addEventListener('mouseenter', onMouseEnter);
    containerRef.current.addEventListener('mouseleave', onMouseLeave);
  }, []);

  return (
    <Flex
      className={classes.card}
      onClick={handleClick}
      ref={containerRef}
      sx={{ borderColor: clicked && borderColor }}
    >
      {hovered ? <>{activeImage || image}</> : <>{image}</>}
    </Flex>
  );
}

export default ToolCard;
