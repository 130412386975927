import React, { useEffect } from 'react';
import { Box, Stack, Tabs } from '@mantine/core';
import { useDispatch } from 'react-redux';
import OutlineTabs from '../../components/OutlineTabs/OutlineTabs';
import CustomText from '../../components/Typography/CustomText/CustomText';
import { setActiveLinkKey, setHeaderTitle } from '../../features/layoutSlice';
import { useStyle } from './AccountPage.style';
// import ProfileTab from '../../components/account/ProfileTab/ProfileTab';
import TeamTab from '../../components/account/TeamTab/TeamTab';
// import PlanTab from '../../components/account/PlanTab/PlanTab';
// import NotificationTab from '../../components/account/NotificationTab/NotificationTab';
// import PaymentTab from '../../components/account/PaymentTab/PaymentTab';

const TABS = [
  // 'Your profile',
  'Your team',
  // 'Your plan',
  // 'Payment',
  // 'Notifications',
];

function AccountPage() {
  const { classes } = useStyle();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        headerTitle: 'Account',
      }),
    );
    dispatch(
      setActiveLinkKey({
        activeLinkKey: 'Account',
      }),
    );
  }, []);

  return (
    <Box>
      <OutlineTabs tabsItems={TABS} defaultValue={TABS[0]}>
        {/* <Tabs.Panel value={TABS[0]}>
            <Box w={866} p={8}>
              <Stack spacing={4} className={classes.sectionContainer}>
                <Box w="75%">
                  <CustomText variant="h2">
                    Your details
                  </CustomText>
                  <CustomText variant="body2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ex bibendum,
                    rutrum erat non, accumsan mi. Nunc tempor et ex sed iaculis. Aenean pharetra
                    lacus nec interdum viverra.
                  </CustomText>
                </Box>
              </Stack>
              <ProfileTab />
            </Box>
          </Tabs.Panel> */}
        <Tabs.Panel value={TABS[0]}>
          <Box w={866} p={8}>
            <Stack spacing={4} className={classes.sectionContainer}>
              <Box w="75%">
                <CustomText variant="h2">Your Team</CustomText>
                <CustomText variant="body2">
                  Here are all your teammates with access to Air Traffic
                  Control. <br />
                  Connection to Air Traffic Control does not grant access to any
                  connected apps.
                </CustomText>
              </Box>
            </Stack>
            <TeamTab />
          </Box>
        </Tabs.Panel>
        {/* <Tabs.Panel value={TABS[2]}>
            <Box w={866} p={8}>
              <Stack spacing={4} className={classes.sectionContainer}>
                <Box w="75%">
                  <CustomText variant="h2">
                    Your Plan
                  </CustomText>
                  <CustomText variant="body2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ex bibendum,
                    rutrum erat non, accumsan mi. Nunc tempor et ex sed iaculis. Aenean pharetra
                    lacus nec interdum viverra.
                  </CustomText>
                </Box>
              </Stack>
              <PlanTab />
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value={TABS[3]}>
            <Box w={866} p={8}>
              <Stack spacing={4} className={classes.sectionContainer}>
                <Box w="75%">
                  <CustomText variant="h2">
                    Payment Details
                  </CustomText>
                  <CustomText variant="body2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ex bibendum,
                    rutrum erat non, accumsan mi. Nunc tempor et ex sed iaculis. Aenean pharetra
                    lacus nec interdum viverra.
                  </CustomText>
                </Box>
              </Stack>
              <PaymentTab />
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value={TABS[4]}>
            <Box w={866} p={8}>
              <Stack spacing={4} className={classes.sectionContainer}>
                <Box w="75%">
                  <CustomText variant="h2">
                    Notifications
                  </CustomText>
                  <CustomText variant="body2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ex bibendum,
                    rutrum erat non, accumsan mi. Nunc tempor et ex sed iaculis. Aenean pharetra
                    lacus nec interdum viverra.
                  </CustomText>
                </Box>
              </Stack>
              <NotificationTab />
            </Box>
          </Tabs.Panel> */}
      </OutlineTabs>
    </Box>
  );
}

export default AccountPage;
