import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  leftInputWrapper: {
    width: '40%',
  },
  rightInputWrapper: {
    width: '60%',
  },
  leftInput: {
    borderRadius: `${rem(62)} 0 0 ${rem(62)}`,
    border: 'none',
    borderRight: `${rem(1)} solid ${theme.colors.gray[4]}`,
    fontWeight: 700,
    fontSize: rem(16),

    '&::placeholder': {
      fontWeight: 700,
      fontSize: rem(16),
      color: theme.colors.gray[4],
    },
  },
  rightInput: {
    borderRadius: `0 ${rem(62)} ${rem(62)} 0`,
    border: 'none',
    fontSize: rem(16),

    '&::placeholder': {
      fontSize: rem(16),
      color: theme.colors.gray[4],
    },
  },
  input: {
    height: rem(55),
    background: theme.colors.white[2],
    fontFamily: 'Manrope',
    padding: rem(24),
  },
  iconContainer: {
    height: rem(60),
    width: rem(50),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: "hidden",
    cursor: "pointer",

    '& button': {
      width: rem(42),
      height: rem(42),
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      transition: "0.5s",
      border: '1px solid transparent',
    },

    '& .button-text': {
      display: 'none',
    },

    '& svg': {
      height: rem(42),
      width: rem(42),
      transform: 'rotate(45deg)',

      '& path': {
        fill: theme.colors.yellow[1],
      },

      '& circle': {
        stroke: theme.colors.yellow[1],
      },
    },

    '&:hover': {
      width: rem(120),

      '& button': {
        width: 'fit-content',
        borderRadius: rem(122),
        padding: `0 ${rem(16)}`,
        height: rem(32),
        borderColor: theme.colors.yellow[1]
      },

      '& .button-text': {
        display: 'block',
      },

      '& svg': {
        display: 'none',
      },
    },
  },
  selectIcon: {
    paddingRight: rem(24)
  }
}));
