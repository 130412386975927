import { useCallback, useMemo, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';

const usePaymentPortalSession = () => {
  const { getPortalSession } = useRadarApi();
  const [url, setUrl] = useState(null)
  const [fetchFunc] = useFetch(getPortalSession);

  const loading = useMemo(() => {
    if(url) {
        return false
    }
    return true
  }, [url])

  const fetchWrapper = useCallback(async () => {
    const data = await fetchFunc();

    setUrl(data?.url);
  }, [fetchFunc, getPortalSession]);

  return {
    url,
    setUrl,
    retrievePaymentUrl: fetchWrapper,
    loading,
  };
};

export default usePaymentPortalSession;
