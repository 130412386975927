// LandingPage.js

import { useEffect } from 'react';
import './LandingPage.css';
import { useAuth0 } from '@auth0/auth0-react';

function LandingPage() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const redirectUrl =
      localStorage.getItem('skipPreviewPage') === 'true' ? '/' : '/quick-start';
    loginWithRedirect({
      appState: {
        returnTo: redirectUrl,
      },
    });
  }, []);

  return <></>;
}

export default LandingPage;
