import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { Flex } from '@mantine/core';
import CustomText from '../../components/Typography/CustomText/CustomText';
import useURLParams from '../../hooks/useURLParams';
import OutlineTabs from '../../components/OutlineTabs/OutlineTabs';
import { getContactsSteps } from '../../features/guidedTourSteps/contactsPage';
import useGuideModal from '../../hooks/useGuideModal';
import { useDispatch, useSelector } from 'react-redux';
import GuidedTour from '../../components/GuidedTour/GuidedTour';
import GuidedTourModal from '../../components/GuidedTour/GuidedTourModal/GuidedTourModal';
import useJwtToken from '../../hooks/useJwtToken';
import ContactsTable from './components/ContactsTable/ContactsTable';
import useFetchTableData from './hooks/useFetchTableData';
import useFilterOptions from './hooks/useFilterOptions';
import useTabHandler from './hooks/useTabHandler';
import CustomFilterBar from './components/FilterBar/FilterBar';
import { DEFAULT_URL_PARAMS, PARAMS_KEYS, TABS } from './constant';
import useActiveTab from '../../hooks/useActiveTab';
import useIntegrations from '../../hooks/useIntegrations';

function ContactsPage() {
  const [preventRefetch, setPreventRefetch] = useState(false);
  const [contactsFilteringParams, setContactsFilteringParams] = useState(null);
  const [accountsFilteringParams, setAccountsFilteringParams] = useState(null);
  const [
    contactsFilteringParamsPreserved,
    setContactsFilteringParamsPreserved,
  ] = useState(null);
  const [
    accountsFilteringParamsPreserved,
    setAccountsFilteringParamsPreserved,
  ] = useState(null);
  const [selectedContactsIndexes, setSelectedContactsIndexes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const { showGuide, stepIndex, guideModalIndex } = useSelector(
    (state) => state.profile.guide.contacts,
  );
  const dispatch = useDispatch();
  const { handleGuideModalClick } = useGuideModal('contacts', stepIndex);
  const { loading } = useJwtToken();
  const { hubspotDetails } = useIntegrations();

  const { urlParams, updateUrlParams } = useURLParams(PARAMS_KEYS, {
    ...DEFAULT_URL_PARAMS,
  });

  const updateTableInnerSelection = useRef(null);

  const [activeTab, setActiveTab] = useActiveTab(
    urlParams,
    updateUrlParams,
    TABS,
  );

  const filteringParams = useMemo(
    () =>
      activeTab === TABS[0] ? contactsFilteringParams : accountsFilteringParams,
    [activeTab, accountsFilteringParams, contactsFilteringParams],
  );

  // const selectedListsIds = useMemo(() => {
  //   if (urlParams?.list && hubspotDetails) {
  //     const listsIds = [];

  //     urlParams.lists.forEach((list) => {
  //       listsIds.push(
  //         hubspotDetails.availableContactLists.find((el) => el.name === list)
  //           ?.listId,
  //       );
  //     });

  //     return listsIds;
  //   }
  //   return [];
  // }, [urlParams, hubspotDetails]);

  const selectedListsId = useMemo(() => {
    if (filteringParams && hubspotDetails) {
      return hubspotDetails.availableContactLists.find(
        (el) =>
          el.name ===
          (typeof filteringParams.list === 'string'
            ? filteringParams.list
            : filteringParams.list?.[0]),
      )?.listId;
    }
    return '';
  }, [filteringParams, hubspotDetails]);


  const [
    filteringOptions,
    updateFilterOptions,
    contactsFilterOptions,
    setContactsFilterOptions,
    accountsFilterOptions,
    setAccountsFilterOptions,
  ] = useFilterOptions(activeTab);

  const [
    contacts,
    contactsContent,
    contactsLoading,
    maxContacts,
    contactsOffset,
    setContactsOffset,
    fetchContacts,
  ] = useFetchTableData(
    loading,
    urlParams,
    preventRefetch,
    setPreventRefetch,
    contactsFilterOptions,
    setContactsFilterOptions,
    updateFilterOptions,
    activeTab,
    'contacts',
    updateTableInnerSelection.current,
    selectedListsId,
  );

  const [
    accounts,
    accountsContent,
    accountsLoading,
    maxAccounts,
    accountOffset,
    setAccountOffset,
  ] = useFetchTableData(
    loading,
    urlParams,
    preventRefetch,
    setPreventRefetch,
    accountsFilterOptions,
    setAccountsFilterOptions,
    updateFilterOptions,
    activeTab,
    'accounts',
    updateTableInnerSelection.current,
  );

  const handleTabChange = useTabHandler(
    urlParams,
    updateUrlParams,
    setActiveTab,
    contactsFilteringParamsPreserved,
    accountsFilteringParamsPreserved,
    setPreventRefetch,
    fetchContacts,
    accountsLoading,
    contactsContent,
  );

  const setFilteringParamsPreserved = useCallback(
    (data) =>
      activeTab === TABS[0]
        ? setContactsFilteringParamsPreserved(data)
        : setAccountsFilteringParamsPreserved(data),
    [activeTab, accountsFilteringParams, contactsFilteringParams],
  );

  const setFilteringParams = useCallback(
    (data) =>
      activeTab === TABS[0]
        ? setContactsFilteringParams(data)
        : setAccountsFilteringParams(data),
    [activeTab, accountsFilteringParams, contactsFilteringParams],
  );

  useEffect(() => {
    if (!urlParams) return;

    setFilteringParams(urlParams);
  }, [urlParams, activeTab]);

  useEffect(() => {
    if (!urlParams) return;

    setFilteringParamsPreserved(urlParams);
  }, [urlParams]);

  const bindSelection = useCallback((setSelection) => {
    updateTableInnerSelection.current = setSelection;
  }, []);

  const handleAccountButtonClick = useCallback(
    (company) => {
      const newState = {
        ...DEFAULT_URL_PARAMS,
        'filter[company]': [company],
      };

      setContactsFilteringParams(newState);
      setContactsOffset(0);
      setActiveTab(TABS[0]);
      updateUrlParams(newState);
    },
    [urlParams],
  );

  const activeContentTotalItems = useMemo(() => {
    if (activeTab === TABS[0]) {
      return contacts?.totalItems || 0;
    }
    return accounts?.totalItems || 0;
  }, [accounts, contacts, activeTab]);

  return (
    <>
      <CustomFilterBar
        urlParams={urlParams}
        updateUrlParams={updateUrlParams}
        activeTab={activeTab}
        filteringOptions={filteringOptions}
        filteringParams={filteringParams}
        setFilteringParams={setFilteringParams}
        setContactsOffset={setContactsOffset}
        setAccountOffset={setAccountOffset}
        selectedContactsIndexes={selectedContactsIndexes}
        allSelected={allSelected}
        totalItems={contacts?.totalItems}
        contactsContent={contactsContent}
        selectedListsId={selectedListsId}
        hubspotDetails={hubspotDetails}
      />
      <Flex justify="flex-end" mb={-34} mr={8} mt={26}>
        <CustomText
          variant="body2"
          sx={(theme) => ({ color: theme.colors.gray[4] })}
        >
          Showing:{' '}
          {(activeTab === TABS[0]
            ? `${contacts?.totalItems || 0} ${
                contacts?.totalItems === maxContacts ? '(all)' : ''
              }`
            : `${accounts?.totalItems || 0} ${
                accounts?.totalItems === maxAccounts ? '(all)' : ''
              }`
          ).toLocaleString('en-US')}{' '}
        </CustomText>
      </Flex>
      <OutlineTabs
        tabsItems={TABS}
        noPadding
        value={activeTab}
        onTabChange={handleTabChange}
      >
        <ContactsTable
          totalItems={activeContentTotalItems}
          stepIndex={stepIndex}
          urlParams={urlParams}
          updateUrlParams={updateUrlParams}
          contactsContent={contactsContent}
          accountsContent={accountsContent}
          contactsOffset={contactsOffset}
          accountOffset={accountOffset}
          setContactsOffset={setContactsOffset}
          setAccountOffset={setAccountOffset}
          contactsLoading={contactsLoading}
          accountsLoading={accountsLoading}
          selectedContactsIndexes={selectedContactsIndexes}
          setSelectedContactsIndexes={setSelectedContactsIndexes}
          handleGuideModalClick={handleGuideModalClick}
          activeTab={activeTab}
          onAccountsButtonClick={handleAccountButtonClick}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          bindSelection={bindSelection}
        />
      </OutlineTabs>
      {/* Guided Tour Components */}
      <GuidedTourModal
        opened={showGuide && guideModalIndex === 0}
        title="Contacts"
        onClick={handleGuideModalClick}
        onClose={handleGuideModalClick}
      >
        <CustomText variant="body2">
          Here you can find a list of your contacts and the insights and
          recommendations we generate for them.
        </CustomText>
      </GuidedTourModal>
      <GuidedTour
        stepIndex={stepIndex}
        setStepIndex={handleGuideModalClick}
        run={showGuide && guideModalIndex === null}
        steps={getContactsSteps(dispatch)}
      />
    </>
  );
}

export default ContactsPage;
