import React from 'react';
import {
  Box, Center, Flex, Stack,
} from '@mantine/core';
import CustomText from '../Typography/CustomText/CustomText';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross-icon.svg';

function PaymentModalWrapper({ children, onClose }) {
  return (
    <Stack spacing={24}>
        <Flex
            justify="space-between"
            align="center"
            mt={-4}
            mx={-24}
            px={24}
            pb={16}
            sx={(theme) => ({ borderBottom: `1px solid ${theme.colors.gray[3]}` })}
        >
            <CustomText variant="h3">
                Upgrade your plan
            </CustomText>
            <Box sx={{ cursor: 'pointer' }} onClick={onClose}>
                <Center h={24} w={24} sx={{ borderRadius: '50%', border: '1.5px solid #000' }}>
                    <CrossIcon />
                </Center>
            </Box>
        </Flex>
        {children}
    </Stack>
  );
}

export default PaymentModalWrapper;
