import { Box, Stack } from '@mantine/core';
import React, { useCallback, useMemo } from 'react';
import MainButton from '../../buttons/buttons';
import CustomText from '../../Typography/CustomText/CustomText';
import InputGroup from '../InputGroup/InputGroup';

function AddCompetitorDetails({
  competitorDetailData,
  updateCompetitorData,
  onCompetitorAdd,
  updateCompetitor,
}) {
  const inputGroups = useMemo(
    () => [
      [
        {
          label: 'Site section',
          placeholder: 'Homepage',
          name: 'homepage',
          value: competitorDetailData.homepage,
        },
        {
          label: 'Page',
          placeholder: 'https/www.competitorwebsite.com',
          name: 'homepageURL',
          value: competitorDetailData.homepageURL,
        },
      ],
      [
        {
          placeholder: 'Blog',
          name: 'blog',
          value: competitorDetailData.blog,
        },
        {
          placeholder: 'www.site.com/blog',
          name: 'blogURL',
          value: competitorDetailData.blogURL,
        },
      ],
      [
        {
          placeholder: 'Resources',
          name: 'resources',
          value: competitorDetailData.resources,
        },
        {
          placeholder: 'www.site.com/resources',
          name: 'resourcesURL',
          value: competitorDetailData.resourcesURL,
        },
      ],
      [
        {
          placeholder: 'About',
          name: 'about',
          value: competitorDetailData.about,
        },
        {
          placeholder: 'www.site.com/about',
          name: 'aboutURL',
          value: competitorDetailData.aboutURL,
        },
      ],
      [
        {
          placeholder: 'Product',
          name: 'product',
          value: competitorDetailData.product,
        },
        {
          placeholder: 'www.site.com/product',
          name: 'productURL',
          value: competitorDetailData.productURL,
        },
      ],
    ],
    [competitorDetailData],
  );

  const generateInputGroups = useCallback(() => {
    const elements = [];

    inputGroups.forEach((inputGroup, index) => {
      elements.push(
        <InputGroup
          key={index}
          inputs={inputGroup}
          updateState={updateCompetitorData}
        />,
      );
    });

    return elements;
  }, [inputGroups]);

  const isCompetitorDataValid = useMemo(() => {
    let isValid = true;

    Object.values(competitorDetailData).forEach((value) => {
      if (!value) {
        isValid = false;
      }
    });

    return isValid;
  }, [competitorDetailData]);

  return (
    <Box mt={16}>
      <Stack spacing={4} mb={16}>
        <CustomText variant="h4">Tracked pages</CustomText>
        <Stack spacing={8} w="fit-content" className="add-competitors-details">
          {generateInputGroups()}
        </Stack>
      </Stack>
      <MainButton
        smallPadding
        disabled={!isCompetitorDataValid}
        onClick={onCompetitorAdd}
      >
        {updateCompetitor ? 'Save' : 'Add'}
      </MainButton>
    </Box>
  );
}

export default AddCompetitorDetails;
