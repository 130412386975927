import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles({
  h2: {
    fontSize: rem(22),
    fontWeight: 700,
  },
  h3: {
    fontSize: rem(18),
    fontWeight: 700,
  },
  h4: {
    fontSize: rem(16),
    fontWeight: 700,
  },
  h5: {
    fontSize: rem(14),
    fontWeight: 600,
  },
  h6: {
    fontSize: rem(12),
    fontWeight: 700,
  },
  body1: {
    fontSize: rem(16),
    fontWeight: 500,
  },
  body2: {
    fontSize: rem(14),
    fontWeight: 500,
  },
  bodyLarge: {
    fontSize: rem(20),
    fontWeight: 400,
  },
  bodySmall:  {
    fontSize: rem(16),
    fontWeight: 400,
  },
  caption1: {
    fontSize: rem(12),
    fontWeight: 400,
  },
});
