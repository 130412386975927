import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
    imageContainer: {
        position: "relative",
        marginBottom: rem(88),
        maxWidth: 605,
    },
    imageWrapper: {
        maxWidth: 605,
        overflow: "hidden"
    },
    imageFirst: {
        position: "absolute",
        bottom: 0,
        left: 0,
        transform: "translate(-25%, -50%)"
    },
    imageSecond: {
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translate(-25%, -60%)"
    },
    imageThird: {
        marginLeft: rem(80),
    }
}));
