import React from 'react';
import { Box, rem, Stack, UnstyledButton } from '@mantine/core';
import CustomText from '../../../../Typography/CustomText/CustomText';
import { useStyle } from './ContentCard.style';

function ContentCard({ card, redButtons, huge }) {
  const { classes, cx } = useStyle();

  const handleClick = () => {
    window.open(card?.url, '_blank')
  }

  return (
    <Box className={cx(classes.wrapper, {[classes.wrapperHuge]: huge})}>
      <Stack spacing={10}>
        <Box>
          <img src={card.imageUrl} className={cx(classes.image, {[classes.imageHuge]: huge})} />
        </Box>
        <Box className={classes.contentContainer}>
          <Stack spacing={12}>
            <CustomText variant="h4" lineClamp={2} sx={{ fontSize: rem(huge ? 11 : 10), minHeight: rem(31) }}>
              {card.title}
            </CustomText>
            <UnstyledButton
              onClick={handleClick}
              className={cx(classes.button, {
                [classes.buttonBlue]: !redButtons,
                [classes.buttonRed]: redButtons,
                [classes.buttonHuge]: huge
              })}
            >
              <CustomText
                variant="h6"
                sx={(theme) => ({ fontSize: rem(huge ? 10 : 8), color: theme.colors.white[2] })}
              >
                READ MORE
              </CustomText>
            </UnstyledButton>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export default ContentCard;
