import React from 'react';
import { Stack } from '@mantine/core';
import CustomText from '../../Typography/CustomText/CustomText';
import EnrichFlow from '../../Modals/EnrichFlow';

function EnrichmentAction({ ...rest }) {
  return (
    <Stack align="center">
      <CustomText variant="h4" sx={theme => ({color: theme.colors.white[2]})}>Enrich to see the details</CustomText>
      <EnrichFlow title="Enrich" credits={1} {...rest} />
    </Stack>
  );
}

export default EnrichmentAction;
