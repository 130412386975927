// Navbar.jsx
import React from 'react';
import { Navbar, Group, Text, Stack, Flex, Box, rem } from '@mantine/core';
import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SidebarData } from './SidebarData';
import { useStyles } from './Navbar.style';
import { ReactComponent as LogoIcon } from '../../assets/icons/ATC-logo.svg';
import MainButton from '../buttons/buttons';
import { setActiveLinkKey, setHeaderTitle } from '../../features/layoutSlice';
import useJwtToken from '../../hooks/useJwtToken';
import { useAuth0 } from '@auth0/auth0-react';

function Sidebar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { permissions } = useJwtToken();
  const { isAuthenticated } = useAuth0();
  const { classes } = useStyles({});

  const getIsActive = useCallback(
    (sidebarItem) => {
      if (sidebarItem.path === '/') {
        if (location.pathname === '/' || location.pathname === '') {
          return true;
        }
        return false;
      }
      return location.pathname.includes(sidebarItem.path);
    },
    [location],
  );

  const generateSidebarItems = useCallback(
    (items, alwaysShow) => {
      const elements = [];

      items.forEach((sidebarItem) => {
        const featurePermission = permissions.filter((el) =>
          el.includes(sidebarItem.key),
        );

        if (!featurePermission.some((el) => el.includes('read')) && !alwaysShow)
          return;

        elements.push(
          <SidebarItem
            key={sidebarItem.title}
            itemData={sidebarItem}
            handleClick={() => {
              dispatch(
                setActiveLinkKey({
                  activeLinkKey: sidebarItem.title,
                }),
              );

              dispatch(
                setHeaderTitle({
                  headerTitle: sidebarItem.title,
                }),
              );
            }}
            isActive={getIsActive(sidebarItem)}
          />,
        );
      });

      return elements;
    },
    [classes, getIsActive],
  );

  return (
    <Navbar
      sx={(theme) => ({
        background: theme.colors.blue[0],
        width: rem(217),
        borderRadius: '0 40px 40px 0',
      })}
      p={`${rem(24)} 0 ${rem(32)}`}
    >
      <Flex direction="column" justify="space-between" sx={{ flexGrow: 1 }}>
        <Box className={classes.navitemWrapper}>
          <LogoIcon
            className="logo-icon"
            style={{ width: rem(217), height: rem(72) }}
          />
          <Stack spacing={8}>{generateSidebarItems(SidebarData.top)}</Stack>
        </Box>
        <Box className={classes.navitemWrapper}>
          <Stack spacing={8}>
            {generateSidebarItems(SidebarData.bottom, isAuthenticated)}
          </Stack>
        </Box>
      </Flex>
    </Navbar>
  );
}

function SidebarItem({ itemData, isActive, handleClick }) {
  const { classes, cx } = useStyles({ isActive });

  return (
    <Link to={itemData.path}>
      {itemData.renderAsButton ? (
        <MainButton
          icon={itemData.icon}
          sx={{ height: rem(36), width: rem(131), marginLeft: rem(16) }}
          customClassName={classes.responsiveIcon}
        >
          {itemData.title}
        </MainButton>
      ) : (
        <Group
          className={cx(classes.navitem, { [classes.active]: isActive })}
          h={rem(40)}
          onClick={handleClick}
        >
          <Box className={classes.responsiveIcon}>{itemData.icon}</Box>
          <Text fz="md" fw={500} c="white.1">
            {itemData.title}
          </Text>
        </Group>
      )}
    </Link>
  );
}

export default Sidebar;
