import React, { useCallback } from 'react';
import { Box, Flex, Group, rem, Stack, UnstyledButton } from '@mantine/core';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left-bold-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/cross-icon.svg';
import CustomText from '../../Typography/CustomText/CustomText';
import { useStyle } from './Tooltip.style';
import { useMemo } from 'react';

const CustomTooltip = ({ tooltipProps, step, ...rest }) => {
  const { classes, cx } = useStyle();

  const handleClick = useCallback(
    (index) => {
      step.setStepIndex(index);

      if (step.onClick && step.stepIndex < index) {
        step.onClick();
      }
    },
    [step, rest],
  );

  const progress = useMemo(() => {
    const { isLastStep, size } = rest;
    const progressOfOneStep = 100 / size;

    let currentStep = step.stepIndex + 1;

    if (!Number.isInteger(currentStep)) {
      currentStep = 1;
    }

    if (isLastStep) {
      return progressOfOneStep * currentStep;
    }

    return Math.floor(progressOfOneStep) * currentStep;
  }, [step, rest]);

  return (
    <>
        <Box
          className={cx(classes.tooltipContainer, {
            [classes.tooltipContainerOffsetLeft]: /top-start|bottom-start/.test(
              step.placement,
            ),
            [classes.tooltipContainerOffsetRight]: /top-end|bottom-end/.test(
              step.placement,
            ),
            [classes.tooltipContainerOffsetBottom]: /left-end|right-end/.test(
              step.placement,
            ),
            [classes.tooltipContainerBigPadding]: step.withCloseButton,
          })}
          sx={{
            transform: step.xOffset && `translateX(${step.xOffset}px)`,
          }}
          {...tooltipProps}
        >
          {step?.withCloseButton && (
            <Box className={classes.crossIcon}>
              <UnstyledButton onClick={step.onClose}>
                <CloseIcon />
              </UnstyledButton>
            </Box>
          )}
          {step.customBeacon && (
            <Box
              w={24}
              h={24}
              className={cx(classes.beacon, {
                [classes.beaconLeft]: step.placement.includes('right'),
              })}
            />
          )}
          <Stack spacing={8}>
            <CustomText
              variant="h6"
              sx={{
                maxWidth: rem(341),
                minWidth: rem(261),
                fontWeight: 600,
                lineHeight: '120%',
              }}
            >
              {step.content}
            </CustomText>
            {(!step.withoutNavigation || step.withProgress) && (
              <Flex justify="space-between">
                {(!step.withoutNavigation || step.withProgress) && (
                  <CustomText variant="h6" sx={{ opacity: 0.4 }}>
                    {progress}% complete
                  </CustomText>
                )}
                {!step.withoutNavigation && (
                  <Group spacing={8}>
                    <UnstyledButton
                      className={cx(classes.navigationButton, {
                        [classes.disabled]: step.disabledPrevButton,
                      })}
                      onClick={() =>
                        !step.disabledPrevButton &&
                        handleClick(step.stepIndex - 1)
                      }
                    >
                      <ArrowLeftIcon
                        style={{ width: rem(12), height: rem(12) }}
                      />
                    </UnstyledButton>
                    <UnstyledButton
                      className={classes.navigationButton}
                      onClick={() => handleClick(step.stepIndex + 1)}
                    >
                      <ArrowLeftIcon
                        style={{
                          transform: 'rotate(180deg)',
                          width: rem(12),
                          height: rem(12),
                        }}
                      />
                    </UnstyledButton>
                  </Group>
                )}
              </Flex>
            )}
          </Stack>
        </Box>
    </>
  );
};

export default CustomTooltip;
