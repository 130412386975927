import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  menuRoot: {
    width: `${rem(175)}rem !important`,
    padding: `${rem(14)} 0`,
    border: 'none',
    borderRadius: 8,
  },
  datePickerRoot: {
    padding: `0 ${rem(8)}`,
    transform: 'translateY(-10%)',
  },
  datePickerHeader: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  datePickerMonthCell: {
    '&:last-of-type': {
      '& .mantine-DatePicker-day': {
        borderRadius: '0 4px 4px 0',
      },
    },

    '&:first-of-type': {
      '& .mantine-DatePicker-day': {
        borderRadius: '4px 0 0 4px',
      },
    },

    '& [data-with-spacing="true"]': {
      padding: 0,
    },

    '& .mantine-DatePicker-day': {
      height: rem(32),
      width: rem(42),
      fontSize: rem(12),
      fontWeight: 400,
      fontFamily: 'Manrope',
      padding: `0 ${rem(5)}rem`,
    },

    '& [data-in-range="true"]': {
      background: '#D7D6F2 !important',
      color: `${theme.colors.black[0]} !important`,
    },

    '& [data-in-range="true"]:hover': {
      background: '#D7D6F2  !important',
    },

    '& [data-selected="true"]': {
      background: `${theme.colors.blue[0]} !important`,
      color: `${theme.colors.white[0]} !important`,
    },

    '& [data-selected="true"]: hover': {
      background: `${theme.colors.blue[0]} !important`,
    },
  },
  datePickerWeekday: {
    fontSize: rem(12),
    fontWeight: 600,
    fontFamily: 'Manrope',
    textAlign: 'center',
  },
  selectedDateContainer: {
    background: theme.colors.white[1],
    borderRadius: 4,
    padding: `${rem(5)} ${rem(9)}`,
  },
}));
