export const theme = {
  colors: {
    blue: ['#0C0A44'],
    red: ['#ED7470', '#F58D89'],
    white: ['white', '#EDEDED', '#FCFCFC', '#F7F7F7', '#F7F9FC'],
    black: ['#0F0E24', '#0C0A44'],
    gray: [
      'rgba(215, 214, 242, 0.3)',
      'rgba(215, 214, 242, 0.6)',
      '#F7F7F7',
      '#DDD',
      '#8C8B95',
      '#BCBCC2',
      '#B4B8CE',
    ],
    yellow: ['#FBDDA2', '#F6C25D'],
    purple: ['#D7D6F2', '#DEE0ED'],
  },
  headings: {
    fontFamily: 'New Kansas',
  },
  components: {
    Text: {
      styles: {
        root: {
          fontFamily: 'Manrope',
        },
      },
    },
    Paper: {
      styles: {
        root: {
          backgroundColor: '#F7F7F7 !important',
        },
      },
    },
  },
};
