import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { auth0config } from '../config.js';
import jwtDecode from 'jwt-decode';

import logger from '../utils/logger';

const claimsNamespace = auth0config.claimsNamespace;
const orgIdClaim = `${claimsNamespace}/orgId`;
const subscriptionClaim = `${claimsNamespace}/subscription`;

const useJwtToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchToken = useCallback(async (ignoreCache = false) => {
    setLoading(true);
    try {

      const options = {
        audience: auth0config.audience,
      };

      if (ignoreCache) {
        options.cacheMode = 'off';
      }

      logger.debug('Fetching token with options:', options);

      const authToken = await getAccessTokenSilently(options);

      logger.debug('Fetched auth token', JSON.stringify(jwtDecode(authToken)));

      setToken(authToken);

      if (authToken) {
        logger.debug('Setting token claims');
        const orgId = jwtDecode(authToken)[orgIdClaim];
        const permissions = jwtDecode(authToken).permissions;
        const subscriptionStatus = jwtDecode(authToken)[subscriptionClaim]?.status;

        setOrgId(orgId);
        setPermissions(permissions);
        setSubscriptionStatus(subscriptionStatus);
      }

      return Promise.resolve(authToken);
    } catch (error) {
      logger.error('Error fetching token:', error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, token]);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    logger.debug('Current JWT Token state', { orgId, permissions, subscriptionStatus });
  }, [orgId, permissions, subscriptionStatus]);

  // This method allows manual token refresh, potentially ignoring cache.
  const refreshToken = useCallback((options = {}) => {
    return fetchToken(options.ignoreCache);
  }, [fetchToken]);

  return { token, loading, permissions, orgId, subscriptionStatus, refreshToken, subscriptionClaim };
};

export default useJwtToken;
