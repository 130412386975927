import { Box, Notification, rem } from '@mantine/core';
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../features/layoutSlice';

function Snackbar() {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const snackbarOptions = useSelector((state) => state.layout.snackbarOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!snackbarOptions?.variant) {
      setShowSnackbar(false);
      return;
    }

    setShowSnackbar(true);

    setTimeout(() => {
      dispatch(setSnackbar({}));
    }, 3000);
  }, [snackbarOptions]);

  const handleClose = useCallback(() => {
    setShowSnackbar(false)
  }, [])

  return (
    <>
      {showSnackbar && (
        <Box sx={{ position: 'fixed', right: rem(30), bottom: rem(50), zIndex: 50 }}>
          <Notification
            color={snackbarOptions?.variant === 'success' ? 'green' : 'red.0'}
            onClick={handleClose}
          >
            {snackbarOptions?.description}
          </Notification>
        </Box>
      )}
    </>
  );
}

export default Snackbar;
