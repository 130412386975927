import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../../features/layoutSlice';
import useRadarApi from '../../../../hooks/useRadarApi';
import useJwtToken from '../../../../hooks/useJwtToken';
import useFetch from '../../../../hooks/useFetch';

const useTeam = () => {
  const { getUsersFromTeam, addUserToTeam, deleteUserFromTeam } = useRadarApi();
  const { loading: jwtTokenLoading } = useJwtToken();
  const [users, setUsers] = useState([]);
  const [fetchFunc, loading] = useFetch(getUsersFromTeam);
  const [addUserLoading, setAddUserLoading] = useState(false);
  const dispatch = useDispatch();

  const addUsertoTeamWrapper = useCallback(
    (data, callback) => {
      setAddUserLoading(true);
      addUserToTeam(data)
        .then((res) => {
          setUsers((prevState) => [...prevState, res]);
          dispatch(
            setSnackbar({
              variant: 'success',
              description: 'An invitation has been sent',
            }),
          );
          if (callback) {
            callback();
          }
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              variant: 'error',
              description: 'Something went wrong...',
            }),
          );
        })
        .finally(() => {
          setAddUserLoading(false);
        });
    },
    [jwtTokenLoading, addUserToTeam],
  );

  const deleteUserFromTeamWrapper = useCallback(
    (userId, callback) => {
      deleteUserFromTeam(userId)
        .then(() => {
          if (callback) {
            callback();
          }
          setUsers((prevState) =>
            prevState.filter((user) => user._id !== userId),
          );
          dispatch(
            setSnackbar({
              variant: 'success',
              description: 'The user has been deleted',
            }),
          );
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              variant: 'error',
              description: 'Something went wrong...',
            }),
          );
        });
    },
    [jwtTokenLoading, deleteUserFromTeam],
  );

  const fetchWrapper = useCallback(async () => {
    const data = await fetchFunc();
    setUsers(data || []);
  }, [fetchFunc, getUsersFromTeam]);

  useEffect(() => {
    if (!jwtTokenLoading) {
      fetchWrapper();
    }
  }, [jwtTokenLoading]);

  return {
    users,
    loading,
    addUserLoading,
    addUserToTeam: addUsertoTeamWrapper,
    deleteUserFromTeam: deleteUserFromTeamWrapper,
  };
};

export default useTeam;
