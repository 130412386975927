import { createStyles } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  checkbox: {
    borderRadius: '50%',
    borderColor: "#000",

    '&:checked': {
        borderColor: theme.colors.red[0]
    },
  },
}));
