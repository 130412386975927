import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  tooltipContainer: {
    background: theme.colors.yellow[1],
    padding: rem(12),
    borderRadius: rem(8),
  },
  tooltipContainerOffsetLeft: {
    transform: `translateX(${rem(15)})`,
  },
  tooltipContainerOffsetRight: {
    transform: `translateX(${rem(-20)})`,
  },
  tooltipContainerOffsetBottom: {
    transform: `translateY(${rem(-20)})`,
  },
  tooltipContainerBigPadding: {
    paddingRight: rem(24),
    paddingTop: rem(12),
  },
  navigationButton: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.colors.black[1]}`,
    background: 'none',
    borderRadius: 100,
    padding: `${rem(6)} ${rem(16)}`,

    '& svg': {
      height: rem(12),
    },

    '&:hover': {
      borderColor: theme.colors.black[1],
    },
  },
  beacon: {
    background: theme.colors.yellow[1],
    borderRadius: '50%',
    position: 'absolute',
    pointerEvents: 'none',
  },
  beaconLeft: {
    left: rem(-30),
    top: rem(15),
  },
  disabled: {
    opacity: 0.4,
  },
  crossIcon: {
    position: 'absolute',
    top: rem(4),
    right: rem(6),

    '& svg': {
      width: rem(20),
    },
  },
}));
