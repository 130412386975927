import { Timeline } from "@mantine/core";
import CustomText from "../../components/Typography/CustomText/CustomText";

export const getTimeLine = (index) => (
  <Timeline active={index}>
    <Timeline.Item>
      <CustomText variant="h3">We’re creating your account</CustomText>
    </Timeline.Item>
    <Timeline.Item>
      <CustomText variant="h3">
        Then you’ll be asked to input your email again
      </CustomText>
    </Timeline.Item>
    <Timeline.Item>
      <CustomText variant="h3">
        You’ll receive a confirmation code in your inbox (Passport Security is
        tight around here)
      </CustomText>
    </Timeline.Item>
    <Timeline.Item>
      <CustomText variant="h3">
        Then you’ll connect your HubSpot account
      </CustomText>
    </Timeline.Item>
  </Timeline>
);

