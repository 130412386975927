import React, { useCallback, useState } from 'react';
import { Box, rem, Stack, Stepper } from '@mantine/core';
import ContentWithImage from '../../ContentWithImage/ContentWithImage';
import Heading from '../../Heading';
import CustomSegmentControl from '../../SegmentControl/SegmentControl';
import { useStyle } from './RecommendationsSection.style';
import ButtonGroup from '../components/ButtonGroup/ButtonGroup';
import { useMemo } from 'react';
import ImageContent from '../components/ImageContent/ImageContent';
import preparationFirstImage from '../../../assets/images/preparation-first-image.png';
import preparationSecondImage from '../../../assets/images/preparation-second-image.png';
import preparationThirdImage from '../../../assets/images/preparation-third-image.png';
import ImageContentWebsite from '../components/ImageContent/ImageContentWebsite';

import logger from '../../../utils/logger';

const CARDS = [
  {
    imageUrl: preparationThirdImage,
    title: 'Brit Awards 2023: Date, line-up and how to watch',
  },
  {
    imageUrl: preparationSecondImage,
    title: 'Brit Awards 2023: Date, line-up and how to watch',
  },
  {
    imageUrl: preparationFirstImage,
    title: 'Brit Awards 2023: Date, line-up and how to watch',
  },
];

const TAGS = ['TAG1', 'TAG2', 'TAG3'];
const ANOTHER_TAGS = ['TAG4', 'TAG5', 'TAG6'];
const SEGEMENT_CONTROL_OPTIONS = ['EMAIL', 'WEBSITE'];

function RecommendationsSection({ onContinue }) {
  const [tags, setTags] = useState(TAGS);
  const [segmentControlOption, setSegmentControlOption] = useState(
    SEGEMENT_CONTROL_OPTIONS[0],
  );
  const { classes } = useStyle();

  const tagsList = useMemo(() => {
    logger.info(tags);
    return `${tags[0]} ${tags[1]} and ${tags[2]}`;
  }, [tags]);

  const updateTags = useCallback(() => {
    setTags(ANOTHER_TAGS);
  }, []);

  return (
    <ContentWithImage
      content={
        <Stack mt={rem(88)} w={rem(500)} spacing={40}>
          <Stepper activeIndex={1} stepsAmount={3} />
          <Heading
            spacing={24}
            small
            align="left"
            title="Recommendations for Katie Barker"
            description={
              <>
                Because Katie has engaged with content related to
                <br />
                {tagsList}, this is the best content
                <br />
                you should share with them next.
              </>
            }
          />
        </Stack>
      }
      image={
        <Stack spacing={rem(104)} mr={rem(80)} mb={rem(88)} align="flex-end">
          <Stack spacing={24} align="flex-end">
            <CustomSegmentControl
              indicatorClassName={classes.segmentIndicator}
              controlActiveClassName={classes.segmentControlActive}
              rootClassName={classes.segmentRoot}
              value={segmentControlOption}
              onChange={setSegmentControlOption}
              data={[
                { value: SEGEMENT_CONTROL_OPTIONS[0], label: 'Email' },
                { value: SEGEMENT_CONTROL_OPTIONS[1], label: 'Website' },
              ]}
            />
            <Box
              sx={{
                width: rem(795),
                marginRight: rem(-52),
              }}
            >
              {segmentControlOption === SEGEMENT_CONTROL_OPTIONS[0] ? (
                <ImageContent
                  title="Top music reads handpicked for you"
                  company="Your company"
                  name="Beyonce"
                  email="yourcompany@gmail.com"
                  cards={CARDS}
                />
              ) : (
                <ImageContentWebsite
                  title="Discover the latest in music this week"
                  cards={CARDS}
                />
              )}
            </Box>
          </Stack>
          <ButtonGroup
            sx={{ width: rem(205), color: 'white' }}
            reversed
            mainText="Show me another!"
            secondaryText="Continue"
            onMainButtonClick={updateTags}
            onSecondaryButtonClick={onContinue}
          />
        </Stack>
      }
    />
  );
}

export default RecommendationsSection;
