import { createStyles } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  segmentRoot: {
    background: '#CCCBD7',
  },
  segmentIndicator: {
    background: theme.colors.white[0]
  },
  segmentControlActive: {
    '& .mantine-SegmentedControl-label': {
      color: theme.colors.black[0],
    },
    '& .mantine-SegmentedControl-label:hover': {
      color: theme.colors.black[0],
    },
  },
}));
