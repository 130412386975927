import { rem, Stack } from '@mantine/core';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserCompetitors } from '../../../features/layoutSlice';
import ContentWithImage from '../../ContentWithImage/ContentWithImage';
import Heading from '../../Heading';
import ButtonGroup from '../components/ButtonGroup/ButtonGroup';
import InputGroup from '../components/InputGroup/InputGroup';
import Stepper from '../components/Stepper/Stepper';

function TrackCompetitor({onContinue, onSkip}) {
  const [competitorData, setCompetitorData] = useState([
    {
      leftValue: '',
      rightValue: '',
      leftPlaceholder: 'Competitor name',
      rightPlaceholder: 'www.competitor.com',
    },
  ]);
  const dispatch = useDispatch()
  const competitors = useSelector(state => state.layout.userCompetitors)

  const onChange = useCallback(
    (value, key, index) => {
      const newState = [...competitorData];
      newState[index][key] = value;
      setCompetitorData(newState);
    },
    [competitorData],
  );

  const handleContinue = useCallback(() => {
    dispatch(setUserCompetitors({
        competitors: [...competitors, competitorData[0].rightValue]
    }))
    onContinue()
  }, [onContinue])

  return (
    <ContentWithImage
      content={
        <Stack mt={rem(88)} w={rem(500)} spacing={40}>
          <Stepper activeIndex={2} stepsAmount={3} />
          <Heading
            spacing={24}
            small
            align="left"
            title="Track your competitors’ content"
            description={
              <>
                We'll track what your competitors are writing about to show you
                what topics you might want to create more content about.
                <br />
                <br />
                You can add choose to add just one competitor for now, and your
                other competitors later, or you can add up to 5 competitors now.
              </>
            }
          />
        </Stack>
      }
      image={
        <Stack spacing={rem(380)} mr={rem(106)} mb={rem(88)} align="flex-end">
          <InputGroup
            sameWidth
            leftTitle="Competitor name"
            rightTitle="Website"
            inputs={competitorData}
            onChange={onChange}
          />
          <ButtonGroup
            mainText="Save competitor"
            secondaryText="I’ll do it later"
            sx={theme => ({color: theme.colors.white[2], width: "fit-content", padding: `0 ${rem(42)}`})}
            onMainButtonClick={handleContinue}
            onSecondaryButtonClick={onSkip}
          />
        </Stack>
      }
    />
  );
}

export default TrackCompetitor;
