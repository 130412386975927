import { useCallback, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';
import { getFromEngagmentRange } from '../../../utils';
import { AUDIENCE_CHART_OPTIONS } from '../constants';

const useFetchSummaries = (jwtTokenLoading, urlParams, selectedListsId) => {
  const { getTopicSummaries } = useRadarApi();
  const [topicSummaries, setTopicSummaries] = useState([]);
  const [topicSummariesContent, setTopicSummariesContent] = useState([]);
  const [fetchTopicSummaries, loading] = useFetch(getTopicSummaries);
  const [topicSummariesOffset, setTopicSummariesOffset] = useState(0);

  const fetchWrapper = useCallback(
    async (fetchMore) => {
      let currentOffset;

      if (fetchMore) {
        currentOffset = topicSummariesOffset;
      } else {
        currentOffset = 0;
        setTopicSummariesOffset(0);
      }

      let activeRelevance = (urlParams?.activeRelevance?.[0] || '').split(' ');
      activeRelevance = activeRelevance[activeRelevance.length - 1];
      const activeTopicIndex = AUDIENCE_CHART_OPTIONS.findIndex(
        (el) => el === activeRelevance,
      );

      const filterTopics = urlParams?.['searchBy[title]'];

      const data = await fetchTopicSummaries(
        {
          offset: currentOffset,
          limit: 15,
          sortBy: urlParams.sortBy,
          sortDirection: urlParams.sortDirection,
          ...(activeTopicIndex !== -1
            ? {
                'filter[audienceRelevance]':
                  AUDIENCE_CHART_OPTIONS[activeTopicIndex].toUpperCase(),
              }
            : {}),
          ...(urlParams?.['searchBy[title]']?.length
            ? {
                'filter[topic]': filterTopics,
              }
            : {}),
          ...(urlParams?.['list']?.length
            ? {
                'filter[listId]': selectedListsId,
              }
            : {}),
        },
        fetchMore,
      );

      const transformContent = (content) =>
        content.map((el) => ({
          topic: el.value,
          contacts: el.contacts,
          id: el.id,
          coverageScore: {
            value: el.coverageScore,
            title: getFromEngagmentRange(el.coverageScore)?.title,
          },
        }));

      if (fetchMore) {
        setTopicSummariesContent((prevState) =>
          prevState.concat(transformContent(data?.content || []) || []),
        );
      } else {
        setTopicSummariesContent(transformContent(data?.content || []));
      }

      setTopicSummaries(data);
    },
    [
      fetchTopicSummaries,
      topicSummariesOffset,
      getTopicSummaries,
      urlParams,
      selectedListsId,
    ],
  );

  useEffect(() => {
    if (!jwtTokenLoading && urlParams) {
      if (urlParams.list.length) return;
      fetchWrapper();
    }
  }, [
    jwtTokenLoading,
    urlParams?.sortBy,
    urlParams?.sortDirection,
    urlParams?.activeRelevance,
    urlParams?.['searchBy[title]'],
    urlParams?.list,
  ]);

  useEffect(() => {
    if (!jwtTokenLoading && urlParams) {
      if (!urlParams.list.length || !selectedListsId.length) return;
      fetchWrapper();
    }
  }, [
    jwtTokenLoading,
    urlParams?.sortBy,
    urlParams?.sortDirection,
    urlParams?.activeRelevance,
    urlParams?.['searchBy[title]'],
    selectedListsId,
  ]);

  useEffect(() => {
    if (!loading && topicSummariesOffset > 0) {
      fetchWrapper(true);
    }
  }, [topicSummariesOffset]);

  return [
    topicSummaries,
    topicSummariesContent,
    topicSummariesOffset,
    setTopicSummariesOffset,
    loading,
  ];
};

export default useFetchSummaries;
