import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  container: {
    marginTop: 24,
    paddingTop: 24,
    borderTop: `${rem(1)} solid ${theme.colors.gray[3]}`,
  },
  selectedCompetitorContainer: {
    marginTop: 0,
    paddingTop: 0,
    borderTop: 0
  },
  editButtonWithTitles: {
    height: 31,
    display: "flex",
    alignItems: "flex-start"
  },
  editCompetitorSeparator: {
    height: 1,
    width: "100%",
    background: theme.colors.gray[3],
    margin: `${rem(24)} 0`,
  }
}));
