import useTable from '../../../hooks/useTable';
import { useMemo } from 'react';

const useContentToPromoteTable = (contentToPromote) => {
  const { getTableData } = useTable();

  const tableData = useMemo(() => {
    let data = [];

    if (contentToPromote) {
      data = contentToPromote.map((el) => ({
        content: {
          title: el.title,
          imageUrl: el.imageUrl,
        },
        id: el.id,
        matches: el.matches,
      }));
    }

    return getTableData(
      data,
      ['Content', '_id', 'Matches'],
      [
        {
          columnSpan: 8,
          useContent: true,
          withoutSorting: true,
        },
        {
          columnSpan: 0,
        },
        {
          columnSpan: 4,
          withoutSorting: true,
        },
      ],
      true,
    );
  }, [contentToPromote, getTableData]);

  return tableData;
};

export default useContentToPromoteTable;
