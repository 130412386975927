import React from 'react';

function PageLoader() {
  return (
    <div className="">
      <h1>Loading...</h1>
    </div>
  );
}

export default PageLoader;
