import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  cardsContainer: {
    flexWrap: 'wrap',
    columnGap: rem(24),
    rowGap: rem(16),
    justifyContent: 'center',
  },
  containerWithScroll: {
    maxHeight: rem(534),
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },

    /* Hide scrollbar for IE, Edge and Firefox */
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
  showingText: {
    position: 'absolute',
    right: rem(15),
    top: rem(-10),
    transform: 'translateY(-100%)',
  },
}));
