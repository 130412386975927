import React from 'react';
import { Box, rem, Stack } from '@mantine/core';
import ContentWithImage from '../../ContentWithImage/ContentWithImage';
import imageATCWithHubspot from '../../../assets/images/atc-with-hubspot-image.png';
import CustomText from '../../Typography/CustomText/CustomText';
import MainButton from '../../buttons/buttons';
import { useStyle } from './GetUpComponent.style';
import Heading from '../../Heading';

function GetUpComponent({ name, tags, onContinue }) {
  const { classes } = useStyle();

  return (
    <ContentWithImage
      content={
        <Stack spacing={56} w={rem(500)} mt={rem(88)}>
          <Heading
            title="Let’s get things set up!"
            description={
              <>
                By connecting Hubspot, you now have an interest profile <br />{' '}
                for every contact
              </>
            }
            align="start"
            small
          />
          <Stack spacing={0}>
            <CustomText variant="bodySmall">For example:</CustomText>
            <Box className={classes.tagsContainer}>
              <CustomText
                variant="bodyLarge"
                sx={{ fontWeight: 700, width: '95%' }}
              >
                {name} has engaged with content related to {tags}
              </CustomText>
            </Box>
            <CustomText variant="bodySmall">
              Let’s add your content to get recommendations
              <br />
              and content insight.
            </CustomText>
          </Stack>
        </Stack>
      }
      image={
        <Stack spacing={0} className={classes.container}>
          <img src={imageATCWithHubspot} className={classes.image} />
          <Box mt={rem(70)} mb={rem(32)}>
            <CustomText
              variant="bodySmall"
              sx={(theme) => ({ color: theme.colors.white[2] })}
            >
              Rome wasn’t built in a day but your access to personalized content
              will be!
              <br />
              <br />
              Just a small reminder that once we have everything we need it may
              take up to 24hrs to get you set up in the radar.
            </CustomText>
          </Box>
          <Box ml="auto">
            <MainButton
              hugePadding
              onClick={onContinue}
              sx={{ width: rem(283) }}
            >
              Let’s go!
            </MainButton>
          </Box>
        </Stack>
      }
    />
  );
}

export default GetUpComponent;
