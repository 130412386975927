import React, { useMemo } from 'react';
import { Stack } from '@mantine/core';
import EditableInput from '../../../EditableInput/EditableInput';

function EditableInputGroup({ keys, disabled, form }) {
  const inputGroup = useMemo(() => keys.map((inputKey) => (
        <EditableInput
            key={inputKey}
            disabled={disabled}
            {...form.getInputProps(inputKey)}
        />
  )), [keys, disabled, form]);

  return (
    <Stack spacing={0}>
        {inputGroup}
    </Stack>
  );
}

export default EditableInputGroup;
