import React, { memo } from 'react';
import { Box, rem } from '@mantine/core';
import { useInView } from 'react-intersection-observer';

function VirtualScrollChild({ children, rootRef, disabled }) {
  const [ref, inView] = useInView({
    rootMargin: '200px 0px',
    threshold: 0,
    root: rootRef,
  });
  return (
    <Box ref={ref} mih={rem(50)}>
      {(inView || disabled) ? children : null}
    </Box>
  );
}

export default memo(VirtualScrollChild);
