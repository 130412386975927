import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  inputsContainer: {
    maxHeight: rem(500),
    paddingBottom: rem(16),
    overflow: "scroll",
    msOverflowStyle: "none",

    "&::-webkit-scrollbar": {
        display: "none"
    }
  }
}));
