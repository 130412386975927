import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  tab: {
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderBottom: 'none',
    borderRadius: `${rem(20)} ${rem(20)} 0 0`,
    padding: `${rem(7)} ${rem(24)}`,

    '&:hover': {
      borderColor: theme.colors.gray[3],
    },

    '&[data-active="true"]': {
      background: theme.colors.gray[2],

      '&::before': {
        backgroundColor: theme.colors.gray[2],
      },

      '& .mantine-Text-root': {
        color: theme.colors.black[0],
        fontWeight: 700,
      },
    },
  },
  tabList: {
    width: 'fit-content',
    border: 'none',
  },
  tabLabel: {
    '& .mantine-Text-root': {
      color: '#8C8B95',
      fontWeight: 500,
    },
  },
  panel: {
    background: theme.colors.gray[2],
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: `0 ${rem(20)} ${rem(20)} ${rem(20)}`,
    padding: rem(16),
  },
  noPadding: {
    padding: 0,
  },
}));
