import {
  Box,
  Center,
  Flex,
  Group,
  Stack,
  UnstyledButton,
  rem,
} from '@mantine/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross-icon.svg';
import CustomSelectionMenu from '../../../components/CustomDropdown/CustomSelectionMenu/CustomSelectionMenu';
import CustomText from '../../../components/Typography/CustomText/CustomText';
import { useStyle } from '../TopicsPage.style';
import FilterPanel from '../../../components/CustomDropdown/FilterPanel/FilterPanel';
import MainButton from '../../../components/buttons/buttons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateSettingsLoading } from '../../../features/settingsSlice';
import BasicModal from '../../../components/Modals/BasicModal';
import CustomTitle from '../../../components/Typography/CustomTitle/CustomTitle';

function FilterPanelDropdown({
  filterOpened,
  setFilterOpened,
  updateUrlParams,
  filterByData,
  filteringParams,
  setFilteringParams,
  filtersControlRef,
  hubspotDetails,
  selectedListsId,
  onlyDropdown,
}) {
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [unselectedListCtaOpened, setUnselectedCtaOpened] = useState(false);
  const [selectionMenuOpened, setSelectionMenuOpened] = useState(false);
  const { classes, cx } = useStyle();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClick = ({ target }) => {
      if (
        dropdownRef.current.contains(target) ||
        filtersControlRef.current.contains(target)
      )
        return;
      setFilterOpened(false);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [filterOpened]);

  useEffect(() => {
    if (!hubspotDetails || !filteringParams?.list?.length) return;
    if (
      !hubspotDetails.contactLists.find(
        (list) =>
          list.name ===
          (typeof filteringParams?.list === 'string'
            ? filteringParams?.list
            : filteringParams?.list?.[0]),
      ) &&
      !hubspotDetails?.syncAllContacts
    ) {
      setUnselectedCtaOpened(true);
    }
  }, [filteringParams?.list]);

  const updateValue = useCallback(
    (value, key) => {
      const isTheSameValue = filteringParams[key] === value;

      if (!isTheSameValue) {
        setFilteringParams((prevValue) => ({
          ...prevValue,
          [key]: value,
        }));
        if (updateUrlParams) {
          updateUrlParams({
            ...filteringParams,
            [key]: value,
          });
        }
      } else {
        setFilteringParams((prevValue) => ({
          ...prevValue,
          [key]: '',
        }));
        if (updateUrlParams) {
          updateUrlParams({
            ...filteringParams,
            [key]: '',
          });
        }
      }
    },
    [filteringParams],
  );

  const getExternalStyles = (option) => {
    if (hubspotDetails?.syncAllContacts) return {};
    if (
      !hubspotDetails.contactLists.find((list) => list.name === option.title)
    ) {
      return {
        color: '#ED7470',
      };
    }
    return {};
  };

  const handleNavigation = () => {
    navigate(`/settings?listIds=${encodeURIComponent(selectedListsId)}`, {
      state: {
        originUrl: (window?.location?.pathname || '').replace('/', ''),
      },
    });
    dispatch(updateSettingsLoading({ loading: true }));
  };

  const filteredOptions = useMemo(() => {
    return (filterByData || [])
      .filter((list) =>
        (list?.title || '')
          .toLowerCase()
          .includes(filterSearchValue.toLowerCase()),
      )
      .sort((a, b) => {
        const aIsSelected = selectedListsId === a.listId;
        const bIsSelected = selectedListsId === b.listId;
        if (aIsSelected && bIsSelected) return a.title > b.title ? 1 : -1;
        else if (aIsSelected) {
          return -1;
        } else if (bIsSelected) {
          return 1;
        }

        const aIsSynced = hubspotDetails.contactLists.find(
          (list) => list.name === a.title,
        );
        const bIsSynced = hubspotDetails.contactLists.find(
          (list) => list.name === b.title,
        );
        if (aIsSynced && bIsSynced) return a.title > b.title ? 1 : -1;
        else if (aIsSynced) {
          return -1;
        } else if (bIsSynced) {
          return 1;
        }

        return a.title > b.title ? 1 : -1;
      });
  }, [filterByData, filterSearchValue]);

  const closeUnselectedListCta = () => {
    setUnselectedCtaOpened(false);
    updateValue('', 'list');
  };

  const selectionMenuTitleValue = useMemo(() => {
    if (selectionMenuOpened) {
      return filterSearchValue;
    }

    const currentSelectedOption = filteringParams?.list;
    return typeof currentSelectedOption === 'string'
      ? currentSelectedOption
      : currentSelectedOption?.[0];
  }, [filteringParams, selectionMenuOpened, filterSearchValue]);

  return (
    <>
      {!onlyDropdown ? (
        <FilterPanel
          filterOpened={filterOpened}
          setFilterOpened={setFilterOpened}
        >
          <Box className={classes.filterDropdown} ref={dropdownRef}>
            <Box
              className={cx(classes.closeButton, 'filter__close-icon')}
              onClick={() => setFilterOpened(false)}
            >
              <CrossIcon />
            </Box>
            <Stack spacing={8}>
              <CustomText variant="h3">Filter</CustomText>
              <CustomText>Contact Lists</CustomText>
              <Flex gap={8} sx={{ flexWrap: 'wrap' }}>
                <Box>
                  <CustomSelectionMenu
                    title={
                      <input
                        className={classes.filterDropdownPlaceholder}
                        placeholder="Search..."
                        value={selectionMenuTitleValue}
                        onChange={(e) => setFilterSearchValue(e.target.value)}
                      />
                    }
                    selectedItemTitles={filteringParams?.list || ''}
                    onItemSelect={(value) => updateValue(value.title, 'list')}
                    menuOptions={filteredOptions}
                    withoutAnimation
                    maxHeight={rem(300)}
                    height={300}
                    getExternalStyles={getExternalStyles}
                    wihtoutCheckboxes
                    setState={setSelectionMenuOpened}
                  />
                </Box>
                <BasicModal
                  withCloseButton={false}
                  opened={unselectedListCtaOpened}
                  onClose={closeUnselectedListCta}
                >
                  <Stack w={rem(455)}>
                    <CustomTitle order={1} sx={{ fontSize: rem(20) }}>
                      Contact Lists
                    </CustomTitle>
                    <Center>
                      <Stack justify="center">
                        <CustomText variant="h4">
                          The list {filteringParams?.list} is not synced. Do you
                          want to...?
                        </CustomText>
                        <Stack spacing={rem(8)}>
                          <MainButton onClick={handleNavigation}>
                            Sync Now
                          </MainButton>
                          <UnstyledButton
                            className={classes.backButton}
                            onClick={() => setUnselectedCtaOpened(false)}
                          >
                            Close
                          </UnstyledButton>
                        </Stack>
                      </Stack>
                    </Center>
                  </Stack>
                </BasicModal>
              </Flex>
            </Stack>
          </Box>
        </FilterPanel>
      ) : (
        <Flex gap={8} sx={{ flexWrap: 'wrap' }}>
          <Box>
            <CustomSelectionMenu
              title={
                <input
                  className={classes.filterDropdownPlaceholder}
                  placeholder="Search..."
                  value={selectionMenuTitleValue}
                  onChange={(e) => setFilterSearchValue(e.target.value)}
                />
              }
              selectedItemTitles={filteringParams?.list || ''}
              onItemSelect={(value) => updateValue(value.title, 'list')}
              menuOptions={filteredOptions}
              withoutAnimation
              maxHeight={rem(300)}
              height={300}
              getExternalStyles={getExternalStyles}
              wihtoutCheckboxes
              setState={setSelectionMenuOpened}
            />
          </Box>
          <BasicModal
            withCloseButton={false}
            opened={unselectedListCtaOpened}
            onClose={closeUnselectedListCta}
          >
            <Stack w={rem(455)}>
              <CustomTitle order={1} sx={{ fontSize: rem(20) }}>
                Contact Lists
              </CustomTitle>
              <Center>
                <Stack justify="center">
                  <CustomText variant="h4">
                    The list{' '}
                    <span className={classes.boldText}>
                      {filteringParams?.list}
                    </span>{' '}
                    is not synced. Do you want to...
                  </CustomText>
                  <Group
                    spacing={rem(8)}
                    sx={{ flexWrap: 'nowrap', '& button': { width: '50%' } }}
                  >
                    <MainButton onClick={handleNavigation}>Sync Now</MainButton>
                    <UnstyledButton
                      className={classes.backButton}
                      onClick={() => setUnselectedCtaOpened(false)}
                    >
                      Close
                    </UnstyledButton>
                  </Group>
                </Stack>
              </Center>
            </Stack>
          </BasicModal>
        </Flex>
      )}
    </>
  );
}

export default FilterPanelDropdown;
