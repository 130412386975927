import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  contactItem: {
    height: rem(52),
    background: theme.colors.white[0],
    borderRadius: rem(14),
    padding: `${rem(12)} ${rem(16)} ${rem(12)} ${rem(24)}`,
    boxShadow: '0px 4px 24px 0px rgba(59, 93, 108, 0.10)',
    cursor: "pointer"
  },
  contactsContainer: {
    maxHeight: rem(316),
    overflow: "scroll",
    padding: `0 ${rem(16)} ${rem(20)}`
  },
  integateAllContainer: {
    marginLeft: "auto",
    cursor: "pointer"
  },
  switchThumb: {
    background: theme.colors.red[0],
    cursor: "pointer"
  },
  switchTrack: {
    background: theme.colors.white[0],
    border: `${rem(1)} solid ${theme.colors.red[0]}`,
    cursor: "pointer"
  },
}));
