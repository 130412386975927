import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  Group,
  Stack,
  TextInput,
  UnstyledButton,
  rem,
} from '@mantine/core';
import CustomText from '../../Typography/CustomText/CustomText';
import { useStyle } from './TeamTab.style';
import MainButton from '../../buttons/buttons';
import useTeam from './hooks/useTeam';
import SkeletonContainer from '../../SkeletonContainer';
import BasicModal from '../../Modals/BasicModal';
import CustomTitle from '../../Typography/CustomTitle/CustomTitle';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import { sanitizeParam } from '../../../utils';
import DOMPurify from 'dompurify';
import { useSearchParams } from 'react-router-dom';
import imagePlaceholder from '../../../assets/icons/placeholder.png';

function TeamTab() {
  const {
    users: teamMembers,
    loading,
    addUserLoading,
    addUserToTeam,
    deleteUserFromTeam,
  } = useTeam();
  const [searchParams] = useSearchParams();
  const [deleteConfimationModalOpened, setDeleteConfimationModalOpened] =
    useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const { classes, cx } = useStyle();

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validate: {
      firstName: isNotEmpty('First name must not be empty'),
      lastName: isNotEmpty('Last name must not be empty'),
      email: isEmail('Enter valid email'),
    },
  });

  const email = useMemo(
    () => sanitizeParam(DOMPurify.sanitize(searchParams.get('email'))),
    [searchParams],
  );
  const firstName = useMemo(
    () => sanitizeParam(DOMPurify.sanitize(searchParams.get('firstName'))),
    [searchParams],
  );
  const lastName = useMemo(
    () => sanitizeParam(DOMPurify.sanitize(searchParams.get('lastName'))),
    [searchParams],
  );

  useEffect(() => {
    form.setFieldValue('email', email);
    form.setFieldValue('firstName', firstName);
    form.setFieldValue('lastName', lastName);
  }, [email, firstName, lastName]);

  const getMemberFullName = (member) => {
    return `${member.firstName} ${member.lastName}`;
  };

  const addUser = useCallback(
    (values) => {
      addUserToTeam(values, () => form.reset());
    },
    [addUserToTeam],
  );

  const teamMembersList = useMemo(() => {
    const elements = [];

    teamMembers.forEach((member, index) => {
      elements.push(
        <Flex key={index} justify="space-between">
          <Group spacing={12}>
            <img src={imagePlaceholder} className={classes.memberImage} />
            <Stack spacing={0}>
              <CustomText variant="h4">{getMemberFullName(member)}</CustomText>
              <CustomText
                variant="h6"
                sx={(theme) => ({
                  color: theme.colors.gray[4],
                  fontWeight: 600,
                  textTransform: 'lowercase',
                  '&::first-letter': {
                    textTransform: 'uppercase',
                  },
                })}
              >
                {member.status}
              </CustomText>
            </Stack>
          </Group>
          <UnstyledButton>
            <CustomText
              variant="body2"
              sx={(theme) => ({ color: theme.colors.red[0] })}
              onClick={() => {
                setCurrentUserId(member._id);
                setDeleteConfimationModalOpened(true);
              }}
            >
              Remove
            </CustomText>
          </UnstyledButton>
        </Flex>,
      );
    });

    return elements;
  }, [teamMembers]);

  return (
    <>
      <Stack spacing={24} py={24}>
        {loading ? (
          <SkeletonContainer height={60} amount={6} />
        ) : (
          <>
            {teamMembers.length ? (
              <>{teamMembersList}</>
            ) : (
              <CustomText variant="h2">No users found</CustomText>
            )}
          </>
        )}
      </Stack>
      <Stack spacing={4} className={classes.sectionContainerBorderTop}>
        <Box w="75%">
          <CustomText variant="h4">Invite new team member</CustomText>
          <CustomText variant="body2">
            There are no limits to how many colleagues you can invite to Air
            Traffic Control. Once you submit their email address, they will be
            added to your account and receive an email letting them know how to
            get access.
          </CustomText>
        </Box>
      </Stack>
      <form onSubmit={form.onSubmit(addUser)}>
        <Stack spacing={0}>
          <Group spacing={10} w={rem(460)} noWrap>
            <TextInput
              placeholder="First name"
              classNames={{
                root: classes.inviteMemberInputWrapper,
                input: cx(
                  classes.input,
                  cx(classes.inviteMemberInput, classes.inviteMemberInputSmall),
                ),
                error: classes.inputError,
              }}
              {...form.getInputProps('firstName')}
            />
            <TextInput
              placeholder="Last name"
              classNames={{
                root: classes.inviteMemberInputWrapper,
                input: cx(
                  classes.input,
                  cx(classes.inviteMemberInput, classes.inviteMemberInputSmall),
                ),
                error: classes.inputError,
              }}
              {...form.getInputProps('lastName')}
            />
          </Group>
          <TextInput
            placeholder="Email address"
            classNames={{
              root: classes.inviteMemberInputWrapper,
              input: cx(classes.input, classes.inviteMemberInput),
              error: classes.inputError,
            }}
            {...form.getInputProps('email')}
          />
        </Stack>
        <MainButton disabled={addUserLoading} smallPadding type="submit">
          <CustomText variant="body2" sx={{ fontWeight: 600 }}>
            Send Invite
          </CustomText>
        </MainButton>
      </form>
      {/* Confirmation modal */}
      <BasicModal
        withCloseButton={false}
        opened={deleteConfimationModalOpened}
        onClose={() => {
          setDeleteConfimationModalOpened(false);
        }}
      >
        <Stack spacing={32} w={rem(500)}>
          <CustomTitle order={1} sx={{ textAlign: 'center' }}>
            Are you sure you want to proceed with this action?
          </CustomTitle>
          <Group grow>
            <MainButton
              onClick={() => {
                deleteUserFromTeam(currentUserId, () =>
                  setDeleteConfimationModalOpened(false),
                );
              }}
            >
              Confirm
            </MainButton>
            <UnstyledButton
              className={classes.cancelButton}
              onClick={() => setDeleteConfimationModalOpened(false)}
            >
              Cancel
            </UnstyledButton>
          </Group>
        </Stack>
      </BasicModal>
    </>
  );
}

export default TeamTab;
