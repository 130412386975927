import { useState, useEffect } from 'react';
import { Box, Center, Flex, Stack, Tabs } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useJwtToken from '../../hooks/useJwtToken.js';

import CompetitorAnalysis from './components/CompetitorAnalysis/CompetitorAnalysis.jsx';
import OutlineTabs from '../../components/OutlineTabs/OutlineTabs.jsx';
import TableWithSorting from '../../components/TableWithSorting/TableWithSorting.jsx';
import CustomText from '../../components/Typography/CustomText/CustomText.jsx';
import MainButton from '../../components/buttons/buttons.jsx';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-icon.svg';
import {
  setActiveLinkKey,
  setHeaderTitle,
} from '../../features/layoutSlice.js';
import CustomTitle from '../../components/Typography/CustomTitle/CustomTitle.jsx';
import FilterBar from '../../components/filtering/FilterBar/FilterBar.jsx';
import { useStyle } from './CompetitorsPage.style.jsx';
import CompetitorContent from './components/CompetitorContent/CompetitorContent.jsx';
import TimePeriodSelection from '../../components/filtering/TimePeriodSelection/TimePeriodSelection.jsx';
import useURLParams from '../../hooks/useURLParams.js';
import GuidedTour from '../../components/GuidedTour/GuidedTour.jsx';
import GuidedTourModal from '../../components/GuidedTour/GuidedTourModal/GuidedTourModal.jsx';
import useGuideModal from '../../hooks/useGuideModal.js';
import { getCompetitorsSteps } from '../../features/guidedTourSteps/competitorsPage.jsx';
import { setGuide } from '../../features/profileSlice.js';
import SkeletonContainer from '../../components/SkeletonContainer.jsx';
import TopicList from '../../components/filtering/FilterBar/Lists/TopicList/TopicList.jsx';
import useStateFromURL from '../../hooks/useStateFromURL.js';
import useFilter from '../../hooks/useFilter.js';
import useFetchAnalysisTimeSeriesData from './hooks/useFetchAnalysisTimeSeriesData.js';
import useFetchAnalysisMetricsData from './hooks/useFetchAnalysisMetricsData.js';
import useFetchCompetitorContents from './hooks/useFetchCompetitorContents.js';
import { DEFAULT_DATE_FILTER_PARAMS } from '../../components/filtering/TimePeriodSelection/constants.js';
import useActiveTab from '../../hooks/useActiveTab.js';

const HEADER_TITLE = 'Competition';
const HEADER_TITLE_NO_COMPETITORS = 'Your Competitors';

const TABS = ['Competitor Analysis', 'Competitor Content'];

const DEFAULT_CONTENT_SORTING_PARAMS = {
  sortBy: 'publishDate',
  sortDirection: 'desc',
  limit: 12,
  topics: [],
  filterByData: [],
  extraTopics: [],
  competitors: [],
  tab: [],
};

const DEFAULT_CONTENT_SORTING_OFFSET = {
  offset: 0,
};

const generateTableData = (analysisMetricsData) => {
  const tableData = {
    head: ['Name', 'News Coverage', 'Content Production', 'Social Engagement'],
    headOptions: [
      {
        columnSpan: 4,
      },
      {
        withoutAlignment: true,
        columnSpan: 0,
      },
      {
        withoutAlignment: true,
        columnSpan: 4,
      },
      {
        withoutAlignment: true,
        columnSpan: 4,
      },
    ],
    content: [],
  };

  analysisMetricsData
    .sort((a, b) => a.competitor.localeCompare(b.competitor))
    .forEach((companyData) => {
      const companyMetrics = [
        {
          title: companyData.metrics.NEWS_MENTIONS.total,
          difference: companyData.metrics.NEWS_MENTIONS.changePercentage,
        },
        {
          title: companyData.metrics.WEBSITE_CONTENT.total,
          difference: companyData.metrics.WEBSITE_CONTENT.changePercentage,
        },
        {
          title: companyData.metrics.LINKEDIN_ENGAGEMENT.total,
          difference: companyData.metrics.LINKEDIN_ENGAGEMENT.changePercentage,
        },
      ];

      tableData.content.push([
        {
          title: companyData.competitor,
        },
        ...companyMetrics,
      ]);
    });

  const sortedTableData = {
    ...tableData,
    content: tableData.content.sort((a, b) => {
      if (a[0].title < b[0].title) {
        return -1;
      }

      return 1;
    }),
  };

  return sortedTableData;
};

const SORTING_OPTIONS = [
  {
    title: 'Newest',
    sortDirection: 'desc',
    sortBy: 'publishDate',
  },
  {
    title: 'Oldest',
    sortDirection: 'asc',
    sortBy: 'publishDate',
  },
];

const PARAMS_KEYS = [
  'sortBy',
  'sortDirection',
  'topics',
  'extraTopics',
  'competitors',
  'dateFilter',
  'offset',
  'tab',
];

function CompetitorsPage() {
  const [chartRedraw, setChartRedraw] = useState(true); // this state is used to cause a redraw of the chart and prevent blank chart
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showGuide, stepIndex, guideModalIndex } = useSelector(
    (state) => state.profile.guide.competitors,
  );
  const { handleGuideModalClick } = useGuideModal('competitors', stepIndex);
  const { classes } = useStyle();

  const { loading } = useJwtToken();
  const {
    urlParams,
    urlDateParams,
    updateUrlParams,
    updateDateParam,
    getValidatedDateFilter,
  } = useURLParams(PARAMS_KEYS, {
    ...DEFAULT_CONTENT_SORTING_PARAMS,
    ...DEFAULT_CONTENT_SORTING_OFFSET,
    ...DEFAULT_DATE_FILTER_PARAMS,
  });

  const [activeTab, setActiveTab] = useActiveTab(
    urlParams,
    updateUrlParams,
  );

  const [filteringParams, setFilteringParams] = useStateFromURL(
    PARAMS_KEYS,
    urlParams,
  );
  const [contentOffset] = useStateFromURL(['offset'], urlParams);
  const updateFilter = useFilter(filteringParams, setFilteringParams);

  const [competitorAnalysisTimeSeriesData, analysisTimeSeriesLoading] =
    useFetchAnalysisTimeSeriesData(loading, getValidatedDateFilter());
  const [competitorAnalysisMetricsData, competitorAnalysisMetricsDataLoading] =
    useFetchAnalysisMetricsData(loading, getValidatedDateFilter());
  const [competitorsContentsData, competitorsContentsLoading] =
    useFetchCompetitorContents(
      loading,
      getValidatedDateFilter(),
      {
        ...urlParams,
        topics: (urlParams?.topics || []).concat(urlParams?.extraTopics || []),
        extraTopics: [],
      },
      filteringParams,
      setFilteringParams,
    );

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        headerTitle:
          competitorAnalysisTimeSeriesData.length > 0
            ? HEADER_TITLE
            : HEADER_TITLE_NO_COMPETITORS,
      }),
    );
  }, [competitorAnalysisTimeSeriesData]);

  useEffect(() => {
    if (activeTab === TABS[0]) {
      dispatch(
        setGuide({
          key: 'competitors',
          value: {
            guideModalIndex: guideModalIndex === 0 ? guideModalIndex : null,
          },
        }),
      );
    } else {
      dispatch(
        setGuide({
          key: 'competitors',
          value: {
            guideModalIndex: stepIndex === 2 ? 1 : null,
          },
        }),
      );
    }
  }, [activeTab]);

  useEffect(() => {
    dispatch(
      setActiveLinkKey({
        activeLinkKey: 'Competition',
      }),
    );
  }, []);

  useEffect(() => {
    const clickHandler = () => setChartRedraw(false);

    if (activeTab === TABS[0]) {
      document.addEventListener('click', clickHandler);
    }

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, [activeTab]);

  return (
    <>
      {competitorAnalysisTimeSeriesData.length === 0 &&
      !analysisTimeSeriesLoading ? (
        <Box className={classes.noCompetitorsBox}>
          <Stack spacing={16}>
            <Center>
              <CustomTitle order={1} sx={{ fontSize: 20 }}>
                You haven't added any competitors yet
              </CustomTitle>
            </Center>
            <Center>
              <CustomText variant="body2">
                First add your competitors for us to track to see their data
              </CustomText>
            </Center>
            <Center>
              <MainButton
                onClick={() =>
                  navigate('/settings', { state: { editCompetitors: true } })
                }
              >
                Add competitors
              </MainButton>
            </Center>
          </Stack>
        </Box>
      ) : (
        <div className="competitors-page">
          <TimePeriodSelection
            updateDateFilter={updateDateParam}
            onDropdownChange={() => activeTab === TABS[0] && setChartRedraw}
            initialFetchPeriodKey={urlDateParams?.dateKey}
          />
          {(activeTab === TABS[0] || activeTab === undefined) ? (
            <Flex align="center" justify="space-between" mb={8}>
              <CustomText variant="h3">Your Competitors</CustomText>
              <MainButton
                icon={<EditIcon />}
                reversed
                customClassName="edit-competitors__button"
                onClick={() => {
                  navigate('/settings', { state: { editCompetitors: true } });
                  if (stepIndex === 6) {
                    dispatch(
                      setGuide({
                        key: 'competitors',
                        value: {
                          showGuide: false,
                        },
                      }),
                    );
                    handleGuideModalClick(stepIndex + 1);
                  }
                }}
              >
                Edit competitors
              </MainButton>
            </Flex>
          ) : (
            <Stack>
              <FilterBar
                title="Search Topics"
                placeholder="Search topics"
                filterByData={competitorsContentsData?.distinctValues?.competitors.map((name) => ({
                  title: name,
                }))}
                paramKeys={{ filterBy: 'competitors', searchBy: 'extraTopics' }}
                filteringParams={filteringParams}
                setFilteringParams={setFilteringParams}
                urlParams={urlParams}
                setParams={updateUrlParams}
                sortingOptions={SORTING_OPTIONS}
                renderChildrenAsFilterList
                multiSelect
                onApply={(params) => {
                  const validatedExtraTopics = params.extraTopics.filter(
                    (el) => !el.includes('__disabled'),
                  );
                  updateUrlParams({
                    ...filteringParams,
                    extraTopics: validatedExtraTopics,
                    offset: 0,
                  });
                }}
                onReset={() => {
                  updateUrlParams({
                    ...DEFAULT_CONTENT_SORTING_PARAMS,
                    offset: filteringParams.offset,
                  });
                }}
              >
                <Box>
                  <TopicList
                    loading={competitorsContentsLoading}
                    activeTopics={filteringParams?.topics}
                    setActiveTopics={(topics) =>
                      updateFilter('topics', topics, true)
                    }
                    topics={competitorsContentsData?.distinctValues?.topics}
                    onSelectTopic={() =>
                      handleGuideModalClick(
                        stepIndex === 4 ? stepIndex + 1 : stepIndex,
                      )
                    }
                  />
                </Box>
              </FilterBar>
            </Stack>
          )}
          <OutlineTabs
            tabsItems={TABS}
            value={activeTab}
            onTabChange={setActiveTab}
          >
            <Tabs.Panel value={TABS[0]}>
              <CompetitorAnalysis
                data={competitorAnalysisTimeSeriesData}
                chartRedraw={chartRedraw}
                setChartRedraw={setChartRedraw}
                loading={analysisTimeSeriesLoading}
              />
              <TableWithSorting
                tableData={generateTableData(competitorAnalysisMetricsData)}
                loading={competitorAnalysisMetricsDataLoading}
                errorText="Metrics not found"
              />
            </Tabs.Panel>
            <Tabs.Panel value={TABS[1]}>
              {!competitorsContentsLoading ? (
                <>
                  {competitorsContentsData?.content?.length ? (
                    <CompetitorContent
                      content={competitorsContentsData?.content}
                      totalItems={competitorsContentsData?.totalItems}
                      offset={contentOffset}
                      setOffset={updateUrlParams}
                    />
                  ) : (
                    <Center>
                      <CustomText variant="h2">No contents found</CustomText>
                    </Center>
                  )}
                </>
              ) : (
                <Flex
                  sx={{
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    columnGap: 24,
                    rowGap: 16,
                  }}
                >
                  <SkeletonContainer
                    width="23%"
                    height={228}
                    radius={20}
                    amount={12}
                  />
                </Flex>
              )}
            </Tabs.Panel>
          </OutlineTabs>
        </div>
      )}
      {/* Guided Tour Components */}
      <GuidedTourModal
        opened={showGuide && guideModalIndex === 0}
        title="Competitors"
        onClick={handleGuideModalClick}
        onClose={handleGuideModalClick}
      >
        <CustomText variant="body2">
          This is your competitors dashboard. Use this feature to analyze your
          competitors content and social media output and give you an indication
          to what content you should write more about.
        </CustomText>
      </GuidedTourModal>
      <GuidedTourModal
        opened={showGuide && guideModalIndex === 1}
        title="Competitor content"
        onClick={() => handleGuideModalClick(3)}
        onClose={() => handleGuideModalClick(3)}
      >
        <CustomText variant="body2">
          Here you can find an index of all your competitors content from the
          webpages you gave us in sign up.
          <br />
          <br />
          (Content sources can be edited in your settings or by clicking the
          Edit competitors button on the previous page).
        </CustomText>
      </GuidedTourModal>
      <GuidedTour
        stepIndex={stepIndex}
        setStepIndex={handleGuideModalClick}
        run={showGuide && guideModalIndex === null}
        steps={getCompetitorsSteps(dispatch)}
      />
    </>
  );
}

export default CompetitorsPage;
