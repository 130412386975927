import { useCallback, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';

const AUDIENCE_DEFAULT_DATA = [0, 0, 0, 0];

const useFetchAudienceRelevance = (
  jwtTokenLoading,
  urlDateParams,
  selectedListsId,
  urlParams,
) => {
  const { getAudienceRelevance } = useRadarApi();
  const [audienceChartData, setAudienceChartData] = useState(
    AUDIENCE_DEFAULT_DATA,
  );
  const [fetchAudienceRelevance, loading] = useFetch(getAudienceRelevance);

  const fetchWrapper = useCallback(async () => {
    const data = await fetchAudienceRelevance({
      dateFilter: JSON.stringify(urlDateParams),
      ...(selectedListsId
        ? {
            'filter[listId]': selectedListsId,
          }
        : {}),
    });

    const relevanceValues = Object.values(data?.relevance);

    setAudienceChartData(relevanceValues.reverse());
  }, [
    fetchAudienceRelevance,
    getAudienceRelevance,
    urlDateParams,
    selectedListsId,
  ]);

  useEffect(() => {
    if (
      !jwtTokenLoading &&
      urlParams &&
      !selectedListsId &&
      selectedListsId !== ''
    ) {
      fetchWrapper();
    }
  }, [jwtTokenLoading, JSON.stringify(urlDateParams), selectedListsId]);

  useEffect(() => {
    if (!jwtTokenLoading && urlParams && selectedListsId) {
      fetchWrapper();
    }
  }, [jwtTokenLoading, selectedListsId]);

  return [audienceChartData, loading];
};

export default useFetchAudienceRelevance;
