import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  filterDropdown: {
    width: "calc(100vw - 19.75rem)",
    background: theme.colors.white[2],
    boxShadow: '0px 4px 12px rgba(54, 66, 71, 0.21)',
    borderRadius: rem(8),
    padding: rem(16),
  },
  filterDropdownPlaceholder: {
    background: "none",
    outline: "none",
    border: "none",
    color: "#000",
    fontFamily: "Manrope",
    fontSize: rem(16),
    display: 'block'
  },
  closeButton: {
    position: 'absolute',
    right: rem(18),
    height: rem(25),
    width: rem(25),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `${rem(1.5)} solid ${theme.colors.black[0]}`,
    borderRadius: '50%',
    cursor: 'pointer',

    '& svg': {
      height: rem(15),
      width: rem(15),
    },
  },
  backButton: {
    width: "50%",
    height: rem(39),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.colors.black[0]}`,
    borderRadius: rem(100),
    padding: rem(6),

    '&:hover': {
      borderColor: theme.colors.black[0],
    },
  },
  boldText: {
    fontSize: rem(16.5),
    fontWeight: 700,
    fontFamily: "Manrope",
    textShadow: "1px 0 0"
  },
  topicItem: {
    height: rem(32),
    padding: `0 ${rem(12)}`,
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: rem(32),
    width: rem(560),
    height: rem(510),
    background: theme.colors.white[3],
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: rem(20),
    paddingTop: rem(24),
  },
  chartInnerBorder: {
    width: rem(220),
    height: rem(220),
    position: 'absolute',
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    background: theme.colors.gray[2],
    zIndex: 90
  },
  chartInnerText: {
    fontWeight: 600,
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
  loadingInner: {
    width: rem(260),
    height: rem(260),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: "50%",
    background: theme.colors.gray[2],
    zIndex: 50
  },
  chartOuterBorder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: rem(390),
    minHeight: rem(390),
    position: 'relative',
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: '50%',
    padding: rem(13),
  },
  chartLabelIcon: {
    position: 'absolute',
    bottom: 0,
    width: rem(60),
    height: rem(21),
  },
  chartLabelIconBottom: {
    top: 0,
  },
  chartLabelIconLeft: {
    right: 0,
  },
  chartLabelIconRight: {
    left: 0,
  },
}));
