import { setGuide } from "../profileSlice"

export const getContentSteps = (dispatch) => ([
    {
        content: `
          Here you can find recommendations for content to promote. 
          This is content that has a high engagement rate and interested 
          contacts, but low traffic. 
        `,
        target: ".sorting-table",
        placement: "top-end",
        offset: 0,
        xOffset: 60,
        disableBeacon: true,
        disabledPrevButton: true,
    },
    {
      content: `
        Click here to learn about content utilization
      `,
      target: ".Content_Utilization-tooltip",
      placement: "right",
      withoutNavigation: true,
      offset: 0,
    },
    {
      content: <>
        Here you can find a catalogue of your website
        <br />
        content and Syndicated content. You can
        <br />
        sort and filter you content items to narrow
        <br />
        your results.
        <br />
        <br />
        Click on a content item to see the content
        <br />
        report.
      </>,
      target: ".mantine-Tabs-tabsList",
      placement: "top-start",
      xOffset: 150,
      withoutNavigation: true,
      withProgress: true,
      offset: -5,
      withCloseButton: true,
      onClose: () => dispatch(setGuide({
        key: 'content',
        value: {
          showGuide: false
        }
      }))
    },
])