import React from 'react';
import { Group } from '@mantine/core';
import CustomText from '../../Typography/CustomText/CustomText';
import { useStyle } from './TopicItem.style';
import { ReactComponent as CircleCross } from '../../../assets/icons/circle-cross-icon.svg';

function TopicItem({ title, selected, onSelect, onDelete }) {
  const { classes, cx } = useStyle();

  return (
    <Group
      spacing={8}
      className={cx(classes.topic, { [classes.selectedTopic]: selected })}
      onClick={() => onSelect(title)}
    >
      <CustomText variant="body1">{title}</CustomText>
      <CircleCross onClick={() => onDelete(title)} />
    </Group>
  );
}

export default TopicItem;
