import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  modalButton: {
    width: rem(203),
  },
  secondaryButton: {
    border: `1px solid ${theme.colors.red[0]}`,
    borderRadius: 100,
    padding: `${rem(6)} 0`,
    color: theme.colors.red[0],

    "&:hover": {
        borderColor: theme.colors.red[0]
    }
  }
}));