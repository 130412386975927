import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  competitors: [],
  managePaymentUrl: null,
  loading: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    addNewCompetitor: (state, { payload: { competitor } }) => {
      state.competitors = [...state.competitors, competitor];
    },
    updateCompetitor: (state, { payload: { competitor } }) => {
      const competitorToUpdateIndex = state.competitors.findIndex(
        (item) => item.companyName === competitor.companyName,
      );
      const newCompetitors = JSON.parse(JSON.stringify(state.competitors)); // deep copy using JSON

      newCompetitors.splice(competitorToUpdateIndex, 1, competitor);

      state.competitors = newCompetitors;
    },
    resetSettingsState: () => {
      return initialState;
    },
    updateManagePaymentUrl: (state, { payload: { url } }) => {
      state.managePaymentUrl = url;
    },
    updateSettingsLoading: (state, { payload: { loading } }) => {
      state.loading = loading;
    },
  },
});

export const {
  addNewCompetitor,
  updateCompetitor,
  resetSettingsState,
  updateManagePaymentUrl,
  updateSettingsLoading,
} = settingsSlice.actions;

export default settingsSlice.reducer;
