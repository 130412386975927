import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  chartContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: rem(560),
    height: rem(510),
    background: 'radial-gradient(50% 50% at 50% 50%, #1A167D 0%, #0C0A44 100%)',
    borderRadius: 20,
  },
  chartHiddenPart: {
    position: 'absolute',
    zIndex: 10,
    transition: '0.5s',
  },
  chartQuestionLabel: {
    position: 'absolute',
    cursor: "pointer"
  },
  chartLabel: {
    position: 'absolute',
    width: rem(114),
    background: 'rgba(252, 252, 252, 0.3)',
    backdropFilter: 'blur(2px)',
    boxShadow: '2px 4px 6px rgba(15, 14, 36, 0.15)',
    borderRadius: 5,
    padding: `${rem(8)} ${rem(16)}`,
    cursor: "pointer"
  },
  chartTooltip: {
    position: 'absolute',
    background: theme.colors.yellow[1],
    borderRadius: 5,
    padding: `${rem(8)} ${rem(16)}`,
    zIndex: 1000,
    transform: 'translate(16px, -16px)',

    '&::before': {
      content: "''",
      position: 'absolute',
      top: 8,
      left: 0,
      width: 12,
      height: 12,
      background: theme.colors.yellow[1],
      transform: 'translateX(-50%) rotate(45deg)',
    },
  },
}));
