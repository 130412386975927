import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  imageContainer: {
    width: rem(705),
    height: "100vh",
    borderRadius: "200px 0 0 0",
    background: theme.colors.blue[0],
  },
  imageWrapper: {
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 15,
  },
  lineLeftContainer: {
    position: "absolute",
    left: 0,
  },
  lineRightContainer: {
    position: "absolute",
    right: rem(25),
    bottom: 0,
  }
}));
