import React, { useMemo } from 'react';
import { Box, Center, Grid, Group } from '@mantine/core';
import CustomText from '../../../../Typography/CustomText/CustomText';
import ListItem from '../ListItem/ListItem';

function FilterList({ title, filters, customMargin, updateFilter, ...rest }) {
  const filterList = useMemo(() => {
    return filters.map((filter, index) => (
        <ListItem
          key={index}
          title={filter.value}
          isActive
          removeActive={() => updateFilter(filter)}
          {...rest}
        />
    ));
  }, [filters, updateFilter]);

  return (
    <Box mt={customMargin || -32} maw="80%">
      <Grid m={0}>
        <Grid.Col span="content" p={0}>
          <Center h={32} pr={16}>
            <CustomText variant="h5">{title}</CustomText>
          </Center>
        </Grid.Col>
        <Grid.Col span="auto" p={0}>
          <Group>{filterList}</Group>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default FilterList;
