import { setGuide } from '../profileSlice';

export const getCompetitorsSettingsSteps = (dispatch) => [
  {
    content: (
      <>
        To add a competitor, fill out the details below
        <br />
        and click continue.
      </>
    ),
    target: '.add-competitor__container',
    placement: 'top-start',
    disableBeacon: true,
    disabledPrevButton: true,
    withoutNavigation: true,
    withProgress: true,
    offset: -5,
    xOffset: 240,
  },
  {
    content: (
      <>
        Great, now we need to know which pages of
        <br />
        your competitors website holds their
        <br />
        marketing content. This will be things like,
        <br />
        blogs and news.
        <br />
        <br />
        Please enter the page name and URL and
        <br />
        then hit save.
      </>
    ),
    target: '.add-competitors-details',
    placement: 'right',
    offset: 0,
    withoutNavigation: true,
    withProgress: true,
  },
  {
    content: (
      <>
        Thanks! We can now start indexing your
        <br />
        competitors content for you to analyze in the
        <br />
        competition dashboard.
        <br />
        <br />
        You can add up to 3 more competitors on a
        <br />
        free account. Why not add those in now?
        <br />
        <br />
        P.s. With a premium account you can track up to
        <br />
        10 competitors and access all the premium
        <br />
        features on the competition dashboard.
      </>
    ),
    target: '.selected-competitors__container',
    placement: 'right',
    offset: -10,
    disabledPrevButton: true,
    onClick: () => {
      dispatch(
        setGuide({
          key: 'competitorsSettings',
          value: {
            showGuide: false,
          },
        }),
      );
    },
  },
];
