import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useRadarApi from "../../../hooks/useRadarApi";

const useFetchAnalysisTimeSeriesData = (jwtTokenLoading, urlDateParams) => {
    const [competitorAnalysisTimeSeriesData, setCompetitorAnalysisTimeSeriesData] = useState([]);
    const { getCompetitorAnalysisTimeSeries } = useRadarApi()
    const [fetchAnalysisTimeSeriesData, loading] = useFetch(getCompetitorAnalysisTimeSeries);

    const fetchWrapper = useCallback(async () => {
      const data = await fetchAnalysisTimeSeriesData({
        dateFilter: JSON.stringify(urlDateParams),
      });
      setCompetitorAnalysisTimeSeriesData(data);
    }, [fetchAnalysisTimeSeriesData, getCompetitorAnalysisTimeSeries, urlDateParams]);

    useEffect(() => {
      if (!jwtTokenLoading) {
        fetchWrapper();
      }
    }, [jwtTokenLoading, JSON.stringify(urlDateParams)]);

    return [competitorAnalysisTimeSeriesData, loading]
}

export  default useFetchAnalysisTimeSeriesData