import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
    stepItem: {
        width: rem(24),
        height: rem(24),
        borderRadius: "50%",
        background: theme.colors.red[0],
        opacity: 0.4
    },
    stepItemActive: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "fit-content",
        padding: `0 ${rem(8)}`,
        borderRadius: rem(23),
        opacity: 1
    },
    stepLine: {
        width: rem(12),
        height: rem(1),
        background: theme.colors.red[0],
        opacity: 0.4
    },
    passedStep: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: 1
    }
}));
