import React, { useState, useCallback, useMemo } from 'react';
import { Box, Flex, Group, rem, Stack } from '@mantine/core';
import CustomText from '../../Typography/CustomText/CustomText';
import { useStyle } from './FilterBar.style';
import MainButton from '../../buttons/buttons';
import CheckBoxDropdown from '../../CustomDropdown/CheckBoxDropdown';
import { isObjectsEqual } from '../../../utils';
import CustomMultiSelect from '../../CustomMultiSelect/CustomMultiSelect';
import useFilter from '../../../hooks/useFilter';
import SearchInput from '../../SearchInput/SearchInput';

function FilterBar({
  children,
  filterByData,
  filteringParams,
  setFilteringParams,
  title,
  paramKeys,
  setParams,
  urlParams,
  onReset,
  onApply,
  withOffset,
  offset,
  filtersControlGroupRef,
  sortingOptions,
  customFilterByComponent,
  filterComponentClassName,
  renderChildrenAsFilterList,
  multiSelect,
  withoutInput,
  ...rest
}) {
  const { classes, cx } = useStyle();
  const [filterOpened, setFilterOpened] = useState(false);
  const [sortingOpened, setSortingOpened] = useState(false);

  const updateFilter = useFilter(filteringParams, setFilteringParams);

  const handleReset = useCallback(() => {
    const params = {
      [paramKeys?.filterBy]: [],
      sortBy: 'publishDate',
      sortDirection: 'desc',
    };

    if (isObjectsEqual({ ...filteringParams }, urlParams)) return;

    if (onReset) {
      onReset();
    } else {
      setParams(params);
    }
  }, [onReset, filteringParams]);

  const handleApply = useCallback(() => {
    if (isObjectsEqual({ ...filteringParams }, urlParams)) return;

    if (onApply) {
      onApply(filteringParams);
    } else {
      setParams(filteringParams);
    }
  }, [filteringParams, onApply]);

  const handleFilterSelection = (filter) => {
    updateFilter(paramKeys.filterBy, filter);
  };

  const handleSortingSelection = (sortingOptions) => {
    setFilteringParams((prevState) => ({
      ...prevState,
      sortBy: sortingOptions.sortBy,
      sortDirection: sortingOptions.sortDirection,
    }));
  };

  const handleInput = (value) => {
    updateFilter(paramKeys.searchBy, value, true);
  };

  const selectedSortingOption = useMemo(() => {
    if (!filteringParams || !sortingOptions) return null;
    return sortingOptions.find(
      (option) =>
        option.sortBy === filteringParams.sortBy &&
        option.sortDirection === filteringParams.sortDirection,
    );
  }, [filteringParams, sortingOptions]);

  const getDropdownStyles = useCallback(
    (theme, condition) => {
      if (!condition) {
        return {
          minWidth: rem(74),
          justifyContent: 'space-between',
          transition: 'width 2s',
        };
      }
      return {
        position: 'absolute',
        transition: 'min-width 0.1s',
        top: rem(-4),
        minWidth: rem(265),
        minHeight: rem(34),
        justifyContent: 'space-between',
        border: `${rem(1)} solid ${theme.colors.gray[3]}`,
        background: theme.colors.white[0],
        borderRadius: 8,
        padding: `${rem(8)} ${rem(16)}`,
        transform: 'translateX(-70%)',

        '& > div': {
          minWidth: 'fit-content',
        },
      };
    },
    [rem],
  );

  const withGap = useMemo(() => {
    if (
      !(filterByData?.length > 0 && !customFilterByComponent) &&
      !sortingOptions &&
      !customFilterByComponent
    ) {
      return false;
    }
    return true;
  }, [filterByData, customFilterByComponent, sortingOptions]);

  return (
    <Stack>
      <Stack spacing={16}>
        <Flex gap={40} w="100%">
          {title && (
            <Group miw={155}>
              <CustomText variant="h3">{title}</CustomText>
            </Group>
          )}
          <Group
            spacing={withGap ? 32 : 0}
            sx={{ flexGrow: 2, flexWrap: 'nowrap', justifyContent: 'flex-end' }}
          >
            {!withoutInput && (
              <>
                {multiSelect ? (
                  <CustomMultiSelect
                    value={filteringParams?.[paramKeys.searchBy]}
                    setValue={handleInput}
                    {...rest}
                  />
                ) : (
                  <SearchInput
                    value={filteringParams?.[paramKeys.searchBy]}
                    onChange={(e) => handleInput(e.target.value)}
                    {...rest}
                  />
                )}
              </>
            )}
            <Group spacing={32} mih={40}>
              <>
                {filterByData?.length > 0 && !customFilterByComponent ? (
                  <Box
                    className={cx(
                      classes.dropdownFullSizeContainer,
                      'filter-by',
                    )}
                  >
                    <CheckBoxDropdown
                      title="Filter by"
                      selectedItems={filteringParams[paramKeys?.filterBy]}
                      onItemSelect={(filter) =>
                        handleFilterSelection(filter.title)
                      }
                      menuOptions={filterByData}
                      gap={8}
                      dropdownClassName={cx(
                        classes.dropdownFullSize,
                        filterComponentClassName,
                      )}
                      targetStyles={(theme) =>
                        getDropdownStyles(theme, filterOpened)
                      }
                      opened={filterOpened}
                      onChange={setFilterOpened}
                      customAnimationState={filterOpened}
                    />
                  </Box>
                ) : (
                  <>{customFilterByComponent}</>
                )}
              </>
              {sortingOptions && (
                <Box
                  className={cx(classes.dropdownFullSizeContainer, 'sort-by')}
                >
                  <CheckBoxDropdown
                    selectedItems={selectedSortingOption}
                    onItemSelect={(sortingOption) =>
                      handleSortingSelection(sortingOption)
                    }
                    dropdownClassName={classes.dropdownFullSize}
                    gap={8}
                    menuOptions={sortingOptions}
                    targetItem={
                      <CustomText variant="body2">Sort by</CustomText>
                    }
                    targetStyles={(theme) =>
                      getDropdownStyles(theme, sortingOpened)
                    }
                    opened={sortingOpened}
                    onChange={setSortingOpened}
                    customAnimationState={sortingOpened}
                  />
                </Box>
              )}
              {children && !renderChildrenAsFilterList && <>{children}</>}
            </Group>
          </Group>
        </Flex>
        {children && renderChildrenAsFilterList && <>{children}</>}
        <Stack mt={0} justify="space-between">
          <Flex
            w="100%"
            justify="flex-end"
            h="fit-content"
            sx={{ margin: 0 }}
            ref={filtersControlGroupRef}
          >
            <Group
              mt={withOffset ? (offset || rem(215)) : 0}
              mb={withOffset ? (-offset || rem(-215)) : 0}
              sx={{ flexWrap: 'nowrap' }}
            >
              <MainButton
                smallPadding
                secondary
                sx={{ height: rem(34) }}
                onClick={handleReset}
              >
                Reset
              </MainButton>
              <MainButton
                smallPadding
                sx={{ height: rem(34) }}
                onClick={handleApply}
              >
                Apply
              </MainButton>
            </Group>
          </Flex>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default FilterBar;
