import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles(() => ({
  secondaryHeadingContainer: {
    position: "relative",
    marginTop: rem(40)
  },
  arrow: {
    position: "absolute",
    right: 0,
    top: 0,
    transform: "translate(120%, -50%)"
  }
}));
