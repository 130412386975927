import React, { useState } from 'react';
import QuestionWrapper from './QuestionWrapper';
import { ReactComponent as GoogleAnalyticsLogo } from '../../../assets/icons/google-analytics-logo.svg';
import { Box, rem, Stack, UnstyledButton } from '@mantine/core';
import CustomText from '../../Typography/CustomText/CustomText';
import BasicModal from '../../Modals/BasicModal';
import Heading from '../../Heading';
import MainButton from '../../buttons/buttons';

function ConnectAnalytics({ onSkip, onContinue }) {
  const [opened, setOpened] = useState(false);

  return (
    <QuestionWrapper
      title="Connect Google Analytics"
      description="Google Analytics let’s you track your web content engagement."
      mainText="Connect"
      secondaryText="I’ll do it later"
      onMainButtonClick={onContinue}
      onSecondaryButtonClick={onSkip}
      onSkip={onSkip}
      image={<GoogleAnalyticsLogo />}
    >
      <UnstyledButton onClick={() => setOpened(true)}>
        <Box py={rem(16)}>
          <CustomText
            variant="h3"
            sx={{ fontWeight: 600, textAlign: 'center' }}
          >
            I don’t have Google Analytics
          </CustomText>
        </Box>
      </UnstyledButton>
      <BasicModal withCloseButton={false} opened={opened} onClose={onSkip}>
        <Box w={rem(650)} mx={rem(80)} my={rem(32)}>
          <Stack spacing={40}>
            <Heading
              title="Why Google Analytics?"
              description={`You don’t need to have Google Analytics to set up your Air Traffic Control account. 
              However it’s a great tool to have so you can enable web engagement tracking for even further insights.`}
            />
            <Box mx="auto">
              <MainButton hugePadding onClick={onSkip}>
                <CustomText variant="body2">Good to know!</CustomText>
              </MainButton>
            </Box>
          </Stack>
        </Box>
      </BasicModal>
    </QuestionWrapper>
  );
}

export default ConnectAnalytics;
