import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  modalBody: {
    background: theme.colors.white[2],
  },
  modalCloseButton: {
    position: 'absolute',
    top: rem(16),
    right: rem(16),
  },
}));
