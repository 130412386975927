import { Box, Flex, Stack } from '@mantine/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CompetitorContentCard from '../../../components/CompetitorContentCard/CompetitorContentCard';
import SkeletonContainer from '../../../components/SkeletonContainer';
import TagsList from '../../../components/TagsList/TagsList';
import CustomText from '../../../components/Typography/CustomText/CustomText';
import { setGuide } from '../../../features/profileSlice';
import useJwtToken from '../../../hooks/useJwtToken';
import useNavigateWithState from '../../../hooks/useNavigateWithState';
import useRadarApi from '../../../hooks/useRadarApi';
import { useStyle } from '../ContactsPage.style';

import logger from '../../../utils/logger';

const PeopleRowExpanded = ({ id }) => {
  const [contactsSummary, setContactsSummary] = useState(null);
  const [contactsSummaryLoading, setContactsSummaryLoading] = useState(true);
  const containerRef = useRef(null);
  const { getContactsSummary } = useRadarApi();
  const { loading } = useJwtToken();
  const tourActive = useSelector((state) => state.profile.guide.tourActive);
  const { stepIndex } = useSelector((state) => state.profile.guide.contacts);
  const dispatch = useDispatch();
  const navigateWithState = useNavigateWithState()
  const { classes } = useStyle();

  const generateContentCards = useCallback(() => {
    if (!contactsSummary) return;

    const elements = [];

    if (!contactsSummary.articles.length) {
      return (
        <Box mt={-12} sx={{ width: '100%', textAlign: 'center' }}>
          <CustomText
            variant="h4"
            sx={{
              fontWeight: 500,
            }}
          >
            Articles not found
          </CustomText>
        </Box>
      );
    }

    contactsSummary.articles.forEach((article, index) => {
      elements.push(
        <CompetitorContentCard
          key={index}
          image={article.imageUrl}
          title={article.title}
          publishDate={article.publishDate}
          source={article.articleType}
          smallCard
          onClick={() => navigateWithState(`/contents/detail/${article.id}`)}
        />,
      );
    });

    return elements;
  }, [contactsSummary]);

  const fetchContactsSummary = useCallback(async () => {
    try {
      const data = await getContactsSummary(id);
      setContactsSummary(data);
      setContactsSummaryLoading(false);
    } catch (error) {
      setContactsSummaryLoading(false);
      logger.error('Failed to fetch contacts summary data:', error);
    }
  }, [getContactsSummary, id]);

  useEffect(() => {
    if (!loading) {
      fetchContactsSummary();
    }
  }, [loading]);

  useEffect(() => {
    if ((contactsSummary?.articles?.length ?? 0) === 0) return;
    // dispatch(
    //   setTourActive({
    //     active: true,
    //   }),
    // );
  }, [contactsSummary]);

  useEffect(() => {
    return () => {
      if (containerRef?.current === null && stepIndex === 1 && tourActive) {
        dispatch(
          setGuide({
            key: 'contacts',
            value: {
              stepIndex: 2,
            },
          }),
        );
      }
    };
  }, [stepIndex]);

  return (
    <Box className={classes.rowExpanded} ref={containerRef}>
      <TagsList
        data={contactsSummary?.topics}
        loading={contactsSummaryLoading}
        title="Top Interests"
      />
      <Stack mt={32}>
        <CustomText variant="h4">Recommended Content</CustomText>
        <Flex sx={{ rowGap: 16, columnGap: 12, flexWrap: 'wrap' }}>
          {!contactsSummaryLoading ? (
            <>{generateContentCards()}</>
          ) : (
            <SkeletonContainer width={340} height={90} radius={12} amount={3} />
          )}
        </Flex>
      </Stack>
    </Box>
  );
};

export default PeopleRowExpanded;
