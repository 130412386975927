import React, { useState, useEffect } from 'react';
import { Box, rem, Stack } from '@mantine/core';
import { useDispatch } from 'react-redux';
import PlanPageComponent from '../components/PlanPageComponent/PlanPageComponent';
import BasicModal from '../components/Modals/BasicModal';
import UpdatePlanPayment from '../components/upgradePlanComponents/paymentComponents/UpgradePlanPayment/UpdatePlanPayment';
import { useStyle } from './PlanPage.style';
import PaymentModalWrapper from '../components/upgradePlanComponents/PaymentModalWrapper';
import { setActiveLinkKey, setHeaderTitle } from '../features/layoutSlice';
import SuccessfulPaymentModal from '../components/Modals/SuccessfulPaymentModal';
import CustomTitle from '../components/Typography/CustomTitle/CustomTitle';
import CustomText from '../components/Typography/CustomText/CustomText';
import MainButton from '../components/buttons/buttons';
import useJwtToken from '../hooks/useJwtToken';
import Snackbar from '../components/Snackbar';

function PlanPage({ redirectionFlow }) {
  const [paymentModalIndex, setPaymentModalIndex] = useState(null);
  const [selectedPlanData, setSelectedPlanData] = useState({});
  const { subscriptionStatus } = useJwtToken();
  const [showRedirectionFlowModal, setShowRedirectionFlowModal] =
    useState(false);

  const dispatch = useDispatch();
  const { classes } = useStyle();

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        headerTitle: 'Upgrade',
      }),
    );
    dispatch(
      setActiveLinkKey({
        activeLinkKey: 'Upgrade',
      }),
    );
  }, []);

  useEffect(() => {
    if (redirectionFlow && subscriptionStatus) {
      setShowRedirectionFlowModal(true);
      if (subscriptionStatus !== 'PAUSED') {
        window.location = '/';
      }
    }
  }, [redirectionFlow, subscriptionStatus]);

  return (
    <Box p={redirectionFlow ? 72 : 0}>
      <PlanPageComponent
        redirectionFlow={redirectionFlow}
        onPlanSelect={(data) => {
          setSelectedPlanData(data);
          if (!redirectionFlow) {
            setPaymentModalIndex(1);
          }
        }}
      />
      <BasicModal
        withCloseButton={false}
        opened={showRedirectionFlowModal}
        onClose={() => setShowRedirectionFlowModal(false)}
        bodyClassName={classes.modalBody}
      >
        <Stack w={rem(400)} align="center">
          <CustomTitle order={1}>Your Free Trial Has Ended</CustomTitle>
          <CustomText>
            Your free trial period has expired.
            <br />
            To continue, please select a plan.
          </CustomText>
          <MainButton onClick={() => setShowRedirectionFlowModal(false)}>
            Select a Plan
          </MainButton>
        </Stack>
      </BasicModal>
      <BasicModal
        withCloseButton={false}
        opened={paymentModalIndex === 0}
        onClose={() => setPaymentModalIndex(null)}
        bodyClassName={classes.modalBody}
      >
        <PaymentModalWrapper onClose={() => setPaymentModalIndex(null)}>
          <UpdatePlanPayment
            title={selectedPlanData.title}
            planList={selectedPlanData.planList}
            price={selectedPlanData.price}
            period={selectedPlanData.period}
            onSubmit={() => setPaymentModalIndex(1)}
          />
        </PaymentModalWrapper>
      </BasicModal>
      <SuccessfulPaymentModal
        withoutTitle
        paymentModalIndex={paymentModalIndex}
        setPaymentModalIndex={setPaymentModalIndex}
        content={
          <>
            You will shortly receive an email notification <br /> about this
            subscription change
          </>
        }
      />
      {redirectionFlow && <Snackbar />}
    </Box>
  );
}

export default PlanPage;
