import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  secondaryButton: {
    height: rem(60),
    width: rem(234),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: rem(100),
    border: `${rem(1)} solid ${theme.colors.red[0]}`,

    "&:hover": {
        borderColor: theme.colors.red[0]
    }
  },
}));
