import { setGuide } from "../profileSlice"

export const getContentDetailsSteps = (dispatch) => ([
    {
        content: <>
            Topic tags are how we categorize the content into
            <br />
            topics.
        </>,
        target: ".tag-1",
        placement: "top-start",
        offset: 0,
        xOffset: 0,
        disableBeacon: true,
        disabledPrevButton: true,
    },
    {
      content: <>
        You can control whether you like the content
        <br />
        to be active for automation.
      </>,
      target: ".active__checkbox",
      placement: "right-start",
      offset: 0,
    },
    {
      content: <>
        You can  export this data report as a full
        <br />
        report or as a Linked In custom audience file.
      </>,
      target: ".export-contacts__button",
      placement: "left-start",
      onClick: () => dispatch(setGuide({
        key: 'contentDetails',
        value: {
          showGuide: false
        }
      }))
    },
])