import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  tableWrapperWithoutBorder: {
    border: 'none',
  },
  tableWrapper: {
    background: theme.colors.white[3],
  },
  tableRow: {
    background: theme.colors.white[2],
  },
  hoverItem: {
    position: 'absolute',
    right: rem(18),
    top: '50%',
    transform: 'translateY(-50%)',
    transition: '0.5s',
  },
  rowExpanded: {
    padding: `${rem(16)} ${rem(8)} ${rem(24)} ${rem(30)}`,
  },
  rowExpandedAccount: {
    padding: `${rem(16)} 0 ${rem(8)} ${rem(16)}`,
  },
  interestItem: {
    background: theme.colors.purple[0],
    borderRadius: 100,
    padding: `${rem(5)} ${rem(14)}`,
  },
  interestContainer: {
    maxHeight: rem(29),
    overflow: 'hidden',
    transition: '0.5s',
  },
  tableWrapperScroll: {
    maxHeight: rem(750),
  },
}));
