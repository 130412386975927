import { useCallback, useMemo } from "react";

const useActiveTab = (urlParams, updateUrlParams, tabs) => {
  const activeTab = useMemo(() => {
    if (!urlParams?.tab?.length) return !tabs ? undefined : tabs[0];
    if (Array.isArray(urlParams.tab)) {
      return urlParams.tab[0];
    }
    return urlParams.tab;
  }, [urlParams]);

  const setActiveTab = useCallback(
    (value) => {
      updateUrlParams({
        tab: [value],
      });
    },
    [urlParams],
  );

  return [activeTab, setActiveTab]
};

export default useActiveTab;
