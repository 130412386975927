import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useRadarApi from "../../../hooks/useRadarApi";

const useFetchContentToPromote = (jwtTokenLoading) => {
    const [contentToPromote, setContentToPromote] = useState([]);
    const { getContentsToPromote } = useRadarApi()
    const [fetchContentToPromote, loading] = useFetch(getContentsToPromote);

    const fetchWrapper = useCallback(async () => {
      const data = await fetchContentToPromote();
      setContentToPromote(data || {});
    }, [fetchContentToPromote, getContentsToPromote]);

    useEffect(() => {
      if (!jwtTokenLoading) {
        fetchWrapper();
      }
    }, [jwtTokenLoading]);

    return [contentToPromote, loading]
}

export default useFetchContentToPromote