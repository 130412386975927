import React, { memo, useMemo } from 'react';
import { Group } from '@mantine/core';
import CustomCheckbox from '../../Checkbox';
import CustomText from '../../Typography/CustomText/CustomText';
import { ReactComponent as CheckedIcon } from '../../../assets/icons/checked-icon.svg';

function CustomSelectionMenuItem({
  option,
  selectedItemTitles,
  onItemSelect,
  getExternalStyles,
  wihtoutCheckboxes,
}) {
  const checked = useMemo(() => {
    if (!selectedItemTitles) return false;
    else if (typeof selectedItemTitles === 'string') {
      return selectedItemTitles === option.title;
    } else {
      return selectedItemTitles.reduce(
        (acc, curr) => acc || curr === option.title,
        false,
      );
    }
  }, [selectedItemTitles, option.title]);

  return (
    <Group
      className="menu-target"
      px={16}
      py={4}
      spacing={8}
      sx={(theme) => ({
        cursor: 'pointer',
        flexWrap: 'nowrap',
        '&:hover': {
          background: theme.colors.purple[0],
        },
      })}
      onClick={() => onItemSelect(option)}
    >
      {!wihtoutCheckboxes && (
        <CustomCheckbox checked={checked} size={17} className="menu-target" />
      )}
      <CustomText
        className="menu-target"
        variant="body2"
        sx={(theme) => ({
          color: !checked && theme.colors.gray[4],
          overflowWrap: 'anywhere',
          ...(getExternalStyles ? getExternalStyles(option) : {}),
        })}
      >
        {option.title}
      </CustomText>
      {wihtoutCheckboxes && checked && <CheckedIcon />}
    </Group>
  );
}

export default memo(CustomSelectionMenuItem, (prev, next) => {
  if (
    prev.selectedItemTitles.includes(prev.option.title) ||
    next.selectedItemTitles.includes(prev.option.title) ||
    prev.option.title !== next.option.title
  ) {
    return false;
  } else {
    return true;
  }
});
