import React, { useCallback } from 'react';
import { Flex, Group, Box, rem, UnstyledButton } from '@mantine/core';
import { ReactComponent as ShowIcon } from '../../../../../assets/icons/show-icon.svg';
import { ReactComponent as HideIcon } from '../../../../../assets/icons/hide-icon.svg';
import CustomText from '../../../../../components/Typography/CustomText/CustomText';

function CompanyItem({
  companyData,
  isVisible,
  setIsVisible,
  activeCompetitorName,
  setActiveCompetitorName,
}) {
  const handleClick = useCallback((e, value) => {
    setActiveCompetitorName(value)
  }, [setActiveCompetitorName])

  return (
    <Flex
      w={251}
      h="fit-content"
      justify="space-between"
      align="center"
      sx={(theme) => ({
        border: `${rem(1)} solid ${theme.colors.gray[3]}`,
        borderRadius: 100,
        padding: `${rem(7)} ${rem(11)} ${rem(7)} ${rem(8)}`,
        cursor: 'pointer',
        boxShadow:
          activeCompetitorName === companyData?.name &&
          '0px 4px 12px rgba(54, 66, 71, 0.21)',
      })}
      onClick={(e) => handleClick(e, companyData?.name)}
    >
      <Group
        spacing={8}
        sx={{
          flexWrap: 'nowrap',
          '& svg': {
            minWidth: rem(22),
          },
        }}
      >
        <UnstyledButton onClick={e => e.stopPropagation()}>
          {!isVisible ? (
            <HideIcon
              style={{ height: rem(22) }}
              onClick={() => setIsVisible(companyData?.name)}
            />
          ) : (
            <ShowIcon
              style={{ height: rem(22) }}
              onClick={() => setIsVisible(companyData?.name)}
            />
          )}
        </UnstyledButton>
        <CustomText variant="body2" color="#0C0A44">
          {companyData?.name}
        </CustomText>
      </Group>
      <Box
        h={16}
        miw={16}
        sx={(theme) => ({
          background: isVisible ? companyData?.color : theme.colors.gray[3],
          borderRadius: '50%',
        })}
      />
    </Flex>
  );
}

export default CompanyItem;
