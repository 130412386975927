// Layout.jsx
import React, { useLayoutEffect } from 'react';
import Sidebar from './navigation/Navbar';
import HeaderMenu from './header/Header';
import './Layout.css';
import { AppShell, Box, LoadingOverlay } from '@mantine/core';
import Snackbar from './Snackbar';
import { useSelector } from 'react-redux';

function Layout({ children }) {
  const loadingOverlay = useSelector((state) => state.layout.loadingOverlay);
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  return (
    <AppShell
      navbar={<Sidebar />}
      header={<HeaderMenu />}
      styles={(theme) => ({
        main: {
          backgroundColor: theme.colors.white[0],
          paddingLeft: 0,
          paddingRight: 0,
        },
      })}
    >
      <LoadingOverlay visible={loadingOverlay} zIndex={1000} />
      <Snackbar />
      <div className="main-container">
        <div className="content-area">
          <Box>{children}</Box>
        </div>
      </div>
    </AppShell>
  );
}

export default Layout;
