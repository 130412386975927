import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  wrapper: {
    position: "fixed",
    width: rem(340),
    left: rem(250),
    bottom: 0,
    borderRadius: `${rem(20)} ${rem(20)} 0 0`,
    background: theme.colors.white[3],
    zIndex: 100
  },
  heading: {
    background: theme.colors.yellow[1],
    borderRadius: `${rem(20)} ${rem(20)} 0 0`,
    padding: `${rem(16)} ${rem(24)}`
  }
}));
