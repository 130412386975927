import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  rowItem: {
    padding: 0,
  },
  contentImage: {
    height: rem(48),
    width: rem(48),
    borderRadius: 7,
    objectFit: "cover"
  },
  linkIcon: {
    '& svg': {
      width: rem(25),
      height: rem(25),
    },
  },
  progress: {
    background: theme.colors.white[1],
  },
}));
