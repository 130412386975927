import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  wrapper: {
    width: rem(150),
    height: rem(165),
    borderRadius: rem(16),
    boxShadow: '0px 0px 8.8px 0.55px rgba(0, 0, 0, 0.15)',
  },
  wrapperHuge: {
    width: rem(173),
    height: rem(184),
  },
  image: {
    width: '100%',
    height: rem(80),
    objectFit: 'cover',
    borderRadius: `${rem(16)} ${rem(16)} 0 0`,
  },
  imageHuge: {
    height: rem(93)
  },
  contentContainer: {
    padding: `0 ${rem(6)}`,
  },
  button: {
    width: rem(68),
    height: rem(20),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: rem(10),
  },
  buttonHuge: {
    height: rem(25),
    width: rem(78),
    borderRadius: rem(12)
  },
  buttonBlue: {
    background: 'linear-gradient(90deg, #0185FF 0%, #8BD8FF 100%)',
  },
  buttonRed: {
    background: theme.colors.red[0],
  },
}));
