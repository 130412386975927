import { Global } from '@mantine/core';
// New Kansas Font
import newKansasBold from './assets/fonts/NewKansas/NewKansas-Bold.woff2';

// Manrope Fonts
import manropeBold from './assets/fonts/Manrope/Manrope-Bold.woff2';
import manropeSemiBold from './assets/fonts/Manrope/Manrope-SemiBold.woff2';
import manropeMedium from './assets/fonts/Manrope/Manrope-Medium.woff2';
import manropeRegular from './assets/fonts/Manrope/Manrope-Regular.woff2';

// Roboto
import robotoMedium from './assets/fonts/Roboto/Roboto-Medium.woff2'

export function GlobalStyles() {
  return (
        <Global
            styles={[
              {
                '@font-face': {
                  fontFamily: 'New Kansas',
                  src: `url(${newKansasBold}) format("woff2")`,
                  fontWeight: 700,
                },
              },
              {
                '@font-face': {
                  fontFamily: 'Manrope',
                  src: `url(${manropeBold}) format("woff2")`,
                  fontWeight: 700,
                },
              },
              {
                '@font-face': {
                  fontFamily: 'Manrope',
                  src: `url(${manropeSemiBold}) format("woff2")`,
                  fontWeight: 600,
                },
              },
              {
                '@font-face': {
                  fontFamily: 'Manrope',
                  src: `url(${manropeMedium}) format("woff2")`,
                  fontWeight: 500,
                },
              },
              {
                '@font-face': {
                  fontFamily: 'Manrope',
                  src: `url(${manropeRegular}) format("woff2")`,
                  fontWeight: 400,
                },
              },
              {
                '@font-face': {
                  fontFamily: 'Roboto',
                  src: `url(${robotoMedium}) format("woff2")`,
                  fontWeight: 500,
                },
              },
              {
                svg: {
                  transition: '0.5s',
                },
              },
            ]}
        />
  );
}
