import React, { useState } from 'react';
import { Box, rem, Stack, UnstyledButton } from '@mantine/core';
import imageVideoPreview from '../../../../assets/images/video-preview.png';
import CustomText from '../../../Typography/CustomText/CustomText';
import MainButton from '../../../buttons/buttons';
import CustomTitle from '../../../Typography/CustomTitle/CustomTitle';
import { useStyle } from './ContentWithVideo.style';
import { useCallback } from 'react';
import BasicModal from '../../../Modals/BasicModal';

function ContentWithVideo({
  title,
  description,
  content,
  onClick,
  buttonText,
  spacing,
  fontSize,
}) {
  const { classes } = useStyle();
  const [opened, setOpened] = useState(false);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <Stack spacing={32} align="center" className={classes.container}>
      <Stack spacing={spacing || 8}>
        <CustomTitle
          order={0}
          sx={{ fontSize: rem(40), lineHeight: rem(46), textAlign: 'center' }}
        >
          {title}
        </CustomTitle>
        <CustomText variant="bodySmall" sx={{ textAlign: 'center' }}>
          {description}
        </CustomText>
      </Stack>
      {content}
      <Box className={classes.videoContainer}>
        <UnstyledButton
          className={classes.watchVideoButton}
          onClick={() => setOpened(true)}
        >
          <CustomText variant="body1">Watch video</CustomText>
        </UnstyledButton>
        <img
          src={imageVideoPreview}
          className={classes.image}
          alt="video preview"
        />
      </Box>
      <MainButton hugePadding onClick={handleClick} sx={{ minWidth: rem(178) }}>
        <CustomText
          variant="body2"
          sx={{ fontSize: rem(fontSize || 18), fontWeight: 500 }}
        >
          {buttonText}
        </CustomText>
      </MainButton>
      {/* embedded youtube video modal */}
      <BasicModal
        withCloseButton={false}
        opened={opened}
        onClose={() => setOpened(false)}
        bodyClassName={classes.videoModal}
        contentClassName={classes.videoModalContent}
      >
        <Box mb={-8}>
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Box>
      </BasicModal>
    </Stack>
  );
}

export default ContentWithVideo;
