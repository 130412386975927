import { createStyles } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  modalBody: {
    background: theme.colors.white[2],
  },
  modalCloseButton: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
}));
