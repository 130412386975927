import React, { useState, useCallback, useMemo } from 'react';
import { Box, Center, Flex, Group, rem, UnstyledButton } from '@mantine/core';
import SkeletonContainer from '../../../../SkeletonContainer';
import CustomText from '../../../../Typography/CustomText/CustomText';
import { useStyle } from './TopicList.style';
import ListItem from '../ListItem/ListItem';
import { ReactComponent as ChevronDown } from '../../../../../assets/icons/chevronDown-icon.svg';

function TopicList({
  topics,
  onSelectTopic,
  setActiveTopics,
  activeTopics,
  loading,
  ...rest
}) {
  const [showExtraTopic, setShowExtraTopics] = useState(false);
  const { classes, cx } = useStyle();

  const showMoreTopics = useMemo(
    () => !loading && topics?.length > 0,
    [loading, topics],
  );

  const addActiveTopics = (topic, e) => {
    if (['path', 'svg'].includes(e?.target?.tagName)) return;

    if (onSelectTopic) {
      onSelectTopic();
    }

    setActiveTopics([...activeTopics, topic]);
  };

  const removeActiveTopic = (topic) => {
    setActiveTopics(activeTopics.filter((el) => el !== topic));
  };

  const generateTopicsElements = useCallback(() => {
    if (!topics) return;

    const elements = [];
    topics.forEach((topic, index) => {
      elements.push(
        <ListItem
          key={topic?.id || index}
          title={topic?.value}
          customClassName={`topic-${index}`}
          isActive={
            activeTopics.findIndex((item) => item === topic?.value) !== -1
          }
          addActive={(e) => addActiveTopics(topic.value, e)}
          removeActive={() => removeActiveTopic(topic.value)}
          {...rest}
        />,
      );
    });

    return elements;
  }, [activeTopics, topics, onSelectTopic]);

  return (
    <Flex gap={24}>
      <Flex p={0}>
        <Center h={37} w="fit-content">
          <CustomText variant="h5" sx={{ minWidth: rem(195) }}>
            Filter by Competitive Topics:
          </CustomText>
        </Center>
      </Flex>
      <Flex p={0} w="100%">
        <Flex gap={50} align="inherit" justify="space-between" sx={{ flex: 2 }}>
          <Group
            gap={8}
            className={cx(classes.topicItemsContainer, {
              [classes.topicItemsContainerExpanded]: showExtraTopic,
            })}
          >
            <Box
              className="topic-guide-box"
              ml={-16}
              sx={{
                position: 'absolute',
                transform: 'translateX(35px)',
              }}
            />
            {loading ? (
              <>
                <SkeletonContainer
                  width={120}
                  height={35}
                  radius={100}
                  amount={7}
                />
              </>
            ) : (
              <>
                {!topics || topics?.length === 0 ? (
                  <Center h={37}>
                    <CustomText variant="body2">No topics found</CustomText>
                  </Center>
                ) : (
                  <>{generateTopicsElements()}</>
                )}
              </>
            )}
          </Group>
          {showMoreTopics && (
            <UnstyledButton
              onClick={() =>
                setShowExtraTopics((prevState) => (prevState ? null : 'topics'))
              }
              sx={{ maxHeight: rem(37), minWidth: 'fit-content' }}
            >
              <Group spacing={8}>
                <CustomText variant="body2">
                  {showExtraTopic ? 'See less' : 'See more'}
                </CustomText>
                <ChevronDown
                  style={{
                    width: rem(14),
                    height: rem(8),
                    transform: `rotate(${showExtraTopic ? 180 : 0}deg)`,
                  }}
                />
              </Group>
            </UnstyledButton>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TopicList;
