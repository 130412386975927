import { useCallback } from 'react';

const useTable = () => {
  const getTableData = useCallback((data, head, headOptions, withoutSorting) => {
    const table = {
      head,
      headOptions,
      content: [],
    };

    if (!data) return table;

    data.forEach((row) => {
      let tableRowData = [];

      Object.keys(row).forEach((columnDataKey, index) => {
        if (columnDataKey === 'id') {
          tableRowData.push({
            id: row[columnDataKey],
          });
        } else if (headOptions[index]?.useRange) {
          tableRowData.push({
            value: row[columnDataKey].value,
            title: row[columnDataKey].title,
            color: row[columnDataKey].color,
            key: columnDataKey,
          });
        } else if (table.headOptions[index]?.useContent) {
          tableRowData.push({
            title: row[columnDataKey].title,
            imageUrl: row[columnDataKey].imageUrl,
            key: columnDataKey,
          });
        } else {
          tableRowData.push({
            title: row[columnDataKey],
            key: columnDataKey,
          });
        }
      });
      if(withoutSorting) {
        table.content.push(tableRowData);
        return
      }

      const sortedTableRowData = [];

      headOptions.forEach((option) => {
        const rowData = tableRowData.find((el) => el.key === option.key);
        sortedTableRowData.push(
          rowData || {
            title: '-',
          },
        );
      });
      table.content.push(sortedTableRowData);
      tableRowData = [];
    });

    return table;
  }, []);

  return { getTableData };
};

export default useTable;
