import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  rowContainer: {
    maxHeight: rem(50),
    width: '100%',
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: rem(8),
    margin: '0 auto',
    padding: `0 ${rem(16)}`,
    transition: '0.5s',

    '& .hover-item': {
      display: 'none',
    },

    '&:hover .hover-item': {
      display: 'block',
    },
  },
  row: {
    position: 'relative',
    minHeight: rem(48),
    maxHeight: rem(48),
    margin: '0 auto',
  },
  rowHover: {
    '&:hover': {
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
    },
  },
}));
