import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';
import useJwtToken from '../../../hooks/useJwtToken';

const useFetchPlans = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([])
  const { jwtTokenLoading } = useJwtToken();
  const { getSubscriptionPlans, updateSubscriptionPlan } = useRadarApi();
  const [fetchFunc, loading] = useFetch(getSubscriptionPlans);

  const updateSubscriptionPlanWrapper = useCallback(async (planId) => {
    return await updateSubscriptionPlan({planId})
  }, [updateSubscriptionPlan, jwtTokenLoading])

  const fetchWrapper = useCallback(async () => {
    const data = await fetchFunc();
    setSubscriptionPlans(data);
  }, [fetchFunc, getSubscriptionPlans]);

  useEffect(() => {
    if (!jwtTokenLoading) {
      fetchWrapper();
    }
  }, [jwtTokenLoading, fetchWrapper]);

  return [subscriptionPlans, loading, updateSubscriptionPlanWrapper];
};

export default useFetchPlans;
