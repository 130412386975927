import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  container: {
    width: rem(451),
    margin: `0 ${rem(100)} ${rem(88)} 0`
  },
  image: {
    height: rem(260),
    width: rem(451),
  },
  tagsContainer: {
    background: theme.colors.white[2],
    padding: rem(16),
    borderRadius: rem(24),
    boxShadow: "0px 4px 24px 0px rgba(59, 93, 108, 0.10)",
    margin: `${rem(16)} 0 ${rem(32)}`
  }
}));
