import { useCallback, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useJwtToken from '../../../hooks/useJwtToken';
import useRadarApi from '../../../hooks/useRadarApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../features/layoutSlice';

const useOrganizationSettings = () => {
  const { getOrganizationSettings, updateOrganizationSettings } = useRadarApi();
  const { loading: jwtTokenLoading } = useJwtToken();
  const [organizationSettings, setGrganizationSettings] = useState({});
  const [fetchFunc, loading] = useFetch(getOrganizationSettings);
  const dispatch = useDispatch();

  const updateOrganizationSettingsWrapper = useCallback(
    (syncRecommendedContent) => {
      updateOrganizationSettings({ syncRecommendedContent })
        .then(() => {
          dispatch(
            setSnackbar({
              variant: 'success',
              description: 'Your changes have been successfully updated',
            }),
          );
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              variant: 'error',
              description: 'Something went wrong...',
            }),
          );
        });
    },
    [jwtTokenLoading, updateOrganizationSettings],
  );

  const fetchWrapper = useCallback(async () => {
    const data = await fetchFunc();
    setGrganizationSettings(data);
  }, [fetchFunc, getOrganizationSettings]);

  useEffect(() => {
    if (!jwtTokenLoading) {
      fetchWrapper();
    }
  }, [jwtTokenLoading]);

  return {
    organizationSettings,
    loading,
    updateOrganizationSettings: updateOrganizationSettingsWrapper,
  };
};

export default useOrganizationSettings;
