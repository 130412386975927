import React, { useRef } from 'react';
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';

import logger from '../../utils/logger';

const DoughnutChart = ({
  labels, chartData, chartColors, onSegmentSelection,
}) => {
  const datasets = [{
    labels,
    data: chartData,
    backgroundColor: chartColors,
    borderWidth: 0,
  }];

  const lineRef = useRef(null);

  const onClick = (event) => {
    try {
      const elem = getElementAtEvent(lineRef.current, event);
      onSegmentSelection(elem[0].index);
    } catch {
      onSegmentSelection(null);
    }
  };

  const options = {
    maintainAspectRatio: false,
    cutout: '70%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,
      },
    },
  };

  const data = { labels, datasets };

  logger.info('Doughnut data', data);

  return (
    <Doughnut ref={lineRef} data={data} options={options} onClick={onClick} />
  );
};

export default DoughnutChart;
