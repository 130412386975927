import ApiResponseError from '../errors/apiResponseError';
import { validateURLParams } from '../utils';

const fetchJson = async (url, options = {}) => {
  const mergedOptions = {
    ...options,
    credentials: 'include',
  };

  const response = await fetch(url, mergedOptions);

  if (response.status === 204 || response.status === 202) {
    return {};
  }

  if (!response.ok) {
    let error;

    switch (response.status) {
      case 400:
        error = new ApiResponseError('Bad request', response.status);
        break;
      case 401:
        error = new ApiResponseError('Unauthorized', response.status);
        break;
      case 403:
        error = new ApiResponseError('Forbidden', response.status);
        break;
      case 404:
        error = new ApiResponseError('Not found', response.status);
        break;
      case 409:
        error = new ApiResponseError('Conflict', response.status);
        break;
      case 500:
      case 502:
      case 503:
      case 504:
        error = new ApiResponseError(
          'Server error, please try again later',
          response.status,
        );
        break;
      default:
        error = new ApiResponseError('An error occurred', response.status);
        break;
    }

    throw error;
  }

  return response.json();
};

const getContents = async (token, apiBaseUrl, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/contents${queryString ? `?${queryString}` : ''}`;
  return fetchJson(url, requestOptions);
};

const getContentsToPromote = async (token, apiBaseUrl, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/contents/toPromote${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getContentsUtilization = async (token, apiBaseUrl, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/contents/utilization${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getContentDetails = async (token, apiBaseUrl, id, queryParams) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/contents/${id}${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getContentDetailsRelatedContent = async (
  token,
  apiBaseUrl,
  id,
  queryParams,
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/contents/${id}/relatedContents${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const createContent = async (token, apiBaseUrl, data) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  const url = `${apiBaseUrl}/contents`;
  return fetchJson(url, requestOptions);
};

const getCompetitorAnalysisTimeSeries = async (
  token,
  apiBaseUrl,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/competitors/analysisTimeSeries${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getCompetitorAnalysisMetrics = async (
  token,
  apiBaseUrl,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/competitors/analysisMetrics${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getCompetitorsContents = async (token, apiBaseUrl, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/competitors/contents${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getCompetitorsTopics = async (token, apiBaseUrl, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/competitors/topics${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getCompetitorMentions = async (token, apiBaseUrl, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/competitors/mentions${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getContentSummary = async (token, apiBaseUrl, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/competitors/contentSummary${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getContentInteractions = async (token, apiBaseUrl, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/competitors/contentInteractions${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const updateContentInteractions = async (token, apiBaseUrl, data) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  const url = `${apiBaseUrl}/competitors/contentInteractions`;
  return fetchJson(url, requestOptions);
};

const getContentRelatedArticles = async (
  token,
  apiBaseUrl,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/competitors/contentRelatedArticles${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getContentRelatedSocialPosts = async (
  token,
  apiBaseUrl,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/competitors/contentRelatedSocialPosts${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getContacts = async (token, apiBaseUrl, queryParams = {}) => {
  const validatedQueryParams = validateURLParams(queryParams);
  const queryString = decodeURIComponent(
    new URLSearchParams(validatedQueryParams).toString(),
  );

  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/contacts${queryString ? `?${queryString}` : ''}`;
  return fetchJson(url, requestOptions);
};

const getContactsSummary = async (token, apiBaseUrl, id) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/contacts/${id}`;
  return fetchJson(url, requestOptions);
};

const getAccounts = async (token, apiBaseUrl, queryParams = {}) => {
  const validatedQueryParams = validateURLParams(queryParams);
  const queryString = decodeURIComponent(
    new URLSearchParams(validatedQueryParams).toString(),
  );

  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/accounts${queryString ? `?${queryString}` : ''}`;
  return fetchJson(url, requestOptions);
};

const getAccountsSummary = async (token, apiBaseUrl, id) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/accounts/${id}`;
  return fetchJson(url, requestOptions);
};

const updateSocialEnrichments = async (token, apiBaseUrl, data) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const url = `${apiBaseUrl}/contacts/socialEnrichments`;
  return fetchJson(url, requestOptions);
};

const getHubspotAuthURL = async (token, apiBaseUrl, queryParams) => {
  const queryString = new URLSearchParams(queryParams).toString();

  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/integrations/hubspot/authUrl${
    queryString ? `?${queryString}` : ''
  }`;
  const response = await fetch(url, requestOptions);

  const { authUrl } = await response.json();

  return authUrl;
};

const getPardotAuthURL = async (token, apiBaseUrl, queryParams) => {
  const queryString = new URLSearchParams(queryParams).toString();

  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/integrations/pardot/authUrl${
    queryString ? `?${queryString}` : ''
  }`;
  const response = await fetch(url, requestOptions);

  const { authUrl } = await response.json();

  return authUrl;
};

const getGoogleAnalyticsAuthURL = async (token, apiBaseUrl, queryParams) => {
  const queryString = new URLSearchParams(queryParams).toString();

  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/integrations/googleAnalytics/authUrl${
    queryString ? `?${queryString}` : ''
  }`;
  const response = await fetch(url, requestOptions);

  const { authUrl } = await response.json();

  return authUrl;
};

const getAudienceRelevance = async (token, apiBaseUrl, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/topics/audienceRelevance${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getTopicSummaries = async (token, apiBaseUrl, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/topics/summaries${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getIncidenceAndEngagement = async (
  token,
  apiBaseUrl,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/topics/incidenceAndEngagement${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getTopicInterestedContacts = async (
  token,
  apiBaseUrl,
  id,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/topics/${id}/interestedContacts${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getTopicEngagementRate = async (
  token,
  apiBaseUrl,
  id,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/topics/${id}/engagementRate${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getTopicCoverageScore = async (token, apiBaseUrl, id) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/topics/${id}/coverageScore`;
  return fetchJson(url, requestOptions);
};

const getTopicValue = async (token, apiBaseUrl, id) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/topics/${id}`;
  return fetchJson(url, requestOptions);
};

const getTopicDetailContent = async (
  token,
  apiBaseUrl,
  id,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/topics/${id}/contents${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getTopicOtherAudienceInterests = async (token, apiBaseUrl, id) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/topics/${id}/otherAudienceInterests`;
  return fetchJson(url, requestOptions);
};

const getTopicPeopleAlsoAsk = async (token, apiBaseUrl, id) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/topics/${id}/peopleAlsoAsk`;
  return fetchJson(url, requestOptions);
};

const getTopicContactMatches = async (token, apiBaseUrl, id) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/topics/${id}/contactMatches`;
  return fetchJson(url, requestOptions);
};

const enrichTopic = async (token, apiBaseUrl, data) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const url = `${apiBaseUrl}/topics/organicSearch`;
  return fetchJson(url, requestOptions);
};

// radar endpoints

const getRadarContentToPromote = async (token, apiBaseUrl) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/radar/contentToPromote`;
  return fetchJson(url, requestOptions);
};

const getRadarTopicsToWriteAbout = async (token, apiBaseUrl) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/radar/topicsToWriteAbout`;
  return fetchJson(url, requestOptions);
};

const getRadarTopicsIncidenceAndEngagement = async (
  token,
  apiBaseUrl,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/radar/topicsIncidenceAndEngagement${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getRadarPersonalizationScore = async (
  token,
  apiBaseUrl,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/radar/personalizationScore${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getRadarContentInfluencedRevenue = async (
  token,
  apiBaseUrl,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/radar/contentInfluencedRevenue${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getRadarNewPersonalizedEngagementsScore = async (
  token,
  apiBaseUrl,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/radar/newPersonalizedEngagementsScore${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getRadarPersonalizedContentEngagementRate = async (
  token,
  apiBaseUrl,
  queryParams = {},
) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/radar/personalizedContentEngagementRate${
    queryString ? `?${queryString}` : ''
  }`;
  return fetchJson(url, requestOptions);
};

const getCredits = async (token, apiBaseUrl) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/settings/credits`;
  return fetchJson(url, requestOptions);
};

const getIntegrations = async (token, apiBaseUrl) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/settings/integrations`;
  return fetchJson(url, requestOptions);
};

const getHubspotContactLists = async (token, apiBaseUrl) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/integrations/hubspot/contactLists`;
  return fetchJson(url, requestOptions);
};

const getPardotContactLists = async (token, apiBaseUrl) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/integrations/pardot/contactLists`;
  return fetchJson(url, requestOptions);
};

const patchIntegration = async (token, apiBaseUrl, id, body) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const url = `${apiBaseUrl}/settings/integrations/${id}`;
  return fetchJson(url, requestOptions);
};

const patchContentDetails = async (token, apiBaseUrl, id, body) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const url = `${apiBaseUrl}/contents/${id}`;
  return fetchJson(url, requestOptions);
};

const getSubscriptionPlans = async (token, apiBaseUrl) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/config/plans`;
  return fetchJson(url, requestOptions);
};

const updateSubscriptionPlan = async (token, apiBaseUrl, body) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const url = `${apiBaseUrl}/settings/subscriptions`;
  return fetchJson(url, requestOptions);
};

const createAccount = async (token, apiBaseUrl, data) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const url = `${apiBaseUrl}/config/accounts`;
  return fetchJson(url, requestOptions);
};

const getActiveSubscriptionPlan = async (token, apiBaseUrl, orgId) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };

  const url = `${apiBaseUrl}/settings/subscriptions?orgId=${orgId}`;
  return fetchJson(url, requestOptions);
};

const getStripeClientSecret = async (token, apiBaseUrl) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };
  const url = `${apiBaseUrl}/settings/setupIntents`;
  return fetchJson(url, requestOptions);
};

const getOrganizationSettings = async (token, apiBaseUrl) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };
  const url = `${apiBaseUrl}/settings/org`;
  return fetchJson(url, requestOptions);
};

const updateOrganizationSettings = async (token, apiBaseUrl, data) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const url = `${apiBaseUrl}/settings/org`;
  return fetchJson(url, requestOptions);
};

const getPortalSession = async (token, apiBaseUrl) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };
  const url = `${apiBaseUrl}/settings/portalSession`;
  return fetchJson(url, requestOptions);
};

const getUsersFromTeam = async (token, apiBaseUrl) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };
  const url = `${apiBaseUrl}/settings/users`;
  return fetchJson(url, requestOptions);
};

const addUserToTeam = async (token, apiBaseUrl, data) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const url = `${apiBaseUrl}/settings/users`;
  return fetchJson(url, requestOptions);
};

const deleteUserFromTeam = async (token, apiBaseUrl, userId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };
  const url = `${apiBaseUrl}/settings/users/${userId}`;
  return fetchJson(url, requestOptions);
};

const getMemberships = async (token, apiBaseUrl) => {
  const requestOptions = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };
  const url = `${apiBaseUrl}/users/memberships`;
  return fetchJson(url, requestOptions);
};

const updateUserContext = async (token, apiBaseUrl, data) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const url = `${apiBaseUrl}/users/context`;
  return fetchJson(url, requestOptions);
};

export {
  getContents,
  getContentsToPromote,
  getContentsUtilization,
  getContentDetails,
  getContentDetailsRelatedContent,
  createContent,
  getCompetitorAnalysisTimeSeries,
  getCompetitorAnalysisMetrics,
  getCompetitorsContents,
  getCompetitorMentions,
  getCompetitorsTopics,
  getContentSummary,
  getContentInteractions,
  updateContentInteractions,
  getContentRelatedArticles,
  getContentRelatedSocialPosts,
  getContacts,
  getContactsSummary,
  getAccounts,
  getAccountsSummary,
  updateSocialEnrichments,
  getHubspotAuthURL,
  getPardotAuthURL,
  getGoogleAnalyticsAuthURL,
  getAudienceRelevance,
  getTopicSummaries,
  getIncidenceAndEngagement,
  getTopicInterestedContacts,
  getTopicEngagementRate,
  getTopicCoverageScore,
  getTopicValue,
  getTopicDetailContent,
  getTopicOtherAudienceInterests,
  getTopicPeopleAlsoAsk,
  getTopicContactMatches,
  enrichTopic,
  getRadarContentToPromote,
  getRadarTopicsToWriteAbout,
  getRadarTopicsIncidenceAndEngagement,
  getRadarPersonalizationScore,
  getRadarContentInfluencedRevenue,
  getRadarNewPersonalizedEngagementsScore,
  getRadarPersonalizedContentEngagementRate,
  getCredits,
  getIntegrations,
  getHubspotContactLists,
  getPardotContactLists,
  patchIntegration,
  patchContentDetails,
  getSubscriptionPlans,
  createAccount,
  getActiveSubscriptionPlan,
  getStripeClientSecret,
  getOrganizationSettings,
  updateOrganizationSettings,
  updateSubscriptionPlan,
  getPortalSession,
  getUsersFromTeam,
  addUserToTeam,
  deleteUserFromTeam,
  getMemberships,
  updateUserContext,
};
