import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import competitorContentReducer from '../features/competitorContentSlice';
import layoutReducer from '../features/layoutSlice';
import settingsReducer from '../features/settingsSlice';
import profileReducer from '../features/profileSlice';
import sessionStorage from 'redux-persist/es/storage/session';

const rootReducer = combineReducers({
  layout: layoutReducer,
  settings: settingsReducer,
  profile: profileReducer,
  competitorContent: competitorContentReducer,
});

const persistConfig = {
  key: 'radar',
  storage:  sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
