import React from 'react';
import QuestionWrapper from './QuestionWrapper';
import { ReactComponent as GoogleAnalyticsLogo } from '../../../assets/icons/google-analytics-logo.svg';
import { Group, rem, Stack } from '@mantine/core';
import CustomText from '../../Typography/CustomText/CustomText';
import LoginInput from '../../LoginInput/LoginInput';
import CustomCheckbox from '../../Checkbox';
import ButtonGroup from '../components/ButtonGroup/ButtonGroup';
import { isNotEmpty, useForm } from '@mantine/form';

function EnterAnalyticsId({ onContinue, onSkip }) {
  const form = useForm({
    initialValues: {
      analyticsId: '',
    },
    validate: {
      analyticsId: isNotEmpty(
        "Are you sure that you've entered your id correctly?",
      ),
    },
  });

  return (
    <Stack spacing={40}>
      <QuestionWrapper
        title="Add your Google Analytics ID"
        description={
          <>
            You can find your ID in your Google Analytics account by going to{' '}
            <br /> Admin -{'>'} Property settings -{'>'} Tracking ID
          </>
        }
        onSkip={onSkip}
        image={<GoogleAnalyticsLogo />}
      />
      <Stack mx="auto" mt={rem(-44)} w={rem(427)}>
        <LoginInput
          placeholder="G-"
          height={rem(55)}
          {...form.getInputProps('analyticsId')}
        />
        <Group spacing={8}>
          <CustomCheckbox checked />
          <CustomText variant="body2">IP Anonymization</CustomText>
        </Group>
      </Stack>
      <ButtonGroup onMainButtonClick={form.onSubmit(onContinue)} mainText="Save & continue" />
    </Stack>
  );
}

export default EnterAnalyticsId;
