import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../../features/layoutSlice';
import useFetch from '../../../../hooks/useFetch';
import useJwtToken from '../../../../hooks/useJwtToken';
import useRadarApi from '../../../../hooks/useRadarApi';

const useContactLists = (contactLists, fetchFunc, isSelectedAll, callback) => {
  const [selectedLists, setSelectedLists] = useState([]);
  const [availableContactLists, setAvailableContactLists] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showError, setShowError] = useState(false);
  const [lastUpdateSnapshot, setLastUpdateSnapshot] = useState(null);
  const { patchIntegration } = useRadarApi();
  const dispatch = useDispatch();
  const { loading } = useJwtToken();
  const [fetchContactLists, contactListsLoading] = useFetch(fetchFunc);

  useEffect(() => {
    if (isSelectedAll) {
      setSelectAll(true);
    }
  }, [isSelectedAll]);

  const patchContactLists = useCallback(
    (integrationId, externalBody) => {
      if (!selectedLists?.length && !selectAll) {
        setShowError(true);
        return true;
      }

      const body = {};

      if (selectAll) {
        body.syncAllContacts = true;
      } else {
        body.contactLists = selectedLists;
      }

      patchIntegration(integrationId, externalBody ? externalBody : body)
        .then((data) => {
          setLastUpdateSnapshot(data);
          if(callback) {
            callback()
          }
          dispatch(
            setSnackbar({
              variant: 'success',
              description: 'Your changes have been successfully updated',
            }),
          );
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              variant: 'error',
              description: 'Something went wrong...',
            }),
          );
        });
    },
    [selectedLists, selectAll],
  );

  const fetchWrapper = useCallback(async () => {
    const data = await fetchContactLists();

    setAvailableContactLists(data);
  }, [fetchContactLists]);

  useEffect(() => {
    if (!loading) {
      fetchWrapper();
    }
  }, [loading]);

  useEffect(() => {
    if (!contactLists) return;

    setSelectedLists(contactLists);
  }, [contactLists]);

  useEffect(() => {
    if (selectedLists?.length > 0 && showError) {
      setShowError(false);
    } else if (selectAll && showError) {
      setShowError(false);
    }
  }, [selectedLists, selectAll]);

  return {
    contactLists: availableContactLists,
    contactListsLoading,
    patchContactLists,
    selectedLists,
    setSelectedLists,
    selectAll,
    setSelectAll,
    showError,
    lastUpdateSnapshot,
  };
};

export default useContactLists;
