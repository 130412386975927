import React, { useEffect, useMemo, useState } from 'react';
import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import CustomTooltip from './Tooltip/Tooltip';

function GuidedTour({ steps, run, stepIndex, setStepIndex, ...rest }) {
  const [internalStepIndex, setInternalStepIndex] = useState(0);
  const [tourStopped, setTourStopped] = useState(false);
  const tourActive = useSelector((state) => state.profile.guide.tourActive);

  const stepsWithHandler = useMemo(
    () =>
      steps.map((el) => {
        return {
          ...el,
          stepIndex: stepIndex || internalStepIndex,
          setStepIndex: setStepIndex || setInternalStepIndex,
        };
      }),
    [steps, internalStepIndex, stepIndex],
  );

  useEffect(() => {
    const targetElement = document.querySelector(steps[stepIndex]?.target);
    let interval;

    if (!targetElement) {
      setTourStopped(true);
      interval = setInterval(() => {
        const element = document.querySelector(steps[stepIndex]?.target);

        if(element) {
          setTourStopped(false)
          clearInterval(interval)
        }
      }, 100)
    } else {
      const { x, y } = targetElement.getBoundingClientRect();
      if (x === 0 || y === 0) {
        setTourStopped(true);
      } else {
        setTourStopped(false);
      }
    }

    return () => {
      clearInterval(interval)
    }
  });

  return (
    <Joyride
      steps={stepsWithHandler}
      run={run && tourActive && !tourStopped}
      stepIndex={stepIndex || internalStepIndex}
      disableOverlay={true}
      tooltipComponent={CustomTooltip}
      disableScrolling={steps[stepIndex || internalStepIndex]?.disableScrolling}
      tooltipOffset={0}
      scrollOffset={400}
      continuous={true}
      floaterProps={{
        styles: {
          arrow: {
            length: 9,
            spread: 18,
          },
        },
        offset: 0,
      }}
      styles={{
        options: {
          arrowColor: '#F6C25D',
        },
      }}
      {...rest}
    />
  );
}

export default GuidedTour;
