import React, { useCallback } from 'react';
import { Box, Checkbox, rem } from '@mantine/core';

function CustomCheckbox({
  checked,
  size,
  onChange,
  blackBorder,
  grayBackground,
  ...rest
}) {
  const handleClick = useCallback(() => {
    if(onChange) {
      onChange()
    }
  }, [onChange])

  return (
    <Box
      sx={(theme) => ({
        '& svg': {
          color: checked ? 'black !important' : '',
        },
        '& input:disabled + svg': {
          color: `${theme.colors.gray[5]} !important`
        }
      })}
    >
      <Checkbox
        size={rem(size)}
        styles={(theme) => ({
          input: {
            cursor: 'pointer',
            borderColor: blackBorder
              ? theme.colors.black[0]
              : theme.colors.gray[4],
            background: grayBackground ? theme.colors.white[3] : '',
            borderWidth: 1.5,
            borderRadius: rem(6),

            '&:checked': {
              backgroundColor: theme.colors.white[0],
              borderColor: theme.colors.black[0],
              borderWidth: 1.5,
              borderRadius: rem(6),
            },

            '&:disabled': {
              backgroundColor: theme.colors.white[0],
              borderColor: theme.colors.gray[3]
            }
          },
        })}
        checked={!!checked}
        onChange={handleClick}
        {...rest}
      />
    </Box>
  );
}

export default CustomCheckbox;
