import React from 'react';
import { Group, rem, Stack, UnstyledButton } from '@mantine/core';
import Heading from '../../Heading';
import ButtonGroup from '../components/ButtonGroup/ButtonGroup';
import CustomText from '../../Typography/CustomText/CustomText';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevronDown-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setSignupPageIndex } from '../../../features/layoutSlice';

function QuestionWrapper({
  children,
  title,
  description,
  image,
  onSkip,
  ...rest
}) {
  const pageIndex = useSelector((state) => state.layout.signupPageIndex);
  const dispatch = useDispatch();

  return (
    <Stack align="center" pt={88}>
      {onSkip && (
        <>
          <UnstyledButton
            sx={{ position: 'absolute', top: rem(32), left: rem(104) }}
            onClick={() =>
              dispatch(
                setSignupPageIndex({
                  pageIndex: pageIndex - 1,
                }),
              )
            }
          >
            <Group spacing={8}>
              <ChevronDown style={{ transform: 'rotate(90deg)' }} />
              <CustomText variant="bodyLarge">Back</CustomText>
            </Group>
          </UnstyledButton>
          <UnstyledButton
            sx={{ position: 'absolute', top: rem(32), right: rem(104) }}
            onClick={onSkip}
          >
            <CustomText
              variant="bodyLarge"
              sx={(theme) => ({ color: theme.colors.gray[4] })}
            >
              Skip
            </CustomText>
          </UnstyledButton>
        </>
      )}
      {image && <>{image}</>}
      <Stack spacing={44}>
        <Heading title={title} description={description} />
        <Stack>
          <ButtonGroup {...rest} />
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default QuestionWrapper;
