import { useAuth0 } from '@auth0/auth0-react';

import logger from '../utils/logger';

function AuthCallbackPage() {
  const { isLoading, error, isAuthenticated, user } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    logger.error(error);
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
        <h1>isAuthenticated</h1>
        <p>{JSON.stringify(isAuthenticated)}</p>
        <h1>user</h1>
        <p>{JSON.stringify(user)}</p>
      </div>
    );
  }

  return null;
}

export default AuthCallbackPage;
