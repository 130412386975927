import React from 'react';
import { useStyle } from './FilterPanel.style';
import CustomDropdown from '../CustomDropdown';
import CustomText from '../../Typography/CustomText/CustomText';

function FilterPanel({ children, filterOpened, setFilterOpened }) {
  const { classes } = useStyle();

  return (
    <CustomDropdown
      opened={filterOpened}
      onChange={(value) => {
        if (value === false) return;
        setFilterOpened(value);
      }}
      customAnimationState={filterOpened}
      targetItem={
        <CustomText variant="body2" className="filter-by" onClick={() => setFilterOpened(!filterOpened)}>
          Filter by
        </CustomText>
      }
      gap={8}
      menuOptions={[]}
      targetStyles={{
        minWidth: 'fit-content',
        justifyContent: 'space-between',
      }}
      dropdownClassName={classes.filterDropdownContainer}
      customDropdownComponent={children}
    />
  );
}

export default FilterPanel;
