/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto';

import LineChart from '../../../../components/charts/LineChart.jsx';
import BarChart from '../../../../components/charts/BarChart.jsx';

import './CompetitorAnalysis.css';
import { Flex, Box, Stack, Group, Center, Loader, rem } from '@mantine/core';
import { useStyle } from './CompetitorsAnalysis.style.jsx';
import CompanyItem from './components/CompanyItem.jsx';
import { ReactComponent as HideIcon } from '../../../../assets/icons/hide-icon.svg';
import { ReactComponent as ShowIcon } from '../../../../assets/icons/show-icon.svg';
import CustomText from '../../../../components/Typography/CustomText/CustomText.jsx';
import { changeColorOpacity, mapLabelsForChart } from '../../../../utils.js';
import CustomSegmentControl from '../../../../components/SegmentControl/SegmentControl.jsx';
import SkeletonContainer from '../../../../components/SkeletonContainer.jsx';
import { ReactComponent as LockIcon } from '../../../../assets/icons/lock-icon.svg';

const generateColor = (opacity = 1) => {
  const r = Math.floor(Math.random() * 100) + 100; // range: 100-200
  const g = Math.floor(Math.random() * 100) + 100; // range: 100-200
  const b = Math.floor(Math.random() * 100) + 100; // range: 100-200
  return `rgba(${r},${g},${b},${opacity})`;
};

function CompetitorAnalysis({ data, chartRedraw, setChartRedraw, loading }) {
  const websiteContentVolume = 'WEBSITE_CONTENT';
  const linkedInEngagementCount = 'LINKEDIN_ENGAGEMENT';
  const publicMediaMentions = 'NEWS_MENTIONS';

  const [chartType, setChartType] = useState(websiteContentVolume);
  const [visibleCompanies, setVisibleCompanies] = useState([]); // initially, all companies are visible
  const [companyColors, setCompanyColors] = useState({});
  const [isAllItemsHidden, setIsAllItemsHidden] = useState(false);
  const [activeCompetitorName, setActiveCompetitorName] = useState(null);

  const { classes } = useStyle();

  useEffect(() => {
    if (!data?.length) return;

    setVisibleCompanies(
      data.reduce(
        (obj, competitorAnalysis) => ({
          ...obj,
          [competitorAnalysis.competitor]: true,
        }),
        {},
      ),
    );
  }, [data]);

  useEffect(() => {
    setCompanyColors((prevColors) => {
      const newColors = { ...prevColors };
      data.forEach((item) => {
        if (!newColors[item.competitor]?.color) {
          const generatedColor = generateColor();
          newColors[item.competitor] = {
            color: generatedColor,
            chartColor: generatedColor,
          };
        } else {
          const isColorLight =
            item.competitor !== activeCompetitorName && activeCompetitorName;
          newColors[item.competitor].chartColor = changeColorOpacity(
            newColors[item.competitor].color,
            isColorLight ? '0.3' : '1',
          );
        }
      });

      return newColors;
    });
  }, [data, activeCompetitorName]);

  useEffect(() => {
    const prevCompanyVisibility = Object.values(visibleCompanies);

    Object.values(visibleCompanies).forEach((companyVisibility) => {
      if (companyVisibility !== prevCompanyVisibility) {
        setIsAllItemsHidden((prevState) => !prevState);
      }
    });
    setIsAllItemsHidden(Object.values(visibleCompanies).every((el) => !el));
  }, [visibleCompanies]);

  const handleCompanyVisibilityChange = (companyName) => {
    setChartRedraw(false);
    setVisibleCompanies({
      ...visibleCompanies,
      [companyName]: !visibleCompanies[companyName],
    }); // toggle the visibility of the company
  };

  const handleAllCompaniesVisibilityChange = () => {
    const newVisibleCompanies = {};

    Object.keys(visibleCompanies).forEach((key) => {
      newVisibleCompanies[key] = isAllItemsHidden;
    });

    setIsAllItemsHidden((prevState) => !prevState);
    setVisibleCompanies(newVisibleCompanies);
  };

  const getLabelsForChart = () => {
    if (!data?.length) return;
    const timeSeries = data[0].timeSeries[chartType];
    return mapLabelsForChart(timeSeries);
  };

  const mapCompanyDataForChart = (showHidden = false) => {
    const filteredData = data
      .filter((competitorAnalysisTimeSeries) =>
        showHidden
          ? true
          : visibleCompanies[competitorAnalysisTimeSeries.competitor],
      )
      .map((competitorAnalysisTimeSeries) => ({
        name: competitorAnalysisTimeSeries.competitor,
        color: companyColors[competitorAnalysisTimeSeries.competitor]?.color,
        chartColor:
          companyColors[competitorAnalysisTimeSeries.competitor]?.chartColor,
        data: competitorAnalysisTimeSeries.timeSeries[chartType].map(
          (datapoint) => datapoint.count,
        ),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    return filteredData;
  };

  const generateCompaniesElements = useCallback(() => {
    const elements = [];

    const companies = mapCompanyDataForChart(true);

    companies.forEach((companyData, index) => {
      elements.push(
        <CompanyItem
          key={index}
          companyData={companyData}
          isVisible={visibleCompanies[companyData?.name]}
          setIsVisible={(companyName) =>
            handleCompanyVisibilityChange(companyName)
          }
          activeCompetitorName={activeCompetitorName}
          setActiveCompetitorName={setActiveCompetitorName}
        />,
      );
    });

    return elements;
  }, [data, visibleCompanies, chartType, companyColors, activeCompetitorName]);

  return (
    <Flex justify="space-between" gap={26} mb={16}>
      <Flex
        direction="column"
        gap={16}
        mt={13}
        className="companies__container"
      >
        <Group
          spacing={8}
          pl={8}
          onClick={() => handleAllCompaniesVisibilityChange()}
          sx={{
            cursor: 'pointer',
          }}
        >
          {!isAllItemsHidden ? (
            <>
              <HideIcon style={{ height: rem(22), width: rem(22) }} />
              <CustomText variant="body2">Hide all</CustomText>
            </>
          ) : (
            <>
              <ShowIcon style={{ height: rem(22), width: rem(22) }} />
              <CustomText variant="body2">Show all</CustomText>
            </>
          )}
        </Group>
        <Stack className={classes.companiesContainer}>
          {loading ? (
            <>
              <SkeletonContainer
                amount={12}
                width={251}
                height={40}
                radius={100}
              />
            </>
          ) : (
            <>{generateCompaniesElements()}</>
          )}
        </Stack>
      </Flex>
      <Flex direction="column" align="flex-end" sx={{ flex: 2 }}>
        <CustomSegmentControl
          value={chartType}
          onChange={setChartType}
          data={[
            {
              value: websiteContentVolume,
              label: <SegmentControlLabel title="Website Content Volume" />,
            },
            {
              value: linkedInEngagementCount,
              disabled: false,
              label: (
                <SegmentControlLabel title="LinkedIn Engagement Count" />
              ),
            },
          ]}
        />
        <Box className={classes.chartContainer}>
          {isAllItemsHidden && (
            <Box className={classes.noChartsBox}>
              <Center h="100%">
                <CustomText variant="h4" sx={{ textAlign: 'center' }}>
                  Select your competitors from the left and measures from above
                  to compare competitors on graph
                </CustomText>
              </Center>
            </Box>
          )}
          <Flex
            h={21}
            mb={8}
            justify={activeCompetitorName ? 'space-between' : 'flex-end'}
            align="center"
          >
            {activeCompetitorName && (
              <CustomText
                variant="h6"
                sx={{ fontWeight: 600, paddingLeft: rem(24) }}
              >
                Click anywhere on graph to see all
              </CustomText>
            )}
          </Flex>
          {data ? (
            <div className="chart-area">
              {chartType === websiteContentVolume && (
                <BarChart
                  labels={getLabelsForChart()}
                  companyData={mapCompanyDataForChart()}
                  categoryType="Website Content"
                  unit="Volume"
                  setActiveCompetitorName={setActiveCompetitorName}
                  redraw={chartRedraw}
                  setRedraw={setChartRedraw}
                />
              )}
              {chartType === linkedInEngagementCount && (
                <LineChart
                  labels={getLabelsForChart()}
                  companyData={mapCompanyDataForChart()}
                  categoryType="LinkedIn Engagement"
                  unit="Count"
                  setActiveCompetitorName={setActiveCompetitorName}
                />
              )}
              {chartType === publicMediaMentions && (
                <LineChart
                  labels={getLabelsForChart()}
                  companyData={mapCompanyDataForChart()}
                  categoryType="Public Media"
                  unit="Mentions"
                  setActiveCompetitorName={setActiveCompetitorName}
                />
              )}
            </div>
          ) : (
            <Center>
              <Loader />
            </Center>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}

const SegmentControlLabel = ({ title, locked }) => {
  const { classes, cx } = useStyle();

  return (
    <Group
      spacing={locked ? 8 : 0}
      className={cx(classes.segmentControlLabel, {
        [classes.segmentControlLabelLocked]: locked,
      })}
    >
      <Box className={`segment-control__${title.replace(' ', '_')}`}>
        {locked && <LockIcon style={{ height: 20, width: 20 }} />}
      </Box>
      <CustomText variant="h5">{title}</CustomText>
    </Group>
  );
};

export default CompetitorAnalysis;
