import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  progressRoot: {
    background: theme.colors.gray[3],
    borderRadius: rem(100)
  },
  imageContainer: {
    width: "100%",
    background: theme.colors.yellow[1],
    borderRadius: rem(32),
    padding: `${rem(32)} 0`
  },
  image: {
    height: rem(180),
    width: rem(218),
    objectFit: "contain"
  }
}));
