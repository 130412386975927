import { Box, Grid } from '@mantine/core';
import React from 'react';
import { useCallback } from 'react';
import { memo } from 'react';
import { useMemo } from 'react';
import { isArraysEqual } from '../../../../utils';
import VirtualScrollChild from '../../../VirtualScrollChild';
import TableColumn from '../TableColumn/TableColumn';
import { useStyle } from './TableRow.style';

function TableRow({
  index,
  row,
  hoverOptions,
  rowClassName,
  opened,
  tableData,
  maxNumbersLengthArray,
  withSelect,
  selection,
  toggleRow,
  handleClick,
  rootRef,
  infiniteScrollOptions,
  allSelected,
}) {
  const { classes, cx } = useStyle();
  const DropdownElement = useMemo(() => {
    return hoverOptions?.dropdownElement;
  }, []);

  const generateColumns = useCallback(
    (rowData, rowIndex, withExtraColumn) => {
      const elements = [];
      rowData.forEach((rowItem, index) => {
        let gridSpan = 12 / rowData.length;
        let span = null;
        let LinkIcon = null;
        let useRange = null;
        let useContent = null;
        if (tableData?.headOptions) {
          LinkIcon = tableData?.headOptions[index]?.LinkIcon;
          useRange = tableData?.headOptions[index]?.useRange;
          useContent = tableData?.headOptions[index]?.useContent;
          span = tableData.headOptions[index]?.columnSpan;
        }

        span = span || span === 0 ? span : gridSpan;
        if (span === 0) return;

        span = span || gridSpan;

        if (rowData.length - 1 === index && withExtraColumn) {
          span -= 1;
        }

        elements.push(
          <TableColumn
            key={index}
            index={index}
            rowIndex={rowIndex}
            rowItem={rowItem}
            span={span}
            useRange={useRange}
            useContent={useContent}
            icon={LinkIcon}
            withSelect={withSelect}
            checked={selection.includes(rowIndex) || allSelected}
            disabled={allSelected}
            toggleRow={toggleRow}
            maxNumbersLengthArray={maxNumbersLengthArray}
            tableData={tableData}
          />,
        );
      });

      return elements;
    },
    [tableData, selection, allSelected],
  );

  return (
    <VirtualScrollChild
      rootRef={rootRef?.current}
      disabled={infiniteScrollOptions?.disabled}
    >
      <Box
        key={index}
        className={cx(classes.rowContainer, rowClassName, {
          [classes.rowHover]: hoverOptions && !hoverOptions?.withoutHoverEffect,
        })}
        sx={{
          maxHeight: opened ? '1000vh' : null,
        }}
      >
        <Grid
          className={classes.row}
          onClick={(e) =>
            handleClick(
              e,
              index,
              row.filter((el) => Object.keys(el).includes('id'))[0]?.id,
            )
          }
          sx={{
            cursor: hoverOptions ? 'pointer' : '',
          }}
        >
          {generateColumns(row, index, hoverOptions)}
          {hoverOptions && (
            <Grid.Col
              span={1}
              p={0}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box
                className="hover-item"
                sx={{
                  '& div': {
                    transform:
                      hoverOptions.dropdownElement && opened
                        ? 'translateY(-50%) rotate(180deg)'
                        : null,
                  },
                }}
              >
                {hoverOptions.hoverElement}
              </Box>
            </Grid.Col>
          )}
        </Grid>
        {hoverOptions?.dropdownElement && opened && (
          <>
            {
              <DropdownElement
                id={row.filter((el) => Object.keys(el).includes('id'))[0]?.id}
                data={hoverOptions?.data}
                firstColumnData={row[0]}
              />
            }
          </>
        )}
      </Box>
    </VirtualScrollChild>
  );
}

export default memo(TableRow, (prev, next) => {
  //   re-render component only if selection or opened props have been changed
  //   or if row data has been changed
  if (
    (prev.selection.includes(prev.index) &&
      !next.selection.includes(next.index)) ||
    (!prev.selection.includes(prev.index) &&
      next.selection.includes(next.index)) ||
    (prev.opened && !next.opened) ||
    (!prev.opened && next.opened)
  ) {
    return false;
  } else if (JSON.stringify(prev.row) !== JSON.stringify(next.row)) {
    return false;
  } else if (
    !isArraysEqual(prev.maxNumbersLengthArray, next.maxNumbersLengthArray)
  ) {
    return false;
  }
  if (prev.allSelected !== next.allSelected) {
    return false;
  } else {
    return true;
  }
});
