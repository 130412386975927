import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useJwtToken from './useJwtToken';

const dummyData = new Array(8).fill({
  id: '646ef61f0f9bade6591659c0',
  title: 'Ford is the best',
  imageUrl: 'https://www.example.com/ford.jpg',
  url: 'https://www.example.com/ford',
  articleType: 'NEWS',
  publishDate: '2020-01-01T00:00:00.000Z',
  matches: 1,
  source: 'ford.com',
});

const useTabsWithContent = (
  tabs,
  articleTypes,
  activeTab,
  fetchFunc,
  optionsList = [],
  extraParams,
) => {
  const { loading: jwtTokenLoading } = useJwtToken();
  const [activeIndex, setActiveIndex] = useState(0);
  const [offset, setOffset] = useState(new Array(articleTypes.length).fill(0));
  const [loading, setLoading] = useState(
    new Array(articleTypes.length).fill(true),
  );
  const [contents, setContents] = useState(
    new Array(articleTypes.length).fill({ content: [] }),
  );

  const fetched = useRef(new Array(articleTypes.length).fill(false));

  const getBlurredElement = useCallback(
    (index) => {
      if (!contents[index]?.totalItems) {
        return optionsList[index]?.element;
      }
      return null;
    },
    [optionsList, contents],
  );

  const getHideMatches = useCallback(
    (index) => {
      return optionsList[index]?.hideMatches;
    },
    [optionsList],
  );

  const updateState = (value, index, func) => {
    func((prevState) => {
      const newState = [...prevState];
      newState[index] = value;

      return newState;
    });
  };

  const fetchContent = async (index, newOffset, replace) => {
    setActiveIndex(index);
    const content = await fetchFunc({
      offset: newOffset[index],
      setLoading: (value) => updateState(value, index, setLoading),
      articleType: articleTypes[index],
    });

    if (replace) {
      updateState(content, index, setContents);
    } else {
      updateState(
        {
          ...content,
          content: contents[index].content.concat(content?.content || []),
        },
        index,
        setContents,
      );
    }
  };

  const loadMore = async (index) => {
    const newOffset = [...offset];
    newOffset[index] = newOffset[index] + 8;
    await fetchContent(index, newOffset);
    setOffset(newOffset);
  };

  const updateOffsetExternal = useCallback(
    (value) => {
      updateState(value, activeIndex, setOffset);
    },
    [offset, activeIndex],
  );

  useEffect(() => {
    if (extraParams !== undefined && !Object.keys(extraParams || {}).length)
      return;

    if (!jwtTokenLoading && loading[activeTab] && !fetched.current[activeTab]) {
      updateState(true, activeTab, setLoading);
      fetchContent(activeTab, offset);
      fetched.current = fetched.current.map((el, index) =>
        index === activeTab ? true : el,
      );
    }
  }, [activeTab, jwtTokenLoading, loading]);

  useEffect(() => {
    if (!Object.keys(extraParams || {}).length) return;

    updateState(true, activeTab, setLoading);
    fetchContent(activeTab, offset, true);
  }, [JSON.stringify(extraParams)]);

  useEffect(() => {
    setActiveIndex(activeTab)
  }, [activeTab])

  const contentList = useMemo(() => {
    const data = [];
    for (let i = 0; i < articleTypes.length; i++) {
      data.push({
        tabValue: tabs[i],
        content: !getBlurredElement(i) ? contents[i]?.content || [] : dummyData,
        contentAmount: offset[i] + 8,
        totalItems: contents[i]?.totalItems,
        loading: loading[i],
        setContentAmount: () => loadMore(i),
        blurredComponent: !loading[i] && getBlurredElement(i),
        hideMatches: getHideMatches(i),
      });
    }

    return data;
  }, [contents, offset, loading]);

  return [contentList, updateOffsetExternal, contents[activeIndex]];
};

export default useTabsWithContent;
