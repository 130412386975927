export const AUDIENCE_CHART_OPTIONS = ['HIGH', 'MEDIUM', 'LOW', 'NONE'];
export const DEFAULT_COLORS = [
  'rgba(98, 177, 142, 1)',
  'rgba(240, 143, 88, 1)',
  'rgba(246, 194, 93, 1)',
  'rgba(240, 88, 88, 1)',
];
export const DEFAULT_COLORS_REVERSED = [
  'rgba(240, 88, 88, 1)',
  'rgba(246, 194, 93, 1)',
  'rgba(240, 143, 88, 1)',
  'rgba(98, 177, 142, 1)',
];
