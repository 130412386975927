import useTable from '../../../hooks/useTable';
import { useMemo } from 'react';

const useTopicsToWriteAboutTable = (topicsToWriteAbout) => {
  const { getTableData } = useTable();

  const tableData = useMemo(() => {
    const data = (topicsToWriteAbout || []).map(el => ({
      topic: el?.value,
      id: el?.id,
      contacts: el?.contactIncidence,
      topicIncidence: el?.topicIncidence
    }))

    return getTableData(
      data,
      ['Topic', '_id', 'Contacts', 'Topic Incidence'],
      [
        {
          withoutSorting: true,
          columnSpan: 4,
        },
        {
          columnSpan: 0,
        },
        {
          withoutSorting: true,
          columnSpan: 4,
        },
        {
          withoutSorting: true,
          columnSpan: 4,
        },
      ],
      true,
    );
  }, [topicsToWriteAbout, getTableData]);

  return tableData;
};

export default useTopicsToWriteAboutTable;
