import { Stack } from "@mantine/core"
import CustomText from "../../components/Typography/CustomText/CustomText"
import { setGuide } from "../profileSlice"

export const getTopicDetailsSteps = (dispatch) => ([
    {
        content: <>
            <Stack spacing={8}>
                <CustomText variant="h6" sx={{fontWeight: 600, lineHeight: "120%"}}>
                    Get to know your Topic’s performance with stats on interested, engagement and coverage.
                </CustomText>
                <CustomText variant="h6" sx={{fontWeight: 600, lineHeight: "120%"}}>
                    We’ll show you how to set up our AI personalization tags in step 2, so you can boost these scores!
                </CustomText>
            </Stack>
        </>,
        target: ".tour-first",
        placement: "bottom",
        disableBeacon: true,
        offset: -12,
        disabledPrevButton: true,
    },
    {
        content: `
            See your own content and Organic search, 
            news and competitor content related to this topic.
        `,
        target: ".tab-Organic_search",
        placement: "top-start",
        offset: 0,
    },
    {
        content: `
            Get insights on other audience interests and 
            popular searched questions  related to this topic.
        `,
        target: ".other-audience",
        placement: "top-end",
        offset: -15,
    },
    {
        content: <>
            You can also export this data report as a full
            <br />
            report or as a Linked In custom audience file.
        </>,
        target: ".export-contacts__button",
        placement: "left-start",
        offset: 0,
    },
    {
        content: `
            Click here to go back to the topics dashboard
        `,
        target: ".back-navigation",
        placement: "right",
        offset: 0,
        withoutNavigation: true,
        onClick: () => dispatch(setGuide({
            key: 'topicDetails',
            value: {
                showGuide: false
            }
        }))
    }
])