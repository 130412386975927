import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  radioOuter: {
    width: rem(19),
    height: rem(19),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.colors.black[1]}`,
    borderRadius: '50%',
  },
  radioInner: {
    width: 0,
    height: 0,
    background: theme.colors.black[1],
    borderRadius: '50%',
    transition: '0.2s',
  },
  radioInnerSelected: {
    width: rem(12),
    height: rem(12),
  },
}));
