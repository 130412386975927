import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  questionIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

        "& svg": {
            height: rem(17),
            width: "fit-content",
        },
        "& path": {
            stroke: theme.colors.gray[4]
        }
    },
    active: {
        background: theme.colors.yellow[1],
        padding: rem(5),
        borderRadius: "50%",
        boxShadow: "0px 4px 12px 0px rgba(54, 66, 71, 0.21)",

        "& path": {
            stroke: "#000 !important"
        }
    }
}))
