import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import './Buttons.css';

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    const redirectUrl =
      localStorage.getItem('skipPreviewPage') === 'true' ? '/' : '/quick-start';

    await loginWithRedirect({
      appState: {
        returnTo: redirectUrl, // TODO: Change this to the page you want to redirect to after login
      },
    });
  };

  return (
    <button className="button__login button__spacing" onClick={handleLogin}>
      Log In
    </button>
  );
};
