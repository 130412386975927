import { useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Center, Flex, Pagination, Stack } from '@mantine/core';
import CompetitorContentCard from '../../../../components/CompetitorContentCard/CompetitorContentCard';
import { useStyle } from './CompetitorContent.style';
import CustomText from '../../../../components/Typography/CustomText/CustomText';
import useNavigateWithState from '../../../../hooks/useNavigateWithState';

function CompetitorContent({ content = [], totalItems, offset, setOffset }) {
  const [activePage, setActivePage] = useState(1);
  const { classes } = useStyle();
  const { search } = useLocation();
  const navigate = useNavigate(); // Move this line inside the component
  const navigateWithState = useNavigateWithState();

  const generateContentCards = useCallback(() => {
    if (!content.length) return;

    const elements = [];

    content.forEach((item, index) => {
      elements.push(
        <CompetitorContentCard
          key={index}
          image={item.imageUrl}
          title={item.title}
          publishDate={item.publishDate}
          source={item.source}
          onClick={() => navigateWithState(`/competitors/content/${item.id}`)}
          customClassName={`card-${index}`}
        />,
      );
    });

    return elements;
  }, [content, navigate, search]);

  const updateOffset = (currentActivePage) => {
    setOffset({
      offset: (currentActivePage - 1) * 12,
    });
  };

  const onActivePageChangeWithCallback = useCallback(
    (callback) => {
      updateOffset(callback(activePage));
      setActivePage((prevState) => callback(prevState));
    },
    [activePage],
  );

  const onActivePageChange = useCallback(
    (value) => {
      updateOffset(value);
      setActivePage(value);
    },
    [activePage],
  );

  useEffect(() => {
    setActivePage(Math.max(offset / 12 + 1, 1));
  }, [offset]);

  return (
    <Stack>
      <Flex className={classes.cardsContainer}>{generateContentCards()}</Flex>
      <Flex justify="flex-end">
        <Pagination.Root
          total={Math.ceil(totalItems / 12)}
          value={offset / 12 + 1}
          onChange={onActivePageChange}
          boundaries={1}
          classNames={{ control: classes.paginationControl }}
        >
          <Center
            pr={4}
            onClick={() =>
              onActivePageChangeWithCallback((prevState) =>
                Math.max(prevState - 1, 1),
              )
            }
            sx={(theme) => ({
              cursor: 'pointer',
              userSelect: 'none',
              color: activePage === 1 && theme.colors.gray[4],
            })}
          >
            <CustomText variant="body2">Prev</CustomText>
          </Center>
          <Pagination.Items />
          <Center
            pl={4}
            pr={8}
            onClick={() =>
              onActivePageChangeWithCallback((prevState) =>
                Math.min(prevState + 1, Math.ceil(totalItems / 12)),
              )
            }
            sx={(theme) => ({
              cursor: 'pointer',
              userSelect: 'none',
              color:
                activePage === Math.ceil(totalItems / 12) &&
                theme.colors.gray[4],
            })}
          >
            <CustomText variant="body2">Next</CustomText>
          </Center>
        </Pagination.Root>
      </Flex>
    </Stack>
  );
}

export default CompetitorContent;
