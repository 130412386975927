import { Box, Flex, rem, Stack, TextInput } from '@mantine/core';
import React, { useCallback, useRef, useState } from 'react';
import { useMemo } from 'react';
import ContentWithImage from '../../ContentWithImage/ContentWithImage';
import Heading from '../../Heading';
import CustomText from '../../Typography/CustomText/CustomText';
import AddMoreButton from '../components/AddMoreButton/AddMoreButton';
import ButtonGroup from '../components/ButtonGroup/ButtonGroup';
import Stepper from '../components/Stepper/Stepper';
import { useStyle } from './SelectTopics.style';
import TopicItem from './TopicItem';

const DEFAULT_TOPICS = [
  'Music',
  'The Brits',
  'UK artists',
  "The Grammy's",
  'Beyoncé',
  'Live band',
  'UK charts',
  'Performance',
  'Vinyl',
];

function SelectTopics({ onContinue }) {
  const [topics, setTopics] = useState(DEFAULT_TOPICS);
  const [selectedTopicTitles, setSelectedTopicTitles] = useState([]);
  const [addButtonOpened, setAddButtonOpened] = useState(false);
  const inputRef = useRef(null)
  const { classes, cx } = useStyle();

  const handleSelection = useCallback(
    (topic) => {
      const topicIndex = selectedTopicTitles.findIndex((el) => el === topic);

      if (topicIndex === -1) {
        const newState = [...selectedTopicTitles, topic]
        if(newState.length > 3) {
            newState.splice(0, 1)
        }
        setSelectedTopicTitles(newState);
      } else {
        setSelectedTopicTitles((prevState) =>
          prevState.filter((el) => el !== topic),
        );
      }
    },
    [selectedTopicTitles, topics],
  );

  const handleDeletion = useCallback(
    (topic) => {
      setTopics((prevState) => prevState.filter((el) => el !== topic));
    },
    [],
  );

  const handleAdding = useCallback(() => {
    setAddButtonOpened(prevState => !prevState)

    if(inputRef.current.value) {
        setTopics(prevState => [...prevState, inputRef.current.value])
    }
  }, [addButtonOpened, inputRef, topics])

  const topicList = useMemo(() => {
    return topics.map((topic, index) => (
      <TopicItem
        key={`topic-${topic}-${index}`}
        title={topic}
        selected={selectedTopicTitles.includes(topic)}
        onSelect={handleSelection}
        onDelete={handleDeletion}
      />
    ));
  }, [topics, selectedTopicTitles]);

  return (
    <ContentWithImage
      content={
        <Stack mt={rem(88)} w={rem(500)} spacing={40}>
          <Stepper activeIndex={2} stepsAmount={3} />
          <Heading
            spacing={24}
            small
            align="left"
            title="Last step! Track your key topics"
            description={
              <>
                We track thousands of news outlets to understand what's
                happening *right now* related to the things you and your
                customers care about most. That news informs what you might want
                to create content around and can even be syndicated back to your
                own audience.
                <br />
                <br />
                Let’s add some key topics that you and your customers care about
                so that we can monitor the news for you.
              </>
            }
          />
        </Stack>
      }
      image={
        <Stack w={rem(496)} spacing={rem(165)} mb={rem(83)} mr={rem(104)}>
          <Stack>
            <Stack spacing={8}>
              <CustomText
                variant="h2"
                sx={(theme) => ({
                  fontSize: rem(24),
                  color: theme.colors.white[2],
                })}
              >
                Key topics
              </CustomText>
              <CustomText
                variant="bodySmall"
                sx={(theme) => ({ color: theme.colors.white[2] })}
              >
                We’ve added topics below that you write about the most. Are they
                the most important topics to your customers?
                <br />
                Please select 3 that you’d like us to track
              </CustomText>
            </Stack>
            <Stack spacing={10}>
              <Flex gap={10} sx={{ flexWrap: 'wrap' }}>
                {topicList}
              </Flex>
              <Box
                className={cx(classes.buttonContainer, {
                  [classes.inputContainer]: addButtonOpened,
                })}
              >
                {addButtonOpened && (
                  <TextInput
                    classNames={{
                      input: classes.input,
                      root: classes.inputRoot,
                    }}
                    placeholder="Enter topic"
                    ref={inputRef}
                  />
                )}
                <AddMoreButton
                  text="Add"
                  onClick={handleAdding}
                  sx={{minWidth: rem(92)}}
                />
              </Box>
            </Stack>
          </Stack>
          <ButtonGroup
            mainText="Save my topics"
            secondaryText="I'll do it later"
            width={[162, 237]}
            color="white"
            onMainButtonClick={onContinue}
            onSecondaryButtonClick={onContinue}
          />
        </Stack>
      }
    />
  );
}

export default SelectTopics;
