import React, { useMemo, useCallback, useEffect, useState } from 'react';
import {
  Flex,
  Group,
  Menu,
  rem,
  UnstyledButton,
} from '@mantine/core';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevronDown-icon.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/icons/checked-icon.svg';
import CustomText from '../../Typography/CustomText/CustomText';
import { useStyle } from './TimePeriodSelection.style';

const PERIOD_DAYS = {
  lastSevenDays: {
    key: 'lastSevenDays',
    title: 'Last 7 days',
    value: { start: 7 },
  },
  lastThirtyDays: {
    key: 'lastThirtyDays',
    title: 'Last 30 days',
    value: { start: 30 },
  },
  thisQuarter: {
    key: 'thisQuarter',
    title: 'Last 90 days',
    value: { start: 90 },
  },
  thisYear: { key: 'thisYear', title: 'Last 365 days', value: { start: 365 } },
};

function TimePeriodSelection({
  updateDateFilter,
  onDropdownChange,
  initialFetchPeriodKey,
}) {
  const [fetchPeriod, setFetchPeriod] = useState({});
  const [fetchPeriodDropdownOpen, setFetchPeriodDropdown] = useState(false);
  const [customFetchPeriod, setCustomFetchPeriod] = useState([null, null]);
  // eslint-disable-next-line no-unused-vars
  const [customDateButtonDisabled, setCustomDateButtonDisabled] =
    useState(false);

  const [searchParams] = useSearchParams();
  const { classes } = useStyle();

  const menuOptions = useMemo(
    () => [
      { title: PERIOD_DAYS.lastSevenDays.title },
      { title: PERIOD_DAYS.lastThirtyDays.title },
      { title: PERIOD_DAYS.thisQuarter.title },
      { title: PERIOD_DAYS.thisYear.title },
    ],
    [],
  );

  useEffect(() => {
    if (!fetchPeriod?.title && initialFetchPeriodKey) {
      if (initialFetchPeriodKey === 'customPeriod') {
        setFetchPeriod({ title: 'Custom period' });
        setCustomFetchPeriod([
          searchParams.get('from'),
          searchParams.get('to'),
        ]);
      } else {
        const newFetchPeriod = Object.values(PERIOD_DAYS).filter(
          (el) => el.key === initialFetchPeriodKey,
        );
        setFetchPeriod(newFetchPeriod[0]);
      }
      return;
    }

    if (customFetchPeriod[0]) {
      const [start, end] = customFetchPeriod;
      const startDay = dayjs(start);
      const endDay = dayjs(end);
      const currentDate = dayjs();
      setCustomDateButtonDisabled(
        startDay.isAfter(currentDate) ||
          endDay.isAfter(currentDate) ||
          Math.abs(endDay.diff(startDay, 'day')) > 90,
      );
    }

    if (fetchPeriod.title && fetchPeriod.title !== 'Custom period') {
      // skip re-render if new date is the same as previous one
      if (searchParams.get('dateKey') === fetchPeriod.key) return;

      const { start, end } = fetchPeriod.value;
      const from = dayjs().subtract(start, 'day').format('YYYY-MM-DD');
      const to = end
        ? dayjs().subtract(end, 'day').format('YYYY-MM-DD')
        : undefined;

      updateDateFilter({ from, to, dateKey: fetchPeriod.key });
    }
  }, [fetchPeriod, customFetchPeriod, initialFetchPeriodKey]);

  // eslint-disable-next-line no-unused-vars
  const onCustomPeriodSelect = useCallback(() => {
    // Handle the case where a custom period is selected.
    const [start, end] = customFetchPeriod;
    const startDay = dayjs(start);
    const endDay = dayjs(end);
    const currentDate = dayjs();

    startDay.subtract(currentDate.diff(startDay, 'days'), 'day');
    startDay.subtract(currentDate.diff(startDay, 'months'), 'month');
    startDay.subtract(currentDate.diff(startDay, 'years'), 'year');

    endDay.subtract(currentDate.diff(endDay, 'days'), 'day');
    endDay.subtract(currentDate.diff(endDay, 'months'), 'month');
    endDay.subtract(currentDate.diff(endDay, 'years'), 'year');

    // skip re-render if new date is the same as previous one
    if (
      startDay.isSame(dayjs(searchParams.get('from', 'YYYY-MM-DD'))) &&
      endDay.isSame(dayjs(searchParams.get('to', 'YYYY-MM-DD')))
    )
      return;

    updateDateFilter({
      from: startDay.format('YYYY-MM-DD'),
      to: end ? endDay.format('YYYY-MM-DD') : currentDate.format('YYYY-MM-DD'),
      dateKey: 'customPeriod',
    });
  }, [customFetchPeriod]);

  const generateMenuElements = useCallback(() => {
    if (!fetchPeriod?.title) return;

    const elements = [];

    menuOptions.forEach((option, index) => {
      const handleClick = () => {
        setFetchPeriod(
          Object.values(PERIOD_DAYS).filter(
            (period) => period.title === option.title,
          )[0],
        );
        setFetchPeriodDropdown(false);

        if (option.onClick) {
          option.onClick();
        }
      };

      elements.push(
        <Menu.Item key={index} onClick={handleClick}>
          <Flex
            align="center"
            justify="space-between"
            className={`menu__option-${index}`}
          >
            <CustomText
              variant="h5"
              color={option.title === fetchPeriod.title ? '#0F0E24' : '#8C8B95'}
            >
              {option.title}
            </CustomText>
            {option.title === fetchPeriod.title && <CheckedIcon />}
          </Flex>
        </Menu.Item>,
      );
    });

    return elements;
  }, [fetchPeriod]);

  return (
    <Flex justify="flex-end" mb={16} gap={8}>
      <CustomText variant="body2">See results for:</CustomText>
      <Menu
        position="bottom-end"
        opened={fetchPeriodDropdownOpen}
        classNames={{ dropdown: classes.menuRoot }}
        closeOnItemClick={false}
        onChange={(state) => {
          setFetchPeriodDropdown(state);
          if (onDropdownChange) {
            onDropdownChange();
          }
        }}
      >
        <Menu.Target>
          <UnstyledButton>
            <Group spacing={8} className="date__selection">
              <CustomText variant="body2">
                {fetchPeriod?.title || 'Loading...'}
              </CustomText>
              <ChevronDown
                style={{
                  width: rem(14),
                  height: rem(8),
                }}
              />
            </Group>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          {generateMenuElements()}
          {/* uncomment if there is a need for custom date selection */}
          {/* <Menu.Item>
            <CustomDatePicker
              fetchPeriod={fetchPeriod}
              setFetchPeriod={setFetchPeriod}
              customFetchPeriod={customFetchPeriod}
              setCustomFetchPeriod={setCustomFetchPeriod}
              customDateButtonDisabled={customDateButtonDisabled}
              setFetchPeriodDropdown={setFetchPeriodDropdown}
              onCustomPeriodSelect={onCustomPeriodSelect}
            />
          </Menu.Item> */}
        </Menu.Dropdown>
      </Menu>
    </Flex>
  );
}

export default TimePeriodSelection;
