import ImageContent from '../../components/accountCreation/components/ImageContent/ImageContent';
import CustomSegmentControl from '../../components/SegmentControl/SegmentControl';
import { useStyle } from './PreviewPage.style';
import { useState, useEffect, useMemo } from 'react';
import { Box, Group, rem, Stack } from '@mantine/core';
import ImageContentWebsite from '../../components/accountCreation/components/ImageContent/ImageContentWebsite';
import CustomSelectionMenu from '../../components/CustomDropdown/CustomSelectionMenu/CustomSelectionMenu';
import useRecommendedContacts from './hooks/useRecommendedContacts';
import useJwtToken from '../../hooks/useJwtToken';
import { capitalizeFirstLetter, getCompanyName, getContactName } from './utils';
import useRecommendations from './hooks/useRecommendation';
import CustomTitle from '../../components/Typography/CustomTitle/CustomTitle';
import CustomText from '../../components/Typography/CustomText/CustomText';
import CustomCheckbox from '../../components/Checkbox';
import { useAuth0 } from '@auth0/auth0-react';

const SEGEMENT_CONTROL_OPTIONS = ['EMAIL', 'WEBSITE'];

function PreviewPage() {
  const [segmentControlOption, setSegmentControlOption] = useState(
    SEGEMENT_CONTROL_OPTIONS[0],
  );
  const { user } = useAuth0();
  const [skipPage, setSkipPage] = useState(false);

  const { loading } = useJwtToken();
  const {
    contacts,
    selectedContact,
    setSelectedContact,
  } = useRecommendedContacts(loading);

  const { slicedArticles, selectedTopic } = useRecommendations(
    loading,
    selectedContact?.id,
  );

  const { classes } = useStyle();

  const modifyUrl = (originalUrl) => {
    if (!originalUrl || !selectedContact?.id) return originalUrl;
    const url = new URL(originalUrl);
    url.searchParams.append('ignore', true);
    return url.toString();
  };

  // Update the URLs in the slicedArticles whenever dependencies change
  const updatedArticles = useMemo(() => {
    return slicedArticles.map((article) => {
      const updatedUrl = modifyUrl(article.url);
      return {
        ...article,
        url: updatedUrl,
      };
    });
  }, [slicedArticles, selectedContact]);

  useEffect(() => {
    localStorage.setItem('skipPreviewPage', skipPage);
  }, [skipPage]);

  return (
    <div>
      <Stack spacing={32} w="100%">
        {/* Header and Instructions */}
        <Stack maw={rem(890)}>
          <CustomTitle order={0}>
            It’s Time for your Pre-Flight Check, {capitalizeFirstLetter(user?.nickname)}
          </CustomTitle>
          <CustomText variant="h4">
            Below you can preview an example email and landing page template for
            a few of your HubSpot contacts. Be sure to hit the “Select contact”
            dropdown to preview these for a few of your contacts and hit the
            toggle below the preview to see how this can work for you across
            emails and the web.
          </CustomText>
        </Stack>

        {/* Contact Selection */}
        <Group>
          <CustomText variant="h4">Select contact:</CustomText>
          <CustomSelectionMenu
            title={getContactName(selectedContact) || 'Contacts'}
            selectedItemTitles={getContactName(selectedContact)}
            onItemSelect={setSelectedContact}
            menuOptions={(contacts?.content || []).map((el) => ({
              title: getContactName(el),
              ...(el || {}),
            }))}
            withoutAnimation
            wihtoutCheckboxes
          />
        </Group>

        {/* Image Content and Controls */}
        <Stack maw={rem(900)} w="100%">
          <Box>
            {segmentControlOption === SEGEMENT_CONTROL_OPTIONS[0] ? (
              <ImageContent
                title={selectedTopic}
                company={getCompanyName(user?.email)}
                name={getContactName(selectedContact)}
                // TODO: marketing@[current company email] could probably grok this from account domain
                userEmail={user?.email}
                contactEmail={selectedContact?.email}
                cards={updatedArticles}
              />
            ) : (
              <ImageContentWebsite
                title={selectedTopic}
                cards={updatedArticles}
              />
            )}
          </Box>

          {/* Segment Control and "Don't Show Again" Checkbox */}
          <Group position="apart" align="center" w="100%">
            {/* Segment Control */}
            <CustomSegmentControl
              indicatorClassName={classes.segmentIndicator}
              controlActiveClassName={classes.segmentControlActive}
              rootClassName={classes.segmentRoot}
              value={segmentControlOption}
              onChange={setSegmentControlOption}
              data={[
                { value: SEGEMENT_CONTROL_OPTIONS[0], label: 'Email' },
                { value: SEGEMENT_CONTROL_OPTIONS[1], label: 'Website' },
              ]}
            />

            {/* "Don't Show Again" Checkbox */}
            <Group
              sx={{ cursor: 'pointer' }}
              onClick={() => setSkipPage((prevState) => !prevState)}
            >
              <CustomCheckbox checked={skipPage} />
              <CustomText variant="h5">
                Don’t show on <span className={classes.bold}>Log In</span>
              </CustomText>
            </Group>
          </Group>
        </Stack>
      </Stack>
    </div>
  );
}

export default PreviewPage;
