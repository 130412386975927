import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  inputIcon: {
    paddingLeft: rem(20),
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    borderColor: theme.colors.gray[3],
    borderRadius: 100,
    padding: `${rem(8)} ${rem(24)} ${rem(8)} calc(2.25rem + ${rem(8)}) !important`,
    minHeight: rem(40),
  },
  inputRoot: {
    display: 'flex',
    flexGrow: '2 !important',

    '& .mantine-Input-wrapper': {
      width: '100%',
    },
  },
  selectWrapper: {
    flex: 1,

    '& .mantine-MultiSelect-rightSection': {
      display: 'none',
    },

    '& .mantine-MultiSelect-values': {
      rowGap: 8,
      minHeight: 'fit-content',
    },
  },
  selectValue: {
    backgroundColor: theme.colors.yellow[0],
  },
}));
