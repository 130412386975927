import React, { useMemo } from 'react';
import { Box, Group } from '@mantine/core';
import CustomText from '../../../Typography/CustomText/CustomText';
import { useStyle } from './Stepper.style';
import { ReactComponent as TickIcon } from '../../../../assets/icons/step-tick-icon.svg';

function Stepper({ stepsAmount, activeIndex }) {
  const { classes, cx } = useStyle();

  const steps = useMemo(() => {
    return [...Array(stepsAmount).keys()].map((el, index) => (
      <>
        <Box
          key={`stepper-${index}`}
          className={cx(classes.stepItem, {
            [classes.stepItemActive]: activeIndex === index,
            [classes.passedStep]: index < activeIndex
          })}
        >
          {index < activeIndex && (
            <TickIcon />
          )}
          {activeIndex === index && (
            <CustomText variant="h6" sx={theme => ({color: theme.colors.white[2]})}>STEP {index + 1}</CustomText>
          )}
        </Box>
        {index !== stepsAmount - 1 && <Box className={classes.stepLine} />}
      </>
    ));
  }, [activeIndex]);

  return <Group spacing={4}>{steps}</Group>;
}

export default Stepper;
