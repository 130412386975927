import React, { useMemo } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Box, Stack, rem } from '@mantine/core';
import MainButton from '../buttons/buttons';
import CustomText from '../Typography/CustomText/CustomText';
import { ReactComponent as ArrangeSquareIcon } from '../../assets/icons/arrange-square-icon.svg';
import { settings } from '../../config';

import logger from '../../utils/logger';

const StripeCheckoutForm = ({
  metadata = {},
  callback,
  clientSecret,
  setLoading,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (setLoading) {
      setLoading(true);
    }

    elements.submit();
    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      metadata,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    });

    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      logger.error(error.message);
      if (setLoading) {
        setLoading(true);
      }
      return;
    } else if (error) {
      logger.error('An unexpected error occurred.');
      if (setLoading) {
        setLoading(true);
      }
      return;
    }

    if (callback) {
      callback();
    }
  };

  const currentEnviroment = useMemo(() => {
    if (settings.environment === 'production') {
      return ' airtrafficcontrol';
    }
    return ' airtrafficcontrol-dev';
  }, []);

  const paymentElementOptions = {
    layout: 'tabs',
    terms: {
      card: 'never',
    },
  };

  return (
    <Stack spacing={42} w="100%">
      <Box>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {stripe && elements && (
          <Box mt={rem(16)}>
            <CustomText
              variant="body2"
              sx={{
                fontSize: rem(12),
                fontWeight: 400,
                color: '#6d6e78',
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI" !important',

                a: {
                  color: '#0570de !important',
                },
              }}
            >
              By providing your card information, you allow
              {currentEnviroment} to charge your card for future payments in
              accordance with their{' '}
              <a
                href="http://www.airtrafficcontrol.io/terms-of-use"
                target="__blank"
              >
                terms
              </a>
              .
            </CustomText>
          </Box>
        )}
      </Box>
      <MainButton
        onClick={handleSubmit}
        icon={<ArrangeSquareIcon />}
        sx={{ height: rem(48) }}
      >
        <CustomText variant="body1">Confirm</CustomText>
      </MainButton>
    </Stack>
  );
};

export default StripeCheckoutForm;
