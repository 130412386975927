import {
  Box,
  Flex,
  Group,
  rem,
  Stack,
  UnstyledButton,
} from '@mantine/core';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import CustomCheckbox from '../../../../components/Checkbox';
import SkeletonContainer from '../../../../components/SkeletonContainer';
import CustomText from '../../../../components/Typography/CustomText/CustomText';
import { useStyle } from './InfoList.style';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link-icon.svg';
import { getCountryAdjustedDate } from '../../../../utils';
import useRadarApi from '../../../../hooks/useRadarApi';
import { useState } from 'react';
import BasicModal from '../../../../components/Modals/BasicModal';
import MainButton from '../../../../components/buttons/buttons';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { setSnackbar } from '../../../../features/layoutSlice';
import { useDispatch } from 'react-redux';

function InfoList({ contentDetails, loading }) {
  const [opened, setOpened] = useState(false);
  const [active, setActive] = useState(false);
  const dispatch = useDispatch()
  const { classes } = useStyle();
  const { patchContentDetails } = useRadarApi();

  const source = useMemo(() => {
    if (!contentDetails?.url) return;
    return new URL(contentDetails.url).hostname.replace('www.', '');
  }, [contentDetails]);

  const handleActiveSwitching = useCallback(() => {
    patchContentDetails(contentDetails?.id, { active: !active })
      .then(() => {
        setActive(prevState => !prevState)
        dispatch(
          setSnackbar({
            variant: 'success',
            description: 'Your changes have been successfully updated',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setSnackbar({
            variant: 'error',
            description: 'Something went wrong...',
          }),
        );
      })
      .finally(() => {
        setOpened(false);
      });
  }, [patchContentDetails, active, contentDetails]);

  useEffect(() => {
    if (!contentDetails) return;
    setActive(contentDetails?.active);
  }, [contentDetails]);

  return (
    <Box className={classes.statsContainer}>
      <Flex gap={16}>
        {loading ? (
          <Box miw={265}>
            <SkeletonContainer width={265} height={265} radius={12} />
          </Box>
        ) : (
          <img src={contentDetails?.imageUrl} className={classes.image} />
        )}
        <Stack spacing={18} pt={24} w="100%" maw={rem(176)}>
          {loading ? (
            <SkeletonContainer width={150} height={20} amount={5} />
          ) : (
            <>
              <CustomText
                variant="h5"
                lineClamp={2}
                sx={{ wordBreak: 'break-all' }}
              >
                {source}
              </CustomText>
              <Flex justify="space-between">
                <CustomText variant="body2">Active</CustomText>
                <Box className="active__checkbox">
                  <UnstyledButton onClick={() => setOpened(true)}>
                    <CustomCheckbox
                      blackBorder
                      grayBackground
                      checked={active}
                    />
                  </UnstyledButton>
                </Box>
              </Flex>
              <Flex justify="space-between">
                <CustomText variant="body2">Matches</CustomText>
                <CustomText
                  variant="body2"
                  sx={(theme) => ({ color: theme.colors.gray[4] })}
                >
                  {contentDetails?.matches}
                </CustomText>
              </Flex>
              <Flex justify="space-between">
                <CustomText variant="body2">Published</CustomText>
                <CustomText
                  variant="body2"
                  sx={(theme) => ({ color: theme.colors.gray[4] })}
                >
                  {getCountryAdjustedDate(contentDetails?.publishDate)}
                </CustomText>
              </Flex>
              <Flex justify="space-between">
                <CustomText variant="body2">Link</CustomText>
                <Link to={contentDetails?.url} target="_blank">
                  <LinkIcon />
                </Link>
              </Flex>
            </>
          )}
        </Stack>
      </Flex>
      <BasicModal
        withCloseButton={false}
        opened={opened}
        onClose={() => setOpened(false)}
        contentClassName={classes.modalContent}
      >
        <Stack w={rem(455)} spacing={24}>
          <CustomText variant="h3" sx={{ textAlign: 'center', fontWeight: "500" }}>
            Are you sure you want to make this content <CustomText variant="h3">{active ? "Inactive" : "Active"}</CustomText>
          </CustomText>
          <Flex className={classes.buttonsContainer}>
            <Group>
              <MainButton onClick={handleActiveSwitching}>Continue</MainButton>
              <UnstyledButton onClick={() => setOpened(false)}>
                Cancel
              </UnstyledButton>
            </Group>
          </Flex>
        </Stack>
      </BasicModal>
    </Box>
  );
}

export default InfoList;
