import { useCallback, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';

const useFetchIncidenceAndEngagement = (
  jwtTokenLoading,
  activeRelevance,
  selectedListsId,
  urlParams,
) => {
  const { getIncidenceAndEngagement } = useRadarApi();
  const [incidenceAndEngagement, setIncidenceAndEngagement] = useState([]);
  const [fetchIncidenceAndEngagement, loading] = useFetch(
    getIncidenceAndEngagement,
  );

  const fetchWrapper = useCallback(async () => {
    const data = await fetchIncidenceAndEngagement(
      selectedListsId
        ? {
            'filter[listId]': selectedListsId,
          }
        : {},
    );

    setIncidenceAndEngagement(data);
  }, [
    fetchIncidenceAndEngagement,
    getIncidenceAndEngagement,
    activeRelevance,
    selectedListsId,
  ]);

  useEffect(() => {
    if (
      !jwtTokenLoading &&
      urlParams &&
      !selectedListsId &&
      selectedListsId !== ''
    ) {
      fetchWrapper();
    }
  }, [jwtTokenLoading, selectedListsId]);

  useEffect(() => {
    if (!jwtTokenLoading && urlParams && selectedListsId) {
      fetchWrapper();
    }
  }, [jwtTokenLoading, selectedListsId]);

  return [incidenceAndEngagement, loading];
};

export default useFetchIncidenceAndEngagement;
