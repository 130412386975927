import React, { useCallback } from 'react';
import { UnstyledButton, Flex, rem } from '@mantine/core';
import CustomText from '../../../Typography/CustomText/CustomText';
import { ReactComponent as LogoIcon } from '../../../../assets/icons/sort-chevron-icon.svg';
import { useStyle } from './HeadTH.style';

function HeadTH({ title, withSorting, setSortType, onSort, onClick }) {
  const { classes, cx } = useStyle();

  const handleClick = useCallback(() => {
    if(onSort) {
      onSort()
    }

    if(onClick) {
      onClick()
    }
  }, [onClick, onSort]);

  return (
    <UnstyledButton
      onClick={handleClick}
      className={cx(classes.button, `table-header__${title.replace(' ', '_')}`)}
    >
      <Flex align="center" gap={4} onClick={setSortType}>
        <CustomText variant="body2" color="#8C8B95">
          {title}
        </CustomText>
        {withSorting && <LogoIcon style={{ width: rem(8), height: rem(12) }} />}
      </Flex>
    </UnstyledButton>
  );
}

export default HeadTH;
