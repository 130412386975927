import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setGuide } from "../features/profileSlice";

import logger from "../utils/logger";

const useGuideModal = (key, stepIndex) => {
    const dispatch = useDispatch();

    const handleGuideModalClick = useCallback((index) => {
      logger.debug(index, Number.isInteger(index));
      dispatch(setGuide({
          key,
          value: {
            guideModalIndex: null,
            stepIndex: Number.isInteger(index) ? index : stepIndex
          },
      }));
    }, [dispatch]);

  return { handleGuideModalClick };
};

export default useGuideModal;
