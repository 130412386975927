import { createStyles, rem } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  header: {
    height: rem(64),
    width: `calc(100vw - ${rem(217)})`,
    borderBottom: `${rem(1)} solid ${theme.colors.gray[3]}`,
    marginLeft: 'auto',
    padding: `0 ${rem(40)}`,

    '& .profile-icon': {
      borderRadius: '50%',
    },
  },
}));
