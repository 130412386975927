import React from 'react'
import { useCallback } from 'react';
import { useMemo } from 'react';
import { convertRemToPixels } from '../../../utils';
import { useStyle } from '../TopicsPage.style';
import { ReactComponent as ChartTopLeftIcon } from '../../../assets/icons/top-left-chart-icon.svg';
import { ReactComponent as ChartTopRightIcon } from '../../../assets/icons/top-right-chart-icon.svg';
import { ReactComponent as ChartBottomLeftIcon } from '../../../assets/icons/bottom-left-chart-icon.svg';
import { ReactComponent as ChartBottomRightIcon } from '../../../assets/icons/bottom-right-chart-icon.svg';
import { Box, Stack } from '@mantine/core';
import CustomText from '../../../components/Typography/CustomText/CustomText';

const ChartLabels = ({ label, x, y, isActive }) => {
  const audienceChartRadius = useMemo(() => convertRemToPixels(12.125), []);
  const { classes, cx } = useStyle();

  const checkLabelPosition = (returnValues) => {
    switch (true) {
      case x > audienceChartRadius && y <= audienceChartRadius:
        return returnValues[0];
      case x > audienceChartRadius && y > audienceChartRadius:
        return returnValues[1];
      case x <= audienceChartRadius && y <= audienceChartRadius:
        return returnValues[2];
      case x < audienceChartRadius && y > audienceChartRadius:
        return returnValues[3];
    }
  };

  const getIcon = useCallback(
    () =>
      checkLabelPosition([
        <ChartTopRightIcon
          key="chart-top-right"
          className={cx(classes.chartLabelIcon, classes.chartLabelIconRight)}
        />,
        <ChartBottomRightIcon
          key="chart-bottom-right"
          className={cx(
            classes.chartLabelIcon,
            classes.chartLabelIconBottom,
            classes.chartLabelIconRight,
          )}
        />,
        <ChartTopLeftIcon
          key="chart-top-left"
          className={cx(classes.chartLabelIcon, classes.chartLabelIconLeft)}
        />,
        <ChartBottomLeftIcon
          key="chart-bottom-left"
          className={cx(
            classes.chartLabelIcon,
            classes.chartLabelIconBottom,
            classes.chartLabelIconLeft,
          )}
        />,
      ]),
    [x, y],
  );

  const getLabelsOffset = useCallback(
    () =>
      checkLabelPosition([
        'translate(0, -100%)',
        'translate(0%, 0%)',
        'translate(-100%, -100%)',
        'translate(-100%, 0)',
      ]),
    [x, y],
  );

  return (
    <Box
      sx={{
        width: '3.875rem',
        height: '2.813rem',
        position: 'absolute',
        left: x,
        top: y,
        transform: getLabelsOffset(),
      }}
    >
      <Stack spacing={8}>
        <CustomText
          variant="h6"
          sx={(theme) => ({
            fontWeight: 700,
            textAlign: x > audienceChartRadius ? 'end' : 'start',
            padding: `0 ${x > audienceChartRadius ? '0.5rem' : 0} 0.5rem ${
              x <= audienceChartRadius ? '0.5rem' : 0
            }`,
            color: !isActive && theme.colors.gray[4],
          })}
        >
          {`${label}%`}
        </CustomText>
        {getIcon()}
      </Stack>
    </Box>
  );
};

export default ChartLabels;
