import { useCallback, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useRadarApi from '../../../hooks/useRadarApi';
import { serializeParams } from '../../../utils';
import { TABS } from '../constant';
import { convertRangeParam, isUrlEmpty } from '../utils';

const useFetchTableData = (
  jwtTokenLoading,
  urlParams,
  preventRefetch,
  setPreventRefetch,
  filterOptions,
  setFilterOptions,
  updateFilterOptions,
  activeTab,
  contentType,
  updateSelection,
  selectedListsId,
) => {
  const { getContacts, getAccounts } = useRadarApi();
  const fetchFunction = contentType === 'accounts' ? getAccounts : getContacts;
  const [data, setData] = useState();
  const [dataContent, setDataContent] = useState();
  const [maxDataLength, setMaxDataLength] = useState(null);
  const [offset, setOffset] = useState(0);
  const [fetchContent, loading] = useFetch(fetchFunction);

  const fetchWrapper = useCallback(
    async (fetchMore, defaultParams, callback, updateData = false) => {
      if (preventRefetch && !fetchMore) {
        setPreventRefetch(false);
        return;
      }

      if (!fetchMore) {
        setOffset(0);
      }
      const data = await fetchContent(
        defaultParams || {
          ...serializeParams({
            ...urlParams,
            ...convertRangeParam('insights', urlParams),
            ...convertRangeParam('contacts', urlParams),
            ...(selectedListsId?.length
              ? {
                  'filter[listId]': selectedListsId,
                }
              : {}),
            tab: [],
          }),
          offset: fetchMore ? offset : 0,
          limit: 15,
        },
        fetchMore,
      );
      if (fetchMore) {
        setDataContent((prevState) => prevState.concat(data?.content));
      } else if (!defaultParams || updateData) {
        setData(data);
        setDataContent(data?.content);
      }
      if (
        defaultParams ||
        filterOptions[contentType === 'accounts' ? 1 : 0].options.length === 0
      ) {
        updateFilterOptions(
          data?.distinctValues,
          setFilterOptions,
          contentType,
        );
      }
      if (maxDataLength === null) {
        setMaxDataLength(data?.totalItems);
      }

      if (callback) {
        callback();
      }
    },
    [
      fetchContent,
      fetchFunction,
      urlParams,
      preventRefetch,
      offset,
      filterOptions,
      updateFilterOptions,
      selectedListsId,
    ],
  );
  useEffect(() => {
    if (
      !jwtTokenLoading &&
      activeTab === TABS[contentType === 'accounts' ? 1 : 0]
    ) {
      if (
        !isUrlEmpty(urlParams) &&
        (filterOptions[0].options.length ?? 0) === 0
      ) {
        fetchWrapper(false, {}, fetchWrapper);
      } else {
        fetchWrapper();
        updateSelection([]);
      }
    }
  }, [jwtTokenLoading, JSON.stringify(urlParams)]);

  useEffect(() => {
    if (!offset) return;

    fetchWrapper(true);
  }, [offset]);

  return [
    data,
    dataContent,
    loading,
    maxDataLength,
    offset,
    setOffset,
    fetchWrapper,
  ];
};

export default useFetchTableData;
